import axiosInstance from "../../interceptor/interceptor-instance"

export const createAppointmentEMR = (data) => {
    return axiosInstance.post(`doctor/appointment/emr/create`, data);
}

export const addAllergyEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/allergies', data);
};

export const addVaccinationEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/vaccination', data);
};
