import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import dummyImg from "../../assets/images/MyImages/no_image.png";
import { toast } from "react-toastify";
import TopHeader from "../../components/topHeader";
import { addPatient } from "./services/patient-service";
import Loader from "../../components/loader";
// import ImageLoader from "../../components/imageLoader";

export default function AddPatient(props) {
    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [phoneCode, setPhoneCode] = useState('');
    const [fullPhone, setFullPhone] = useState('');
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        nationalID: '',
        phone: '',
        email: '',
        // password: '',
        dob: '',
        gender: '',
        age: '',
        bloodGroup: '',
        address: '',
        imageFile: null,
    });
    // const [togglePassword, setTogglePassword] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Get the current date in the format required by the date input element
    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
    };

    // Handle phone number change
    const handlePhoneChange = (value, country) => {
        const { dialCode } = country;
        if (value.startsWith(`${dialCode}`)) {
            const phoneNumber = value.slice(dialCode.length).trim();
            const countryCode = `+${dialCode}`
            setPhoneCode(countryCode)
            setFormValues({ ...formValues, phone: phoneNumber });
            setFormErrors({ ...formErrors, phone: "" });
            setFullPhone(`${countryCode}${phoneNumber}`)
            // console.log(phoneCode)
            // console.log(phoneNumber)
            // console.log(fullPhone)
        } else {
            const phoneNumber = value;
            const countryCode = `+${dialCode}`
            console.log(countryCode)
            console.log(phoneNumber)
            setPhoneCode(countryCode)
            setFormValues({ ...formValues, phone: phoneNumber });
            setFormErrors({ ...formErrors, phone: "" });
            setFullPhone(`${countryCode}${phoneNumber}`);
        }
    };

    // Function to handle image changes
    const handleImageChange = (e) => {
        console.log('handleImageChange called');
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            console.log('File selected:', file);
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/bpg', 'image/bmp', 'image/heic', 'image/heif'];

            // Check if the selected file type is allowed
            if (allowedTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImageSource(e.target.result); // Set the image source to the selected image
                    console.log('Image source updated:', e.target.result);
                };
                reader.readAsDataURL(file);
                setImageFile(file);
                console.log('File set:', file);
            } else {
                // Show an error toast message if the file type is not allowed
                toast.error("File type is not allowed. Only png, jpg, jpeg, tiff, bpg, bmp, heic, and heif files are allowed.");

                // Reset the image file and image source to the default image
                setImageSource(dummyImg);
                setImageFile(null);

                // Clear the file input
                e.target.value = ""; // Set the file input value to an empty string to remove the chosen file

                console.log('File type not allowed');
            }
        }
    };

    // Handle form submission
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const errors = {};

        // Validation for required fields
        if (!formValues.firstName.trim()) {
            errors.firstName = "First Name is required.";
        }
        if (!formValues.lastName.trim()) {
            errors.lastName = "Last Name is required.";
        }
        if (!formValues.nationalID.trim()) {
            errors.nationalID = "National ID is required.";
        }
        if (!formValues.phone) {
            errors.phone = "Phone Number is required.";
        }
        if (!formValues.email.trim()) {
            errors.email = "Email is required.";
        }
        // if (!formValues.password.trim()) {
        //     errors.password = "Password is required.";
        // }
        if (!formValues.dob.trim()) {
            errors.dob = "Date of Birth is required.";
        }
        if (new Date(formValues.dob) > new Date()) {
            errors.dob = "Date of Birth cannot be in the future.";
        }
        if (!formValues.gender) {
            errors.gender = "Gender is required.";
        }
        if (!formValues.age.trim()) {
            errors.age = "Age is required.";
        }
        if (!formValues.bloodGroup.trim()) {
            errors.bloodGroup = "Blood Group is required.";
        }
        if (!formValues.address.trim()) {
            errors.address = "Address is required.";
        }

        setFormErrors(errors);

        // If there are no errors, proceed with form submission logic
        if (Object.keys(errors).length === 0) {
            console.log("Form submitted:", formValues); // Debug log
            // Perform form submission logic here
            const formData = new FormData();
            formData.append('first_name', formValues.firstName);
            formData.append('last_name', formValues.lastName);
            formData.append('email', formValues.email);
            formData.append('phone_code', phoneCode);
            formData.append('mobile', formValues.phone);
            formData.append('country_code', countryCode);
            formData.append('gender', formValues.gender);
            // formData.append('country', formValues.country);
            // formData.append('city', formValues.city);
            formData.append('address', formValues.address);
            formData.append('dob', formValues.dob);
            formData.append('age', formValues.age);
            formData.append('blood_group', formValues.bloodGroup);
            if (imageFile) {
                formData.append('picture', imageFile, imageFile.name);
            }
            try {
                setLoading(true);
                console.log("Sending API request with data:", formData); // Debug log
                const response = await addPatient(formData);
                console.log("API response:", response); // Debug log
                if (response.data.status === 'success') {
                    toast.success(response.data.message);
                    navigate('/landing');
                    setLoading(false);
                } else {
                    console.error('Error in response:', response);
                    toast.error('Error in submitting the form.');
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    // State variables for image handling
    const [imageFile, setImageFile] = useState(null);
    const [imageSource, setImageSource] = useState(dummyImg);

    const navigate = useNavigate();

    const backToList = () => {
        navigate("/landing");
    };

    return (

        <>
        <Loader loading={loading} />
            <div className={`${toggle ? '' : 'toggled'} page-wrapper doctris-theme`}>
                <main className="page-content bg-light">
                    <TopHeader toggle={toggle} setToggle={setToggle} />
                    {props.children}
                </main>
                <div className="container-fluid" style={{ marginTop: '6rem' }}>
                    <div className="layout-specing">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-0 p-4 shadow">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mb-4">
                                            <div className="col-md-12">
                                                <h5 className="mb-0 custom-text-shadow">Add A Patient</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* First Name Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">First Name <span className="text-danger">*</span></label>
                                                    <input
                                                        name="firstName"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter First Name"
                                                        value={formValues.firstName}
                                                        onChange={handleInputChange}
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.firstName && <div className="text-danger">{formErrors.firstName}</div>}
                                                </div>
                                            </div>

                                            {/* Last Name Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                                    <input
                                                        name="lastName"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Last Name"
                                                        value={formValues.lastName}
                                                        onChange={handleInputChange}
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.lastName && <div className="text-danger">{formErrors.lastName}</div>}
                                                </div>
                                            </div>
                                            {/* national ID Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">National ID <span className="text-danger">*</span></label>
                                                    <input
                                                        name="nationalID"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter National ID"
                                                        value={formValues.nationalID}
                                                        onChange={handleInputChange}
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.nationalID && <div className="text-danger">{formErrors.nationalID}</div>}
                                                </div>
                                            </div>

                                            {/* Email Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Email <span className="text-danger">*</span></label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        value={formValues.email}
                                                        onChange={handleInputChange}
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                                                </div>
                                            </div>

                                            {/* Password Field */}
                                            {/* <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Password <span className="text-danger">*</span></label>
                                                <div className="position-relative">
                                                    <input
                                                        type={togglePassword ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        autoComplete="current-password"
                                                        value={formValues.password}
                                                        onChange={handleInputChange}
                                                        name="password"
                                                        style={{borderRadius: "50px"}}
                                                        required=""
                                                    />
                                                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                                        {togglePassword ? (
                                                            <i className="mdi mdi-eye-off"></i>
                                                        ) : (
                                                            <i className="mdi mdi-eye"></i>
                                                        )}
                                                    </div>
                                                </div>
                                                {formSubmitted && formErrors.password && <div className="text-danger">{formErrors.password}</div>}
                                            </div>
                                        </div> */}



                                            {/* Phone Number Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                                    <PhoneInput
                                                        country="in"
                                                        value={fullPhone}
                                                        onChange={handlePhoneChange}
                                                        containerClassName="phone-input-contained"
                                                        enableSearch
                                                        searchPlaceholder="Search your country"
                                                        searchNotFound="Country not found"
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.phone && <div className="text-danger">{formErrors.phone}</div>}
                                                </div>
                                            </div>

                                            {/* Date of Birth Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={formValues.dob}
                                                        onChange={handleInputChange}
                                                        name="dob"
                                                        max={getCurrentDate()} // Setting max to current date to restrict future dates
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.dob && <div className="text-danger">{formErrors.dob}</div>}
                                                </div>
                                            </div>

                                            {/* Gender Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Gender <span className="text-danger">*</span></label>
                                                    <select
                                                        className="form-select form-control"
                                                        value={formValues.gender}
                                                        onChange={handleInputChange}
                                                        name="gender"
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                    {formSubmitted && formErrors.gender && <div className="text-danger">{formErrors.gender}</div>}
                                                </div>
                                            </div>

                                            {/* Age Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Age <span className="text-danger">*</span></label>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        className="form-control"
                                                        placeholder="Enter Your Age"
                                                        value={formValues.age}
                                                        onChange={handleInputChange}
                                                        name="age"
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.age && <div className="text-danger">{formErrors.age}</div>}
                                                </div>
                                            </div>

                                            {/* Blood Group Field */}
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Blood Group <span className="text-danger">*</span></label>
                                                    <input
                                                        name="bloodGroup"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Blood Group"
                                                        value={formValues.bloodGroup}
                                                        onChange={handleInputChange}
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                    />
                                                    {formSubmitted && formErrors.bloodGroup && <div className="text-danger">{formErrors.bloodGroup}</div>}
                                                </div>
                                            </div>

                                            {/* Profile Image Field */}
                                            <div className="col-md-3 mb-3">
                                                <label className="form-label">Profile Image </label>
                                                <input className="form-control" type="file" onChange={handleImageChange}
                                                    style={{ borderRadius: "50px" }} />

                                            </div>

                                            {/* Profile Image Preview */}
                                            <div className="col-md-1">
                                                <img
                                                    src={imageSource}
                                                    style={{ objectFit: 'contain' }}
                                                    height="75px"
                                                    width="75px"
                                                    alt="Patient Profile"
                                                />
                                            </div>

                                            {/* Address Preview */}
                                            <div className="col-md-8">
                                                <div className="mb-3">
                                                    <label className="form-label">Address <span className="text-danger">*</span></label>
                                                    <textarea name="address" id="address" rows="2" className="form-control"
                                                        onChange={handleInputChange}
                                                        style={{ borderRadius: "50px" }}
                                                        required=""
                                                        value={formValues.address}></textarea>
                                                    {formSubmitted && formErrors.address && <div className="text-danger">{formErrors.address}</div>}
                                                </div>
                                            </div>
                                        </div>


                                        {/* Form submission buttons */}
                                        <div className="row mt-3" style={{ justifyContent: 'end' }}>
                                            <div className="col-md-2">
                                                <button type="submit" className="btn btn-primary w-100">Submit</button>
                                            </div>
                                            <div className="col-md-2">
                                                <button type="button" className="btn btn-danger w-100" onClick={backToList}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
