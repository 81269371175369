import React, { useEffect } from 'react';
import { ZoomVideo } from '@zoom/videosdk';
import CryptoJS from 'crypto-js';

const ZoomMeeting = ({ apiKey, apiSecret, meetingNumber, userName, sessionName }) => {
    useEffect(() => {
        const initZoomSDK = async () => {
            try {
                console.log('Initializing Zoom Video SDK...');

                // Wait for ZoomVideo to be defined
                while (!ZoomVideo) {
                    await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms
                    console.log('ZoomVideo is not defined yet. Waiting...');
                }

                const client = ZoomVideo.createClient();
                console.log('Zoom Video SDK client created.');

                const signature = generateSignature(apiKey, apiSecret, meetingNumber);
                console.log('Generated Signature:', signature);

                await client.init('en-US', 'https://source.zoom.us/videosdk/1.11.6');
                console.log('Zoom Video SDK initialized successfully.');

                await client.join(sessionName, signature, userName, '');
                console.log('Session joined successfully.');
            } catch (error) {
                console.error('Zoom Video SDK initialization failed:', error);
            }
        };

        initZoomSDK();
    }, [apiKey, apiSecret, meetingNumber, userName, sessionName]);

    function generateSignature(apiKey, apiSecret, meetingNumber) {
        const timestamp = new Date().getTime() - 30000;
        const msg = Buffer.from(apiKey + meetingNumber + timestamp + '1').toString('base64');
        const hash = CryptoJS.HmacSHA256(msg, apiSecret).toString(CryptoJS.enc.Base64);
        const signature = `${apiKey}.${meetingNumber}.${timestamp}.1.${hash}`;
        console.log(signature);
        return signature;
    }

    return (
        <div className="zoom-meeting-container">
            <p>Zoom Meeting Loading...</p>
        </div>
    );
};

export default ZoomMeeting;
