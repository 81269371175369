import React, {useState} from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { doctorData } from "../../data/data";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiEye, FiEdit, FiUserCheck, FiUserX, FiSearch, LiaTimesSolid, RiFileListFill } from "../../assets/icons/vander"

export default function Doctors() {

     // State to store the search input value
     const [searchValue, setSearchValue] = useState('');

     // Event handler to clear the search input field
     const handleClear = () => {
         setSearchValue('');
     };
 
     // Event handler to update the search input value
     const handleSearchChange = (event) => {
         setSearchValue(event.target.value);
     };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 p-4 rounded shadow">

                                <div className="row">
                                    <div className="col-xl-9 col-md-6">
                                        <h5 className="mb-0 custom-text-shadow">Doctors</h5>

                                        <nav aria-label="breadcrumb" className="d-inline-block mt-2">
                                            <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                                                <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Doctors</li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                        <Link to="/add-doctor" className="btn btn-primary">Add A Doctor</Link>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <div className="col-lg-10 col-md-9 col-sm-9 mt-2">
                                        <input
                                            type="text"
                                            className="form-control border rounded-pill"
                                            style={{ lineHeight: 2 }}
                                            name="s"
                                            id="s"
                                            placeholder="Search..."
                                            value={searchValue} // Bind the input value to the searchValue state
                                            onChange={handleSearchChange} // Update searchValue on input change
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mt-2 d-flex justify-content-start align-items-center" style={{ gap: '18px' }}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Search</Tooltip>}
                                        >
                                            <button className="btn btn-icon btn-pills btn-primary" style={{ height: '45px', width: '45px' }}>
                                                <FiSearch className="icons" />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Clear</Tooltip>}
                                        >
                                            <button
                                                className="btn btn-icon btn-pills btn-danger"
                                                style={{ height: '45px', width: '45px' }}
                                                onClick={handleClear} // Attach the handleClear function to the button's onClick event
                                            >
                                                <LiaTimesSolid className="icons" />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="row row-cols-md-2 row-cols-lg-5">
                                    {doctorData.map((item, index) => {
                                        return (
                                            <div className="col mt-4" key={index}>
                                                <div className="card team border-0 rounded shadow overflow-hidden">
                                                    <div className="team-img position-relative">
                                                        <img src={item.image} className="img-fluid" alt="" />
                                                        <ul className="list-unstyled team-social mb-0">
                                                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">View</Tooltip>}>
                                                                <li><Link to="/view-doctor" className="btn btn-icon btn-pills btn-soft-primary"><FiEye className="icons" /></Link></li>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}>
                                                                <li className="mt-2"><Link to="/edit-doctor" className="btn btn-icon btn-pills btn-soft-primary"><FiEdit className="icons" /></Link></li>
                                                            </OverlayTrigger>
                                                            {/* <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Active</Tooltip>}>
                                                                <li className="mt-2"><Link to="#" className="btn btn-icon btn-pills btn-soft-success"><FiUserCheck className="icons" /></Link></li>
                                                            </OverlayTrigger> */}
                                                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Inactive</Tooltip>}>
                                                                <li className="mt-2"><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiUserX className="icons" /></Link></li>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Appointments</Tooltip>}>
                                                                <li className="mt-2"><Link to="/doctor-appointment" className="btn btn-icon btn-pills btn-soft-success"><RiFileListFill className="icons" /></Link></li>
                                                            </OverlayTrigger>
                                                        </ul>
                                                    </div>
                                                    <div className="card-body content text-center">
                                                        <Link to={`/view-doctor/${item.id}`} className="title text-dark h5 d-block mb-0">{item.name}</Link>
                                                        {/* <Link to={`/view-doctor/${item.id}`} className="title text-dark h5 d-block mb-0">{item.name} <FiUserCheck className="icons mb-1" style={{color: '#00ff95'}} /></Link> */}
                                                        <small className="text-muted speciality">{item.title}</small><br />
                                                        <span className="badge bg-soft-success" style={{color: '#00ff95'}}>Active</span>
                                                        <div className="mt-2">
                                                        <Link to="/doctor-availability" style={{padding: "6px 20px", fontSize: "12px"}} className="btn btn-dark">Availability</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="row text-center">
                                    <div className="col-12 mt-4">
                                        <div className="d-md-flex align-items-center text-center justify-content-between">
                                            <span className="text-muted me-3">Showing 1 - 10 out of 50</span>
                                            <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                                <li className="page-item"><Link className="page-link" to="" aria-label="Previous">Prev</Link></li>
                                                <li className="page-item active"><Link className="page-link" to="">1</Link></li>
                                                <li className="page-item"><Link className="page-link" to="">2</Link></li>
                                                <li className="page-item"><Link className="page-link" to="">3</Link></li>
                                                <li className="page-item"><Link className="page-link" to="" aria-label="Next">Next</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}