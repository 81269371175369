import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import SimpleBar from "simplebar-react";
import Wrapper from "../../components/wrapper";
import Modal from 'react-bootstrap/Modal';
import { FiEdit, FiMessageCircle, FiVideo, FiVideoOff, RiCloseFill } from '../../assets/icons/vander';
import consultationImg1 from '../../assets/images/tab-icons/consultation1.png'
import consultationImg2 from '../../assets/images/tab-icons/consultation2.png'
import allergyImg from '../../assets/images/tab-icons/allergy.png'
import VaccinationImg from '../../assets/images/tab-icons/vaccination.png'
import labReportImg from '../../assets/images/tab-icons/lab.png'
import DocumentsImg from '../../assets/images/tab-icons/document.png'
import prescriptionImg from '../../assets/images/tab-icons/medicine.png'
// import vitalsImg from '../../assets/images/svg/Vital_0.svg'
import procedureImg from '../../assets/images/tab-icons/process.png'
import imagingReportImg from '../../assets/images/tab-icons/imaging.png'
import referImg from '../../assets/images/tab-icons/refer.png'
import downArrowImg from '../../assets/images/tab-icons/down-arrow.png'
import upArrowImg from '../../assets/images/tab-icons/up-arrow.png'
import { LiaFile, RiAddFill, RiDeleteBinFill, RiArrowUpFill, RiArrowDownFill } from "../../assets/icons/vander";
import { useTranslation } from 'react-i18next';
import dummyDocument from '../../assets/images/MyImages/documents.png';
import { format, getTime, getYear } from "date-fns";
import { addAllergyEMR, addDocumentsEMR, addImagingEMR, addLabReportsEMR, addVaccinationEMR, createAppointmentEMR, doctorReferPatient, patientContactHistory } from "./services/patient-dashboard-service"
import Loader from "../../components/loader";
import { toast } from "react-toastify";
import axiosInstance from "../../interceptor/interceptor-instance";
import { BaseurlForImage } from "../../environments/environments";
import errorImage from "../../assets/images/doctors/blank_profile.png"
import EllipsisTooltip from "../../components/EllipsisTooltip";
import EllipsisTooltipAddress from "../../components/EllipsisTooltipAddress";
import PersonChat from "../../components/personChat";
import noChatIcon from '../../assets/images/MyImages/refuse.png';
import ZoomMeeting from "../../components/zoom-call";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Offcanvas } from 'bootstrap'; // Import Bootstrap's Offcanvas
import 'simplebar-react/dist/simplebar.min.css';
export default function Index() {
    const { t } = useTranslation(); // Translation
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const setTime = [
        { time: "00:00" }, { time: "00:30" }, { time: "01:00" }, { time: "01:30" }, { time: "02:00" },
        { time: "02:30" }, { time: "03:00" }, { time: "03:30" }, { time: "04:00" }, { time: "04:30" },
        { time: "05:00" }, { time: "05:30" }, { time: "06:00" }, { time: "06:30" }, { time: "07:00" },
        { time: "07:30" }, { time: "08:00" }, { time: "08:30" }, { time: "09:00" }, { time: "09:30" },
        { time: "10:00" }, { time: "10:30" }, { time: "11:00" }, { time: "11:30" }, { time: "12:00" },
        { time: "12:30" }, { time: "13:00" }, { time: "13:30" }, { time: "14:00" }, { time: "14:30" },
        { time: "15:00" }, { time: "15:30" }, { time: "16:00" }, { time: "16:30" }, { time: "17:00" },
        { time: "17:30" }, { time: "18:00" }, { time: "18:30" }, { time: "19:00" }, { time: "19:30" },
        { time: "20:00" }, { time: "20:30" }, { time: "21:00" }, { time: "21:30" }, { time: "22:00" },
        { time: "22:30" }, { time: "23:00" }, { time: "23:30" },
    ];


    // const location = useLocation();
    // const { appointmentData } = location.state || {};
    // console.log(appointmentData); // Check if appointmentData is correctly logged here

    // Use useEffect to set appointmentData only once when the component mounts
    const location = useLocation();
    const [appointmentData, setAppointmentData] = useState(localStorage.getItem('patientData'));

    // useEffect(() => {
    //     if (location.state && location.state.appointmentData) {
    //         setAppointmentData(location.state.appointmentData);
    //         console.log(location.state.appointmentData);
    //     }
    // }, [location.state]);

    // const handleTabClick = (index) => {
    //     setActiveIndex(index);
    // };

    const [formValues, setFormValues] = useState({
        consultationDate: '',
        startTime: '',
        endTime: '',
        consultationType: '',
        consultationComments: '',
        problem: '',
        category: '',
        type: '',
        history: '',
        Temperature: '',
        heartRate: '',
        respiratoryRate: '',
        bloodOxygen: '',
        physicalExamination: '',
        problemStartDate: '',
        problemEndDate: '',
    });

    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
    };

    const safeFormatDOB = (date) => {
        if (isValidDate(new Date(date))) {
            return formatDOB(date);
        } else {
            return 'N/A';
        }
    };

    const formatDOB = (dob) => {
        return format(new Date(dob), 'dd MMM yyyy');
    };

    function formatContactHistoryDate(dateString) {
        const options = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        };

        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    }


    const [contactHistory, setContactHistory] = useState({});
    const [systolic, setSystolic] = useState('');
    const [diastolic, setDiastolic] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [problems, setProblems] = useState([]);
    const [medications, setMedications] = useState([]);
    const [medicineDosage, setMedicineDosage] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [anaesthesia, setAnaesthesia] = useState([]);
    const [testName, setTestName] = useState([]);
    const [selectedLabTests, setSelectedLabTests] = useState([]);
    const [documentType, setDocumentType] = useState([]);
    const [specialty, setSpecialty] = useState([]);
    const [imaging, setImaging] = useState([]);
    const [imagingType, setImagingType] = useState([]);

    useEffect(() => {
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        const patientAppointmentType = JSON.parse(localStorage.getItem('patientAppointmentType'));
        // console.log(appointmentData);
        if (appointmentData && patientAppointmentType === 'appointment') {
            console.log(appointmentData.start_time);
            setFormValues({
                // consultationDate: format(new Date(appointmentData.appointment.consulation_date), 'yyyy-MM-dd'),
                // startTime: format(new Date(`01/01/2000 ${appointmentData.start_time}`), 'HH:mm'),
                // endTime: format(new Date(`01/01/2000 ${appointmentData.end_time}`), 'HH:mm'),
                // consultationType: appointmentData.appointment.consulation_type,
                // consultationComments: appointmentData.appointment.consulation_reason,
                consultationDate: format(new Date(appointmentData.consulation_date), 'yyyy-MM-dd'),
                startTime: format(new Date(`01/01/2000 ${appointmentData.start_time}`), 'HH:mm'),
                endTime: format(new Date(`01/01/2000 ${appointmentData.end_time}`), 'HH:mm'),
                consultationType: appointmentData.consulation_type,
                consultationComments: appointmentData.consulation_reason,
                problem: '',
                category: '',
                type: '',
                history: '',
                Temperature: '',
                heartRate: '',
                respiratoryRate: '',
                bloodOxygen: '',
                physicalExamination: '',
                problemStartDate: '',
                problemEndDate: '',
            });
        } else if (appointmentData && patientAppointmentType === 'non-appointment') {
            console.log("Else If condition Call");
            setFormValues({
                consultationDate: getTodayDate(),
                startTime: '',
                endTime: '',
                consultationType: '',
                consultationComments: '',
                problem: '',
                category: '',
                type: '',
                history: '',
                Temperature: '',
                heartRate: '',
                respiratoryRate: '',
                bloodOxygen: '',
                physicalExamination: '',
                problemStartDate: '',
                problemEndDate: '',
            });
        } else {
            navigate("/landing");
        }

        if (appointmentData && appointmentData?._id) {
            setTimeout(() => {
                getPatientContactHistory(appointmentData._id);
                getProblemMaster();
                getMedicationMaster();
                getMedicineDosageMaster();
                getInterventionsMaster();
                getAnaesthesiaMaster();
                // getAllergyMaster();
                getTestNameMaster();
                getDocumentTypeMaster();
                getSpecialtyMaster();
                getImagingMaster();
                getImagingTypeMaster();
                getReferLabTestNameMaster();
                getReferImagingMaster();
                getReferImagingTypeMaster();
                getReferDoctorMaster();
                getReferProcedureMaster();
            }, 300);
        }
    }, [navigate]);

    // Get Contact History Details
    const getPatientContactHistory = async (patientId) => {
        const data = {
            patient_id: patientId,
        };
        // console.log(patientId);
        setLoading(true);
        try {
            const response = await patientContactHistory(data);
            // console.log(response.data.data);
            // console.log(response.data.data.contact_history);
            if (response.data.status === 'success') {
                setContactHistory(response.data.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
        setLoading(false);
    };

    // Problem Master
    const getProblemMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/problems`, '');
            if (response.data.status === 'success') {
                setProblems(response.data.data.problems);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Medication Master
    const getMedicationMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/medications`, '');
            if (response.data.status === 'success') {
                setMedications(response.data.data.medications);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // medicine dosage Master
    const getMedicineDosageMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/medicine-dosage`, '');
            if (response.data.status === 'success') {
                setMedicineDosage(response.data.data.medicine_dosage);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Procedure Master
    const getInterventionsMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/interventions`, '');
            if (response.data.status === 'success') {
                setInterventions(response.data.data.interventions);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Procedure Master
    const getAnaesthesiaMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/anaesthesias`, '');
            if (response.data.status === 'success') {
                setAnaesthesia(response.data.data.anaesthesias);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Test Name Master
    const getTestNameMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/labprofiles`, '');
            if (response.data.status === 'success') {
                setTestName(response.data.data.labprofiles);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Document Type Master
    const getDocumentTypeMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/document-type`);
            if (response.data.status === 'success') {
                // console.log(response.data.data.document_types);
                setDocumentType(response.data.data.document_types);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // Optionally, show a toast notification or some form of user feedback
            // toast.error('Failed to fetch document types');
        }
    };
    // Specialty Master
    const getSpecialtyMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/specialities`, '');
            if (response.data.status === 'success') {
                setSpecialty(response.data.data.specialities);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Imaging Master
    const getImagingMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/radiologies`, '');
            if (response.data.status === 'success') {
                setImaging(response.data.data.radiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };
    // Imaging Type Master
    const getImagingTypeMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/subradiologies`, '');
            if (response.data.status === 'success') {
                setImagingType(response.data.data.subradiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    let [activeIndex, setActiveIndex] = useState(1);
    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    // Function to get today's date in the format yyyy-mm-dd
    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Function to handle input changes and update form values
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Function to handle input changes and update state
    const handleInputChange2 = (event) => {
        const { name, value } = event.target;
        if (name === "systolic") {
            setSystolic(value);
        } else if (name === "diastolic") {
            setDiastolic(value);
        }
    };

    const handleTypeChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

        // Reset start and end dates if type changes
        if (value !== "past") {
            setFormValues(prevState => ({
                ...prevState,
                problemStartDate: "",
                problemEndDate: ""
            }));
        }
    };

    // Function to validate the form
    const validateForm = () => {
        const errors = {};
        const systolicRegex = /^\d{1,3}$/; // Allow only numbers up to 3 digits
        const diastolicRegex = /^\d{1,3}$/; // Allow only numbers up to 3 digits

        // Validate systolic input
        // if (!systolicRegex.test(systolic)) {
        //     errors.systolic = "Enter only numbers";
        // }

        // // Validate diastolic input
        // if (!diastolicRegex.test(diastolic)) {
        //     errors.diastolic = "Enter only numbers";
        // }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);

        if (validateForm()) {
            // Form submission logic
            console.log("Form submitted successfully with values:", {
                systolic,
                diastolic,
            });

            // Reset form values after successful submission
            setSystolic("");
            setDiastolic("");
            setFormErrors({});
            setFormSubmitted(false);
        }
    };

    // Function to reset the form values and errors
    // const consultationResetForm = () => {
    //     setFormValues({
    //         consultationDate: getTodayDate(), // Initialize consultationDate with today's date
    //         consultationTime: '',
    //         consultationType: '',
    //         consultationComments: '',
    //         problem: '',
    //         category: '',
    //         type: '',
    //         history: '',
    //         Temperature: '',
    //         heartRate: '',
    //         respiratoryRate: '',
    //         bloodOxygen: '',
    //         physicalExamination: '',
    //     });
    //     setFormErrors({});
    //     setFormSubmitted(false);
    // };

    const formatPrescriptionData = (prescriptionList) => {
        return prescriptionList.map(prescription => ({
            medication_name: prescription.medication,
            medication_id: prescription.medication_id,
            medicine_dosage: prescription.dose,
            medicine_dosage_id: prescription.medicine_dosage_id,
            medication_type: prescription.prescriptionType,
            quantity: prescription.quantity,
            instruction: prescription.comment
        }));
    };

    const formatProcedureData = (procedureFormData) => {
        return {
            intervention_id: procedureFormData.intervention_id,
            intervention_name: procedureFormData.procedure,
            pre_diagnosis: procedureFormData.preOpDiagnosis,
            post_diagnosis: procedureFormData.postOpDiagnosis,
            surgeon: procedureFormData.surgeon,
            assistant: procedureFormData.assistant,
            anaesthetist: procedureFormData.anaesthetist,
            anaesthesia_category_id: procedureFormData.anaesthesia_category_id,
            anaesthesia_category_name: procedureFormData.anaesthesia_category_name,
            description: procedureFormData.procedureDescription,
            findings: procedureFormData.findings,
            specimens: procedureFormData.specimens,
            tube_drain: procedureFormData.tubesDrainsPacks,
            complications: procedureFormData.complications,
            post_instruction: procedureFormData.postOpInstructions,
            intervention_date: procedureFormData.interventionDate,
            comment: procedureFormData.comment
        };
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setFormSubmitted(true);

    //     const errors = validateForm(formValues);
    //     if (Object.keys(errors).length === 0) {
    //         const formData = new FormData();
    //         formData.append('patient_id', appointmentData?._id);
    //         formData.append('appointment_id', appointmentData?.appointment_id);
    //         formData.append('consultation_for', formValues.consultationComments);
    //         formData.append('consultation_type', formValues.consultationType);

    //         // Check if formValues has temperature and heartRate
    //         if (formValues.Temperature && formValues.heartRate) {
    //             const vitalsData = {
    //                 temperature: formValues.Temperature,
    //                 heart_rate: formValues.heartRate,
    //                 blood_pressure: {
    //                     systolic: systolic,
    //                     diastolic: diastolic
    //                 },
    //                 respiratory_rate: formValues.respiratoryRate,
    //                 oxygen_saturation: formValues.bloodOxygen
    //             };
    //             const vitalsString = JSON.stringify(vitalsData);
    //             formData.append('vitals', vitalsString);
    //         }

    //         // Check if formValues has category and problem
    //         if (formValues.category && formValues.problem) {
    //             // Find the problem object from the problems array
    //             const problem = problems.find((problem) => problem.name === formValues.problem);

    //             // Prepare the base problem data
    //             const problemData = {
    //                 category: formValues.category,
    //                 type: formValues.type,
    //                 name: formValues.problem,
    //                 problem_id: problem ? problem._id : null,
    //             };

    //             // Conditionally add start_date and end_date if the type is "past"
    //             if (formValues.type === "past") {
    //                 problemData.start_date = formValues.problemStartDate;
    //                 problemData.end_date = formValues.problemEndDate;
    //             }

    //             // Stringify the problemData and append it to formData
    //             const problemString = JSON.stringify(problemData);
    //             formData.append('problems', problemString);
    //         }

    //         // Check if formValues has physicalExamination and history
    //         if (formValues.physicalExamination && formValues.history) {
    //             formData.append('physical_examination', formValues.physicalExamination);
    //             formData.append('history', formValues.history);
    //         }

    //         // Check if prescriptionList has data
    //         if (prescriptionList.length > 0) {
    //             const prescriptionData = formatPrescriptionData(prescriptionList);
    //             const prescriptionString = JSON.stringify(prescriptionData);
    //             formData.append('prescriptions', prescriptionString);
    //         }
    //         const procedureData = formatProcedureData(procedureFormValues);
    //         const procedureString = JSON.stringify(procedureData);
    //         formData.append('procedure', procedureString);
    //         formData.append('intervention_file', procedureFormValues.file, procedureFormValues.file.name);

    //         try {
    //             setLoading(true);
    //             const response = await createAppointmentEMR(formData);
    //             if (response.data.status === "success") {
    //                 console.log('Form submitted successfully:', response.data);
    //                 toast.success(response.data.message);
    //                 setLoading(false);
    //             }
    //         } catch (error) {
    //             setLoading(false);
    //             console.error('Error submitting form:', error);
    //         }
    //     } else {
    //         setLoading(false);
    //         setFormErrors(errors);
    //     }
    // };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        const errors = validateForm(formValues);
        if (Object.keys(errors).length === 0) {
            try {
                setLoading(true);
                const formData = new FormData();
                if (appointmentData?.appointment_id) {
                    formData.append('appointment_id', appointmentData?.appointment_id);
                }
                console.log(formValues.startTime);
                console.log(formValues.endTime);
                formData.append('patient_id', appointmentData?._id);
                formData.append('appointment_date', formValues.consultationDate);
                formData.append('consultation_for', formValues.consultationComments);
                formData.append('consultation_type', formValues.consultationType);
                formData.append('start_time', formValues.startTime);
                formData.append('end_time', formValues.endTime);
                // Check if formValues has temperature and heartRate
                if (formValues.Temperature && formValues.heartRate) {
                    const vitalsData = {
                        temperature: formValues.Temperature,
                        heart_rate: formValues.heartRate,
                        blood_pressure: {
                            systolic: systolic,
                            diastolic: diastolic
                        },
                        respiratory_rate: formValues.respiratoryRate,
                        oxygen_saturation: formValues.bloodOxygen
                    };
                    const vitalsString = JSON.stringify(vitalsData);
                    formData.append('vitals', vitalsString);
                }

                // Check if formValues has category and problem
                if (formValues.category && formValues.problem) {
                    const problem = problems.find((problem) => problem.name === formValues.problem);
                    const problemData = {
                        category: formValues.category,
                        type: formValues.type,
                        name: formValues.problem,
                        problem_id: problem ? problem._id : null,
                    };

                    // Conditionally add start_date and end_date if the type is "past"
                    if (formValues.type === "past") {
                        problemData.start_date = formValues.problemStartDate;
                        problemData.end_date = formValues.problemEndDate;
                    }

                    const problemString = JSON.stringify(problemData);
                    formData.append('problems', problemString);
                }

                // Check if formValues has physicalExamination and history
                if (formValues.physicalExamination && formValues.history) {
                    formData.append('physical_examination', formValues.physicalExamination);
                    formData.append('history', formValues.history);
                }

                // Check if prescriptionList has data
                if (prescriptionList.length > 0) {
                    // Filter out prescriptions with all empty fields
                    const validPrescriptions = prescriptionList.filter(prescription =>
                        (prescription.medication_name && prescription.medication_name.trim() !== "") ||
                        (prescription.medicine_dosage && prescription.medicine_dosage.trim() !== "") ||
                        (prescription.medication_type && prescription.medication_type.trim() !== "") ||
                        (prescription.quantity && prescription.quantity.trim() !== "") ||
                        (prescription.instruction && prescription.instruction.trim() !== "")
                    );

                    if (validPrescriptions.length > 0) {
                        const prescriptionData = formatPrescriptionData(validPrescriptions);
                        const prescriptionString = JSON.stringify(prescriptionData);
                        formData.append('prescriptions', prescriptionString);
                    }
                }

                // const procedureData = formatProcedureData(procedureFormValues);
                const procedureData = {
                    intervention_name: procedureFormValues?.procedure,
                    pre_diagnosis: procedureFormValues?.preOpDiagnosis,
                    post_diagnosis: procedureFormValues?.postOpDiagnosis,
                    surgeon: procedureFormValues?.surgeon,
                    assistant: procedureFormValues?.assistant,
                    anaesthetist: procedureFormValues?.anaesthetist,
                    description: procedureFormValues?.procedureDescription,
                    findings: procedureFormValues?.findings,
                    specimens: procedureFormValues?.specimens,
                    tube_drain: procedureFormValues?.tubesDrainsPacks,
                    complications: procedureFormValues?.complications,
                    post_instruction: procedureFormValues?.postOpInstructions
                };

                console.log(procedureData);
                console.log(JSON.stringify(procedureData));

                // Check if procedureData is valid
                const isValidProcedureData = Object.values(procedureData).some(value => value !== '');
                if (isValidProcedureData) {
                    formData.append('procedure', JSON.stringify(procedureData));
                    if (procedureFormValues?.file) {
                        formData.append('intervention_file', procedureFormValues.file, procedureFormValues.file.name);
                    } else {
                        console.error('File data is invalid:', procedureFormValues?.file);
                    }
                } else {
                    console.log('Procedure data is not valid, not appending to formData');
                }
                // console.log(formData.get('procedure'));
                const response = await createAppointmentEMR(formData);
                if (response.data.status === "success") {
                    console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    setLoading(false);
                    getPatientContactHistory(appointmentData._id);
                    procedureResetForm();
                    prescriptionResetForm();
                }
            } catch (error) {
                setLoading(false);
                console.error('Error submitting form:', error);
            }
        } else {
            setLoading(false);
            setFormErrors(errors);
        }
    };

    // Allergy Functionality
    let [allergyShow, setAllergyShow] = useState(false);
    const [allergyFormValues, setAllergyFormValues] = useState({
        allergyName: '',
        allergyType: '',
        allergyStartDate: '',
        allergyEndDate: '',
        allergyComments: ''
    });

    const [allergy, setAllergy] = useState([]);
    const [listAllergy, setListAllergy] = useState([]);

    const [allergyFormErrors, setAllergyFormErrors] = useState({});
    const [allergyFormSubmitted, setAllergyFormSubmitted] = useState(false);

    // Allergy Master
    const getAllergyMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/allergies`, '');
            if (response.data.status === 'success') {
                setAllergy(response.data.data.allergies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Allergy List
    useEffect(() => {
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        console.log("Appointment Data:", appointmentData);
        if (!appointmentData) {
            console.log("No appointment data found.");
            return; // Exit early if appointmentData is not available
        }

        console.log("Appointment ID:", appointmentData._id);

        // Allergy List
        const getAllergyList = async () => {
            try {
                setLoading(true); // Set loading to true
                const response = await axiosInstance.get(`/doctor/appointment/previous/allergies/${appointmentData._id}`);
                if (response.data.status === 'success') {
                    console.log("Allergies:", response.data.data.allergies);
                    setListAllergy(response.data.data.allergies);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // toast.error(error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        if (allergyShow) {
            getAllergyList();
            getAllergyMaster();
        }
    }, [allergyShow]);


    // Function to handle input changes and update form values
    const handleInputChange3 = (event) => {
        const { name, value } = event.target;
        setAllergyFormValues({ ...allergyFormValues, [name]: value });

        // If the name is "allergyName", update both allergyName and allergyId
        if (name === 'allergyName') {
            const selectedAllergy = allergy.find(item => item.allergy_name === value);
            if (selectedAllergy) {
                setAllergyFormValues({
                    ...allergyFormValues,
                    allergyName: selectedAllergy.allergy_name,
                    allergyId: selectedAllergy._id
                });
            }
        }
    };

    // Function to validate the form
    const validateAllergyForm = () => {
        const errors = {};

        if (!allergyFormValues.allergyName) {
            errors.allergyName = 'Allergy name is required.';
        }

        if (!allergyFormValues.allergyType) {
            errors.allergyType = 'Allergy type is required.';
        }

        if (allergyFormValues.allergyType === 'past') {
            if (!allergyFormValues.allergyStartDate) {
                errors.allergyStartDate = 'Start date is required.';
            }

            if (!allergyFormValues.allergyEndDate) {
                errors.allergyEndDate = 'End date is required.';
            }
        }

        if (!allergyFormValues.allergyComments) {
            errors.allergyComments = 'Comments are required.';
        }

        setAllergyFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitAllergy = async (event) => {
        event.preventDefault();

        setAllergyFormSubmitted(true);
        const errors = validateAllergyForm(formValues);
        // Validate the form
        if (validateAllergyForm()) {
            console.log('Form is valid, submitting form:', allergyFormValues);

            // Get appointment data from local storage
            const appointmentData = JSON.parse(localStorage.getItem('patientData'));
            if (!appointmentData || !appointmentData._id) {
                console.error('No appointment data found or missing patient ID.');
                toast.error('No appointment data found or missing patient ID.');
                return;
            }

            // Prepare the data to be sent to the API
            const allergyData = {
                patient_id: appointmentData._id,
                allergies: {
                    allergy_id: allergyFormValues.allergyId,
                    name: allergyFormValues.allergyName,
                    type: allergyFormValues.allergyType,
                    ...(allergyFormValues.allergyType === 'past' && {
                        end_date: format(new Date(allergyFormValues.allergyEndDate), 'yyyy-MM-dd'),
                        start_date: format(new Date(allergyFormValues.allergyStartDate), 'yyyy-MM-dd')
                    }),
                    comment: allergyFormValues.allergyComments
                }
            };

            try {
                setLoading(true);
                const response = await addAllergyEMR(allergyData);
                if (response.data.status === 'success') {
                    console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    // Close the modal since the form is valid and the request was successful
                    getPatientContactHistory(appointmentData._id);
                    setAllergyShow(false);
                    // Reset the form values and errors after successful submission
                    allergyResetForm();
                } else {
                    console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
            } finally {
                setLoading(false);
            }
        } else {
            console.log('Form validation failed');
            setAllergyFormErrors(errors);
        }
    };


    // Function to reset the form values and errors
    const allergyResetForm = () => {
        setAllergyFormValues({
            allergyName: '',
            allergyType: '',
            allergyStartDate: '',
            allergyEndDate: '',
            allergyComments: ''
        });
        setAllergyFormErrors({});
        setAllergyFormSubmitted(false);
    };


    // Vaccination Modal functionality
    let [vaccinationShow, setVaccinationShow] = useState(false);

    const [vaccinationFormValues, setVaccinationFormValues] = useState({
        vaccineName: '',
        manufactureName: '',
        vaccinationDate: '',
        expiryDate: '',
        additionalNotes: ''
    });

    const [vaccinationFormErrors, setVaccinationFormErrors] = useState({});
    const [vaccinationFormSubmitted, setVaccinationFormSubmitted] = useState(false);

    const [vaccinations, setVaccinations] = useState([]);
    const [listVaccination, setListVaccination] = useState([]);

    // Vaccinations Master
    const getVaccinationsMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/vaccinations`, '');
            if (response.data.status === 'success') {
                setVaccinations(response.data.data.vaccinations);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Vaccination List
    useEffect(() => {
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        console.log("Appointment Data:", appointmentData);
        if (!appointmentData) {
            console.log("No appointment data found.");
            return; // Exit early if appointmentData is not available
        }

        console.log("Appointment ID:", appointmentData._id);

        // Vaccination List
        const getVaccinationList = async () => {
            try {
                setLoading(true); // Set loading to true
                const response = await axiosInstance.get(`/doctor/appointment/previous/vaccination/${appointmentData._id}`);
                if (response.data.status === 'success') {
                    setListVaccination(response.data.data.vaccinations);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // toast.error(error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        if (vaccinationShow) {
            getVaccinationList();
            getVaccinationsMaster();
        }
    }, [vaccinationShow]);



    const handleVaccinationInputChange = (event) => {
        const { name, value } = event.target;
        setVaccinationFormValues({ ...vaccinationFormValues, [name]: value });

        // If the name is "vaccineName", update both vaccineName and vaccineId
        if (name === 'vaccineName') {
            const selectedVaccine = vaccinations.find(item => item.name === value);
            if (selectedVaccine) {
                setVaccinationFormValues({
                    ...vaccinationFormValues,
                    vaccineName: selectedVaccine.name,
                    vaccineId: selectedVaccine._id
                });
            }
        }
    };

    // Function to handle form submission for vaccination
    const handleSubmitVaccination = async (event) => {
        event.preventDefault();

        setVaccinationFormSubmitted(true);
        const errors = validateVaccinationForm(formValues);

        // Validate the form
        if (validateVaccinationForm()) {
            console.log('Form is valid, submitting form:', vaccinationFormValues);

            // Get appointment data from local storage
            const appointmentData = JSON.parse(localStorage.getItem('patientData'));
            if (!appointmentData || !appointmentData._id) {
                console.error('No appointment data found or missing patient ID.');
                toast.error('No appointment data found or missing patient ID.');
                return;
            }

            // Prepare the data to be sent to the API
            const vaccinationData = {
                patient_id: appointmentData._id,
                vaccinations: {
                    vaccination_id: vaccinationFormValues.vaccineId,
                    name: vaccinationFormValues.vaccineName,
                    manufacture_name: vaccinationFormValues.manufactureName,
                    vaccination_date: format(new Date(vaccinationFormValues.vaccinationDate), 'yyyy-MM-dd'),
                    expiry_date: format(new Date(vaccinationFormValues.expiryDate), 'yyyy-MM-dd'),
                    comment: vaccinationFormValues.additionalNotes
                }
            };

            try {
                setLoading(true);
                const response = await addVaccinationEMR(vaccinationData);
                if (response.data.status === 'success') {
                    console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    // Close the modal since the form is valid and the request was successful
                    setVaccinationShow(false);
                    getPatientContactHistory(appointmentData._id);
                    // Reset the form values and errors after successful submission
                    vaccinationResetForm();
                } else {
                    console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
            } finally {
                setLoading(false);
            }
        } else {
            console.log('Form validation failed');
            setVaccinationFormErrors(errors);
        }
    };


    // Function to validate the vaccination form
    const validateVaccinationForm = () => {
        const errors = {};

        if (!vaccinationFormValues.vaccineName) {
            errors.vaccineName = 'Vaccine name is required.';
        }

        if (!vaccinationFormValues.manufactureName) {
            errors.manufactureName = 'Manufacture name is required.';
        }

        if (!vaccinationFormValues.vaccinationDate) {
            errors.vaccinationDate = 'Date of vaccination is required.';
        }

        if (!vaccinationFormValues.expiryDate) {
            errors.expiryDate = 'Expiry date is required.';
        }

        if (!vaccinationFormValues.additionalNotes) {
            errors.additionalNotes = 'Additional notes are required.';
        }

        setVaccinationFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to reset the form values and errors
    const vaccinationResetForm = () => {
        setVaccinationFormValues({
            vaccineName: '',
            manufactureName: '',
            vaccinationDate: '',
            expiryDate: '',
            additionalNotes: ''
        });
        setVaccinationFormErrors({});
        setVaccinationFormSubmitted(false);
    };

    // Lab report Functionality
    let [labReportShow, setLabReportShow] = useState(false);
    // Define the state for form values and errors
    const [labReportFormValues, setLabReportFormValues] = useState({
        testName: '',
        dateOfCollectSample: '',
        dateOfReceiveSample: '',
        labReportComment: '',
        labReportFile: null,
    });
    const [labReportFormErrors, setLabReportFormErrors] = useState({});
    const [labReportFormSubmitted, setLabReportFormSubmitted] = useState(false);

    const [listLabReport, setListLabReport] = useState([]);

    // LabReport List
    useEffect(() => {
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        // console.log("Appointment Data:", appointmentData);
        if (!appointmentData) {
            console.log("No appointment data found.");
            return; // Exit early if appointmentData is not available
        }

        // console.log("Appointment ID:", appointmentData._id);

        // LabReport List
        const getLabReportList = async () => {
            try {
                setLoading(true); // Set loading to true
                const response = await axiosInstance.get(`/doctor/appointment/previous/lab_profile/${appointmentData._id}`);
                if (response.data.status === 'success') {
                    setListLabReport(response.data.data.examinations);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // toast.error(error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        if (labReportShow) {
            getLabReportList();
        }
    }, [labReportShow]);

    let [labReportFilePreviewURL, setLabReportFilePreviewURL] = useState(null);
    // Function to handle input changes and update form values
    const handleInputChange4 = (event) => {
        const { name, value } = event.target;
        setLabReportFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        if (name === 'testName') {
            const selectedTest = testName.find(item => item.test_name === value);
            if (selectedTest) {
                setAllergyFormValues({
                    ...allergyFormValues,
                    testName: selectedTest.test_name,
                    testId: selectedTest._id
                });
            }
        }

    };

    // State for managing uploaded files and validation status
    const [files, setFiles] = useState([]);

    // Function to handle file changes and update the file preview URL
    const handleLabReportFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setLabReportFormValues((prevValues) => ({
            ...prevValues,
            labReportFile: file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setLabReportFilePreviewURL(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setLabReportFilePreviewURL(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setLabReportFilePreviewURL(null);
        }
    };

    // Validate the form
    const validateLabReportForm = () => {
        const errors = {};

        // Validate testName field
        if (!labReportFormValues.testName) {
            errors.testName = 'Test name is required.';
        }

        // Validate dateOfCollectSample field
        if (!labReportFormValues.dateOfCollectSample) {
            errors.dateOfCollectSample = 'Date of collect sample is required.';
        }

        // Validate dateOfReceiveSample field
        if (!labReportFormValues.dateOfReceiveSample) {
            errors.dateOfReceiveSample = 'Date of receive sample is required.';
        }
        if (!labReportFormValues.labReportComment) {
            errors.labReportComment = 'comment is required.';
        }

        if (!labReportFormValues.labReportFile) {
            errors.labReportFile = 'Lab report file is required.';
        }

        // Set form errors state
        setLabReportFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmitLabReport = async (event) => {
        event.preventDefault();
        setLabReportFormSubmitted(true);
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        // Validate the form
        if (validateLabReportForm()) {
            // Form is valid, prepare the data for submission
            const labReportData = {
                lab_profile_id: labReportFormValues.testId,
                lab_profile_name: labReportFormValues.testName,
                date_of_collect_sample: format(new Date(labReportFormValues.dateOfCollectSample), 'yyyy-MM-dd'),
                date_of_receive_sample: format(new Date(labReportFormValues.dateOfReceiveSample), 'yyyy-MM-dd'),
                comment: labReportFormValues.labReportComment,
            };
            // Create FormData object to send the data along with files
            const formData = new FormData();
            formData.append('patient_id', appointmentData._id);
            formData.append('refered_examinations', JSON.stringify(labReportData));
            formData.append('report', labReportFormValues.labReportFile, labReportFormValues.labReportFile.name);
            try {
                setLoading(true); // Set loading to true
                // Perform API call to submit the lab report form
                const response = await addLabReportsEMR(formData);
                console.log('Form submitted successfully:', response.data);
                if (response.data.status === 'success') {
                    // console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    getPatientContactHistory(appointmentData._id);
                    // Reset form values and close the modal after submission
                    resetLabReportForm();
                    setLabReportShow(false);
                    setLoading(true);
                } else {
                    console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                setLoading(false);
                // Handle error cases appropriately, e.g., show error message to the user
            }
        } else {
            console.log('Form validation failed');
            // Handle validation failure cases appropriately, e.g., display validation errors to the user
        }
    };

    // Function to reset form and clear errors
    const resetLabReportForm = () => {
        setLabReportFormValues({
            testName: '',
            dateOfCollectSample: '',
            dateOfReceiveSample: '',
            labReportFile: null,
            labReportComment: '',
        });
        setLabReportFormErrors({});
        setLabReportFormSubmitted(false);
        setLabReportFilePreviewURL(null);
    };

    // Documents Modal Functionality
    let [documentsShow, setDocumentsShow] = useState(false);
    let [documentsFormValues, setDocumentsFormValues] = useState({
        documentType: '',
        specialty: '',
        documentDate: '',
        description: '',
        documentFile: null,
    });
    let [documentsFormErrors, setDocumentsFormErrors] = useState({});
    let [documentFormSubmitted, setDocumentFormSubmitted] = useState(false);

    const [listDocuments, setListDocuments] = useState([]);

    // documents List
    useEffect(() => {
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        // console.log("Appointment Data:", appointmentData);
        if (!appointmentData) {
            console.log("No appointment data found.");
            return; // Exit early if appointmentData is not available
        }
        const getDocumentsList = async () => {
            try {
                setLoading(true); // Set loading to true
                const response = await axiosInstance.get(`/doctor/appointment/previous/documents/${appointmentData._id}`);
                if (response.data.status === 'success') {
                    // console.log(response.data.data.documents);
                    setListDocuments(response.data.data.documents);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // toast.error(error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        if (documentsShow) {
            getDocumentsList();
        }
    }, [documentsShow]);

    // State variable for file preview URL
    let [filePreviewURL, setFilePreviewURL] = useState(null);

    // Function to handle input changes and update form values
    const handleDocumentsInputChange = (event) => {
        const { name, value } = event.target;

        setDocumentsFormValues(prevValues => {
            // Create a new object to hold the updated values
            const newValues = { ...prevValues, [name]: value };
            // Update the corresponding ID if necessary
            if (name === 'documentType') {
                const selectedDocumentType = documentType.find(item => item.document_type === value);
                if (selectedDocumentType) {
                    newValues.documentTypeId = selectedDocumentType._id;
                }
            } else if (name === 'specialty') {
                const selectedSpecialty = specialty.find(item => item.name === value);
                if (selectedSpecialty) {
                    newValues.specialtyId = selectedSpecialty._id;
                }
            }
            return newValues;
        });
    };


    // Function to handle file changes and update the file preview URL
    const handleDocumentsFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setDocumentsFormValues((prevValues) => ({
            ...prevValues,
            documentFile: file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setFilePreviewURL(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setFilePreviewURL(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setFilePreviewURL(null);
        }
    };

    // Function to validate the Documents form
    const validateDocumentsForm = () => {
        const errors = {};

        if (!documentsFormValues.documentType) {
            errors.documentType = 'Document type is required.';
        }

        if (!documentsFormValues.specialty) {
            errors.specialty = 'Specialty is required.';
        }

        if (!documentsFormValues.documentDate) {
            errors.documentDate = 'Date of document is required.';
        }

        if (!documentsFormValues.description) {
            errors.description = 'Description is required.';
        }

        if (!documentsFormValues.documentFile) {
            errors.documentFile = 'Document file is required.';
        }

        setDocumentsFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitDocuments = async (event) => {
        event.preventDefault();
        setDocumentFormSubmitted(true);
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        if (validateDocumentsForm()) {
            const documentData = {
                document_id: documentsFormValues.documentTypeId,
                document_type: documentsFormValues.documentType,
                speciality_id: documentsFormValues.specialtyId,
                speciality: documentsFormValues.specialty,
                date: format(new Date(documentsFormValues.documentDate), 'yyyy-MM-dd'),
                comment: documentsFormValues.description
            };
            const formData = new FormData();
            formData.append('patient_id', appointmentData._id);
            formData.append('documents', JSON.stringify(documentData));
            // formData.append('documentFile', documentsFormValues.documentFile);
            formData.append('report', documentsFormValues.documentFile, documentsFormValues.documentFile.name);
            // console.log(documentsFormValues.documentFile);
            try {
                setLoading(true);
                const response = await addDocumentsEMR(formData);
                if (response.data.status === 'success') {
                    // console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    getPatientContactHistory(appointmentData._id);
                    // Close the modal since the form is valid and the request was successful
                    setDocumentsShow(false);
                    // Reset the form values and errors after successful submission
                    documentsResetForm();
                } else {
                    console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
            } finally {
                setLoading(false);
            }
        } else {
            console.log('Form validation failed');
        }
    };

    // Function to reset the form values and errors
    const documentsResetForm = () => {
        setDocumentsFormValues({
            documentType: '',
            specialty: '',
            documentDate: '',
            description: '',
            documentFile: null,
        });
        setDocumentsFormErrors({});
        setDocumentFormSubmitted(false);
        setFilePreviewURL(null);
    };

    // Prescription Functionality
    let [prescriptionShow, setPrescriptionShow] = useState(false);
    // Initial state for the form array
    const [prescriptionList, setPrescriptionList] = useState([
        {
            medication: '',
            prescriptionType: '',
            dose: '',
            quantity: '',
            comment: '',
        },
    ]);

    // State variables for form submission and error handling
    const [prescriptionFormSubmitted, setPrescriptionFormSubmitted] = useState(false);
    const [prescriptionFormErrors, setPrescriptionFormErrors] = useState([]);

    // Function to handle adding a new medication row
    // Function to handle adding a prescription row
    const addPrescriptionRow = (index) => {
        // Check if the length of the prescription list is equal to 3
        if (prescriptionList.length === 10) {
            // If the length is 3, return undefined (or any specific value you want)
            // console.log("If the length is 3, return undefined (or any specific value you want)");
            return undefined;
        } else {
            // Otherwise, add a new prescription row at the specified index
            setPrescriptionList((prevList) => [
                ...prevList.slice(0, index + 1),
                {
                    medication: '',
                    prescriptionType: '',
                    dose: '',
                    quantity: '',
                    comment: '',
                },
                ...prevList.slice(index + 1),
            ]);
            return true; // Return true indicating a new row was added
        }
    };

    // Function to handle deleting a medication row
    const deletePrescriptionRow = (index) => {
        // Check the length of the prescription list
        if (prescriptionList.length === 1) {
            // If the length is 1, you may choose to skip deletion or add additional logic
            console.log("Cannot delete the only remaining item.");
            // You might also want to display a message to the user or take other action
        } else {
            // Remove the item at the specified index
            setPrescriptionList((prevList) => {
                return prevList.filter((_, i) => i !== index);
            });
        }
    };

    // Function to handle input changes
    const handleInputChange5 = (index, event) => {
        const { name, value } = event.target;

        setPrescriptionList((prevList) =>
            prevList.map((item, i) => {
                if (i === index) {
                    const updatedItem = { ...item, [name]: value };

                    // If the medication field is changed, update the medication_id
                    if (name === 'medication') {
                        const selectedMedication = medications.find(med => med.name === value);
                        if (selectedMedication) {
                            updatedItem.medication_id = selectedMedication._id;
                        }
                    }

                    // If the dose field is changed, update the medicine_dosage_id
                    if (name === 'dose') {
                        const selectedDosage = medicineDosage.find(dosage => dosage.medicine_dosage === value);
                        if (selectedDosage) {
                            updatedItem.medicine_dosage_id = selectedDosage._id;
                        }
                    }

                    return updatedItem;
                }
                return item;
            })
        );
    };


    // Function to validate the form and set errors
    const prescriptionValidateForm = () => {
        const errors = [];
        prescriptionList.forEach((item, i) => {
            const rowErrors = {};
            if (!item.medication) {
                rowErrors.medication = 'Medication is required.';
            }
            if (!item.prescriptionType) {
                rowErrors.prescriptionType = 'Prescription Type is required.';
            }
            if (!item.dose) {
                rowErrors.dose = 'Dose is required.';
            }
            if (!item.quantity) {
                rowErrors.quantity = 'Quantity is required.';
            }
            if (!item.comment) {
                rowErrors.comment = 'Comment is required.';
            }
            if (Object.keys(rowErrors).length > 0) {
                errors[i] = rowErrors;
            }
        });
        setPrescriptionFormErrors(errors);
        return errors.length === 0;
    };

    // Function to handle form submission
    const prescriptionHandleFormSubmit = (event) => {
        event.preventDefault();
        setPrescriptionFormSubmitted(true);
        const isFormValid = prescriptionValidateForm();
        if (isFormValid) {
            // Form is valid, proceed with form submission logic
            console.log('Form is valid, submitting form:', prescriptionList);

            // Save the form details here (if needed)

            setPrescriptionShow(false);
            setPrescriptionFormErrors([]);
            setPrescriptionFormSubmitted(false);
        } else {
            console.log('Form validation failed');
        }
    };


    // Function to reset the form values and errors
    const prescriptionResetForm = () => {
        setPrescriptionList([
            {
                medication: '',
                prescriptionType: '',
                dose: '',
                quantity: '',
                comment: '',
            },
        ]);
        setPrescriptionFormErrors([]);
        setPrescriptionFormSubmitted(false);
    };

    // Procedure Functionality
    let [procedureShow, setProcedureShow] = useState(false);
    let [procedureFormValues, setProcedureFormValues] = useState({
        procedure: '',
        preOpDiagnosis: '',
        postOpDiagnosis: '',
        surgeon: '',
        assistant: '',
        anaesthetist: '',
        procedureDescription: '',
        findings: '',
        specimens: '',
        tubesDrainsPacks: '',
        complications: '',
        postOpInstructions: '',
        file: null,
    });
    let [procedureFormErrors, setProcedureFormErrors] = useState({});
    let [procedureFormSubmitted, setProcedureFormSubmitted] = useState(false);

    // State variable for file preview URL
    let [filePreviewURL2, setFilePreviewURL2] = useState(null);
    const [savedProcedure, setSavedProcedure] = useState(null);

    // Function to handle input changes and update form values
    const handleProcedureInputChange = (event) => {
        const { name, value } = event.target;
        setProcedureFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Function to handle file changes and update the file preview URL
    const handleProcedureFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setProcedureFormValues((prevValues) => ({
            ...prevValues,
            file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setFilePreviewURL2(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setFilePreviewURL2(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setFilePreviewURL2(null);
        }
    };

    // Function to validate the Procedure form
    const validateProcedureForm = () => {
        const errors = {};

        // Validate each field as required
        if (!procedureFormValues.procedure) {
            errors.procedure = 'Procedure/Intervention is required.';
        }
        if (!procedureFormValues.preOpDiagnosis) {
            errors.preOpDiagnosis = 'Pre-Op Diagnosis is required.';
        }
        if (!procedureFormValues.postOpDiagnosis) {
            errors.postOpDiagnosis = 'Post-Op Diagnosis is required.';
        }
        if (!procedureFormValues.surgeon) {
            errors.surgeon = 'Surgeon is required.';
        }
        if (!procedureFormValues.assistant) {
            errors.assistant = 'Assistant is required.';
        }
        if (!procedureFormValues.anaesthetist) {
            errors.anaesthetist = 'Anaesthetist is required.';
        }
        if (!procedureFormValues.procedureDescription) {
            errors.procedureDescription = 'Description of Procedure is required.';
        }
        if (!procedureFormValues.findings) {
            errors.findings = 'Findings are required.';
        }
        if (!procedureFormValues.specimens) {
            errors.specimens = 'Specimens are required.';
        }
        if (!procedureFormValues.tubesDrainsPacks) {
            errors.tubesDrainsPacks = 'Tubes, Drains, Packs etc. are required.';
        }
        if (!procedureFormValues.complications) {
            errors.complications = 'Complications are required.';
        }
        if (!procedureFormValues.postOpInstructions) {
            errors.postOpInstructions = 'Post-Op Instructions & Treatment are required.';
        }
        if (!procedureFormValues.file) {
            errors.file = 'File is required.';
        }

        setProcedureFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitProcedure = (event) => {
        event.preventDefault();
        setProcedureFormSubmitted(true);

        if (validateProcedureForm()) {
            console.log('Form is valid, submitting form:', procedureFormValues);
            setSavedProcedure(procedureFormValues);
            setProcedureShow(false);
            // procedureResetForm();
        } else {
            console.log('Form validation failed');
        }
    };


    // Function to reset the form values and errors
    const procedureResetForm = () => {
        setProcedureFormValues({
            procedure: '',
            preOpDiagnosis: '',
            postOpDiagnosis: '',
            surgeon: '',
            assistant: '',
            anaesthetist: '',
            procedureDescription: '',
            findings: '',
            specimens: '',
            tubesDrainsPacks: '',
            complications: '',
            postOpInstructions: '',
            file: null,
        });
        setProcedureFormErrors({});
        setProcedureFormSubmitted(false);
        setFilePreviewURL2(null);
        setSavedProcedure(null);
    };

    let [imagingShow, setImagingShow] = useState(false);
    let [imagingFormValues, setImagingFormValues] = useState({
        imaging: '',
        imagingType: '',
        file: null,
        resultComment: '',
    });
    let [imagingFormErrors, setImagingFormErrors] = useState({});
    let [imagingFormSubmitted, setImagingFormSubmitted] = useState(false);

    const [listImaging, setListImaging] = useState([]);
    // imaging List
    useEffect(() => {
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        if (!appointmentData) {
            console.log("No appointment data found.");
            return;
        }
        const getImagingList = async () => {
            try {
                setLoading(true); // Set loading to true
                const response = await axiosInstance.get(`/doctor/appointment/previous/imaging/${appointmentData._id}`);
                if (response.data.status === 'success') {
                    // console.log(response.data.data.imaging);
                    setListImaging(response.data.data.imagings);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // toast.error(error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        if (imagingShow) {
            getImagingList();
        }
    }, [imagingShow]);

    // State variable for file preview URL
    let [filePreviewURL3, setFilePreviewURL3] = useState(null);

    // Function to handle input changes and update form values
    const handleImagingInputChange = (event) => {
        const { name, value } = event.target;
        setImagingFormValues(prevValues => {
            // Create a new object to hold the updated values
            const newValues = { ...prevValues, [name]: value };
            // Update the corresponding ID if necessary
            if (name === 'imagingType') {
                const selectedImagingType = imagingType.find(item => item.name === value);
                if (selectedImagingType) {
                    newValues.imagingTypeId = selectedImagingType._id;
                    newValues.imagingTypeName = selectedImagingType.name; // Set imaging name if needed
                }
            } else if (name === 'imaging') {
                const selectedImaging = imaging.find(item => item.name === value);
                if (selectedImaging) {
                    newValues.imagingId = selectedImaging._id;
                    newValues.imagingName = selectedImaging.name; // Set imaging name if needed
                }
            }
            return newValues;
        });
    };


    // Function to handle file changes and update the file preview URL
    const handleImagingFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setImagingFormValues((prevValues) => ({
            ...prevValues,
            file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setFilePreviewURL3(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setFilePreviewURL3(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setFilePreviewURL3(null);
        }
    };

    // Function to validate the Imaging form
    const validateImagingForm = () => {
        const errors = {};

        // Validate each field as required
        if (!imagingFormValues.imaging) {
            errors.imaging = 'Imaging is required.';
        }
        if (!imagingFormValues.imagingType) {
            errors.imagingType = 'Imaging type is required.';
        }
        if (!imagingFormValues.file) {
            errors.file = 'File is required.';
        }
        if (!imagingFormValues.resultComment) {
            errors.resultComment = 'Result & Comment are required.';
        }

        setImagingFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmitImaging = async (event) => {
        event.preventDefault();
        setImagingFormSubmitted(true);
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));

        // Validate the form
        if (validateImagingForm()) {
            // Form is valid, prepare the data for submission
            // console.log(imagingFormValues.imagingId);
            // console.log(imagingFormValues.imagingName);
            // console.log(imagingFormValues.imagingTypeId);
            // console.log(imagingFormValues.imagingTypeName);
            // console.log(imagingFormValues.resultComment);
            // console.log(imagingFormValues.file);
            // console.log(imagingFormValues.file.name);    
            const imagingData = {
                radiology_id: imagingFormValues.imagingId || '',
                radiologies_name: imagingFormValues.imagingName || '',
                sub_radiology_id: imagingFormValues.imagingTypeId || '',
                sub_radiologies_name: imagingFormValues.imagingTypeName || '',
                comment: imagingFormValues.resultComment || '',
            };

            // Create FormData object to send the data along with files
            const formData = new FormData();
            formData.append('patient_id', appointmentData._id);
            formData.append('imagings', JSON.stringify(imagingData));

            if (imagingFormValues.file && imagingFormValues.file.name) {
                formData.append('report', imagingFormValues.file, imagingFormValues.file.name);
            } else {
                console.error('Report file is missing or invalid.');
                return;
            }

            try {
                setLoading(true);
                // Perform API call to submit the imaging form
                const response = await addImagingEMR(formData);
                console.log('Form submitted successfully:', response.data);
                if (response.data.status === 'success') {
                    toast.success(response.data.message);
                    // Reset form values and close the modal after submission
                    getPatientContactHistory(appointmentData._id);
                    imagingResetForm();
                    setImagingShow(false);
                } else {
                    // console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                setLoading(false);
                // Handle error cases appropriately, e.g., show error message to the user
            }
        } else {
            console.log('Form validation failed');
            // Handle validation failure cases appropriately, e.g., display validation errors to the user
        }
    };

    // Function to reset the form values and errors
    const imagingResetForm = () => {
        setImagingFormValues({
            imaging: '',
            imagingType: '',
            file: null,
            resultComment: '',
        });
        setImagingFormErrors({});
        setImagingFormSubmitted(false);
        setFilePreviewURL3(null);
    };

    // Refer Functionality
    const [referOption, setReferOption] = useState("");
    const [labReportsTestFormValues, setLabReportsTestFormValues] = useState({ labTest: [], labTestComments: '' });
    const [labReportsTestFormErrors, setLabReportsTestFormErrors] = useState({});
    const [imagingTypeFormValues, setImagingTypeFormValues] = useState({ imaging: '', imagingType: '' });
    const [imagingTypeFormErrors, setImagingTypeFormErrors] = useState({});
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [referredDoctorFormErrors, setReferredDoctorFormErrors] = useState({});
    const [referProcedureFormValues, setReferProcedureFormValues] = useState({ procedureType: '', procedureComments: '' });
    const [referProcedureFormErrors, setReferProcedureFormErrors] = useState({});

    // Function to handle refer option change
    const handleReferOptionChange = (event) => {
        setReferOption(event.target.value);
        // Clear the errors when refer option is changed
        setReferredDoctorFormErrors({ referOptionMessage: '', doctor: '', doctorComments: '' });
        setLabReportsTestFormErrors({ labTest: '', labTestComments: '' });
        setImagingTypeFormErrors({ imaging: '', imagingType: '', imagingComments: '' });
        setReferProcedureFormErrors({ procedureType: '', procedureComments: '' });
    };

    // Function to handle form submission
    const handleReferSubmit = async (event) => {
        event.preventDefault();
        const appointmentData = JSON.parse(localStorage.getItem('patientData'));
        setLoading(true);  // Set loading state to true at the start
        const imagingValue = imagingTypeFormValues.imaging;
        try {
            // Validation for radio buttons
            if (!referOption) {
                setReferredDoctorFormErrors({ referOptionMessage: 'Please select an option for referral.' });
                return;
            } else {
                setReferredDoctorFormErrors({ referOptionMessage: '' });
            }

            // Validation based on selected refer option
            if (referOption === 'lab_profile') {
                if (labReportsTestFormValues && labReportsTestFormValues.labTest.length === 0) {
                    setLabReportsTestFormErrors({ labTest: 'Please select at least one lab test.' });
                    return;
                } else {
                    setLabReportsTestFormErrors({ labTest: '' });
                }

                const labTestComments = document.getElementById('labTestComments').value;
                if (!labTestComments.trim()) {
                    setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTestComments: 'Lab Test Comments is required.' });
                    return;
                } else {
                    setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTestComments: '' });
                }
            } else if (referOption === 'imaging') {
                if (!imagingTypeFormValues || !imagingTypeFormValues.imaging) {
                    setImagingTypeFormErrors({ imaging: 'Please select an imaging option.' });
                    return;
                } else {
                    setImagingTypeFormErrors({ imaging: '' });
                }

                if (!imagingTypeFormValues || !imagingTypeFormValues.imagingType) {
                    setImagingTypeFormErrors({ imagingType: 'Please select imaging type.' });
                    return;
                } else {
                    setImagingTypeFormErrors({ imagingType: '' });
                }

                const imagingComments = document.getElementById('imagingComments').value;
                if (!imagingComments.trim()) {
                    setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingComments: 'Imaging Comments is required.' });
                    return;
                } else {
                    setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingComments: '' });
                }
            } else if (referOption === 'doctor') {
                if (!selectedDoctor) {
                    setReferredDoctorFormErrors({ doctor: 'Please select a doctor.' });
                    return;
                } else {
                    setReferredDoctorFormErrors({ doctor: '' });
                }

                const doctorComments = document.getElementById('doctorComments').value;
                if (!doctorComments.trim()) {
                    setReferredDoctorFormErrors({ ...referredDoctorFormErrors, doctorComments: 'Doctor Comments is required.' });
                    return;
                } else {
                    setReferredDoctorFormErrors({ ...referredDoctorFormErrors, doctorComments: '' });
                }
            } else if (referOption === 'procedure') {
                if (!referProcedureFormValues.procedureType || referProcedureFormValues.procedureType === '') {
                    setReferProcedureFormErrors({ procedureType: 'Please select a procedure.' });
                    return;
                } else {
                    setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureType: '' });
                }

                const procedureComments = document.getElementById('procedureComments').value;
                if (!procedureComments.trim()) {
                    setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureComments: 'Procedure Comments is required.' });
                    return;
                } else {
                    setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureComments: '' });
                }
            }

            // Prepare the payload for submission
            const payload = {
                patient_id: appointmentData._id,
                referal_type: referOption,
            };

            if (referOption === 'imaging') {
                const imagingId = referImaging.find(item => item.name === imagingTypeFormValues.imaging)?._id || '';
                const imagingTypeId = referImagingType.find(item => item.name === imagingTypeFormValues.imagingType)?._id || '';
                payload.imaging = {
                    radiology_id: imagingId,
                    radiologies_name: imagingTypeFormValues.imaging || '',
                    sub_radiology_id: imagingTypeId,
                    sub_radiologies_name: imagingTypeFormValues.imagingType || '',
                    comment: document.getElementById('imagingComments').value
                };
            } else if (referOption === 'lab_profile') {
                payload.refered_examinations = {
                    examinations: labReportsTestFormValues.labTest.map(test => ({
                        _id: test._id,
                        name: test.name
                    })),
                    comment: document.getElementById('labTestComments').value
                };
            } else if (referOption === 'doctor') {
                const doctor = (referDoctor && selectedDoctor && referDoctor.find(item => item.full_name === selectedDoctor));
                if (doctor) {
                    payload.doctor = {
                        refered_doctor_id: doctor._id,
                        name: doctor.full_name,
                        comment: document.getElementById('doctorComments').value
                    };
                }
            } else if (referOption === 'procedure') {
                const procedureName = referProcedureFormValues.procedureType;
                const procedureId = referProcedure.find(item => item.name === procedureName)?._id || '';
                const procedureComments = document.getElementById('procedureComments').value;
                payload.procedure = {
                    procedure_id: procedureId, // Procedure ID
                    name: procedureName, // Procedure name
                    comment: procedureComments
                };
            }

            // Perform API call to submit the form
            const response = await doctorReferPatient(payload);
            console.log('Form submitted successfully:', response.data);
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                getPatientContactHistory(appointmentData._id);
                clearForm();
                setImagingTypeFormValues(prevState => ({
                    ...prevState,
                    imaging: imagingValue
                }));
            } else {
                console.error('Failed to submit form:', response.data.message);
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error cases appropriately, e.g., show error message to the user
        } finally {
            setLoading(false);  // Set loading state to false after the process is complete
        }
    };

    const clearForm = () => {
        setReferOption("");
        setLabReportsTestFormValues({ labTest: [] });
        setLabReportsTestFormErrors({});
        setImagingTypeFormValues({ imagingType: [] });
        setImagingTypeFormErrors({});
        setSelectedDoctor("");
        setReferredDoctorFormErrors({});
        setReferProcedureFormValues({ procedureType: '', procedureComments: '' });
        setReferProcedureFormErrors({});

        const radioButtons = document.getElementsByName("referOptions");
        radioButtons.forEach((radio) => {
            radio.checked = false;
        });
    };

    const handleClear = () => {
        clearForm();
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    };

    const [referLabTestName, setReferLabTestName] = useState([]);
    // Refer Lab Test Master
    const getReferLabTestNameMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/labprofiles`, '');
            if (response.data.status === 'success') {
                setReferLabTestName(response.data.data.labprofiles);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    const [referImaging, setReferImaging] = useState([]);
    // Refer Imaging Master
    const getReferImagingMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/radiologies`, '');
            if (response.data.status === 'success') {
                setReferImaging(response.data.data.radiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };
    const [referImagingType, setReferImagingType] = useState([]);
    // Refer Imaging Type Master
    const getReferImagingTypeMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/subradiologies`, '');
            if (response.data.status === 'success') {
                setReferImagingType(response.data.data.subradiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    const [referDoctor, setReferDoctor] = useState([]);
    // Refer Doctor Master
    const getReferDoctorMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/doctors`, '');
            if (response.data.status === 'success') {
                setReferDoctor(response.data.data.doctors);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };
    const [referProcedure, setReferProcedure] = useState([]);
    // Refer Procedure Master
    const getReferProcedureMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/interventions`, '');
            if (response.data.status === 'success') {
                setReferProcedure(response.data.data.interventions);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Vitals Hide/Show
    const [showVitals, setShowVitals] = useState(false);
    const toggleVitals = () => {
        setShowVitals(!showVitals);
    };

    // Chat Hide/Shw
    const [showPersonChat, setShowPersonChat] = useState(false);
    const togglePersonChat = () => {
        const data = JSON.parse(localStorage.getItem('patientData'));
        if (data) {
            setAppointmentData(data);
        }
        setShowPersonChat(!showPersonChat);
        if (!showPersonChat) {
            setShowZoomMeeting(false); // Close ZoomMeeting if PersonChat is opened
        }
    };

    const offcanvasRef = useRef(null);

    useEffect(() => {
        if (offcanvasRef.current) {
            const offcanvas = new Offcanvas(offcanvasRef.current, {
                backdrop: false, // Disable backdrop
                scroll: true, // Allow body scrolling
            });
            if (showPersonChat) {
                offcanvas.show();
            } else {
                offcanvas.hide();
            }
        }
    }, [showPersonChat]);

    const [showZoomMeeting, setShowZoomMeeting] = useState(false);
    const apiKey = "ucwlruvwTfSq59DfTEiMwA";
    const apiSecret = "VwVOPQTk1trMmokqo0ACBZiHRx2Shj7jaxHe";
    const meetingNumber = "1234567890";
    const userName = "John Doe";
    const sessionName = "Patient and Doctor Discussion";

    const toggleZoomMeeting = () => {
        const data = JSON.parse(localStorage.getItem('patientData'));
        if (data) {
            setAppointmentData(data);
        }

        setShowZoomMeeting(!showZoomMeeting);
        // Close PersonChat if ZoomMeeting is opened
        if (!showZoomMeeting) {
            setShowPersonChat(false);
        }
    };

    const [isMinimized, setIsMinimized] = useState(false);

    const handleMinimize = () => {
        if (offcanvasRef.current) {
            const offcanvas = offcanvasRef.current;
            if (!isMinimized) {
                // Minimize the offcanvas
                offcanvas.style.height = '50px'; // Minimized height
                offcanvas.style.bottom = '0'; // Position at bottom
                setIsMinimized(true); // Set minimized state
            } else {
                // Maximize the offcanvas
                offcanvas.style.height = '530px'; // Reset height
                offcanvas.style.bottom = ''; // Reset bottom position
                setIsMinimized(false); // Set maximized state
            }
        }
    };

    const offcanvasZoomCallRef = useRef(null);

    useEffect(() => {
        if (offcanvasZoomCallRef.current) {
            const offcanvas = new Offcanvas(offcanvasZoomCallRef.current, {
                backdrop: false, // Disable backdrop
                scroll: true, // Allow body scrolling
            });
            if (showZoomMeeting) {
                offcanvas.show();
            } else {
                offcanvas.hide();
            }
        }
    }, [showZoomMeeting]);

    const [containerHeight, setContainerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        <>
            <Wrapper>
                <Loader loading={loading} />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="row">
                            <div className="col-md-5 mt-2">
                                {/* {contactHistory.patient && ( */}
                                <div className="card p-3 shadow">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex">
                                                {contactHistory?.patient?.profile_picture ? (
                                                    <img src={BaseurlForImage + contactHistory.patient.profile_picture} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                                ) : (
                                                    <div className="avatar avatar-md-sm rounded-circle border shadow" style={{ backgroundColor: "#ccc" }}></div> // Placeholder for missing image
                                                )}
                                                <div className="overflow-hidden ms-3 d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <div className="text-dark mb-0 h6 align-items-center">
                                                            <span className="text-truncate">
                                                                {contactHistory?.patient?.first_name} {contactHistory?.patient?.last_name}&nbsp;&nbsp;
                                                                {contactHistory?.patient?.gender === 'male' && (
                                                                    <i className="mdi mdi-gender-male" style={{ fontSize: '18px' }}></i>
                                                                )}
                                                                {contactHistory?.patient?.gender === 'female' && (
                                                                    <i className="mdi mdi-gender-female" style={{ fontSize: '18px' }}></i>
                                                                )}
                                                                {contactHistory?.patient?.gender === '' && (
                                                                    <span>-</span>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <small className="text-muted">
                                                            <EllipsisTooltipAddress text={contactHistory?.patient?.address || 'Address not available'} />
                                                        </small>
                                                    </div>
                                                </div>
                                                <button className="btn btn-icon btn-pills btn-soft-primary mt-1" onClick={togglePersonChat}>
                                                    {showPersonChat ? (
                                                        <img src={noChatIcon} style={{ fontSize: '13px', height: '13px', width: '13px' }} className="icons" alt="No Chat Icon" />
                                                    ) : (
                                                        <FiMessageCircle className="icons" />
                                                    )}
                                                </button>&nbsp;&nbsp;
                                                <button className="btn btn-icon btn-pills btn-soft-primary mt-1" onClick={toggleZoomMeeting}>
                                                    {showZoomMeeting ? <FiVideoOff className="icons" /> : <FiVideo className="icons" />}
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-4">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                NHS Registration NO
                                            </span> <br />
                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                {contactHistory?.patient?.health_id || 'N/A'}
                                            </span>
                                        </div>
                                        <div className="col-md-4">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                DOB - (Age)
                                            </span> <br />
                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                {contactHistory?.patient?.dob ? safeFormatDOB(contactHistory.patient.dob) : 'N/A'} - ({contactHistory?.patient?.age || 'N/A'})
                                            </span>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                Gender
                                            </span> <br />
                                            <span className="text-dark" style={{ fontSize: "13px", textTransform: "capitalize" }}>
                                                {contactHistory?.patient?.gender || 'N/A'}
                                            </span>
                                        </div> */}
                                        <div className="col-md-4">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                Last Visit
                                            </span> <br />
                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                {contactHistory?.patient?.last_visit ? safeFormatDOB(contactHistory.patient.last_visit) : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                    {/* <hr style={{ marginTop: "0.2rem", marginBottom: '0.3rem' }} /> */}
                                    {showPersonChat && (
                                        <div
                                            className="offcanvas offcanvas-end"
                                            tabIndex="-1"
                                            id="offcanvasPersonChat"
                                            aria-labelledby="offcanvasPersonChatLabel"
                                            ref={offcanvasRef}
                                        >
                                            <div className="offcanvas-header d-flex justify-content-between align-items-center">
                                                <h5 className="offcanvas-title" id="offcanvasPersonChatLabel">Chat</h5>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-pills btn-soft-primary mt-1"
                                                        onClick={handleMinimize}
                                                    >
                                                        {isMinimized ? <RiArrowUpFill className="icons" /> : <RiArrowDownFill className="icons" />}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-pills btn-soft-danger mt-1 ms-2"
                                                        data-bs-dismiss="offcanvas"
                                                        onClick={togglePersonChat}
                                                    >
                                                        <RiCloseFill className="icons" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="offcanvas-body">
                                                <div className="row mt-2">
                                                    <PersonChat height={isMinimized ? '50px' : '400px'} patientId={appointmentData._id} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {showZoomMeeting && (
                                        <div
                                            className="offcanvas offcanvas-end"
                                            tabIndex="-1"
                                            id="offcanvasPersonChat"
                                            aria-labelledby="offcanvasZoomCallLabel"
                                            ref={offcanvasZoomCallRef}
                                        >
                                            <div className="offcanvas-header">
                                                <h5 className="offcanvas-title" id="offcanvasZoomCallLabel">Call</h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="offcanvas"
                                                    aria-label="Close"
                                                    onClick={togglePersonChat}
                                                ></button>
                                            </div>
                                            <div className="offcanvas-body">
                                                <div className="row mt-2">
                                                    <ZoomMeeting
                                                        apiKey={apiKey}
                                                        apiSecret={apiSecret}
                                                        meetingNumber={meetingNumber}
                                                        userName={userName}
                                                        sessionName={sessionName}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* <div className="card p-3 shadow mt-2"> */}
                                <div className="col-md-12 mt-2" style={{borderRadius: '24px', overflow: 'hidden'}}>
                                    <SimpleBar style={{ height: `${(containerHeight * 0.65)}px`, overflowY: 'unset'}} >
                                        <div className="card p-3 shadow mt-2">
                                            <div className="row">
                                                {contactHistory?.vitals?.consultation_emr?.vitals ? (
                                                    <>
                                                        <div className="col-md-12">
                                                            <h4 style={{ fontSize: "16px", color: "#8930FD", fontWeight: "600" }}>
                                                                {t("Vitals")}
                                                            </h4>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>Blood Pressure</span><br />
                                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                                {contactHistory.vitals.consultation_emr.vitals.blood_pressure?.systolic}/{contactHistory.vitals.consultation_emr.vitals.blood_pressure?.diastolic} mmHg
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>Heart Rate</span><br />
                                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                                {contactHistory.vitals.consultation_emr.vitals.heart_rate} bpm
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>Oxygen Saturation</span><br />
                                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                                {contactHistory.vitals.consultation_emr.vitals.oxygen_saturation} %
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>Respiratory Rate</span><br />
                                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                                {contactHistory.vitals.consultation_emr.vitals.respiratory_rate} breaths/min
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>Temperature</span><br />
                                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                                {contactHistory.vitals.consultation_emr.vitals.temperature} °C
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>Date</span><br />
                                                            <span className="text-dark" style={{ fontSize: "13px" }}>
                                                                {safeFormatDOB(contactHistory.vitals.consultation_emr.vitals.date)}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>No vitals data available.</div>
                                                )}
                                            </div>
                                        </div>

                                        <ul className="list-unstyled mb-0">
                                            {contactHistory && contactHistory?.contact_history && contactHistory?.contact_history.length > 0 ? (
                                                contactHistory?.contact_history.map((contact, index) => (
                                                    <li key={index}>
                                                        <div className="card p-3 shadow mt-2">
                                                            <div className="row">
                                                                <div className="col-md-12 text-center">
                                                                    <h4 style={{ fontSize: "16px", color: "#8930FD", fontWeight: "600" }}>
                                                                        {formatContactHistoryDate(contact.createdAt)}
                                                                    </h4>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                                                                        {contact?.doctor_first_name} {contact?.doctor_last_name} - (<span style={{ textTransform: "capitalize" }}>{contact?.created_role}</span>)
                                                                    </span>
                                                                </div>
                                                                {/* Conditional rendering based on event type */}
                                                                {contact.event === "appointment" && (
                                                                    <React.Fragment>
                                                                        {/* Render appointment details */}
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Consultation")}</span>
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Consultation For")}: <span style={{ fontWeight: "400" }}>{contact?.consultation.consultation_for}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap', textTransform: "capitalize" }}>
                                                                                {t("Consultation Type")}: <span style={{ fontWeight: "400" }}>{contact?.consultation?.consultation_type}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {/* Appointment Date : <strong>{formatDOB(contact.consultation.appointment_date)}</strong> */}
                                                                                {t("Appointment Date")}: <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.consultation?.appointment_date)}</span>
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {contact?.event === "vaccination" && (
                                                                    <React.Fragment>
                                                                        {/* Render vaccination details */}
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Vaccinations")}</span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Name")} : <span style={{ fontWeight: "400" }}>{contact?.vaccinations?.name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Manufacture Name")} : <span style={{ fontWeight: "400" }}>{contact?.vaccinations?.manufacture_name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {/* Vaccination Date : <strong>{formatDOB(contact.vaccinations.vaccination_date)}</strong> */}
                                                                                {t("Vaccination Date")} : <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.vaccinations?.vaccination_date)}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {/* Expiry Date : <strong>{formatDOB(contact.vaccinations.expiry_date)}</strong> */}
                                                                                {t("Expiry Date")} : <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.vaccinations?.expiry_date)}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Comment")} : <span style={{ fontWeight: "400" }}>{contact?.vaccinations?.comment}</span>
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {contact?.event === "allergy" && (
                                                                    <React.Fragment>
                                                                        {/* Render allergy details */}
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Allergy Details")}</span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Name")} : <span style={{ fontWeight: "400" }}>{contact?.allergy_details?.name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Type")} : <span style={{ textTransform: "capitalize", fontWeight: "400" }}>{contact?.allergy_details?.type}</span>
                                                                            </span>
                                                                        </div>
                                                                        {contact?.allergy_details.type === "past" && (
                                                                            <React.Fragment>
                                                                                <div className="col-md-6 mt-2">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {/* Start Date : <strong>{formatDOB(contact.allergy_details.start_date)}</strong> */}
                                                                                        {t("Start Date")} : <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.allergy_details?.start_date)}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6 mt-2">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {/* End Date : <strong>{formatDOB(contact.allergy_details.end_date)}</strong> */}
                                                                                        {t("End Date")} : <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.allergy_details?.end_date)}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Comment")} : <span style={{ fontWeight: "400" }}>{contact.allergy_details.comment}</span>
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {contact?.event === "lab_report" && (
                                                                    <React.Fragment>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Lab Report")}</span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Test Name")}: <span style={{ fontWeight: "400" }}>{contact?.refered_examinations?.lab_profile_name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Date of Collect Sample")}: <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.refered_examinations?.date_of_collect_sample)}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {("Date of Receive Sample")}: <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.refered_examinations?.date_of_receive_sample)}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Lab Report")}: <a href={`${BaseurlForImage}${contact?.refered_examinations?.report}`} target="_blank" rel="noopener noreferrer"><span style={{ fontWeight: "400" }}>View File</span></a>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Comment")}: <span style={{ fontWeight: "400" }}>{contact?.refered_examinations?.comment}</span>
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {contact?.event === "documents" && (
                                                                    <React.Fragment>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Documents")}</span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Document Type")}: <span style={{ fontWeight: "400" }}>{contact?.documents?.document_type}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Specialty")}: <span style={{ fontWeight: "400" }}>{contact?.documents?.speciality}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {("Date Of Document")}: <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.documents?.date)}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {("Document File")}: <a href={`${BaseurlForImage}${contact?.documents?.report}`} target="_blank" rel="noopener noreferrer"><span style={{ fontWeight: "400" }}>View File</span></a>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Description")}: <span style={{ fontWeight: "400" }}>{contact?.documents?.comment}</span>
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {contact?.event === "imaging" && (
                                                                    <React.Fragment>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Imaging Report")}</span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Imaging")}: <span style={{ fontWeight: "400" }}>{contact?.imagings?.radiologies_name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Imaging Type")}: <span style={{ fontWeight: "400" }}>{contact?.imagings?.sub_radiologies_name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("File")}: <a href={`${BaseurlForImage}${contact?.imagings?.report}`} target="_blank" rel="noopener noreferrer"><span style={{ fontWeight: "400" }}>View File</span></a>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Result & Comment")}: <span style={{ fontWeight: "400" }}>{contact?.imagings?.comment}</span>
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {contact?.event === "refer" && (
                                                                    <React.Fragment>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Referred")} - {contact?.referals?.referal_type === "imaging" && (
                                                                                <span>Imaging</span>
                                                                            )}
                                                                                {contact?.referals?.referal_type === "doctor" && (
                                                                                    <span>Doctor</span>
                                                                                )}
                                                                                {contact?.referals?.referal_type === "procedure" && (
                                                                                    <span>Procedure</span>
                                                                                )}
                                                                                {contact?.referals?.referal_type === "lab_profile" && (
                                                                                    <span>Lab Report</span>
                                                                                )}</span>
                                                                        </div>
                                                                        {contact?.referals?.referal_type === "lab_profile" && (
                                                                            <>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        <span>Lab Test:</span>
                                                                                        {contact?.referals?.refered_examinations?.examinations.map(exam => (
                                                                                            <div key={exam._id}>
                                                                                                <span style={{ fontWeight: "400" }}>{exam.name}</span>
                                                                                            </div>
                                                                                        ))}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Comment")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.refered_examinations?.comment}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {contact?.referals?.referal_type === "procedure" && (
                                                                            <>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Lab Test Name")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.procedure?.name}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Comment")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.procedure?.comment}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {contact?.referals?.referal_type === "imaging" && (
                                                                            <>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Imaging")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.imaging?.radiologies_name}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Imaging Type")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.imaging?.sub_radiologies_name}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Imaging Comments")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.imaging?.comment}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {contact?.referals?.referal_type === "doctor" && (
                                                                            <>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Doctor Name")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.doctor?.name}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Comments")}: <span style={{ fontWeight: "400" }}>{contact?.referals?.doctor?.comment}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}

                                                                {contact.event === "consultation_emr" && (
                                                                    <React.Fragment>
                                                                        {/* Render consultation EMR details */}
                                                                        <div className="col-md-12 mt-2">
                                                                            <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>{t("Consultation EMR")}</span>
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("Physical Examination")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.physical_examination}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                {t("History")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.history}</span>
                                                                            </span>
                                                                        </div>
                                                                        {/* Render problems details */}
                                                                        {contact?.consultation_emr?.problems && (
                                                                            <>
                                                                                <div className="col-md-12 mt-2">
                                                                                    <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}><u>{t("Problems Details")} :</u></span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Name")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.problems?.name}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap', textTransform: "capitalize" }}>
                                                                                        {t("Category")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.problems?.category}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap', textTransform: "capitalize" }}>
                                                                                        {t("Type")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.problems?.type}</span>
                                                                                    </span>
                                                                                </div>
                                                                                {contact?.consultation_emr?.problems?.type === 'past' && (
                                                                                    <>
                                                                                        <div className="col-md-6">
                                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                                {t("Start Date")}: <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.consultation_emr?.problems?.start_date)}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                                {t("End Date")}: <span style={{ fontWeight: "400" }}>{safeFormatDOB(contact?.consultation_emr?.problems?.end_date)}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {/* Render prescriptions */}
                                                                        {contact?.consultation_emr?.prescriptions?.length > 0 && (
                                                                            <>
                                                                                <div className="col-md-12 mt-2">
                                                                                    <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}><u>{t("Prescriptions")} :</u></span>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-bordered table-responsive">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>{t("Medication Name")}</th>
                                                                                                <th>{t("Medication Type")}</th>
                                                                                                <th>{t("Medicine Dosage")}</th>
                                                                                                <th>{t("Quantity")}</th>
                                                                                                <th>{t("Instruction")}</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {contact?.consultation_emr?.prescriptions.map((prescription, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td>{prescription?.medication_name}</td>
                                                                                                    <td>{prescription?.medication_type}</td>
                                                                                                    <td>{prescription?.medicine_dosage}</td>
                                                                                                    <td>{prescription?.quantity}</td>
                                                                                                    <td>{prescription?.instruction}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {/* Render procedure details */}
                                                                        {contact?.consultation_emr?.procedure && (
                                                                            <>
                                                                                {/* Render procedure details */}
                                                                                <div className="col-md-12 mt-1">
                                                                                    <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}><u>{t("Procedure Details")} :</u></span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Intervention Name")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.intervention_name}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Pre-Op Diagnosis")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.pre_diagnosis}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Post-Op Diagnosis")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.post_diagnosis}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Surgeon")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.surgeon}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Assistant")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.assistant}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Anaesthetist")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.anaesthetist}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Description of Procedure")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.description}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Findings")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.findings}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {("Specimens")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.specimens}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Tubes, Drains, Packs etc")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.tube_drain}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Complications")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.complications}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Post-Op Instructions & Treatment")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.procedure?.post_instruction}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("File")}: <a href={`${BaseurlForImage}${contact?.consultation_emr?.procedure?.intervention_file}`} target="_blank" rel="noopener noreferrer"><span style={{ fontWeight: "400" }}>View File</span></a>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}


                                                                        {/* Render vitals */}
                                                                        {contact?.consultation_emr?.vitals && (
                                                                            <>
                                                                                {/* Render vitals */}
                                                                                <div className="col-md-12 mt-2">
                                                                                    <span className="text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}><u>{t("Vitals")} :</u></span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Temperature")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.vitals?.temperature}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Heart Rate")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.vitals?.heart_rate}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Blood Pressure")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.vitals?.blood_pressure?.systolic}/{contact?.consultation_emr?.vitals?.blood_pressure?.diastolic}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Respiratory Rate")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.vitals?.respiratory_rate}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                        {t("Oxygen Saturation")}: <span style={{ fontWeight: "400" }}>{contact?.consultation_emr?.vitals?.oxygen_saturation}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* <hr /> */}
                                                        {index < contactHistory.length - 1 && <hr style={{ margin: "0.5rem 0" }} />}
                                                    </li>
                                                ))
                                            ) : (
                                                <div className="card p-3 shadow mt-2">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="text-left mt-1">{t("No contact history data found")}.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </ul>
                                    </SimpleBar>

                                </div>
                            </div>
                            <div className="col-md-7 mt-2" style={{ paddingLeft: "5px", paddingRight: "8px" }}>
                                <div className="card p-3 shadow" style={{height: '100%'}}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="border-bottom">
                                                <div className="">
                                                    <nav>
                                                        <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded shadow overflow-hidden">
                                                            <li className="nav-item">
                                                                <Link
                                                                    className={`${activeIndex === 1 ? 'active' : ''} nav-link rounded-0`}
                                                                    to="#"
                                                                    onClick={() => {
                                                                        setActiveIndex(1);
                                                                    }}
                                                                >
                                                                    <div className="text-center">
                                                                        <div className="svg-container">
                                                                            <img
                                                                                src={activeIndex === 1 ? consultationImg2 : consultationImg1}
                                                                                className="tab-icons-size"
                                                                                alt="consultationImg"
                                                                            />
                                                                        </div>
                                                                        <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                            {t("Consultation")}
                                                                        </label>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link
                                                                    className={`${activeIndex === 2 ? 'active' : ''} nav-link rounded-0`}
                                                                    to="#"
                                                                    onClick={() => setAllergyShow(true)}
                                                                >
                                                                    <div className="text-center">
                                                                        <div className="svg-container">
                                                                            <img src={allergyImg} className="tab-icons-size" alt="Allergy" />
                                                                        </div>
                                                                        <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                            Allergy
                                                                        </label>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link
                                                                    className={`${activeIndex === 3 ? 'active' : ''} nav-link rounded-0`}
                                                                    to="#"
                                                                    onClick={() => setVaccinationShow(true)}
                                                                >
                                                                    <div className="text-center">
                                                                        <div className="svg-container">
                                                                            <img src={VaccinationImg} className="tab-icons-size" alt="Vaccination" />
                                                                        </div>
                                                                        <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                            Vaccination
                                                                        </label>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link
                                                                    className={`${activeIndex === 4 ? 'active' : ''} nav-link rounded-0`}
                                                                    to="#"
                                                                    onClick={() => setLabReportShow(true)}
                                                                >
                                                                    <div className="text-center">
                                                                        <div className="svg-container">
                                                                            <img src={labReportImg} className="tab-icons-size" alt="Lab Report" />
                                                                        </div>
                                                                        <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                            Lab Report
                                                                        </label>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item dropdown-primary me-2 mt-2">
                                                                <div className="text-center" style={{ cursor: "pointer" }} onClick={handleShowMore}>
                                                                    <div className="svg-container">
                                                                        {showMore ? <img src={upArrowImg} className="tab-icons-size" alt="Lab Report" /> : <img src={downArrowImg} className="tab-icons-size" alt="Lab Report" />}
                                                                    </div>
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>
                                                                        {showMore ? "Show Less" : "Show More"}
                                                                    </label>
                                                                </div>

                                                                <div className={`${showMore ? 'show' : ''} dropdown-menu dropdown-menu-left`}>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setDocumentsShow(true);
                                                                            handleShowMore();
                                                                        }}
                                                                    >
                                                                        <div className="text-left pt-1 pb-1" onClick={() => setDocumentsShow(true)}>
                                                                            <img src={DocumentsImg} className="tab-icons-size" alt="Documents" />
                                                                            <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                                Documents
                                                                            </label>
                                                                        </div>
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setPrescriptionShow(true);
                                                                            handleShowMore();
                                                                        }}
                                                                    >
                                                                        <div className="text-left pt-1 pb-1">
                                                                            <img src={prescriptionImg} className="tab-icons-size" alt="Prescription" />
                                                                            <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                                Prescription
                                                                            </label>
                                                                        </div>
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setProcedureShow(true);
                                                                            handleShowMore();
                                                                        }}
                                                                    >
                                                                        <div className="text-left pt-1 pb-1">
                                                                            <img src={procedureImg} className="tab-icons-size" alt="Procedure" />
                                                                            <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                                Procedure
                                                                            </label>
                                                                        </div>
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setImagingShow(true);
                                                                            handleShowMore();
                                                                        }}
                                                                    >
                                                                        <div className="text-left pt-1 pb-1">
                                                                            <img src={imagingReportImg} className="tab-icons-size" alt="Imaging Report" />
                                                                            <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                                Imaging Report
                                                                            </label>
                                                                        </div>
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setActiveIndex(9);
                                                                            handleShowMore();
                                                                        }}
                                                                    >
                                                                        <div className="text-left pt-1 pb-1">
                                                                            <img src={referImg} className="tab-icons-size" alt="Refer" />
                                                                            <label className="custom-font-label" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                                                                Refer
                                                                            </label>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SimpleBar style={{ height: `${(containerHeight * 0.67)}px` }}>
                                        <div className="tab-content mt-2" id="pills-tabContent">
                                            {activeIndex === 1 ?
                                                <div className="tab-pane fade show active">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h6>
                                                                {t("Enter New Record")}
                                                            </h6>
                                                            <form className="mt-2" onSubmit={handleSubmit}>
                                                                <div className="row">
                                                                    {/* Date Field */}
                                                                    <div className="col-md-6">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="consultationDate"
                                                                                value={formValues.consultationDate} // Bind value to formValues.consultationDate
                                                                                // onChange={handleInputChange}
                                                                                onChange={(e) => setFormValues({ ...formValues, consultationDate: e.target.value })}
                                                                                required=""
                                                                                style={{ borderRadius: "8px" }}
                                                                                disabled={!!(appointmentData?.appointment)}
                                                                            />
                                                                            {formSubmitted && formErrors.consultationDate && (
                                                                                <div className="text-danger">{formErrors.consultationDate}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {/* Time Field */}
                                                                    {/* <div className="col-md-4">
                                                                        <div className="row">
                                                                            <label className="custom-font-label">Consultation Time</label>
                                                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                                                <div className="form-group mb-0 w-100 me-auto">
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        style={{ borderRadius: "8px" }}
                                                                                        value={formValues.startTime}
                                                                                        onChange={handleInputChange}
                                                                                        name="startTime"
                                                                                        required=""
                                                                                        disabled={!!(appointmentData?.appointment)}
                                                                                    >
                                                                                        <option value="" disabled>From</option>
                                                                                        {setTime.map((time, idx) => (
                                                                                            <option key={idx} value={time.time}>
                                                                                                {time.time}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                                                <div className="form-group mb-0 w-100 me-auto">
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        style={{ borderRadius: "8px" }}
                                                                                        value={formValues.endTime}
                                                                                        onChange={handleInputChange}
                                                                                        name="endTime"
                                                                                        required=""
                                                                                        disabled={!!(appointmentData?.appointment)}
                                                                                    >
                                                                                        <option value="" disabled>To</option>
                                                                                        {setTime.map((time, idx) => (
                                                                                            <option key={idx} value={time.time}>
                                                                                                {time.time}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            {formSubmitted && formErrors.startTime && (
                                                                                <div className="text-danger">{formErrors.startTime}</div>
                                                                            )}
                                                                            {formSubmitted && formErrors.endTime && (
                                                                                <div className="text-danger">{formErrors.endTime}</div>
                                                                            )}
                                                                        </div>
                                                                    </div> */}

                                                                    {/* Consultation Type Field */}
                                                                    <div className="col-md-6">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Consultation Type</label>
                                                                            <select
                                                                                className="form-select form-control"
                                                                                value={formValues.consultationType}
                                                                                onChange={handleInputChange}
                                                                                name="consultationType"
                                                                                required=""
                                                                                style={{ borderRadius: "8px" }}
                                                                                disabled={!!(appointmentData?.appointment)}
                                                                            >
                                                                                <option value="" disabled>Select</option>
                                                                                <option value="online">Online</option>
                                                                                <option value="offline">Offline</option>
                                                                            </select>
                                                                            {formSubmitted && formErrors.consultationType && (
                                                                                <div className="text-danger">{formErrors.consultationType}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Consultation Comment Field */}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Consultation For</label>
                                                                            <textarea
                                                                                name="consultationComments"
                                                                                id="consultationComments"
                                                                                rows="2"
                                                                                className="form-control"
                                                                                value={formValues.consultationComments} /* Bind value to formValues.consultationComments */
                                                                                onChange={handleInputChange} /* Handle input change */
                                                                                disabled={!!(appointmentData?.appointment)}
                                                                            ></textarea>
                                                                            {formSubmitted && formErrors.consultationComments && (
                                                                                <div className="text-danger">{formErrors.consultationComments}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr style={{ margin: "0px" }} />
                                                                <div className="row">
                                                                    {/* Problem Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Problem</label>
                                                                            <select
                                                                                name="problem"
                                                                                className="form-select form-control"
                                                                                value={formValues.problem}
                                                                                onChange={handleInputChange}
                                                                                style={{ borderRadius: "8px" }}
                                                                            >
                                                                                <option value="" disabled>Select Problem</option>
                                                                                {problems.map((problem) => (
                                                                                    <option key={problem._id} value={problem.name}>
                                                                                        {problem.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {/* Category Field */}
                                                                    {/* <div className="col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Category</label>
                                                                            <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                    <div className="form-check mb-0">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="category"
                                                                                            value="major"
                                                                                            checked={formValues.category === 'major'}
                                                                                            onChange={handleInputChange}
                                                                                            id="categoryMajor"
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor="categoryMajor">
                                                                                            Major
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                    <div className="form-check mb-0">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="category"
                                                                                            value="minor"
                                                                                            checked={formValues.category === 'minor'}
                                                                                            onChange={handleInputChange}
                                                                                            id="categoryMinor"
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor="categoryMinor">
                                                                                            Minor
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Category</label>
                                                                            <div style={{ display: "flex", marginTop: "5px" }}>
                                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                    <div className="form-check mb-0">
                                                                                        <input
                                                                                            className="form-check-input custom-radio-input"
                                                                                            type="radio"
                                                                                            name="category"
                                                                                            value="major"
                                                                                            checked={formValues.category === 'major'}
                                                                                            onChange={handleInputChange}
                                                                                            id="categoryMajor"
                                                                                        />
                                                                                        <label className="form-check-label custom-radio-label" htmlFor="categoryMajor">
                                                                                            Major
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                    <div className="form-check mb-0">
                                                                                        <input
                                                                                            className="form-check-input custom-radio-input"
                                                                                            type="radio"
                                                                                            name="category"
                                                                                            value="minor"
                                                                                            checked={formValues.category === 'minor'}
                                                                                            onChange={handleInputChange}
                                                                                            id="categoryMinor"
                                                                                        />
                                                                                        <label className="form-check-label custom-radio-label" htmlFor="categoryMinor">
                                                                                            Minor
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {/* Type Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Type</label>
                                                                            <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input custom-radio-input"
                                                                                            type="radio"
                                                                                            name="type"
                                                                                            value="active"
                                                                                            checked={formValues.type === 'active'}
                                                                                            onChange={handleTypeChange}
                                                                                            id="typeActive"
                                                                                        />
                                                                                        <label className="form-check-label custom-radio-label" htmlFor="typeActive">
                                                                                            Active
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input custom-radio-input"
                                                                                            type="radio"
                                                                                            name="type"
                                                                                            value="past"
                                                                                            checked={formValues.type === 'past'}
                                                                                            onChange={handleTypeChange}
                                                                                            id="typePast"
                                                                                        />
                                                                                        <label className="form-check-label custom-radio-label" htmlFor="typePast">
                                                                                            Past
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {formValues.type === "past" && (
                                                                    <div className="row">
                                                                        {/* Problem Start Date Field */}
                                                                        <div className="col-md-6">
                                                                            <div className="mb-2">
                                                                                <label className="custom-font-label">Problem Start Date</label>
                                                                                <input
                                                                                    name="problemStartDate"
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    value={formValues.problemStartDate}
                                                                                    onChange={handleInputChange}
                                                                                    style={{ borderRadius: "8px" }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* Problem End/Expected Date Field */}
                                                                        <div className="col-md-6">
                                                                            <div className="mb-2">
                                                                                <label className="custom-font-label">Problem End/Expected Date</label>
                                                                                <input
                                                                                    name="problemEndDate"
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    value={formValues.problemEndDate}
                                                                                    onChange={handleInputChange}
                                                                                    style={{ borderRadius: "8px" }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <hr style={{ margin: "0px" }} />
                                                                {/* History Field */}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">History</label>
                                                                            <textarea
                                                                                name="history"
                                                                                id="history"
                                                                                rows="2"
                                                                                className="form-control"
                                                                                value={formValues.history} /* Bind value to formValues.history */
                                                                                onChange={handleInputChange} /* Handle input change */
                                                                            ></textarea>
                                                                            {formSubmitted && formErrors.history && (
                                                                                <div className="text-danger">{formErrors.history}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr style={{ margin: "0px" }} />
                                                                <div className="row">
                                                                    {/* Temperature Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Temperature (&deg;C)</label>
                                                                            <input
                                                                                name="Temperature"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Temp"
                                                                                value={formValues.Temperature}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: "8px" }}
                                                                            />
                                                                            {formSubmitted && formErrors.Temperature && <div className="text-danger">{formErrors.Temperature}</div>}
                                                                        </div>
                                                                    </div>
                                                                    {/* Heart Rate Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Heart Rate (BPM)</label>
                                                                            <input
                                                                                name="heartRate"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="HR"
                                                                                value={formValues.heartRate}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: "8px" }}
                                                                            />
                                                                            {formSubmitted && formErrors.heartRate && <div className="text-danger">{formErrors.heartRate}</div>}
                                                                        </div>
                                                                    </div>
                                                                    {/* Blood Pressure Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="form-group text-start">
                                                                            <label className="custom-font-label">Blood Pressure (mmHg)</label>
                                                                            <div className="d-flex align-items-center">
                                                                                {/* Systolic input */}
                                                                                <input
                                                                                    className={`form-control`}
                                                                                    placeholder="Systolic"
                                                                                    name="systolic"
                                                                                    type="text"
                                                                                    maxLength={3}
                                                                                    value={systolic}
                                                                                    onChange={handleInputChange2}
                                                                                    style={{ borderRadius: "8px" }}
                                                                                />
                                                                                &nbsp;<b>/</b>&nbsp;
                                                                                {/* Diastolic input */}
                                                                                <input
                                                                                    className={`form-control`}
                                                                                    placeholder="Diastolic"
                                                                                    name="diastolic"
                                                                                    type="text"
                                                                                    maxLength={3}
                                                                                    value={diastolic}
                                                                                    onChange={handleInputChange2}
                                                                                    style={{ borderRadius: "8px" }}
                                                                                />
                                                                            </div>
                                                                            {/* Display errors if any */}
                                                                            {formSubmitted && (formErrors.systolic || formErrors.diastolic) && (
                                                                                <div className="text-danger">
                                                                                    <small>
                                                                                        {formErrors.systolic && <div>{formErrors.systolic}</div>}
                                                                                        {formErrors.diastolic && <div>{formErrors.diastolic}</div>}
                                                                                    </small>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {/* Respiratory Rate Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Respiratory Rate (bpm)</label>
                                                                            <input
                                                                                name="respiratoryRate"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="RR"
                                                                                value={formValues.respiratoryRate}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: "8px" }}
                                                                            />
                                                                            {formSubmitted && formErrors.respiratoryRate && <div className="text-danger">{formErrors.respiratoryRate}</div>}
                                                                        </div>
                                                                    </div>
                                                                    {/* Blood Oxygen (%) Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <label className="custom-font-label">Blood Oxygen (%)</label>
                                                                            <input
                                                                                name="bloodOxygen"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="BO"
                                                                                value={formValues.bloodOxygen}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: "8px" }}
                                                                            />
                                                                            {formSubmitted && formErrors.bloodOxygen && <div className="text-danger">{formErrors.bloodOxygen}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Physical Examination Field */}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-1">
                                                                            <label className="custom-font-label">Physical Examination</label>
                                                                            <textarea
                                                                                name="physicalExamination"
                                                                                id="physicalExamination"
                                                                                rows="2"
                                                                                className="form-control"
                                                                                value={formValues.physicalExamination} /* Bind value to formValues.physicalExamination */
                                                                                onChange={handleInputChange} /* Handle input change */
                                                                            ></textarea>
                                                                            {formSubmitted && formErrors.physicalExamination && (
                                                                                <div className="text-danger">{formErrors.physicalExamination}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Prescription Details */}
                                                                <div className="row mb-4">
                                                                    {prescriptionList.length > 0 && prescriptionList[0].medication && prescriptionList[0].prescriptionType && prescriptionList[0].dose && prescriptionList[0].quantity && prescriptionList[0].comment && (
                                                                        <>
                                                                            <div className="col-md-12">
                                                                                <label className="custom-font-label">Prescriptions</label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive shadow rounded">
                                                                                    <table className="table table-center bg-white mb-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>SR No</th>
                                                                                                <th>Medication</th>
                                                                                                <th>Prescription Type</th>
                                                                                                <th>Dose</th>
                                                                                                <th>Quantity</th>
                                                                                                <th>Comment</th>
                                                                                                <th>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {prescriptionList.map((item, i) => (
                                                                                                <tr key={i}>
                                                                                                    <td>{i + 1}</td>
                                                                                                    <td>{item.medication}</td>
                                                                                                    <td style={{ textTransform: "capitalize" }}>{item.prescriptionType}</td>
                                                                                                    <td>{item.dose}</td>
                                                                                                    <td>{item.quantity}</td>
                                                                                                    <td>{item.comment}</td>
                                                                                                    <td>
                                                                                                        <Link onClick={() => setPrescriptionShow(true)} className="btn btn-icon btn-pills btn-soft-primary">
                                                                                                            <FiEdit />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </div>

                                                                {/* Procedure Details */}
                                                                {savedProcedure && (
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-12">
                                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                                <label className="custom-font-label">Procedure Details</label>
                                                                                <Link onClick={() => setProcedureShow(true)} className="btn btn-icon btn-pills btn-soft-primary">
                                                                                    <FiEdit />
                                                                                </Link>
                                                                            </div>
                                                                            <div className="p-3 border rounded">
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Procedure: <br /> <strong>{savedProcedure.procedure}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Pre-Op Diagnosis: <br /> <strong>{savedProcedure.preOpDiagnosis}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Post-Op Diagnosis: <br /> <strong>{savedProcedure.postOpDiagnosis}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Surgeon: <br /> <strong>{savedProcedure.surgeon}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Assistant: <br /> <strong>{savedProcedure.assistant}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Anaesthetist: <br /> <strong>{savedProcedure.anaesthetist}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Description of Procedure: <br /> <strong>{savedProcedure.procedureDescription}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Findings: <br /> <strong>{savedProcedure.findings}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Specimens: <br /> <strong>{savedProcedure.specimens}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Tubes, Drains, Packs: <br /> <strong>{savedProcedure.tubesDrainsPacks}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Complications: <br /> <strong>{savedProcedure.complications}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                            Post-Op Instructions: <br /> <strong>{savedProcedure.postOpInstructions}</strong>
                                                                                        </span>
                                                                                    </div>

                                                                                    {savedProcedure.file && (
                                                                                        <div className="col-md-4">
                                                                                            <span style={{ fontSize: "13px", fontWeight: "bold", textWrap: 'wrap' }}>
                                                                                                File: <br />
                                                                                                <a href={URL.createObjectURL(savedProcedure.file)} target="_blank" rel="noopener noreferrer"><span style={{ fontWeight: "400" }}>View File</span></a>
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-5 btn-responsive-custom mb-2">
                                                                        <button type="button" style={{ padding: "9px 28px" }} className="btn btn-danger w-100">Cancel</button>
                                                                    </div>
                                                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-5 mb-2">
                                                                        <button type="submit" style={{ padding: "9px 28px" }} className="btn btn-primary w-100">Save</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                            {activeIndex === 9 && (
                                                <div className="tab-pane fade show active">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h6>Referred</h6>
                                                        </div>
                                                        {/* Referred Form */}
                                                        <form className="mt-2" onSubmit={handleReferSubmit}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="mb-2">
                                                                        <label className="custom-font-label">Refer Options</label>
                                                                        <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                            <div className="custom-control custom-radio custom-control-inline" style={{ marginLeft: "4px", marginRight: "12px" }}>
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input custom-radio-input" type="radio" name="referOptions" value="lab_profile" id="labReportBtn" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label custom-radio-label" htmlFor="labReportBtn">Lab Report</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input custom-radio-input" type="radio" name="referOptions" value="imaging" id="imagingBtn" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label custom-radio-label" htmlFor="imagingBtn">Imaging</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input custom-radio-input" type="radio" name="referOptions" value="doctor" id="ReferredDoctor" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label custom-radio-label" htmlFor="ReferredDoctor">Referred Doctor</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input custom-radio-input" type="radio" name="referOptions" value="procedure" id="procedureBtn" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label custom-radio-label" htmlFor="procedureBtn">Procedure</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {referredDoctorFormErrors.referOptionMessage && (
                                                                            <div className="text-danger">{referredDoctorFormErrors.referOptionMessage}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {referOption === "lab_profile" && (
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Lab Test</label>
                                                                            <Multiselect
                                                                                displayValue="name"
                                                                                placeholder="Select Lab Test"
                                                                                // selectedValues={labReportsTestFormValues.labTest}
                                                                                options={referLabTestName} // Use departments array directly
                                                                                selectedValues={labReportsTestFormValues.labTest.filter(Boolean)}
                                                                                onSelect={(selectedList) => {
                                                                                    setLabReportsTestFormValues({ ...labReportsTestFormValues, labTest: selectedList });
                                                                                    setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTest: "" });
                                                                                }}
                                                                                onRemove={(selectedList) => {
                                                                                    setLabReportsTestFormValues({ ...labReportsTestFormValues, labTest: selectedList });
                                                                                    setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTest: "" });
                                                                                }}
                                                                                required=""
                                                                            />
                                                                            {labReportsTestFormErrors.labTest && (
                                                                                <div className="text-danger">{labReportsTestFormErrors.labTest}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Lab Test Comments</label>
                                                                                <textarea
                                                                                    name="labTestComments"
                                                                                    id="labTestComments"
                                                                                    rows="5"
                                                                                    className="form-control"
                                                                                    value={labReportsTestFormValues.labTestComments} // Bind value with labTestComments from state
                                                                                    onChange={(e) => {
                                                                                        setLabReportsTestFormValues({ ...labReportsTestFormValues, labTestComments: e.target.value });
                                                                                    }}
                                                                                    required=""
                                                                                ></textarea>
                                                                                {labReportsTestFormErrors.labTestComments && (
                                                                                    <div className="text-danger">{labReportsTestFormErrors.labTestComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )}
                                                                {referOption === "imaging" && (
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3">
                                                                                    <label className="custom-font-label">Imaging</label>
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        style={{ borderRadius: "8px" }}
                                                                                        value={imagingTypeFormValues.imaging}
                                                                                        onChange={(e) => {
                                                                                            setImagingTypeFormValues({ ...imagingTypeFormValues, imaging: e.target.value });
                                                                                            setImagingTypeFormErrors({ ...imagingTypeFormErrors, imaging: '' });
                                                                                        }}
                                                                                        required=""
                                                                                    >
                                                                                        <option value="" disabled>Select Imaging</option>
                                                                                        {referImaging.map((item) => (
                                                                                            <option key={item._id} value={item.name}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                    {imagingTypeFormErrors.imaging && (
                                                                                        <div className="text-danger">{imagingTypeFormErrors.imaging}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3">
                                                                                    <label className="custom-font-label">Imaging Type</label>
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        style={{ borderRadius: "8px" }}
                                                                                        value={imagingTypeFormValues.imagingType}
                                                                                        onChange={(e) => {
                                                                                            setImagingTypeFormValues({ ...imagingTypeFormValues, imagingType: e.target.value });
                                                                                            setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingType: '' });
                                                                                        }}
                                                                                        required=""
                                                                                    >
                                                                                        <option value="" disabled>Select Imaging Type</option>
                                                                                        {referImagingType.map((item) => (
                                                                                            <option key={item._id} value={item.name}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                    {imagingTypeFormErrors.imagingType && (
                                                                                        <div className="text-danger">{imagingTypeFormErrors.imagingType}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="mb-3">
                                                                                    <label className="custom-font-label">Imaging Comments</label>
                                                                                    <textarea
                                                                                        name="imagingComments"
                                                                                        id="imagingComments"
                                                                                        rows="5"
                                                                                        className="form-control"
                                                                                        required=""
                                                                                    ></textarea>
                                                                                    {imagingTypeFormErrors.imagingComments && (
                                                                                        <div className="text-danger">{imagingTypeFormErrors.imagingComments}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}


                                                                {referOption === "doctor" && (
                                                                    <div>
                                                                        <div className="col-md-12 mb-3">
                                                                            <label className="custom-font-label">Doctor</label>
                                                                            <select
                                                                                className="form-select form-control"
                                                                                style={{ borderRadius: "8px" }}
                                                                                value={selectedDoctor}
                                                                                onChange={(e) => {
                                                                                    setSelectedDoctor(e.target.value);
                                                                                    setReferredDoctorFormErrors({ ...referredDoctorFormErrors, doctor: '' });
                                                                                }}
                                                                                required=""
                                                                            >
                                                                                <option value="" disabled>Select Doctor</option>
                                                                                {referDoctor.map((item) => (
                                                                                    <option key={item._id} value={item.full_name}>
                                                                                        {item.full_name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            {referredDoctorFormErrors.doctor && (
                                                                                <div className="text-danger">{referredDoctorFormErrors.doctor}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Doctor Comments</label>
                                                                                <textarea name="doctorComments" id="doctorComments" rows="5" className="form-control" required=""></textarea>
                                                                                {referredDoctorFormErrors.doctorComments && (
                                                                                    <div className="text-danger">{referredDoctorFormErrors.doctorComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {referOption === "procedure" && (
                                                                    <div>
                                                                        <div className="col-md-12">
                                                                            {/* Procedure options */}
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Select Procedure</label>
                                                                                <select
                                                                                    className="form-select form-control"
                                                                                    style={{ borderRadius: "8px" }}
                                                                                    value={referProcedureFormValues.procedureType}
                                                                                    onChange={(e) => {
                                                                                        setReferProcedureFormValues({ ...referProcedureFormValues, procedureType: e.target.value });
                                                                                        setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureType: '' }); // Fixed typo here
                                                                                    }}
                                                                                    required=""
                                                                                >
                                                                                    <option value="" disabled>Select Procedure</option>
                                                                                    {referProcedure.map((item) => (
                                                                                        <option key={item._id} value={item.name}>
                                                                                            {item.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {referProcedureFormErrors.procedureType && ( // Corrected this line
                                                                                    <div className="text-danger">{referProcedureFormErrors.procedureType}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">{t("Procedure Comments")}</label>
                                                                                <textarea name="procedureComments" id="procedureComments" rows="5" className="form-control" value={referProcedureFormValues.procedureComments} onChange={(e) => setReferProcedureFormValues({ ...referProcedureFormValues, procedureComments: e.target.value })} required=""></textarea>
                                                                                {referProcedureFormErrors.procedureComments && (
                                                                                    <div className="text-danger">{referProcedureFormErrors.procedureComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                            <div className="row mb-4" style={{ justifyContent: 'end' }}>
                                                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-5">
                                                                    <button type="submit" style={{ padding: "9px 28px" }} className="btn btn-primary w-100">{t("Save")}</button>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-5 btn-responsive-custom">
                                                                    <button type="button" style={{ padding: "9px 28px" }} className="btn btn-danger w-100" onClick={handleClear}>{t("Clear")}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </SimpleBar>
                                    {/* Allergy Modal */}
                                    <Modal show={allergyShow} onHide={() => { setAllergyShow(false); allergyResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="allergyModalID">Add New Allergy</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitAllergy}>
                                                <div className="row">
                                                    {/* Allergy Name Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Allergy</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={allergyFormValues.allergyName}
                                                                onChange={handleInputChange3}
                                                                name="allergyName"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {allergy.map((item) => (
                                                                    <option key={item._id} value={item.allergy_name}>
                                                                        {item.allergy_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {allergyFormSubmitted && allergyFormErrors.allergyName && (
                                                                <div className="text-danger">{allergyFormErrors.allergyName}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Allergy Type Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Allergy Type</label>
                                                            <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                    <div className="form-check mb-0">
                                                                        <input
                                                                            className="form-check-input custom-radio-input"
                                                                            type="radio"
                                                                            name="allergyType"
                                                                            value="active"
                                                                            id="flexRadioDefault1"
                                                                            onChange={handleInputChange3}
                                                                        />
                                                                        <label className="form-check-label custom-radio-label" htmlFor="flexRadioDefault1">Active</label>
                                                                    </div>
                                                                </div>

                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <div className="form-check mb-0">
                                                                        <input
                                                                            className="form-check-input custom-radio-input"
                                                                            type="radio"
                                                                            name="allergyType"
                                                                            value="past"
                                                                            id="flexRadioDefault2"
                                                                            onChange={handleInputChange3}
                                                                        />
                                                                        <label className="form-check-label custom-radio-label" htmlFor="flexRadioDefault2">Past</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {allergyFormSubmitted && allergyFormErrors.allergyType && (
                                                                <div className="text-danger">{allergyFormErrors.allergyType}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Conditional Fields for Start Date and End Date */}
                                                    {allergyFormValues.allergyType === 'past' && (
                                                        <>
                                                            {/* Allergy Start Date Field */}
                                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="custom-font-label">Start Date</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        name="allergyStartDate"
                                                                        value={allergyFormValues.allergyStartDate}
                                                                        onChange={handleInputChange3}
                                                                        required=""
                                                                        style={{ borderRadius: "8px" }}
                                                                    />
                                                                    {allergyFormSubmitted && allergyFormErrors.allergyStartDate && (
                                                                        <div className="text-danger">{allergyFormErrors.allergyStartDate}</div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {/* Allergy End Date Field */}
                                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="custom-font-label">Expected End Date</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        name="allergyEndDate"
                                                                        value={allergyFormValues.allergyEndDate}
                                                                        onChange={handleInputChange3}
                                                                        required=""
                                                                        style={{ borderRadius: "8px" }}
                                                                    />
                                                                    {allergyFormSubmitted && allergyFormErrors.allergyEndDate && (
                                                                        <div className="text-danger">{allergyFormErrors.allergyEndDate}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                {/* Allergy Comment Field */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Comment</label>
                                                            <textarea
                                                                name="allergyComments"
                                                                id="allergyComments"
                                                                rows="3"
                                                                className="form-control"
                                                                value={allergyFormValues.allergyComments}
                                                                onChange={handleInputChange3}
                                                                required=""
                                                            ></textarea>
                                                            {allergyFormSubmitted && allergyFormErrors.allergyComments && (
                                                                <div className="text-danger">{allergyFormErrors.allergyComments}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100"
                                                            onClick={() => { setAllergyShow(false); allergyResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Active / Past Allergies</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="15%">Allergy Name</th>
                                                                    <th className="border-bottom p-2" width="15%">Allergy Type</th>
                                                                    <th className="border-bottom p-2" width="15%">Start Date</th>
                                                                    <th className="border-bottom p-2" width="15%">Expected End Date</th>
                                                                    <th className="border-bottom p-2" width="30%">Comment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listAllergy.length > 0 ? (
                                                                    listAllergy.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.allergy_details?.name}</td>
                                                                            <td style={{ textTransform: "capitalize" }}>{item?.allergy_details?.type}</td>
                                                                            <td>{formatDate(item?.allergy_details?.start_date)}</td>
                                                                            <td>{formatDate(item?.allergy_details?.end_date)}</td>
                                                                            <td>{item?.allergy_details?.comment}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td className="text-center" colSpan="6">No allergy records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>


                                    {/* Vaccination Modal */}
                                    <Modal show={vaccinationShow} onHide={() => { setVaccinationShow(false); vaccinationResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="vaccinationModalID">Add New Vaccinations</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitVaccination}>
                                                <div className="row">
                                                    {/* Vaccine Name Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Vaccine Name</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={vaccinationFormValues.vaccineName}
                                                                onChange={handleVaccinationInputChange}
                                                                name="vaccineName"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {vaccinations.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.vaccineName && (
                                                                <div className="text-danger">{vaccinationFormErrors.vaccineName}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Manufacture Name Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Manufacture Name</label>
                                                            <input
                                                                name="manufactureName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Manufacture Name"
                                                                value={vaccinationFormValues.manufactureName}
                                                                onChange={handleVaccinationInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.manufactureName && (
                                                                <div className="text-danger">{vaccinationFormErrors.manufactureName}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Date of Vaccination Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Vaccination</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="vaccinationDate"
                                                                value={vaccinationFormValues.vaccinationDate}
                                                                onChange={handleVaccinationInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.vaccinationDate && (
                                                                <div className="text-danger">{vaccinationFormErrors.vaccinationDate}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Expiry Date Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Expiry Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="expiryDate"
                                                                value={vaccinationFormValues.expiryDate}
                                                                onChange={handleVaccinationInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.expiryDate && (
                                                                <div className="text-danger">{vaccinationFormErrors.expiryDate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Additional Notes Field */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Additional Notes</label>
                                                            <textarea
                                                                name="additionalNotes"
                                                                id="additionalNotes"
                                                                rows="3"
                                                                className="form-control"
                                                                value={vaccinationFormValues.additionalNotes}
                                                                onChange={handleVaccinationInputChange}
                                                            ></textarea>
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.additionalNotes && (
                                                                <div className="text-danger">{vaccinationFormErrors.additionalNotes}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setVaccinationShow(false); vaccinationResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Vaccinations</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="18%">Name</th>
                                                                    <th className="border-bottom p-2" width="18%">Manufacturer</th>
                                                                    <th className="border-bottom p-2" width="12%">Date Of Vaccine</th>
                                                                    <th className="border-bottom p-2" width="12%">Expire Date</th>
                                                                    <th className="border-bottom p-2" width="30%">Additional Notes</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listVaccination.length > 0 ? (
                                                                    listVaccination.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.vaccinations?.name}</td>
                                                                            <td>{item?.vaccinations?.manufacture_name}</td>
                                                                            <td>{formatDate(item?.vaccinations?.vaccination_date)}</td>
                                                                            <td>{formatDate(item?.vaccinations?.expiry_date)}</td>
                                                                            <td>{item?.vaccinations?.comment}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="text-center">
                                                                        <td colSpan="6">No vaccination records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Lab Report Modal */}
                                    <Modal show={labReportShow} onHide={() => { setLabReportShow(false); resetLabReportForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="labReportModalID">Add New Lab Report</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitLabReport}>
                                                <div className="row">
                                                    {/* Test Name Field */}
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Test Name</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={labReportFormValues.testName}
                                                                onChange={handleInputChange4}
                                                                name="testName"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {testName.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {labReportFormSubmitted && labReportFormErrors.testName && (
                                                                <div className="text-danger">{labReportFormErrors.testName}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Date of Collect Sample Field */}
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Collect Sample</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="dateOfCollectSample"
                                                                value={labReportFormValues.dateOfCollectSample}
                                                                onChange={handleInputChange4}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {labReportFormSubmitted && labReportFormErrors.dateOfCollectSample && (
                                                                <div className="text-danger">{labReportFormErrors.dateOfCollectSample}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Date of Receive Sample Field */}
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Receive Sample</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="dateOfReceiveSample"
                                                                value={labReportFormValues.dateOfReceiveSample}
                                                                onChange={handleInputChange4}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {labReportFormSubmitted && labReportFormErrors.dateOfReceiveSample && (
                                                                <div className="text-danger">{labReportFormErrors.dateOfReceiveSample}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {/* Document File Field */}
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Lab Report</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="documentFile"
                                                                onChange={handleLabReportFileChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {labReportFormSubmitted && labReportFormErrors.labReportFile && (
                                                                <div className="text-danger">{labReportFormErrors.labReportFile}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* File preview column */}
                                                    <div className="col-lg-2 col-md-2">
                                                        {labReportFilePreviewURL && (
                                                            <img
                                                                src={labReportFilePreviewURL}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Comment</label>
                                                            <textarea
                                                                className="form-control"
                                                                name="labReportComment"
                                                                value={labReportFormValues.labReportComment}
                                                                onChange={handleInputChange4}
                                                                rows="3"
                                                            />
                                                            {labReportFormSubmitted && labReportFormErrors.labReportComment && (
                                                                <div className="text-danger">{labReportFormErrors.labReportComment}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setLabReportShow(false); resetLabReportForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Lab Reports</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="20%">Test Name</th>
                                                                    <th className="border-bottom p-2" width="20%">Date Of Collected Sample</th>
                                                                    <th className="border-bottom p-2" width="20%">date Of ReceiveS ample</th>
                                                                    <th className="border-bottom p-2" width="20%">comment</th>
                                                                    <th className="border-bottom p-2" width="10%">Reports</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listLabReport.length > 0 ? (
                                                                    listLabReport.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.refered_examinations?.lab_profile_name}</td>
                                                                            <td>{formatDate(item?.refered_examinations?.date_of_collect_sample)}</td>
                                                                            <td>{formatDate(item?.refered_examinations?.date_of_receive_sample)}</td>
                                                                            <td>
                                                                                <EllipsisTooltip text={item?.refered_examinations?.comment} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <Link
                                                                                    className="btn btn-icon btn-pills btn-soft-primary"
                                                                                    to={`${BaseurlForImage}${item?.refered_examinations?.report}`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                ><LiaFile /></Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="text-center">
                                                                        <td colSpan="6">No lab report records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Documents Modal */}
                                    <Modal show={documentsShow} onHide={() => { setDocumentsShow(false); documentsResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="documentsModalID">Add New Document</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitDocuments}>
                                                <div className="row">
                                                    {/* Document Type Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Document Type</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={documentsFormValues.documentType}
                                                                onChange={handleDocumentsInputChange}
                                                                name="documentType"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {documentType.map((item) => (
                                                                    <option key={item._id} value={item.document_type}>
                                                                        {item.document_type}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {documentFormSubmitted && documentsFormErrors.documentType && (
                                                                <div className="text-danger">{documentsFormErrors.documentType}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Specialty Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Specialty</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={documentsFormValues.specialty}
                                                                onChange={handleDocumentsInputChange}
                                                                name="specialty"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {specialty.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {documentFormSubmitted && documentsFormErrors.specialty && (
                                                                <div className="text-danger">{documentsFormErrors.specialty}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Date of Document Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Document</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="documentDate"
                                                                value={documentsFormValues.documentDate}
                                                                onChange={handleDocumentsInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {documentFormSubmitted && documentsFormErrors.documentDate && (
                                                                <div className="text-danger">{documentsFormErrors.documentDate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Document File Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Document File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="documentFile"
                                                                onChange={handleDocumentsFileChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {documentFormSubmitted && documentsFormErrors.documentFile && (
                                                                <div className="text-danger">{documentsFormErrors.documentFile}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* File preview column */}
                                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                                        {filePreviewURL && (
                                                            <img
                                                                src={filePreviewURL}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Additional Notes Field */}
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Description</label>
                                                            <textarea
                                                                name="description"
                                                                id="description"
                                                                rows="2"
                                                                className="form-control"
                                                                value={documentsFormValues.description}
                                                                onChange={handleDocumentsInputChange}
                                                            ></textarea>
                                                            {documentFormSubmitted && documentsFormErrors.description && (
                                                                <div className="text-danger">{documentsFormErrors.description}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setDocumentsShow(false); documentsResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Documents</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="18%">Document Type</th>
                                                                    <th className="border-bottom p-2" width="17%">Specialty</th>
                                                                    <th className="border-bottom p-2" width="15%">Date Of Document</th>
                                                                    <th className="border-bottom p-2" width="30%">Description</th>
                                                                    <th className="border-bottom p-2" width="15%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listDocuments.length > 0 ? (
                                                                    listDocuments.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.documents?.document_type}</td>
                                                                            <td>{item?.documents?.speciality}</td>
                                                                            <td>{formatDate(item?.documents?.date)}</td>
                                                                            <td>
                                                                                <EllipsisTooltip text={item?.documents?.comment} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <Link
                                                                                    className="btn btn-icon btn-pills btn-soft-primary"
                                                                                    to={`${BaseurlForImage}${item?.documents?.report}`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                ><LiaFile /></Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="text-center">
                                                                        <td colSpan="6">No documents records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Prescription Modal */}
                                    <Modal show={prescriptionShow} onHide={() => { setPrescriptionShow(false); prescriptionResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="prescriptionModalID">Add Prescription</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th width="5%">SR No</th>
                                                                    <th width="20%">Medication</th>
                                                                    <th width="15%">Prescription Type</th>
                                                                    <th width="15%">Dose</th>
                                                                    <th width="10%">Quantity</th>
                                                                    <th width="25%">Comment/Instructions</th>
                                                                    <th width="15%">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {prescriptionList.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>
                                                                            <select
                                                                                name="medication"
                                                                                className={`form-control form-select ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].medication ? 'is-invalid' : ''}`}
                                                                                value={item.medication}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            >
                                                                                <option value="" disabled>Select Medication</option>
                                                                                {/* <option value="Medication 1">Medication 1</option>
                                                                                <option value="Medication 2">Medication 2</option>
                                                                                <option value="Medication 3">Medication 3</option> */}
                                                                                {medications.map((item) => (
                                                                                    <option key={item._id} value={item.name}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                ))}
                                                                                {/* Add other medications as needed */}
                                                                            </select>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].medication && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].medication}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                name="prescriptionType"
                                                                                className={`form-control form-select ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].prescriptionType ? 'is-invalid' : ''}`}
                                                                                value={item.prescriptionType}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            >
                                                                                <option value="" disabled>Select Type</option>
                                                                                <option value="acute">Acute</option>
                                                                                <option value="repeat">Repeat</option>
                                                                                {/* Add other types as needed */}
                                                                            </select>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].prescriptionType && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].prescriptionType}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                name="dose"
                                                                                className={`form-control form-select ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].dose ? 'is-invalid' : ''}`}
                                                                                value={item.dose}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            >
                                                                                <option value="" disabled>Select Dose</option>
                                                                                {/* <option value="Dose 1">Once a day</option>
                                                                                <option value="Dose 2">Twice a day</option>
                                                                                <option value="Dose 3">Three times a day</option>
                                                                                <option value="Dose 3">Once a week</option> */}
                                                                                {medicineDosage.map((item) => (
                                                                                    <option key={item._id} value={item.medicine_dosage}>
                                                                                        {item.medicine_dosage}
                                                                                    </option>
                                                                                ))}
                                                                                {/* Add other doses as needed */}
                                                                            </select>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].dose && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].dose}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="quantity"
                                                                                min={0}
                                                                                max={100}
                                                                                className={`form-control ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].quantity ? 'is-invalid' : ''}`}
                                                                                value={item.quantity}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            />
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].quantity && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].quantity}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <textarea
                                                                                name="comment"
                                                                                className={`form-control ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].comment ? 'is-invalid' : ''}`}
                                                                                rows="2"
                                                                                value={item.comment}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            ></textarea>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].comment && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].comment}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div className="actions" style={{ marginTop: '4px' }}>
                                                                                <Link onClick={() => addPrescriptionRow(i)} className="btn btn-icon btn-pills btn-soft-primary">
                                                                                    <RiAddFill />
                                                                                </Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <Link onClick={() => deletePrescriptionRow(i)} className="btn btn-icon btn-pills btn-soft-danger">
                                                                                    <RiDeleteBinFill />
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3" style={{ justifyContent: 'end' }}>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-100"
                                                        onClick={prescriptionHandleFormSubmit}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                    <button type="button" className="btn btn-danger w-100" onClick={() => { setPrescriptionShow(false); prescriptionResetForm(); }}>Cancel</button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Procedure Modal */}
                                    <Modal show={procedureShow} onHide={() => { setProcedureShow(false); procedureResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="procedureModalID">Add Procedure</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitProcedure}>
                                                <div className="row">
                                                    {/* Select or Add Procedure / Intervention */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Select or Add Procedure / Intervention</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={procedureFormValues.procedure}
                                                                onChange={handleProcedureInputChange}
                                                                name="procedure"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {/* <option value="Procedure 1">Procedure 1</option>
                                                                <option value="Procedure 2">Procedure 2</option>
                                                                <option value="Procedure 3">Procedure 3</option> */}
                                                                {interventions.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                                {/* Add other procedures as needed */}
                                                            </select>
                                                            {procedureFormSubmitted && procedureFormErrors.procedure && (
                                                                <div className="text-danger">{procedureFormErrors.procedure}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Pre-Op Diagnosis Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Pre-Op Diagnosis</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="preOpDiagnosis"
                                                                value={procedureFormValues.preOpDiagnosis}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Pre-Op Diagnosis"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.preOpDiagnosis && (
                                                                <div className="text-danger">{procedureFormErrors.preOpDiagnosis}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Post-Op Diagnosis Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Post-Op Diagnosis</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="postOpDiagnosis"
                                                                value={procedureFormValues.postOpDiagnosis}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Post-Op Diagnosis"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.postOpDiagnosis && (
                                                                <div className="text-danger">{procedureFormErrors.postOpDiagnosis}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Surgeon Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Surgeon</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="surgeon"
                                                                value={procedureFormValues.surgeon}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Surgeon"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.surgeon && (
                                                                <div className="text-danger">{procedureFormErrors.surgeon}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Assistant Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Assistant</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="assistant"
                                                                value={procedureFormValues.assistant}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Assistant"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.assistant && (
                                                                <div className="text-danger">{procedureFormErrors.assistant}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Anaesthetist Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Anaesthetist</label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="anaesthetist"
                                                                value={procedureFormValues.anaesthetist}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select Anaesthetist</option>
                                                                {anaesthesia.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {procedureFormSubmitted && procedureFormErrors.anaesthetist && (
                                                                <div className="text-danger">{procedureFormErrors.anaesthetist}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Description of Procedure Field */}
                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Description of Procedure</label>
                                                            <textarea
                                                                name="procedureDescription"
                                                                id="procedureDescription"
                                                                rows="2"
                                                                className="form-control"
                                                                value={procedureFormValues.procedureDescription}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                            ></textarea>
                                                            {procedureFormSubmitted && procedureFormErrors.procedureDescription && (
                                                                <div className="text-danger">{procedureFormErrors.procedureDescription}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Findings Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Findings</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="findings"
                                                                value={procedureFormValues.findings}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Findings"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.findings && (
                                                                <div className="text-danger">{procedureFormErrors.findings}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Specimens Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Specimens</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="specimens"
                                                                value={procedureFormValues.specimens}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Specimens"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.specimens && (
                                                                <div className="text-danger">{procedureFormErrors.specimens}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Tubes, Drains, Packs etc Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Tubes, Drains, Packs etc</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="tubesDrainsPacks"
                                                                value={procedureFormValues.tubesDrainsPacks}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="ubes, Drains, Packs etc"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.tubesDrainsPacks && (
                                                                <div className="text-danger">{procedureFormErrors.tubesDrainsPacks}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Complications Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Complications</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="complications"
                                                                value={procedureFormValues.complications}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Complications"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.complications && (
                                                                <div className="text-danger">{procedureFormErrors.complications}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Post-Op Instructions & Treatment Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Post-Op Instructions & Treatment</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="postOpInstructions"
                                                                value={procedureFormValues.postOpInstructions}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                                placeholder="Post-Op Instructions & Treatment"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.postOpInstructions && (
                                                                <div className="text-danger">{procedureFormErrors.postOpInstructions}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="file"
                                                                onChange={handleProcedureFileChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.file && (
                                                                <div className="text-danger">{procedureFormErrors.file}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File preview column */}
                                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                                        {filePreviewURL2 && (
                                                            <img
                                                                src={filePreviewURL2}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setProcedureShow(false); procedureResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* Other modal body content */}
                                        </Modal.Body>
                                    </Modal>

                                    {/* Imaging Modal */}
                                    <Modal show={imagingShow} onHide={() => { setImagingShow(false); imagingResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="imagingModalID">Add Imaging</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitImaging}>
                                                <div className="row">
                                                    {/* Imaging Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Imaging</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={imagingFormValues.imaging}
                                                                onChange={handleImagingInputChange}
                                                                name="imaging"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select Imaging</option>
                                                                {imaging.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {imagingFormSubmitted && imagingFormErrors.imaging && (
                                                                <div className="text-danger">{imagingFormErrors.imaging}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Imaging Type Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Imaging Type</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={imagingFormValues.imagingType}
                                                                onChange={handleImagingInputChange}
                                                                name="imagingType"
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            >
                                                                <option value="" disabled>Select Imaging Type</option>
                                                                {imagingType.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {imagingFormSubmitted && imagingFormErrors.imagingType && (
                                                                <div className="text-danger">{imagingFormErrors.imagingType}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File Field */}
                                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="file"
                                                                onChange={handleImagingFileChange}
                                                                required=""
                                                                style={{ borderRadius: "8px" }}
                                                            />
                                                            {imagingFormSubmitted && imagingFormErrors.file && (
                                                                <div className="text-danger">{imagingFormErrors.file}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File preview column */}
                                                    <div className="col-lg-1 col-md-1 col-sm-2">
                                                        {filePreviewURL3 && (
                                                            <img
                                                                src={filePreviewURL3}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Result & Comment Field */}
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Result & Comment</label>
                                                            <textarea
                                                                name="resultComment"
                                                                id="resultComment"
                                                                rows="3"
                                                                className="form-control"
                                                                value={imagingFormValues.resultComment}
                                                                onChange={handleImagingInputChange}
                                                                required=""
                                                            ></textarea>
                                                            {imagingFormSubmitted && imagingFormErrors.resultComment && (
                                                                <div className="text-danger">{imagingFormErrors.resultComment}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setImagingShow(false); imagingResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Imaging</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="17%">Imaging</th>
                                                                    <th className="border-bottom p-2" width="18%">Imaging Type</th>
                                                                    <th className="border-bottom p-2" width="45%">Result & Comment</th>
                                                                    <th className="border-bottom p-2" width="10%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listImaging.length > 0 ? (
                                                                    listImaging.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.imagings?.radiologies_name}</td>
                                                                            <td>{item?.imagings?.sub_radiologies_name}</td>
                                                                            <td>
                                                                                <EllipsisTooltip text={item?.imagings?.comment} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <Link
                                                                                    className="btn btn-icon btn-pills btn-soft-primary"
                                                                                    to={`${BaseurlForImage}${item?.imagings?.report}`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                ><LiaFile /></Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="text-center">
                                                                        <td colSpan="6">No imaging records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </Wrapper >
        </>
    )
}

