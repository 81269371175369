import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar";
import TopHeader from "./topHeader";
import Footer from "./footer";

export default function Wrapper(props){
    let [toggle, setToggle] = useState(false)

    const [containerHeight, setContainerHeight] = useState(window.innerHeight);

    useEffect(() => {
      const handleResize = () => {
        setContainerHeight(window.innerHeight);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return(
        <div className={`${toggle ? '' : 'toggled'} page-wrapper doctris-theme`}>
            <Sidebar manuClass= "sidebar-wrapper"/>

            <main className="page-content bg-light" style={{ height: `${(containerHeight)}px`}}>
                <TopHeader toggle={toggle} setToggle={setToggle}/>

                {props.children}
            {/* <Footer/> */}
            </main>
        </div>
    )
}