import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import bg from '../../assets/images/MyImages/bg.png'
import colorLogo from '../../assets/images/MyImages/color_logo_500.png'
import loginImg from '../../assets/images/MyImages/login_image.png'
import axiosInstance from "../../interceptor/interceptor-instance";
import { toast } from "react-toastify";
import OtpInput from 'react-otp-input';
import styles from './email-verify.module.css';

export default function ResetPassword() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        if (!id) {
            navigate("/forgot-password")
        } else {
            console.log('token: ' + id)
            // localStorage.setItem('authToken', id)
            // setResetToken(token);
        }
    }, [id, navigate]);

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
        setOtpError("");
    }

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordError(""); // Clear password error when user types
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(""); // Clear confirm password error when user types
    };

    const toggleNewPasswordVisibility = () => {
        setToggleNewPassword(!toggleNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setToggleConfirmPassword(!toggleConfirmPassword);
    };

    const validateFields = () => {
        let isValid = true;
        if (!otp) {
            setOtpError("Otp is required");
            isValid = false;
        }
        // Password validation
        if (!newPassword) {
            setPasswordError("New password is required");
            isValid = false;
        } else if (newPassword.length < 8) {
            setPasswordError("Minimum Use 8 characters or more for your password");
            isValid = false;
        }
        if (!confirmPassword) {
            setConfirmPasswordError("Please re-enter the new password");
            isValid = false;
        } else if (newPassword !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form submission
        if (validateFields()) {
            // Perform login or other actions if fields are valid
            // For example, navigate to another page
            const body = {
                otp: otp,
                new_password: newPassword,
                confirm_password: confirmPassword
            }
            try {
                const response = await axiosInstance.patch(`doctor/auth/reset-password/${id}`, body)
                toast.success(response.data.message)
                navigate(`/login`);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    return (
        <>
            <section className="bg-home d-flex bg-light align-items-center login-page-height main-login-wrapper" style={{ backgroundImage: `url(${bg})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="card login-page shadow mt-4" style={{ borderRadius: '5px' }}>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-0 col-sm-0 p-0 d-none d-lg-block" style={{ position: 'relative' }}>
                                            <img src={loginImg} className="w-100 h-auto" style={{ borderRadius: '5px' }} alt="" />
                                            <img src={colorLogo} className="h-auto" style={{ width: '40%', position: 'absolute', top: '8%', left: '30%', transform: 'translate(-50%, -50%)' }} alt="" />
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-sm-12 p-5">
                                            <h4 className="text-left">Reset Password</h4>
                                            <form className="login-form mt-4" onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">OTP <span className="text-danger">*</span></label>
                                                    <OtpInput
                                                        value={otp} containerStyle={{ justifyContent: 'space-around' }} inputStyle={`${styles.formControl}`}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        shouldAutoFocus={true}
                                                        inputType={'tel'}
                                                        renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                                        renderInput={(props) => <input {...props} />}
                                                    />
                                                    {otpError && <div className="text-danger">{otpError}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">New Password <span className="text-danger">*</span></label>
                                                    <div className="position-relative">
                                                        <input placeholder="Enter New Password" className="form-control" autoComplete="new-password" type={toggleNewPassword ? "text" : "password"} onChange={handleNewPasswordChange} value={newPassword} />
                                                        <div className="show-hide" onClick={toggleNewPasswordVisibility}>
                                                            {toggleNewPassword ? (
                                                                <i className="mdi mdi-eye-off"></i>
                                                            ) : (
                                                                <i className="mdi mdi-eye"></i>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {passwordError && <div className="text-danger">{passwordError}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Re-enter Password <span className="text-danger">*</span></label>
                                                    <div className="position-relative">
                                                        <input placeholder="Re-enter New Password" className="form-control" autoComplete="new-password" type={toggleConfirmPassword ? "text" : "password"} onChange={handleConfirmPasswordChange} value={confirmPassword} />
                                                        <div className="show-hide" onClick={toggleConfirmPasswordVisibility}>
                                                            {toggleConfirmPassword ? (
                                                                <i className="mdi mdi-eye-off"></i>
                                                            ) : (
                                                                <i className="mdi mdi-eye"></i>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
                                                </div>
                                                <div className="d-flex justify-content-between mb-5">
                                                    <div>
                                                        <Link to="/forgot-password" style={{ color: '#000' }}>Back To <u style={{ color: '#007af4' }}>Forgot Password</u></Link>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-primary">Reset Password</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
