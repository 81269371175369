import axiosInstance from "../../../interceptor/interceptor-instance"


export const getAllAppointments = (data) => {
    return axiosInstance.post(`doctor/appointment/dashboard`, data);
}

export const createAppointment = (data) => {
    return axiosInstance.post(`doctor/appointment/create`, data);
}

export const markAsPaid = (id, data) => {
    return axiosInstance.patch(`doctor/appointment/mark-paid/${id}`, data);
}

export const markAsCompleted = (id) => {
    return axiosInstance.patch(`doctor/appointment/mark-complete/${id}`);
}

export const markAsCancelled = (id) => {
    return axiosInstance.patch(`doctor/appointment/cancel/${id}`);
}