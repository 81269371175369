import React from "react";

const Loader = ({ loading }) => {
  return (
    <div className={`loader-wrapper ${loading ? "" : "d-none"}`}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"> </div>
      <div className="dot"></div>
    </div>
  );
};

// const Loader = ({ loading }) => {
//   return loading ? <div className="loader">Loading...</div> : null;
// };

export default Loader;
