import axiosInstance from "../../../interceptor/interceptor-instance"

export const getDoctorDetails = (id) => {
    return axiosInstance.get(`doctor/profile/${id}`);
}

export const setDoctorAvailability = (data) => {
    return axiosInstance.post(`doctor/availability/create`, data);
}

export const getAvailabilityInfo = (id) => {
    return axiosInstance.get(`doctor/availability/${id}`);
}

export const updateDayValue = (id, data) => {
    return axiosInstance.patch(`doctor/availability/change/${id}`, data);
}

export const updateShiftValues = (id, data) => {
    return axiosInstance.patch(`doctor/availability/shift/change/${id}`, data);
}

export const deleteSlots = (id, data) => {
    return axiosInstance.delete(`doctor/availability/shift/delete/${id}`, {
        data: data
    });
};

export const getAllDurations = () => {
    return axiosInstance.get(`masters/duration`)
}

export const updateConsultationRates = (data) => {
    return axiosInstance.post(`doctor/availability/duration/set`, data);
}