import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Multiselect } from 'multiselect-react-dropdown';
import SimpleBar from "simplebar-react";
import Wrapper from "../components/wrapper";
import client1 from '../assets/images/client/01.jpg'
import Modal from 'react-bootstrap/Modal';
import { allergyData } from '../data/data';
import { vaccineData, labReportData, documentsData, imagingData } from '../data/data';
import { useDropzone } from 'react-dropzone';
import { FiEdit, RiDeleteBin2Fill } from '../assets/icons/vander';
import consultationImg from '../assets/images/svg/consultation_0.svg'
import allergyImg from '../assets/images/svg/allergy_0.svg'
import VaccinationImg from '../assets/images/svg/allergy_0.svg'
import labReportImg from '../assets/images/svg/labreport.svg'
import DocumentsImg from '../assets/images/svg/documents_0.svg'
import prescriptionImg from '../assets/images/svg/Prescription_0.svg'
// import vitalsImg from '../assets/images/svg/Vital_0.svg'
import procedureImg from '../assets/images/svg/process.svg'
import imagingReportImg from '../assets/images/svg/imagin_scan.svg'
import referImg from '../assets/images/svg/refer.svg'
import { LiaFile, RiAddFill, RiDeleteBinFill } from "../assets/icons/vander";
import { useTranslation } from 'react-i18next';
import dummyDocument from '../assets/images/MyImages/documents.png';
import { format, getYear } from "date-fns";
import { addAllergyEMR, addVaccinationEMR, createAppointmentEMR } from "./services/index-service"
import Loader from "../components/loader";
import { toast } from "react-toastify";
import axiosInstance from "../interceptor/interceptor-instance";

export default function Index() {
    const { t } = useTranslation(); // Translation
    const [loading, setLoading] = useState(false);
    const setTime = [
        { time: "00:00" }, { time: "00:30" }, { time: "01:00" }, { time: "01:30" }, { time: "02:00" },
        { time: "02:30" }, { time: "03:00" }, { time: "03:30" }, { time: "04:00" }, { time: "04:30" },
        { time: "05:00" }, { time: "05:30" }, { time: "06:00" }, { time: "06:30" }, { time: "07:00" },
        { time: "07:30" }, { time: "08:00" }, { time: "08:30" }, { time: "09:00" }, { time: "09:30" },
        { time: "10:00" }, { time: "10:30" }, { time: "11:00" }, { time: "11:30" }, { time: "12:00" },
        { time: "12:30" }, { time: "13:00" }, { time: "13:30" }, { time: "14:00" }, { time: "14:30" },
        { time: "15:00" }, { time: "15:30" }, { time: "16:00" }, { time: "16:30" }, { time: "17:00" },
        { time: "17:30" }, { time: "18:00" }, { time: "18:30" }, { time: "19:00" }, { time: "19:30" },
        { time: "20:00" }, { time: "20:30" }, { time: "21:00" }, { time: "21:30" }, { time: "22:00" },
        { time: "22:30" }, { time: "23:00" }, { time: "23:30" },
    ];


    // const location = useLocation();
    // const { appointmentData } = location.state || {};
    // console.log(appointmentData); // Check if appointmentData is correctly logged here

    // Use useEffect to set appointmentData only once when the component mounts
    const location = useLocation();
    const [appointmentData, setAppointmentData] = useState(null);

    useEffect(() => {
        if (location.state && location.state.appointmentData) {
            setAppointmentData(location.state.appointmentData);
            console.log(location.state.appointmentData);
        }
    }, [location.state]);

    // const handleTabClick = (index) => {
    //     setActiveIndex(index);
    // };

    const [formValues, setFormValues] = useState({
        consultationDate: '',
        startTime: '',
        endTime: '',
        consultationType: '',
        consultationComments: '',
        problem: '',
        category: '',
        type: '',
        history: '',
        Temperature: '',
        heartRate: '',
        respiratoryRate: '',
        bloodOxygen: '',
        physicalExamination: '',
        problemStartDate: '',
        problemEndDate: '',
    });

    const [systolic, setSystolic] = useState('');
    const [diastolic, setDiastolic] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [problems, setProblems] = useState([]);
    const [medications, setMedications] = useState([]);
    const [medicineDosage, setMedicineDosage] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [anaesthesia, setAnaesthesia] = useState([]);
    const [testName, setTestName] = useState([]);
    const [selectedLabTests, setSelectedLabTests] = useState([]);
    const [documentType, setDocumentType] = useState([]);
    const [specialty, setSpecialty] = useState([]);
    const [imaging, setImaging] = useState([]);
    const [imagingType, setImagingType] = useState([]);

    useEffect(() => {
        if (appointmentData) {
            setFormValues({
                consultationDate: format(new Date(appointmentData.appointment.consulation_date), 'yyyy-MM-dd'),
                startTime: format(new Date(`01/01/2000 ${appointmentData.start_time}`), 'HH:mm'),
                endTime: format(new Date(`01/01/2000 ${appointmentData.end_time}`), 'HH:mm'),
                consultationType: appointmentData.appointment.consulation_type,
                consultationComments: appointmentData.appointment.consulation_reason,
                problem: '',
                category: '',
                type: '',
                history: '',
                Temperature: '',
                heartRate: '',
                respiratoryRate: '',
                bloodOxygen: '',
                physicalExamination: '',
                problemStartDate: '',
                problemEndDate: '',
            });
        }
        getProblemMaster();
        getMedicationMaster();
        getMedicineDosageMaster();
        getInterventionsMaster();
        getAnaesthesiaMaster();
        // getAllergyMaster();
        getTestNameMaster();
        getDocumentTypeMaster();
        getSpecialtyMaster();
        getImagingMaster();
        getImagingTypeMaster();
        getReferLabTestNameMaster();
        getReferImagingMaster();
    }, [appointmentData]);

    // Problem Master
    const getProblemMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/problems`, '');
            if (response.data.status === 'success') {
                setProblems(response.data.data.problems);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Medication Master
    const getMedicationMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/medications`, '');
            if (response.data.status === 'success') {
                setMedications(response.data.data.medications);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // medicine dosage Master
    const getMedicineDosageMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/medicine-dosage`, '');
            if (response.data.status === 'success') {
                setMedicineDosage(response.data.data.medicine_dosage);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Procedure Master
    const getInterventionsMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/interventions`, '');
            if (response.data.status === 'success') {
                setInterventions(response.data.data.interventions);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Procedure Master
    const getAnaesthesiaMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/anaesthesias`, '');
            if (response.data.status === 'success') {
                setAnaesthesia(response.data.data.anaesthesias);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Test Name Master
    const getTestNameMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/labprofiles`, '');
            if (response.data.status === 'success') {
                setTestName(response.data.data.labprofiles);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Document Type Master
    const getDocumentTypeMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/document-type`);
            if (response.data.status === 'success') {
                // console.log(response.data.data.document_types);
                setDocumentType(response.data.data.document_types);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // Optionally, show a toast notification or some form of user feedback
            // toast.error('Failed to fetch document types');
        }
    };
    // Specialty Master
    const getSpecialtyMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/specialities`, '');
            if (response.data.status === 'success') {
                setSpecialty(response.data.data.specialities);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Imaging Master
    const getImagingMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/radiologies`, '');
            if (response.data.status === 'success') {
                setImaging(response.data.data.radiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };
    // Imaging Type Master
    const getImagingTypeMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/subradiologies`, '');
            if (response.data.status === 'success') {
                setImagingType(response.data.data.subradiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    let [activeIndex, setActiveIndex] = useState(1)

    // Function to get today's date in the format yyyy-mm-dd
    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Function to handle input changes and update form values
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Function to handle input changes and update state
    const handleInputChange2 = (event) => {
        const { name, value } = event.target;
        if (name === "systolic") {
            setSystolic(value);
        } else if (name === "diastolic") {
            setDiastolic(value);
        }
    };

    const handleTypeChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

        // Reset start and end dates if type changes
        if (value !== "past") {
            setFormValues(prevState => ({
                ...prevState,
                problemStartDate: "",
                problemEndDate: ""
            }));
        }
    };

    // Function to validate the form
    const validateForm = () => {
        const errors = {};
        const systolicRegex = /^\d{1,3}$/; // Allow only numbers up to 3 digits
        const diastolicRegex = /^\d{1,3}$/; // Allow only numbers up to 3 digits

        // Validate systolic input
        // if (!systolicRegex.test(systolic)) {
        //     errors.systolic = "Enter only numbers";
        // }

        // // Validate diastolic input
        // if (!diastolicRegex.test(diastolic)) {
        //     errors.diastolic = "Enter only numbers";
        // }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);

        if (validateForm()) {
            // Form submission logic
            console.log("Form submitted successfully with values:", {
                systolic,
                diastolic,
            });

            // Reset form values after successful submission
            setSystolic("");
            setDiastolic("");
            setFormErrors({});
            setFormSubmitted(false);
        }
    };

    // Function to reset the form values and errors
    // const consultationResetForm = () => {
    //     setFormValues({
    //         consultationDate: getTodayDate(), // Initialize consultationDate with today's date
    //         consultationTime: '',
    //         consultationType: '',
    //         consultationComments: '',
    //         problem: '',
    //         category: '',
    //         type: '',
    //         history: '',
    //         Temperature: '',
    //         heartRate: '',
    //         respiratoryRate: '',
    //         bloodOxygen: '',
    //         physicalExamination: '',
    //     });
    //     setFormErrors({});
    //     setFormSubmitted(false);
    // };

    const formatPrescriptionData = (prescriptionList) => {
        return prescriptionList.map(prescription => ({
            medication_name: prescription.medication,
            medication_id: prescription.medication_id,
            medicine_dosage: prescription.dose,
            medicine_dosage_id: prescription.medicine_dosage_id,
            medication_type: prescription.prescriptionType,
            quantity: prescription.quantity,
            instruction: prescription.comment
        }));
    };

    const formatProcedureData = (procedureFormData) => {
        return {
            intervention_id: procedureFormData.intervention_id,
            intervention_name: procedureFormData.procedure,
            pre_diagnosis: procedureFormData.preOpDiagnosis,
            post_diagnosis: procedureFormData.postOpDiagnosis,
            surgeon: procedureFormData.surgeon,
            assistant: procedureFormData.assistant,
            anaesthetist: procedureFormData.anaesthetist,
            anaesthesia_category_id: procedureFormData.anaesthesia_category_id,
            anaesthesia_category_name: procedureFormData.anaesthesia_category_name,
            description: procedureFormData.procedureDescription,
            findings: procedureFormData.findings,
            specimens: procedureFormData.specimens,
            tube_drain: procedureFormData.tubesDrainsPacks,
            complications: procedureFormData.complications,
            post_instruction: procedureFormData.postOpInstructions,
            intervention_date: procedureFormData.interventionDate,
            comment: procedureFormData.comment
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        const errors = validateForm(formValues);
        if (Object.keys(errors).length === 0) {
            const formData = new FormData();
            formData.append('patient_id', appointmentData?._id);
            formData.append('appointment_id', appointmentData?.appointment_id);
            formData.append('consultation_for', formValues.consultationComments);
            formData.append('consultation_type', formValues.consultationType);

            // Check if formValues has temperature and heartRate
            if (formValues.Temperature && formValues.heartRate) {
                const vitalsData = {
                    temperature: formValues.Temperature,
                    heart_rate: formValues.heartRate,
                    blood_pressure: {
                        systolic: systolic,
                        diastolic: diastolic
                    },
                    respiratory_rate: formValues.respiratoryRate,
                    oxygen_saturation: formValues.bloodOxygen
                };
                const vitalsString = JSON.stringify(vitalsData);
                formData.append('vitals', vitalsString);
            }

            // Check if formValues has category and problem
            if (formValues.category && formValues.problem) {
                // Find the problem object from the problems array
                const problem = problems.find((problem) => problem.name === formValues.problem);

                // Prepare the base problem data
                const problemData = {
                    category: formValues.category,
                    type: formValues.type,
                    name: formValues.problem,
                    problem_id: problem ? problem._id : null,
                };

                // Conditionally add start_date and end_date if the type is "past"
                if (formValues.type === "past") {
                    problemData.start_date = formValues.problemStartDate;
                    problemData.end_date = formValues.problemEndDate;
                }

                // Stringify the problemData and append it to formData
                const problemString = JSON.stringify(problemData);
                formData.append('problems', problemString);
            }

            // Check if formValues has physicalExamination and history
            if (formValues.physicalExamination && formValues.history) {
                formData.append('physical_examination', formValues.physicalExamination);
                formData.append('history', formValues.history);
            }

            // Check if prescriptionList has data
            if (prescriptionList.length > 0) {
                const prescriptionData = formatPrescriptionData(prescriptionList);
                const prescriptionString = JSON.stringify(prescriptionData);
                formData.append('prescriptions', prescriptionString);
            }
            const procedureData = formatProcedureData(procedureFormValues);
            const procedureString = JSON.stringify(procedureData);
            formData.append('procedure', procedureString);
            formData.append('intervention_file', procedureFormValues.file, procedureFormValues.file.name);

            try {
                setLoading(true);
                const response = await createAppointmentEMR(formData);
                if (response.data.status === "success") {
                    console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error submitting form:', error);
            }
        } else {
            setLoading(false);
            setFormErrors(errors);
        }
    };



    // Allergy Functionality
    let [allergyShow, setAllergyShow] = useState(false);
    const [allergyFormValues, setAllergyFormValues] = useState({
        allergyName: '',
        allergyType: '',
        allergyStartDate: '',
        allergyEndDate: '',
        allergyComments: ''
    });

    const [allergy, setAllergy] = useState([]);
    const [listAllergy, setListAllergy] = useState([]);

    const [allergyFormErrors, setAllergyFormErrors] = useState({});
    const [allergyFormSubmitted, setAllergyFormSubmitted] = useState(false);

    // Allergy Master
    const getAllergyMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/allergies`, '');
            if (response.data.status === 'success') {
                setAllergy(response.data.data.allergies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Allergy List
    const getAllergyList = async () => {
        console.log(appointmentData);
        try {
            const response = await axiosInstance.get(`/doctor/appointment/previous/allergies/${appointmentData._id}`);
            if (response.data.status === 'success') {
                console.log(response.data.data.allergies);
                setListAllergy(response.data.data.allergies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

     // useEffect to call getAllergyList when the modal opens
     useEffect(() => {
        if (allergyShow) {
            getAllergyList();
            getAllergyMaster();
        }
    }, [allergyShow]);

    // Function to handle input changes and update form values
    const handleInputChange3 = (event) => {
        const { name, value } = event.target;
        setAllergyFormValues({ ...allergyFormValues, [name]: value });

        // If the name is "allergyName", update both allergyName and allergyId
        if (name === 'allergyName') {
            const selectedAllergy = allergy.find(item => item.allergy_name === value);
            if (selectedAllergy) {
                setAllergyFormValues({
                    ...allergyFormValues,
                    allergyName: selectedAllergy.allergy_name,
                    allergyId: selectedAllergy._id
                });
            }
        }
    };

    // Function to validate the form
    const validateAllergyForm = () => {
        const errors = {};

        if (!allergyFormValues.allergyName) {
            errors.allergyName = 'Allergy name is required.';
        }

        if (!allergyFormValues.allergyType) {
            errors.allergyType = 'Allergy type is required.';
        }

        if (allergyFormValues.allergyType === 'past') {
            if (!allergyFormValues.allergyStartDate) {
                errors.allergyStartDate = 'Start date is required.';
            }

            if (!allergyFormValues.allergyEndDate) {
                errors.allergyEndDate = 'End date is required.';
            }
        }

        if (!allergyFormValues.allergyComments) {
            errors.allergyComments = 'Comments are required.';
        }

        setAllergyFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitAllergy = async (event) => {
        event.preventDefault();

        setAllergyFormSubmitted(true);
        const errors = validateAllergyForm(formValues);
        // Validate the form
        if (validateAllergyForm()) {
            console.log('Form is valid, submitting form:', allergyFormValues);

            // Prepare the data to be sent to the API
            const allergyData = {
                patient_id: appointmentData._id,  // Replace with actual patient_id
                allergies: {
                    allergy_id: allergyFormValues.allergyId, // Assuming allergyName holds the allergy_id
                    name: allergyFormValues.allergyName,
                    type: allergyFormValues.allergyType,
                    end_date: format(new Date(allergyFormValues.allergyEndDate), 'yyyy-MM-dd'),
                    start_date: format(new Date(allergyFormValues.allergyStartDate), 'yyyy-MM-dd'),
                    comment: allergyFormValues.allergyComments
                }
            };

            try {
                setLoading(true);
                const response = await addAllergyEMR(allergyData);
                if (response.data.status === 'success') {
                    console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    setLoading(false);
                    // Close the modal since the form is valid and the request was successful
                    setAllergyShow(false);
                    // Reset the form values and errors after successful submission
                    allergyResetForm();
                } else {
                    console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
                setLoading(false);
            }
        } else {
            console.log('Form validation failed');
            setLoading(false);
            setAllergyFormErrors(errors);
        }
    };


    // Function to reset the form values and errors
    const allergyResetForm = () => {
        setAllergyFormValues({
            allergyName: '',
            allergyType: '',
            allergyStartDate: '',
            allergyEndDate: '',
            allergyComments: ''
        });
        setAllergyFormErrors({});
        setAllergyFormSubmitted(false);
    };


    // Vaccination Modal functionality
    let [vaccinationShow, setVaccinationShow] = useState(false);

    const [vaccinationFormValues, setVaccinationFormValues] = useState({
        vaccineName: '',
        manufactureName: '',
        vaccinationDate: '',
        expiryDate: '',
        additionalNotes: ''
    });

    const [vaccinationFormErrors, setVaccinationFormErrors] = useState({});
    const [vaccinationFormSubmitted, setVaccinationFormSubmitted] = useState(false);

    const [vaccinations, setVaccinations] = useState([]);
    const [listVaccination, setListVaccination] = useState([]);

    // Vaccinations Master
    const getVaccinationsMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/vaccinations`, '');
            if (response.data.status === 'success') {
                setVaccinations(response.data.data.vaccinations);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Vaccination List
    const getVaccinationList = async () => {
        // console.log(appointmentData);
        try {
            const response = await axiosInstance.get(`/doctor/appointment/previous/vaccination/${appointmentData._id}`);
            // console.log(response.data);
            if (response.data.status === 'success') {
                // console.log(response.data.data.vaccinations.length);
                setListVaccination(response.data.data.vaccinations);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // useEffect to call getVaccinationList when the modal opens
    useEffect(() => {
        if (vaccinationShow) {
            getVaccinationList();
            getVaccinationsMaster();
        }
    }, [vaccinationShow]);

    const handleVaccinationInputChange = (event) => {
        const { name, value } = event.target;
        setVaccinationFormValues({ ...vaccinationFormValues, [name]: value });

        // If the name is "vaccineName", update both vaccineName and vaccineId
        if (name === 'vaccineName') {
            const selectedVaccine = vaccinations.find(item => item.name === value);
            if (selectedVaccine) {
                setVaccinationFormValues({
                    ...vaccinationFormValues,
                    vaccineName: selectedVaccine.name,
                    vaccineId: selectedVaccine._id
                });
            }
        }
    };

    // Function to handle form submission for vaccination
    const handleSubmitVaccination = async (event) => {
        event.preventDefault();

        setVaccinationFormSubmitted(true);
        const errors = validateVaccinationForm(formValues);

        // Validate the form
        if (validateVaccinationForm()) {
            console.log('Form is valid, submitting form:', vaccinationFormValues);

            // Prepare the data to be sent to the API
            const vaccinationData = {
                patient_id: appointmentData._id,  // Replace with actual patient_id
                vaccinations: {
                    vaccination_id: vaccinationFormValues.vaccineId, // Using updated vaccineId
                    name: vaccinationFormValues.vaccineName,
                    manufacture_name: vaccinationFormValues.manufactureName,
                    vaccination_date: format(new Date(vaccinationFormValues.vaccinationDate), 'yyyy-MM-dd'),
                    expiry_date: format(new Date(vaccinationFormValues.expiryDate), 'yyyy-MM-dd'),
                    comment: vaccinationFormValues.additionalNotes
                }
            };

            try {
                setLoading(true);
                const response = await addVaccinationEMR(vaccinationData);
                if (response.data.status === 'success') {
                    console.log('Form submitted successfully:', response.data);
                    toast.success(response.data.message);
                    setLoading(false);
                    // Close the modal since the form is valid and the request was successful
                    setVaccinationShow(false);
                    // Reset the form values and errors after successful submission
                    vaccinationResetForm();
                } else {
                    console.error('Failed to submit form:', response.data.message);
                    toast.error(response.data.message);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
                setLoading(false);
            }
        } else {
            console.log('Form validation failed');
            setLoading(false);
            setVaccinationFormErrors(errors);
        }
    };




    // Function to validate the vaccination form
    const validateVaccinationForm = () => {
        const errors = {};

        if (!vaccinationFormValues.vaccineName) {
            errors.vaccineName = 'Vaccine name is required.';
        }

        if (!vaccinationFormValues.manufactureName) {
            errors.manufactureName = 'Manufacture name is required.';
        }

        if (!vaccinationFormValues.vaccinationDate) {
            errors.vaccinationDate = 'Date of vaccination is required.';
        }

        if (!vaccinationFormValues.expiryDate) {
            errors.expiryDate = 'Expiry date is required.';
        }

        if (!vaccinationFormValues.additionalNotes) {
            errors.additionalNotes = 'Additional notes are required.';
        }

        setVaccinationFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to reset the form values and errors
    const vaccinationResetForm = () => {
        setVaccinationFormValues({
            vaccineName: '',
            manufactureName: '',
            vaccinationDate: '',
            expiryDate: '',
            additionalNotes: ''
        });
        setVaccinationFormErrors({});
        setVaccinationFormSubmitted(false);
    };

    // Lab report Functionality
    let [labReportShow, setLabReportShow] = useState(false);
    // Define the state for form values and errors
    const [labReportFormValues, setLabReportFormValues] = useState({
        testName: '',
        dateOfCollectSample: '',
        dateOfReceiveSample: '',
    });
    const [labReportFormErrors, setLabReportFormErrors] = useState({});
    const [labReportFormSubmitted, setLabReportFormSubmitted] = useState(false);

    // Function to handle input changes and update form values
    const handleInputChange4 = (event) => {
        const { name, value } = event.target;
        setLabReportFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // State for managing uploaded files and validation status
    const [files, setFiles] = useState([]);
    const [isInvalid, setIsInvalid] = useState(false);

    // Define dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles);
            setIsInvalid(false);
        },
    });

    // Validate the form
    const validateLabReportForm = () => {
        const errors = {};

        // Validate testName field
        if (!labReportFormValues.testName) {
            errors.testName = 'Test name is required.';
        }

        // Validate dateOfCollectSample field
        if (!labReportFormValues.dateOfCollectSample) {
            errors.dateOfCollectSample = 'Date of collect sample is required.';
        }

        // Validate dateOfReceiveSample field
        if (!labReportFormValues.dateOfReceiveSample) {
            errors.dateOfReceiveSample = 'Date of receive sample is required.';
        }

        // Validate file count (images)
        if (files.length < 2) {
            setIsInvalid(true);
            errors.fileCount = 'Please upload a minimum of 2 images.';
        } else if (files.length > 5) {
            setIsInvalid(true);
            errors.fileCount = 'Maximum 5 images are allowed.';
        }

        // Set form errors state
        setLabReportFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmitLabReport = (event) => {
        event.preventDefault();
        setLabReportFormSubmitted(true);

        // Validate the form
        if (validateLabReportForm()) {
            // Form is valid, submit the form (e.g., API call)
            console.log('Form is valid, submitting form:', labReportFormValues, files);

            // Reset form and close the modal after submission
            resetLabReportForm();
            setLabReportShow(false);
        } else {
            console.log('Form validation failed');
        }
    };

    // Function to reset form and clear errors
    const resetLabReportForm = () => {
        setLabReportFormValues({
            testName: '',
            dateOfCollectSample: '',
            dateOfReceiveSample: '',
        });
        setFiles([]);
        setLabReportFormErrors({});
        setLabReportFormSubmitted(false);
        setIsInvalid(false);
    };

    // Function to remove a file from the list
    const handleRemoveFile = (file) => {
        setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    // Render image previews with a remove button
    const filePreviews = files.map((file) => {
        // Check if the file type is an image
        const isImageFile = file.type.startsWith('image/');

        // Determine the image source based on the file type
        const imageSrc = isImageFile ? URL.createObjectURL(file) : client1;

        return (
            <div key={file.name} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                <img src={imageSrc} alt={file.name} style={{ width: '120px', height: '120px', borderRadius: '8px' }} />
                {/* Container for the delete icon */}
                <button
                    className="btn btn-icon btn-pills btn-danger icon-size-responsive"
                    onClick={() => handleRemoveFile(file)}
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        border: 'none',
                        borderRadius: '50%',
                        padding: '5px',
                        cursor: 'pointer',
                    }}
                >
                    <RiDeleteBin2Fill style={{ color: '#fff' }} />
                </button>
            </div>
        );
    });

    // Documents Modal Functionality
    let [documentsShow, setDocumentsShow] = useState(false);
    let [documentsFormValues, setDocumentsFormValues] = useState({
        documentType: '',
        specialty: '',
        documentDate: '',
        description: '',
        documentFile: null,
    });
    let [documentsFormErrors, setDocumentsFormErrors] = useState({});
    let [documentFormSubmitted, setDocumentFormSubmitted] = useState(false);

    // State variable for file preview URL
    let [filePreviewURL, setFilePreviewURL] = useState(null);

    // Function to handle input changes and update form values
    const handleDocumentsInputChange = (event) => {
        const { name, value } = event.target;
        setDocumentsFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Function to handle file changes and update the file preview URL
    const handleDocumentsFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setDocumentsFormValues((prevValues) => ({
            ...prevValues,
            documentFile: file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setFilePreviewURL(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setFilePreviewURL(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setFilePreviewURL(null);
        }
    };

    // Function to validate the Documents form
    const validateDocumentsForm = () => {
        const errors = {};

        if (!documentsFormValues.documentType) {
            errors.documentType = 'Document type is required.';
        }

        if (!documentsFormValues.specialty) {
            errors.specialty = 'Specialty is required.';
        }

        if (!documentsFormValues.documentDate) {
            errors.documentDate = 'Date of document is required.';
        }

        if (!documentsFormValues.description) {
            errors.description = 'Description is required.';
        }

        if (!documentsFormValues.documentFile) {
            errors.documentFile = 'Document file is required.';
        }

        setDocumentsFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitDocuments = (event) => {
        event.preventDefault();
        setDocumentFormSubmitted(true);

        // Validate the form
        if (validateDocumentsForm()) {
            // Form is valid, perform form submission logic (e.g., API call)
            console.log('Form is valid, submitting form:', documentsFormValues);

            // Close the modal since the form is valid
            setDocumentsShow(false);

            // Reset the form values and errors after successful submission
            documentsResetForm();
        } else {
            console.log('Form validation failed');
        }
    };

    // Function to reset the form values and errors
    const documentsResetForm = () => {
        setDocumentsFormValues({
            documentType: '',
            specialty: '',
            documentDate: '',
            description: '',
            documentFile: null,
        });
        setDocumentsFormErrors({});
        setDocumentFormSubmitted(false);
        setFilePreviewURL(null);
    };

    // Prescription Functionality
    let [prescriptionShow, setPrescriptionShow] = useState(false);
    // Initial state for the form array
    const [prescriptionList, setPrescriptionList] = useState([
        {
            medication: '',
            prescriptionType: '',
            dose: '',
            quantity: '',
            comment: '',
        },
    ]);

    // State variables for form submission and error handling
    const [prescriptionFormSubmitted, setPrescriptionFormSubmitted] = useState(false);
    const [prescriptionFormErrors, setPrescriptionFormErrors] = useState([]);

    // Function to handle adding a new medication row
    // Function to handle adding a prescription row
    const addPrescriptionRow = (index) => {
        // Check if the length of the prescription list is equal to 3
        if (prescriptionList.length === 10) {
            // If the length is 3, return undefined (or any specific value you want)
            // console.log("If the length is 3, return undefined (or any specific value you want)");
            return undefined;
        } else {
            // Otherwise, add a new prescription row at the specified index
            setPrescriptionList((prevList) => [
                ...prevList.slice(0, index + 1),
                {
                    medication: '',
                    prescriptionType: '',
                    dose: '',
                    quantity: '',
                    comment: '',
                },
                ...prevList.slice(index + 1),
            ]);
            return true; // Return true indicating a new row was added
        }
    };

    // Function to handle deleting a medication row
    const deletePrescriptionRow = (index) => {
        // Check the length of the prescription list
        if (prescriptionList.length === 1) {
            // If the length is 1, you may choose to skip deletion or add additional logic
            console.log("Cannot delete the only remaining item.");
            // You might also want to display a message to the user or take other action
        } else {
            // Remove the item at the specified index
            setPrescriptionList((prevList) => {
                return prevList.filter((_, i) => i !== index);
            });
        }
    };

    // Function to handle input changes
    const handleInputChange5 = (index, event) => {
        const { name, value } = event.target;

        setPrescriptionList((prevList) =>
            prevList.map((item, i) => {
                if (i === index) {
                    const updatedItem = { ...item, [name]: value };

                    // If the medication field is changed, update the medication_id
                    if (name === 'medication') {
                        const selectedMedication = medications.find(med => med.name === value);
                        if (selectedMedication) {
                            updatedItem.medication_id = selectedMedication._id;
                        }
                    }

                    // If the dose field is changed, update the medicine_dosage_id
                    if (name === 'dose') {
                        const selectedDosage = medicineDosage.find(dosage => dosage.medicine_dosage === value);
                        if (selectedDosage) {
                            updatedItem.medicine_dosage_id = selectedDosage._id;
                        }
                    }

                    return updatedItem;
                }
                return item;
            })
        );
    };


    // Function to validate the form and set errors
    const prescriptionValidateForm = () => {
        const errors = [];
        prescriptionList.forEach((item, i) => {
            const rowErrors = {};
            if (!item.medication) {
                rowErrors.medication = 'Medication is required.';
            }
            if (!item.prescriptionType) {
                rowErrors.prescriptionType = 'Prescription Type is required.';
            }
            if (!item.dose) {
                rowErrors.dose = 'Dose is required.';
            }
            if (!item.quantity) {
                rowErrors.quantity = 'Quantity is required.';
            }
            if (!item.comment) {
                rowErrors.comment = 'Comment is required.';
            }
            if (Object.keys(rowErrors).length > 0) {
                errors[i] = rowErrors;
            }
        });
        setPrescriptionFormErrors(errors);
        return errors.length === 0;
    };

    // Function to handle form submission
    const prescriptionHandleFormSubmit = (event) => {
        event.preventDefault();
        setPrescriptionFormSubmitted(true);
        const isFormValid = prescriptionValidateForm();
        if (isFormValid) {
            // Form is valid, proceed with form submission logic
            console.log('Form is valid, submitting form:', prescriptionList);

            // Save the form details here (if needed)

            setPrescriptionShow(false);
            setPrescriptionFormErrors([]);
            setPrescriptionFormSubmitted(false);
        } else {
            console.log('Form validation failed');
        }
    };


    // Function to reset the form values and errors
    const prescriptionResetForm = () => {
        setPrescriptionList([
            {
                medication: '',
                prescriptionType: '',
                dose: '',
                quantity: '',
                comment: '',
            },
        ]);
        setPrescriptionFormErrors([]);
        setPrescriptionFormSubmitted(false);
    };

    // Procedure Functionality
    let [procedureShow, setProcedureShow] = useState(false);
    let [procedureFormValues, setProcedureFormValues] = useState({
        procedure: '',
        preOpDiagnosis: '',
        postOpDiagnosis: '',
        surgeon: '',
        assistant: '',
        anaesthetist: '',
        procedureDescription: '',
        findings: '',
        specimens: '',
        tubesDrainsPacks: '',
        complications: '',
        postOpInstructions: '',
        file: null,
    });
    let [procedureFormErrors, setProcedureFormErrors] = useState({});
    let [procedureFormSubmitted, setProcedureFormSubmitted] = useState(false);

    // State variable for file preview URL
    let [filePreviewURL2, setFilePreviewURL2] = useState(null);
    const [savedProcedure, setSavedProcedure] = useState(null);

    // Function to handle input changes and update form values
    const handleProcedureInputChange = (event) => {
        const { name, value } = event.target;
        setProcedureFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Function to handle file changes and update the file preview URL
    const handleProcedureFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setProcedureFormValues((prevValues) => ({
            ...prevValues,
            file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setFilePreviewURL2(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setFilePreviewURL2(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setFilePreviewURL2(null);
        }
    };

    // Function to validate the Procedure form
    const validateProcedureForm = () => {
        const errors = {};

        // Validate each field as required
        if (!procedureFormValues.procedure) {
            errors.procedure = 'Procedure/Intervention is required.';
        }
        if (!procedureFormValues.preOpDiagnosis) {
            errors.preOpDiagnosis = 'Pre-Op Diagnosis is required.';
        }
        if (!procedureFormValues.postOpDiagnosis) {
            errors.postOpDiagnosis = 'Post-Op Diagnosis is required.';
        }
        if (!procedureFormValues.surgeon) {
            errors.surgeon = 'Surgeon is required.';
        }
        if (!procedureFormValues.assistant) {
            errors.assistant = 'Assistant is required.';
        }
        if (!procedureFormValues.anaesthetist) {
            errors.anaesthetist = 'Anaesthetist is required.';
        }
        if (!procedureFormValues.procedureDescription) {
            errors.procedureDescription = 'Description of Procedure is required.';
        }
        if (!procedureFormValues.findings) {
            errors.findings = 'Findings are required.';
        }
        if (!procedureFormValues.specimens) {
            errors.specimens = 'Specimens are required.';
        }
        if (!procedureFormValues.tubesDrainsPacks) {
            errors.tubesDrainsPacks = 'Tubes, Drains, Packs etc. are required.';
        }
        if (!procedureFormValues.complications) {
            errors.complications = 'Complications are required.';
        }
        if (!procedureFormValues.postOpInstructions) {
            errors.postOpInstructions = 'Post-Op Instructions & Treatment are required.';
        }
        if (!procedureFormValues.file) {
            errors.file = 'File is required.';
        }

        setProcedureFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitProcedure = (event) => {
        event.preventDefault();
        setProcedureFormSubmitted(true);

        if (validateProcedureForm()) {
            console.log('Form is valid, submitting form:', procedureFormValues);
            setSavedProcedure(procedureFormValues);
            setProcedureShow(false);
            // procedureResetForm();
        } else {
            console.log('Form validation failed');
        }
    };


    // Function to reset the form values and errors
    const procedureResetForm = () => {
        setProcedureFormValues({
            procedure: '',
            preOpDiagnosis: '',
            postOpDiagnosis: '',
            surgeon: '',
            assistant: '',
            anaesthetist: '',
            procedureDescription: '',
            findings: '',
            specimens: '',
            tubesDrainsPacks: '',
            complications: '',
            postOpInstructions: '',
            file: null,
        });
        setProcedureFormErrors({});
        setProcedureFormSubmitted(false);
        setFilePreviewURL2(null);
    };

    let [imagingShow, setImagingShow] = useState(false);
    let [imagingFormValues, setImagingFormValues] = useState({
        imaging: '',
        imagingType: '',
        file: null,
        resultComment: '',
    });
    let [imagingFormErrors, setImagingFormErrors] = useState({});
    let [imagingFormSubmitted, setImagingFormSubmitted] = useState(false);

    // State variable for file preview URL
    let [filePreviewURL3, setFilePreviewURL3] = useState(null);

    // Function to handle input changes and update form values
    const handleImagingInputChange = (event) => {
        const { name, value } = event.target;
        setImagingFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Function to handle file changes and update the file preview URL
    const handleImagingFileChange = (event) => {
        const { files } = event.target;
        const file = files[0];

        // Store the selected file in state
        setImagingFormValues((prevValues) => ({
            ...prevValues,
            file,
        }));

        // Check the file type
        if (file) {
            const fileType = file.type;

            // Check if the file is an image (starts with 'image/')
            const isImage = fileType.startsWith('image/');

            if (isImage) {
                // If the file is an image, generate a preview URL for the image
                const previewURL = URL.createObjectURL(file);
                setFilePreviewURL3(previewURL);
            } else {
                // If the file is not an image, use the placeholder image
                setFilePreviewURL3(dummyDocument);
            }
        } else {
            // If no file is selected, reset the preview URL
            setFilePreviewURL3(null);
        }
    };

    // Function to validate the Imaging form
    const validateImagingForm = () => {
        const errors = {};

        // Validate each field as required
        if (!imagingFormValues.imaging) {
            errors.imaging = 'Imaging is required.';
        }
        if (!imagingFormValues.imagingType) {
            errors.imagingType = 'Imaging type is required.';
        }
        if (!imagingFormValues.file) {
            errors.file = 'File is required.';
        }
        if (!imagingFormValues.resultComment) {
            errors.resultComment = 'Result & Comment are required.';
        }

        setImagingFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    const handleSubmitImaging = (event) => {
        event.preventDefault();
        setImagingFormSubmitted(true);

        // Validate the form
        if (validateImagingForm()) {
            // Form is valid, perform form submission logic (e.g., API call)
            console.log('Form is valid, submitting form:', imagingFormValues);

            // Close the modal since the form is valid
            setImagingShow(false);

            // Reset the form values and errors after successful submission
            imagingResetForm();
        } else {
            console.log('Form validation failed');
        }
    };

    // Function to reset the form values and errors
    const imagingResetForm = () => {
        setImagingFormValues({
            imaging: '',
            imagingType: '',
            file: null,
            resultComment: '',
        });
        setImagingFormErrors({});
        setImagingFormSubmitted(false);
        setFilePreviewURL3(null);
    };

    // Refer Functionality
    const [referOption, setReferOption] = useState("");
    const [labReportsTestFormValues, setLabReportsTestFormValues] = useState({ labTest: [], labTestComments: '' });
    const [labReportsTestFormErrors, setLabReportsTestFormErrors] = useState({});
    const [imagingTypeFormValues, setImagingTypeFormValues] = useState({ imagingType: [] });
    const [imagingTypeFormErrors, setImagingTypeFormErrors] = useState({});
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [referredDoctorFormErrors, setReferredDoctorFormErrors] = useState({});
    const [referProcedureFormValues, setReferProcedureFormValues] = useState({ procedureType: '', procedureComments: '' });
    const [referProcedureFormErrors, setReferProcedureFormErrors] = useState({});

    // Function to handle refer option change
    const handleReferOptionChange = (event) => {
        setReferOption(event.target.value);
        // Clear the errors when refer option is changed
        setReferredDoctorFormErrors({ referOptionMessage: '', doctor: '', doctorComments: '' });
        setLabReportsTestFormErrors({ labTest: '', labTestComments: '' });
        setImagingTypeFormErrors({ imagingType: '', imagingComments: '' });
        setReferProcedureFormErrors({ procedureType: '', procedureComments: '' });
    };

    // Function to handle form submission
    const handleReferSubmit = (event) => {
        event.preventDefault();

        // Validation for radio buttons
        if (!referOption) {
            setReferredDoctorFormErrors({ referOptionMessage: 'Please select an option for referral.' });
            return;
        } else {
            setReferredDoctorFormErrors({ referOptionMessage: '' });
        }

        // Validation based on selected refer option
        if (referOption === 'Lab Report') {
            if (labReportsTestFormValues.labTest.length === 0) {
                setLabReportsTestFormErrors({ labTest: 'Please select at least one lab test.' });
                return;
            } else {
                setLabReportsTestFormErrors({ labTest: '' });
            }

            const labTestComments = document.getElementById('labTestComments').value;
            if (!labTestComments.trim()) {
                setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTestComments: 'Lab Test Comments is required.' });
                return;
            } else {
                setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTestComments: '' });
            }
        } else if (referOption === 'Imaging') {
            if (imagingTypeFormValues.imagingType.length === 0) {
                setImagingTypeFormErrors({ imagingType: 'Please select at least one imaging type.' });
                return;
            } else {
                setImagingTypeFormErrors({ imagingType: '' });
            }

            const imagingComments = document.getElementById('imagingComments').value;
            if (!imagingComments.trim()) {
                setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingComments: 'Imaging Comments is required.' });
                return;
            } else {
                setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingComments: '' });
            }
        } else if (referOption === 'Referred Doctor') {
            if (!selectedDoctor) {
                setReferredDoctorFormErrors({ doctor: 'Please select a doctor.' });
                return;
            } else {
                setReferredDoctorFormErrors({ doctor: '' });
            }

            const doctorComments = document.getElementById('doctorComments').value;
            if (!doctorComments.trim()) {
                setReferredDoctorFormErrors({ ...referredDoctorFormErrors, doctorComments: 'Doctor Comments is required.' });
                return;
            } else {
                setReferredDoctorFormErrors({ ...referredDoctorFormErrors, doctorComments: '' });
            }
        } else if (referOption === 'Procedure') {
            if (!referProcedureFormValues.procedureType) {
                setReferProcedureFormErrors({ procedureType: 'Please select a procedure.' });
                return;
            } else {
                setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureType: '' });
            }

            const procedureComments = document.getElementById('procedureComments').value;
            if (!procedureComments.trim()) {
                setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureComments: 'Procedure Comments is required.' });
                return;
            } else {
                setReferProcedureFormErrors({ ...referProcedureFormErrors, procedureComments: '' });
            }
        }

        // If all validations pass, you can proceed with form submission
        // Add your form submission logic here
    };

    // Function to handle doctor selection
    const handleDoctorSelection = (e) => {
        setSelectedDoctor(e.target.value);
        setReferredDoctorFormErrors({ ...referredDoctorFormErrors, doctor: '' });
    };

    const clearForm = () => {
        setReferOption("");
        setLabReportsTestFormValues({ labTest: [] });
        setLabReportsTestFormErrors({});
        setImagingTypeFormValues({ imagingType: [] });
        setImagingTypeFormErrors({});
        setSelectedDoctor("");
        setReferredDoctorFormErrors({});
        setReferProcedureFormValues({ procedureType: '', procedureComments: '' });
        setReferProcedureFormErrors({});

        const radioButtons = document.getElementsByName("referOptions");
        radioButtons.forEach((radio) => {
            radio.checked = false;
        });
    };

    const handleClear = () => {
        clearForm();
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    };

    const [referLabTestName, setReferLabTestName] = useState([]);
    // Refer Lab Test Master
    const getReferLabTestNameMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/labprofiles`, '');
            if (response.data.status === 'success') {
                setReferLabTestName(response.data.data.labprofiles);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };
    
    const [referImaging, setReferImaging] = useState([]);
    // Refer Lab Test Master
    const getReferImagingMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/radiologies`, '');
            if (response.data.status === 'success') {
                setReferImaging(response.data.data.radiologies);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    return (
        <>
            <Wrapper>
                <Loader loading={loading} />
                <div className="container-fluid">
                    <div className="layout-specing fixed-layout-responsive" style={{ position: "fixed" }}>
                        <div className="row">
                            <div className="col-md-5 mt-2">
                                <div className="card p-3 shadow">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex">
                                                <img src={client1} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                                <div className="overflow-hidden ms-3 mt-1">
                                                    <Link to="#" className="text-dark mb-0 h6 d-block text-truncate">Robert Wilson</Link>
                                                    <small className="text-muted">London, United Kingdom</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                NHS Registration NO
                                            </span> <br />
                                            <span className="text-muted" style={{ fontSize: "13px" }}>
                                                NHS45654645
                                            </span>
                                        </div>
                                        <div className="col-md-3">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                DOB - (Age)
                                            </span> <br />
                                            <span className="text-muted" style={{ fontSize: "13px" }}>
                                                26 Nov 1996 - (27)
                                            </span>
                                        </div>
                                        <div className="col-md-3">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                Gender
                                            </span> <br />
                                            <span className="text-muted" style={{ fontSize: "13px" }}>
                                                Male
                                            </span>
                                        </div>
                                        <div className="col-md-3">
                                            <span className="text-dark" style={{ fontSize: "11px", fontWeight: "600" }}>
                                                Last Visit
                                            </span> <br />
                                            <span className="text-muted" style={{ fontSize: "13px" }}>
                                                24 Apr 2024
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card p-3 shadow mt-3">
                                    <div className="col-md-12">
                                        <div className="border-bottom">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="mb-0">Contact History</label>
                                            </div>
                                        </div>
                                        <SimpleBar style={{ height: '365px' }}>
                                            <ul className="list-unstyled">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "12px", color: "#00bc98", fontWeight: "600" }}>21 April 2024 19:43:22</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "13px", fontWeight: "bold" }}>GP Surgery CT—Mary'S Health Center. Entered Bym: Barron.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Problem</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "12px", fontWeight: "600" }}>Heart Disease (Minor) (Active)</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>History</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>Lorem Ipsum is Simply dummy text Of the printing end typesetting industry- Lorem Ipsum
                                                                has boon tho industry's standard dummy toxt ovor since tho 15008, when an unknown
                                                                printor took o galloy of typo and scromblod it to mako a typo specimon book. It has
                                                                survived not only five centuries. but also the leap into electronic typesetting, remaining
                                                                essentially unchanged.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Examination</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>Lorem Ipsum is simply durnrny text Of the printing and typesetting industry. Lorem Ipsum
                                                                has been the industrys standard dummy text ever since the 1500s.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Comment</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>It has survived not only five centuries, but also the leap into electronic typesetting.
                                                                remaining essentialty unchanged</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <hr style={{ margin: "0.5rem 0" }} />
                                                <li>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "12px", color: "#00bc98", fontWeight: "600" }}>19 March 2024 :17:54:32</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "13px", fontWeight: "bold" }}>GP Surgery CT—Mary'S Health Center. Entered Bym: Barron.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Problem</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "12px", fontWeight: "600" }}>Acute tonsillitis(Review)</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>History</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>Lorem Ipsum is Simply dummy text Of the printing end typesetting industry- Lorem Ipsum
                                                                has boon tho industry's standard dummy toxt ovor since tho 15008, when an unknown
                                                                printor took o galloy of typo and scromblod it to mako a typo specimon book. It has
                                                                survived not only five centuries. but also the leap into electronic typesetting, remaining
                                                                essentially unchanged.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Examination</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>Lorem Ipsum is simply durnrny text Of the printing and typesetting industry. Lorem Ipsum
                                                                has been the industrys standard dummy text ever since the 1500s.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Comment</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>It has survived not only five centuries, but also the leap into electronic typesetting.
                                                                remaining essentialty unchanged</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <hr style={{ margin: "0.5rem 0" }} />
                                                <li>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "12px", color: "#00bc98", fontWeight: "600" }}>15 March 2024 :12:54:32</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "13px", fontWeight: "bold" }}>GP Surgery CT—Mary'S Health Center. Entered Bym: Barron.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Problem</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "12px", fontWeight: "600" }}>Acute tonsillitis(Review)</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>History</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>Lorem Ipsum is Simply dummy text Of the printing end typesetting industry- Lorem Ipsum
                                                                has boon tho industry's standard dummy toxt ovor since tho 15008, when an unknown
                                                                printor took o galloy of typo and scromblod it to mako a typo specimon book. It has
                                                                survived not only five centuries. but also the leap into electronic typesetting, remaining
                                                                essentially unchanged.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Examination</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>Lorem Ipsum is simply durnrny text Of the printing and typesetting industry. Lorem Ipsum
                                                                has been the industrys standard dummy text ever since the 1500s.</span>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <span className="text-muted" style={{ fontSize: "12px", fontWeight: "bold" }}>Comment</span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <span style={{ fontSize: "11px", fontWeight: "400", textWrap: 'wrap' }}>It has survived not only five centuries, but also the leap into electronic typesetting.
                                                                remaining essentialty unchanged</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 mt-2">
                                <div className="card p-3 shadow">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="border-bottom">
                                                <div className="">
                                                    <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded shadow overflow-hidden" style={{ display: "-webkit-inline-box" }}>
                                                        <li className="nav-item">
                                                            <Link
                                                                className={`${activeIndex === 1 ? 'active' : ''} nav-link rounded-0`}
                                                                to="#"
                                                                onClick={() => setActiveIndex(1)}
                                                            >
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={consultationImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Consultation</label>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 2 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setAllergyShow(true)}>
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={allergyImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Allergy</label>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 3 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setVaccinationShow(true)}>
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={VaccinationImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Vaccination</label>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 4 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setLabReportShow(true)} >
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={labReportImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Lab Report</label>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 5 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setDocumentsShow(true)}>
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={DocumentsImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Documents</label>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 6 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setPrescriptionShow(true)}>
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={prescriptionImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Prescription</label>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 7 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setProcedureShow(true)}>
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={procedureImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Procedure</label>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className={`${activeIndex === 8 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setImagingShow(true)}>
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={imagingReportImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Imaging Report</label>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                className={`${activeIndex === 9 ? 'active' : ''} nav-link rounded-0`}
                                                                to="#"
                                                                onClick={() => setActiveIndex(9)}
                                                            >
                                                                <div className="text-center pt-1 pb-1">
                                                                    <img src={referImg} className="tab-icons-size" alt="" />
                                                                    <label className="custom-font-label" style={{ cursor: "pointer" }}>Refer</label>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SimpleBar style={{ height: '430px' }}>
                                        <div className="tab-content mt-4" id="pills-tabContent">
                                            {activeIndex === 1 ?
                                                <div className="tab-pane fade show active">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h6>
                                                                {t('Enter the details Of Treatment')}
                                                            </h6>
                                                            <form className="mt-4" onSubmit={handleSubmit}>
                                                                <div className="row">
                                                                    {/* Date Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="consultationDate"
                                                                                value={formValues.consultationDate} // Bind value to formValues.consultationDate
                                                                                // onChange={handleInputChange}
                                                                                onChange={(e) => setFormValues({ ...formValues, consultationDate: e.target.value })}
                                                                                required=""
                                                                                style={{ borderRadius: "50px" }}
                                                                                disabled={!!appointmentData}
                                                                            />
                                                                            {formSubmitted && formErrors.consultationDate && (
                                                                                <div className="text-danger">{formErrors.consultationDate}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {/* Time Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="row">
                                                                            <label className="custom-font-label">Consultation Time</label>
                                                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                                                <div className="form-group mb-0 w-100 me-auto">
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        style={{ borderRadius: "50px" }}
                                                                                        value={formValues.startTime}
                                                                                        onChange={handleInputChange}
                                                                                        name="startTime"
                                                                                        required=""
                                                                                        disabled={!!appointmentData}
                                                                                    >
                                                                                        <option value="" disabled>From</option>
                                                                                        {setTime.map((time, idx) => (
                                                                                            <option key={idx} value={time.time}>
                                                                                                {time.time}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                                                <div className="form-group mb-0 w-100 me-auto">
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        style={{ borderRadius: "50px" }}
                                                                                        value={formValues.endTime}
                                                                                        onChange={handleInputChange}
                                                                                        name="endTime"
                                                                                        required=""
                                                                                        disabled={!!appointmentData}
                                                                                    >
                                                                                        <option value="" disabled>To</option>
                                                                                        {setTime.map((time, idx) => (
                                                                                            <option key={idx} value={time.time}>
                                                                                                {time.time}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            {formSubmitted && formErrors.startTime && (
                                                                                <div className="text-danger">{formErrors.startTime}</div>
                                                                            )}
                                                                            {formSubmitted && formErrors.endTime && (
                                                                                <div className="text-danger">{formErrors.endTime}</div>
                                                                            )}
                                                                        </div>

                                                                    </div>

                                                                    {/* Consultation Type Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Consultation Type</label>
                                                                            <select
                                                                                className="form-select form-control"
                                                                                value={formValues.consultationType}
                                                                                onChange={handleInputChange}
                                                                                name="consultationType"
                                                                                required=""
                                                                                style={{ borderRadius: "50px" }}
                                                                                disabled={!!appointmentData}
                                                                            >
                                                                                <option value="" disabled>Select</option>
                                                                                <option value="online">Online</option>
                                                                                <option value="offline">Offline</option>
                                                                            </select>
                                                                            {formSubmitted && formErrors.consultationType && (
                                                                                <div className="text-danger">{formErrors.consultationType}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Consultation Comment Field */}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Consultation Comment</label>
                                                                            <textarea
                                                                                name="consultationComments"
                                                                                id="consultationComments"
                                                                                rows="3"
                                                                                className="form-control"
                                                                                value={formValues.consultationComments} /* Bind value to formValues.consultationComments */
                                                                                onChange={handleInputChange} /* Handle input change */
                                                                                disabled={!!appointmentData}
                                                                            ></textarea>
                                                                            {formSubmitted && formErrors.consultationComments && (
                                                                                <div className="text-danger">{formErrors.consultationComments}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    {/* Problem Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Problem</label>
                                                                            <select
                                                                                name="problem"
                                                                                className="form-select form-control"
                                                                                value={formValues.problem}
                                                                                onChange={handleInputChange}
                                                                                style={{ borderRadius: '50px' }}
                                                                            >
                                                                                <option value="" disabled>Select Problem</option>
                                                                                {problems.map((problem) => (
                                                                                    <option key={problem._id} value={problem.name}>
                                                                                        {problem.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {/* Category Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Category</label>
                                                                            <select
                                                                                className="form-select form-control"
                                                                                value={formValues.category}
                                                                                onChange={handleInputChange}
                                                                                name="category"
                                                                                style={{ borderRadius: "50px" }}
                                                                            >
                                                                                <option value="" disabled>Select</option>
                                                                                <option value="major">Major</option>
                                                                                <option value="minor">Minor</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {/* Type Field */}
                                                                    <div className="col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Type</label>
                                                                            <select
                                                                                className="form-select form-control"
                                                                                value={formValues.type}
                                                                                onChange={handleTypeChange}
                                                                                name="type"
                                                                                style={{ borderRadius: "50px" }}
                                                                            >
                                                                                <option value="" disabled>Select</option>
                                                                                <option value="active">Active</option>
                                                                                <option value="past">Past</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {formValues.type === "past" && (
                                                                    <div className="row">
                                                                        {/* Problem Start Date Field */}
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Problem Start Date</label>
                                                                                <input
                                                                                    name="problemStartDate"
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    value={formValues.problemStartDate}
                                                                                    onChange={handleInputChange}
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* Problem End/Expected Date Field */}
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Problem End/Expected Date</label>
                                                                                <input
                                                                                    name="problemEndDate"
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    value={formValues.problemEndDate}
                                                                                    onChange={handleInputChange}
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {/* History Field */}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">History</label>
                                                                            <textarea
                                                                                name="history"
                                                                                id="history"
                                                                                rows="3"
                                                                                className="form-control"
                                                                                value={formValues.history} /* Bind value to formValues.history */
                                                                                onChange={handleInputChange} /* Handle input change */
                                                                            ></textarea>
                                                                            {formSubmitted && formErrors.history && (
                                                                                <div className="text-danger">{formErrors.history}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    {/* Temperature Field */}
                                                                    <div className="col-md-2">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Temp (&deg;C)</label>
                                                                            <input
                                                                                name="Temperature"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Temp"
                                                                                value={formValues.Temperature}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: '50px' }}
                                                                            />
                                                                            {formSubmitted && formErrors.Temperature && <div className="text-danger">{formErrors.Temperature}</div>}
                                                                        </div>
                                                                    </div>
                                                                    {/* Heart Rate Field */}
                                                                    <div className="col-md-2">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">HR (BPM)</label>
                                                                            <input
                                                                                name="heartRate"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="HR"
                                                                                value={formValues.heartRate}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: '50px' }}
                                                                            />
                                                                            {formSubmitted && formErrors.heartRate && <div className="text-danger">{formErrors.heartRate}</div>}
                                                                        </div>
                                                                    </div>
                                                                    {/* Blood Pressure Field */}
                                                                    <div className="col-md-3">
                                                                        <div className="form-group text-start">
                                                                            <label className="custom-font-label">BP (mmHg)</label>
                                                                            <div className="d-flex align-items-center">
                                                                                {/* Systolic input */}
                                                                                <input
                                                                                    className={`form-control`}
                                                                                    placeholder="Systolic"
                                                                                    name="systolic"
                                                                                    type="text"
                                                                                    maxLength={3}
                                                                                    value={systolic}
                                                                                    onChange={handleInputChange2}
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                                &nbsp;<b>/</b>&nbsp;
                                                                                {/* Diastolic input */}
                                                                                <input
                                                                                    className={`form-control`}
                                                                                    placeholder="Diastolic"
                                                                                    name="diastolic"
                                                                                    type="text"
                                                                                    maxLength={3}
                                                                                    value={diastolic}
                                                                                    onChange={handleInputChange2}
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                            </div>
                                                                            {/* Display errors if any */}
                                                                            {formSubmitted && (formErrors.systolic || formErrors.diastolic) && (
                                                                                <div className="text-danger">
                                                                                    <small>
                                                                                        {formErrors.systolic && <div>{formErrors.systolic}</div>}
                                                                                        {formErrors.diastolic && <div>{formErrors.diastolic}</div>}
                                                                                    </small>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {/* Respiratory Rate Field */}
                                                                    <div className="col-md-3">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Respiratory Rate</label>
                                                                            <input
                                                                                name="respiratoryRate"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="RR"
                                                                                value={formValues.respiratoryRate}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: '50px' }}
                                                                            />
                                                                            {formSubmitted && formErrors.respiratoryRate && <div className="text-danger">{formErrors.respiratoryRate}</div>}
                                                                        </div>
                                                                    </div>
                                                                    {/* Blood Oxygen (%) Field */}
                                                                    <div className="col-md-2">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Blood O (%)</label>
                                                                            <input
                                                                                name="bloodOxygen"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="BO"
                                                                                value={formValues.bloodOxygen}
                                                                                onChange={handleInputChange}
                                                                                required=""
                                                                                style={{ borderRadius: '50px' }}
                                                                            />
                                                                            {formSubmitted && formErrors.bloodOxygen && <div className="text-danger">{formErrors.bloodOxygen}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Physical Examination Field */}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Physical Examination</label>
                                                                            <textarea
                                                                                name="physicalExamination"
                                                                                id="physicalExamination"
                                                                                rows="3"
                                                                                className="form-control"
                                                                                value={formValues.physicalExamination} /* Bind value to formValues.physicalExamination */
                                                                                onChange={handleInputChange} /* Handle input change */
                                                                            ></textarea>
                                                                            {formSubmitted && formErrors.physicalExamination && (
                                                                                <div className="text-danger">{formErrors.physicalExamination}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Prescription Details */}
                                                                <div className="row mb-4">
                                                                    {prescriptionList.length > 0 && prescriptionList[0].medication && prescriptionList[0].prescriptionType && prescriptionList[0].dose && prescriptionList[0].quantity && prescriptionList[0].comment && (
                                                                        <>
                                                                            <div className="col-md-12">
                                                                                <label className="custom-font-label">Prescriptions</label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive shadow rounded">
                                                                                    <table className="table table-center bg-white mb-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>SR No</th>
                                                                                                <th>Medication</th>
                                                                                                <th>Prescription Type</th>
                                                                                                <th>Dose</th>
                                                                                                <th>Quantity</th>
                                                                                                <th>Comment</th>
                                                                                                <th>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {prescriptionList.map((item, i) => (
                                                                                                <tr key={i}>
                                                                                                    <td>{i + 1}</td>
                                                                                                    <td>{item.medication}</td>
                                                                                                    <td style={{ textTransform: "capitalize" }}>{item.prescriptionType}</td>
                                                                                                    <td>{item.dose}</td>
                                                                                                    <td>{item.quantity}</td>
                                                                                                    <td>{item.comment}</td>
                                                                                                    <td>
                                                                                                        <Link onClick={() => setPrescriptionShow(true)} className="btn btn-icon btn-pills btn-soft-primary">
                                                                                                            <FiEdit />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </div>

                                                                {/* Procedure Details */}
                                                                <div className="row mb-4">
                                                                    {savedProcedure && (
                                                                        <div className="col-md-12">
                                                                            <label className="custom-font-label">Procedure Details</label>
                                                                            <div className="p-3 border rounded">
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Procedure:</strong><br /> {savedProcedure.procedure}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Pre-Op Diagnosis:</strong><br /> {savedProcedure.preOpDiagnosis}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Post-Op Diagnosis:</strong><br /> {savedProcedure.postOpDiagnosis}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Surgeon:</strong><br /> {savedProcedure.surgeon}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Assistant:</strong><br /> {savedProcedure.assistant}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Anaesthetist:</strong><br /> {savedProcedure.anaesthetist}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Description of Procedure:</strong><br /> {savedProcedure.procedureDescription}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Findings:</strong><br /> {savedProcedure.findings}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Specimens:</strong><br /> {savedProcedure.specimens}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Tubes, Drains, Packs:</strong><br /> {savedProcedure.tubesDrainsPacks}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Complications:</strong><br /> {savedProcedure.complications}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <p><strong>Post-Op Instructions:</strong><br /> {savedProcedure.postOpInstructions}</p>
                                                                                    </div>
                                                                                    {savedProcedure.file && (
                                                                                        <div className="col-md-4">
                                                                                            <p><strong>File:</strong><br /> <a href={URL.createObjectURL(savedProcedure.file)} target="_blank" rel="noopener noreferrer">View File</a></p>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>



                                                                <div className="row mb-4" style={{ justifyContent: 'end' }}>
                                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5">
                                                                        <button type="submit" style={{ padding: "9px 28px" }} className="btn btn-primary w-100">Submit</button>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 btn-responsive-custom">
                                                                        <button type="button" style={{ padding: "9px 28px" }} className="btn btn-danger w-100">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                            {activeIndex === 9 && (
                                                <div className="tab-pane fade show active">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h6>Referred</h6>
                                                        </div>
                                                        {/* Referred Form */}
                                                        <form className="mt-4" onSubmit={handleReferSubmit}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label className="custom-font-label">Refer Options</label>
                                                                        <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                            <div className="custom-control custom-radio custom-control-inline" style={{ marginLeft: "4px", marginRight: "12px" }}>
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input" type="radio" name="referOptions" value="Lab Report" id="labReportBtn" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label" htmlFor="labReportBtn">Lab Report</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input" type="radio" name="referOptions" value="Imaging" id="imagingBtn" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label" htmlFor="imagingBtn">Imaging</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input" type="radio" name="referOptions" value="Referred Doctor" id="ReferredDoctor" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label" htmlFor="ReferredDoctor">Referred Doctor</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <div className="form-check mb-0">
                                                                                    <input className="form-check-input" type="radio" name="referOptions" value="Procedure" id="procedureBtn" onChange={handleReferOptionChange} />
                                                                                    <label className="form-check-label" htmlFor="procedureBtn">Procedure</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {referredDoctorFormErrors.referOptionMessage && (
                                                                            <div className="text-danger">{referredDoctorFormErrors.referOptionMessage}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {referOption === "Lab Report" && (
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3"> 
                                                                            <label className="custom-font-label">Lab Test</label>
                                                                            <Multiselect
                                                                             displayValue="name" 
                                                                                placeholder="Select Lab Test"
                                                                                // selectedValues={labReportsTestFormValues.labTest}
                                                                                options={referLabTestName} // Use departments array directly
                                                                                selectedValues={labReportsTestFormValues.labTest.filter(Boolean)} 
                                                                                onSelect={(selectedList) => {
                                                                                    setLabReportsTestFormValues({ ...labReportsTestFormValues, labTest: selectedList });
                                                                                    setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTest: "" });
                                                                                }}
                                                                                onRemove={(selectedList) => {
                                                                                    setLabReportsTestFormValues({ ...labReportsTestFormValues, labTest: selectedList });
                                                                                    setLabReportsTestFormErrors({ ...labReportsTestFormErrors, labTest: "" });
                                                                                }}
                                                                                required=""
                                                                            />
                                                                            {labReportsTestFormErrors.labTest && (
                                                                                <div className="text-danger">{labReportsTestFormErrors.labTest}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Lab Test Comments</label>
                                                                                <textarea
                                                                                    name="labTestComments"
                                                                                    id="labTestComments"
                                                                                    rows="5"
                                                                                    className="form-control"
                                                                                    value={labReportsTestFormValues.labTestComments} // Bind value with labTestComments from state
                                                                                    onChange={(e) => {
                                                                                        setLabReportsTestFormValues({ ...labReportsTestFormValues, labTestComments: e.target.value });
                                                                                    }}
                                                                                    required=""
                                                                                ></textarea>
                                                                                {labReportsTestFormErrors.labTestComments && (
                                                                                    <div className="text-danger">{labReportsTestFormErrors.labTestComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )}
                                                                {referOption === "Imaging" && (
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Imaging Type</label>
                                                                            <Multiselect
                                                                                isObject={false}
                                                                                options={['X-Ray', 'MRI', 'CT Scan', 'Ultrasound']}
                                                                                placeholder="Select Imaging Type"
                                                                                selectedValues={imagingTypeFormValues.imagingType}
                                                                                onSelect={(selectedList) => {
                                                                                    setImagingTypeFormValues({ ...imagingTypeFormValues, imagingType: selectedList });
                                                                                    setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingType: "" });
                                                                                }}
                                                                                onRemove={(selectedList) => {
                                                                                    setImagingTypeFormValues({ ...imagingTypeFormValues, imagingType: selectedList });
                                                                                    setImagingTypeFormErrors({ ...imagingTypeFormErrors, imagingType: "" });
                                                                                }}
                                                                                required=""
                                                                            />
                                                                            {imagingTypeFormErrors.imagingType && (
                                                                                <div className="text-danger">{imagingTypeFormErrors.imagingType}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Imaging Comments</label>
                                                                                <textarea name="imagingComments" id="imagingComments" rows="5" className="form-control" required=""></textarea>
                                                                                {imagingTypeFormErrors.imagingComments && (
                                                                                    <div className="text-danger">{imagingTypeFormErrors.imagingComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {referOption === "Referred Doctor" && (
                                                                    <div>
                                                                        <div className="col-md-12 mb-3">
                                                                            <label className="custom-font-label">Doctor</label>
                                                                            <select className="form-select form-control" style={{ borderRadius: "50px" }} onChange={handleDoctorSelection} required="">
                                                                                <option value="" disabled>Select Doctor</option>
                                                                                <option value="1">Dr. John Doe</option>
                                                                                <option value="2">Dr. Jane Smith</option>
                                                                            </select>
                                                                            {referredDoctorFormErrors.doctor && (
                                                                                <div className="text-danger">{referredDoctorFormErrors.doctor}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Doctor Comments</label>
                                                                                <textarea name="doctorComments" id="doctorComments" rows="5" className="form-control" required=""></textarea>
                                                                                {referredDoctorFormErrors.doctorComments && (
                                                                                    <div className="text-danger">{referredDoctorFormErrors.doctorComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {referOption === "Procedure" && (
                                                                    <div className="col-md-12">
                                                                        {/* Procedure options */}
                                                                        <div className="mb-3">
                                                                            <label className="custom-font-label">Select Procedure</label>
                                                                            <select className="form-select form-control" style={{ borderRadius: "50px" }} value={referProcedureFormValues.procedureType} onChange={(e) => setReferProcedureFormValues({ ...referProcedureFormValues, procedureType: e.target.value })} required="">
                                                                                <option value="" disabled>Select Procedure</option>
                                                                                <option value="Procedure 1">Procedure 1</option>
                                                                                <option value="Procedure 2">Procedure 2</option>
                                                                            </select>
                                                                            {referProcedureFormValues.procedureType === "" && (
                                                                                <div className="text-danger">Please select a procedure.</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Procedure Comments</label>
                                                                                <textarea name="procedureComments" id="procedureComments" rows="5" className="form-control" value={referProcedureFormValues.procedureComments} onChange={(e) => setReferProcedureFormValues({ ...referProcedureFormValues, procedureComments: e.target.value })} required=""></textarea>
                                                                                {referProcedureFormErrors.procedureComments && (
                                                                                    <div className="text-danger">{referProcedureFormErrors.procedureComments}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                            <div className="row mb-4" style={{ justifyContent: 'end' }}>
                                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5">
                                                                    <button type="submit" style={{ padding: "9px 28px" }} className="btn btn-primary w-100">Submit</button>
                                                                </div>
                                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 btn-responsive-custom">
                                                                    <button type="button" style={{ padding: "9px 28px" }} className="btn btn-danger w-100" onClick={handleClear}>Clear</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </SimpleBar>
                                    {/* Allergy Modal */}
                                    <Modal show={allergyShow} onHide={() => { setAllergyShow(false); allergyResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="allergyModalID">Add New Allergy</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitAllergy}>
                                                <div className="row">
                                                    {/* Allergy Name Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Allergy</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={allergyFormValues.allergyName}
                                                                onChange={handleInputChange3}
                                                                name="allergyName"
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {allergy.map((item) => (
                                                                    <option key={item._id} value={item.allergy_name}>
                                                                        {item.allergy_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {allergyFormSubmitted && allergyFormErrors.allergyName && (
                                                                <div className="text-danger">{allergyFormErrors.allergyName}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Allergy Type Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Allergy Type</label>
                                                            <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                    <div className="form-check mb-0">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="allergyType"
                                                                            value="active"
                                                                            id="flexRadioDefault1"
                                                                            onChange={handleInputChange3}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">Active</label>
                                                                    </div>
                                                                </div>

                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <div className="form-check mb-0">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="allergyType"
                                                                            value="past"
                                                                            id="flexRadioDefault2"
                                                                            onChange={handleInputChange3}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">Past</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {allergyFormSubmitted && allergyFormErrors.allergyType && (
                                                                <div className="text-danger">{allergyFormErrors.allergyType}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Conditional Fields for Start Date and End Date */}
                                                    {allergyFormValues.allergyType === 'past' && (
                                                        <>
                                                            {/* Allergy Start Date Field */}
                                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="custom-font-label">Start Date</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        name="allergyStartDate"
                                                                        value={allergyFormValues.allergyStartDate}
                                                                        onChange={handleInputChange3}
                                                                        required=""
                                                                        style={{ borderRadius: "50px" }}
                                                                    />
                                                                    {allergyFormSubmitted && allergyFormErrors.allergyStartDate && (
                                                                        <div className="text-danger">{allergyFormErrors.allergyStartDate}</div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {/* Allergy End Date Field */}
                                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="custom-font-label">Expected End Date</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        name="allergyEndDate"
                                                                        value={allergyFormValues.allergyEndDate}
                                                                        onChange={handleInputChange3}
                                                                        required=""
                                                                        style={{ borderRadius: "50px" }}
                                                                    />
                                                                    {allergyFormSubmitted && allergyFormErrors.allergyEndDate && (
                                                                        <div className="text-danger">{allergyFormErrors.allergyEndDate}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                {/* Allergy Comment Field */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Comment</label>
                                                            <textarea
                                                                name="allergyComments"
                                                                id="allergyComments"
                                                                rows="3"
                                                                className="form-control"
                                                                value={allergyFormValues.allergyComments}
                                                                onChange={handleInputChange3}
                                                                required=""
                                                            ></textarea>
                                                            {allergyFormSubmitted && allergyFormErrors.allergyComments && (
                                                                <div className="text-danger">{allergyFormErrors.allergyComments}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100"
                                                            onClick={() => { setAllergyShow(false); allergyResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Active / Past Allergies</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="15%">Allergy Name</th>
                                                                    <th className="border-bottom p-2" width="15%">Allergy Type</th>
                                                                    <th className="border-bottom p-2" width="15%">Start Date</th>
                                                                    <th className="border-bottom p-2" width="15%">Expected End Date</th>
                                                                    <th className="border-bottom p-2" width="30%">Comment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listAllergy.length > 0 ? (
                                                                    listAllergy.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.allergy_details?.name}</td>
                                                                            <td style={{ textTransform: "capitalize" }}>{item?.allergy_details?.type}</td>
                                                                            <td>{formatDate(item?.allergy_details?.start_date)}</td>
                                                                            <td>{formatDate(item?.allergy_details?.end_date)}</td>
                                                                            <td>{item?.allergy_details?.comment}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="6">No allergy records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>


                                    {/* Vaccination Modal */}
                                    <Modal show={vaccinationShow} onHide={() => { setVaccinationShow(false); vaccinationResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="vaccinationModalID">Add New Vaccinations</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitVaccination}>
                                                <div className="row">
                                                    {/* Vaccine Name Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Vaccine Name</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={vaccinationFormValues.vaccineName}
                                                                onChange={handleVaccinationInputChange}
                                                                name="vaccineName"
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {vaccinations.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.vaccineName && (
                                                                <div className="text-danger">{vaccinationFormErrors.vaccineName}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Manufacture Name Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Manufacture Name</label>
                                                            <input
                                                                name="manufactureName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Manufacture Name"
                                                                value={vaccinationFormValues.manufactureName}
                                                                onChange={handleVaccinationInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.manufactureName && (
                                                                <div className="text-danger">{vaccinationFormErrors.manufactureName}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Date of Vaccination Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Vaccination</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="vaccinationDate"
                                                                value={vaccinationFormValues.vaccinationDate}
                                                                onChange={handleVaccinationInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.vaccinationDate && (
                                                                <div className="text-danger">{vaccinationFormErrors.vaccinationDate}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Expiry Date Field */}
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Expiry Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="expiryDate"
                                                                value={vaccinationFormValues.expiryDate}
                                                                onChange={handleVaccinationInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.expiryDate && (
                                                                <div className="text-danger">{vaccinationFormErrors.expiryDate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Additional Notes Field */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Additional Notes</label>
                                                            <textarea
                                                                name="additionalNotes"
                                                                id="additionalNotes"
                                                                rows="3"
                                                                className="form-control"
                                                                value={vaccinationFormValues.additionalNotes}
                                                                onChange={handleVaccinationInputChange}
                                                            ></textarea>
                                                            {vaccinationFormSubmitted && vaccinationFormErrors.additionalNotes && (
                                                                <div className="text-danger">{vaccinationFormErrors.additionalNotes}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setVaccinationShow(false); vaccinationResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Vaccinations</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="18%">Name</th>
                                                                    <th className="border-bottom p-2" width="18%">Manufacturer</th>
                                                                    <th className="border-bottom p-2" width="12%">Date Of Vaccine</th>
                                                                    <th className="border-bottom p-2" width="12%">Expire Date</th>
                                                                    <th className="border-bottom p-2" width="30%">Additional Notes</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listVaccination.length > 0 ? (
                                                                    listVaccination.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.vaccinations?.name}</td>
                                                                            <td>{item?.vaccinations?.manufacture_name}</td>
                                                                            <td>{formatDate(item?.vaccinations?.vaccination_date)}</td>
                                                                            <td>{formatDate(item?.vaccinations?.expiry_date)}</td>
                                                                            <td>{item?.vaccinations?.comment}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="text-center">
                                                                        <td colSpan="6">No vaccination records found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Lab Report Modal */}
                                    <Modal show={labReportShow} onHide={() => { setLabReportShow(false); resetLabReportForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="labReportModalID">Add New Lab Report</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitLabReport}>
                                                <div className="row">
                                                    {/* Test Name Field */}
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Test Name</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={labReportFormValues.testName}
                                                                onChange={handleInputChange4}
                                                                name="testName"
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {testName.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {labReportFormSubmitted && labReportFormErrors.testName && (
                                                                <div className="text-danger">{labReportFormErrors.testName}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Date of Collect Sample Field */}
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Collect Sample</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="dateOfCollectSample"
                                                                value={labReportFormValues.dateOfCollectSample}
                                                                onChange={handleInputChange4}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {labReportFormSubmitted && labReportFormErrors.dateOfCollectSample && (
                                                                <div className="text-danger">{labReportFormErrors.dateOfCollectSample}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Date of Receive Sample Field */}
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Receive Sample</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="dateOfReceiveSample"
                                                                value={labReportFormValues.dateOfReceiveSample}
                                                                onChange={handleInputChange4}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {labReportFormSubmitted && labReportFormErrors.dateOfReceiveSample && (
                                                                <div className="text-danger">{labReportFormErrors.dateOfReceiveSample}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Upload Multiple Images */}
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Upload Reports</label>
                                                            <section>
                                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                                    <input {...getInputProps()} />
                                                                    <p>Upload Images</p>
                                                                </div>
                                                                <aside style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>{filePreviews}</aside>
                                                            </section>
                                                        </div>
                                                        <div className="text-danger text-start">
                                                            {isInvalid && (
                                                                <small>
                                                                    {files.length < 2 && 'Please upload a minimum of 2 images.'}
                                                                    {files.length > 5 && 'Maximum 5 images are allowed.'}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setLabReportShow(false); resetLabReportForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Lab Reports</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="30%">Test Name</th>
                                                                    <th className="border-bottom p-2" width="24%">Date Of Collected Sample</th>
                                                                    <th className="border-bottom p-2" width="24%">date Of ReceiveS ample</th>
                                                                    <th className="border-bottom p-2" width="12%">Reports</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {labReportData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.dateOfCollectedSample}</td>
                                                                        <td>{item.dateOfReceiveSample}</td>
                                                                        <td className="text-center">
                                                                            <Link className="btn btn-icon btn-pills btn-soft-primary"><LiaFile /></Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Documents Modal */}
                                    <Modal show={documentsShow} onHide={() => { setDocumentsShow(false); documentsResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="documentsModalID">Add New Document</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitDocuments}>
                                                <div className="row">
                                                    {/* Document Type Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Document Type</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={documentsFormValues.documentType}
                                                                onChange={handleDocumentsInputChange}
                                                                name="documentType"
                                                                required=""
                                                                style={{ borderRadius: "50px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {documentType.map((item) => (
                                                                    <option key={item._id} value={item.document_type}>
                                                                        {item.document_type}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {documentFormSubmitted && documentsFormErrors.documentType && (
                                                                <div className="text-danger">{documentsFormErrors.documentType}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Specialty Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Specialty</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={documentsFormValues.specialty}
                                                                onChange={handleDocumentsInputChange}
                                                                name="specialty"
                                                                required=""
                                                                style={{ borderRadius: "50px" }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {specialty.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {documentFormSubmitted && documentsFormErrors.specialty && (
                                                                <div className="text-danger">{documentsFormErrors.specialty}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Date of Document Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Date of Document</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="documentDate"
                                                                value={documentsFormValues.documentDate}
                                                                onChange={handleDocumentsInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {documentFormSubmitted && documentsFormErrors.documentDate && (
                                                                <div className="text-danger">{documentsFormErrors.documentDate}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Document File Field */}
                                                    {/* Document File Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Document File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="documentFile"
                                                                onChange={handleDocumentsFileChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {documentFormSubmitted && documentsFormErrors.documentFile && (
                                                                <div className="text-danger">{documentsFormErrors.documentFile}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* File preview column */}
                                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                                        {filePreviewURL && (
                                                            <img
                                                                src={filePreviewURL}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Additional Notes Field */}
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Description</label>
                                                            <textarea
                                                                name="description"
                                                                id="description"
                                                                rows="2"
                                                                className="form-control"
                                                                value={documentsFormValues.description}
                                                                onChange={handleDocumentsInputChange}
                                                            ></textarea>
                                                            {documentFormSubmitted && documentsFormErrors.description && (
                                                                <div className="text-danger">{documentsFormErrors.description}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setDocumentsShow(false); documentsResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Documents</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="18%">Document Type</th>
                                                                    <th className="border-bottom p-2" width="17%">Specialty</th>
                                                                    <th className="border-bottom p-2" width="15%">Date Of Document</th>
                                                                    <th className="border-bottom p-2" width="30%">Description</th>
                                                                    <th className="border-bottom p-2" width="15%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {documentsData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.documentType}</td>
                                                                        <td>{item.specialty}</td>
                                                                        <td>{item.dateOfDocument}</td>
                                                                        <td>{item.description}</td>
                                                                        <td className="text-center">
                                                                            <Link className="btn btn-icon btn-pills btn-soft-primary"><LiaFile /></Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Prescription Modal */}
                                    <Modal show={prescriptionShow} onHide={() => { setPrescriptionShow(false); prescriptionResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="prescriptionModalID">Add Prescription</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th width="5%">SR No</th>
                                                                    <th width="20%">Medication</th>
                                                                    <th width="15%">Prescription Type</th>
                                                                    <th width="15%">Dose</th>
                                                                    <th width="10%">Quantity</th>
                                                                    <th width="25%">Comment/Instructions</th>
                                                                    <th width="15%">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {prescriptionList.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>
                                                                            <select
                                                                                name="medication"
                                                                                className={`form-control form-select ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].medication ? 'is-invalid' : ''}`}
                                                                                value={item.medication}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            >
                                                                                <option value="" disabled>Select Medication</option>
                                                                                {/* <option value="Medication 1">Medication 1</option>
                                                                                <option value="Medication 2">Medication 2</option>
                                                                                <option value="Medication 3">Medication 3</option> */}
                                                                                {medications.map((item) => (
                                                                                    <option key={item._id} value={item.name}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                ))}
                                                                                {/* Add other medications as needed */}
                                                                            </select>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].medication && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].medication}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                name="prescriptionType"
                                                                                className={`form-control form-select ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].prescriptionType ? 'is-invalid' : ''}`}
                                                                                value={item.prescriptionType}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            >
                                                                                <option value="" disabled>Select Type</option>
                                                                                <option value="acute">Acute</option>
                                                                                <option value="repeat">Repeat</option>
                                                                                {/* Add other types as needed */}
                                                                            </select>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].prescriptionType && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].prescriptionType}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                name="dose"
                                                                                className={`form-control form-select ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].dose ? 'is-invalid' : ''}`}
                                                                                value={item.dose}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            >
                                                                                <option value="" disabled>Select Dose</option>
                                                                                {/* <option value="Dose 1">Once a day</option>
                                                                                <option value="Dose 2">Twice a day</option>
                                                                                <option value="Dose 3">Three times a day</option>
                                                                                <option value="Dose 3">Once a week</option> */}
                                                                                {medicineDosage.map((item) => (
                                                                                    <option key={item._id} value={item.medicine_dosage}>
                                                                                        {item.medicine_dosage}
                                                                                    </option>
                                                                                ))}
                                                                                {/* Add other doses as needed */}
                                                                            </select>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].dose && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].dose}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="quantity"
                                                                                min={0}
                                                                                max={100}
                                                                                className={`form-control ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].quantity ? 'is-invalid' : ''}`}
                                                                                value={item.quantity}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            />
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].quantity && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].quantity}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <textarea
                                                                                name="comment"
                                                                                className={`form-control ${prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].comment ? 'is-invalid' : ''}`}
                                                                                rows="2"
                                                                                value={item.comment}
                                                                                onChange={(event) => handleInputChange5(i, event)}
                                                                            ></textarea>
                                                                            {prescriptionFormSubmitted && prescriptionFormErrors[i] && prescriptionFormErrors[i].comment && (
                                                                                <span className="invalid-feedback" style={{ fontSize: '80%' }}>
                                                                                    {prescriptionFormErrors[i].comment}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div className="actions" style={{ marginTop: '4px' }}>
                                                                                <Link onClick={() => addPrescriptionRow(i)} className="btn btn-icon btn-pills btn-soft-primary">
                                                                                    <RiAddFill />
                                                                                </Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <Link onClick={() => deletePrescriptionRow(i)} className="btn btn-icon btn-pills btn-soft-danger">
                                                                                    <RiDeleteBinFill />
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3" style={{ justifyContent: 'end' }}>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-100"
                                                        onClick={prescriptionHandleFormSubmit}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                    <button type="button" className="btn btn-danger w-100" onClick={() => { setPrescriptionShow(false); prescriptionResetForm(); }}>Cancel</button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Procedure Modal */}
                                    <Modal show={procedureShow} onHide={() => { setProcedureShow(false); procedureResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="procedureModalID">Add Procedure</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitProcedure}>
                                                <div className="row">
                                                    {/* Select or Add Procedure / Intervention */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Select or Add Procedure / Intervention</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={procedureFormValues.procedure}
                                                                onChange={handleProcedureInputChange}
                                                                name="procedure"
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {/* <option value="Procedure 1">Procedure 1</option>
                                                                <option value="Procedure 2">Procedure 2</option>
                                                                <option value="Procedure 3">Procedure 3</option> */}
                                                                {interventions.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                                {/* Add other procedures as needed */}
                                                            </select>
                                                            {procedureFormSubmitted && procedureFormErrors.procedure && (
                                                                <div className="text-danger">{procedureFormErrors.procedure}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Pre-Op Diagnosis Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Pre-Op Diagnosis</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="preOpDiagnosis"
                                                                value={procedureFormValues.preOpDiagnosis}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Pre-Op Diagnosis"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.preOpDiagnosis && (
                                                                <div className="text-danger">{procedureFormErrors.preOpDiagnosis}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Post-Op Diagnosis Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Post-Op Diagnosis</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="postOpDiagnosis"
                                                                value={procedureFormValues.postOpDiagnosis}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Post-Op Diagnosis"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.postOpDiagnosis && (
                                                                <div className="text-danger">{procedureFormErrors.postOpDiagnosis}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Surgeon Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Surgeon</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="surgeon"
                                                                value={procedureFormValues.surgeon}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Surgeon"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.surgeon && (
                                                                <div className="text-danger">{procedureFormErrors.surgeon}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Assistant Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Assistant</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="assistant"
                                                                value={procedureFormValues.assistant}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Assistant"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.assistant && (
                                                                <div className="text-danger">{procedureFormErrors.assistant}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Anaesthetist Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Anaesthetist</label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="anaesthetist"
                                                                value={procedureFormValues.anaesthetist}
                                                                onChange={handleProcedureInputChange}
                                                                required
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select Anaesthetist</option>
                                                                {anaesthesia.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {procedureFormSubmitted && procedureFormErrors.anaesthetist && (
                                                                <div className="text-danger">{procedureFormErrors.anaesthetist}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Description of Procedure Field */}
                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Description of Procedure</label>
                                                            <textarea
                                                                name="procedureDescription"
                                                                id="procedureDescription"
                                                                rows="2"
                                                                className="form-control"
                                                                value={procedureFormValues.procedureDescription}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                            ></textarea>
                                                            {procedureFormSubmitted && procedureFormErrors.procedureDescription && (
                                                                <div className="text-danger">{procedureFormErrors.procedureDescription}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Findings Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Findings</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="findings"
                                                                value={procedureFormValues.findings}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Findings"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.findings && (
                                                                <div className="text-danger">{procedureFormErrors.findings}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Specimens Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Specimens</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="specimens"
                                                                value={procedureFormValues.specimens}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Specimens"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.specimens && (
                                                                <div className="text-danger">{procedureFormErrors.specimens}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Tubes, Drains, Packs etc Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Tubes, Drains, Packs etc</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="tubesDrainsPacks"
                                                                value={procedureFormValues.tubesDrainsPacks}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="ubes, Drains, Packs etc"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.tubesDrainsPacks && (
                                                                <div className="text-danger">{procedureFormErrors.tubesDrainsPacks}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Complications Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Complications</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="complications"
                                                                value={procedureFormValues.complications}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Complications"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.complications && (
                                                                <div className="text-danger">{procedureFormErrors.complications}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Post-Op Instructions & Treatment Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Post-Op Instructions & Treatment</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="postOpInstructions"
                                                                value={procedureFormValues.postOpInstructions}
                                                                onChange={handleProcedureInputChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                                placeholder="Post-Op Instructions & Treatment"
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.postOpInstructions && (
                                                                <div className="text-danger">{procedureFormErrors.postOpInstructions}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="file"
                                                                onChange={handleProcedureFileChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {procedureFormSubmitted && procedureFormErrors.file && (
                                                                <div className="text-danger">{procedureFormErrors.file}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File preview column */}
                                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                                        {filePreviewURL2 && (
                                                            <img
                                                                src={filePreviewURL2}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setProcedureShow(false); procedureResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* Other modal body content */}
                                        </Modal.Body>
                                    </Modal>

                                    {/* Imaging Modal */}
                                    <Modal show={imagingShow} onHide={() => { setImagingShow(false); imagingResetForm(); }} size="xl" centered backdrop="static">
                                        <Modal.Header closeButton>
                                            <h5 className="modal-title" id="imagingModalID">Add Imaging</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={handleSubmitImaging}>
                                                <div className="row">
                                                    {/* Imaging Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Imaging</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={imagingFormValues.imaging}
                                                                onChange={handleImagingInputChange}
                                                                name="imaging"
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select Imaging</option>
                                                                {imaging.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {imagingFormSubmitted && imagingFormErrors.imaging && (
                                                                <div className="text-danger">{imagingFormErrors.imaging}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Imaging Type Field */}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Imaging Type</label>
                                                            <select
                                                                className="form-select form-control"
                                                                value={imagingFormValues.imagingType}
                                                                onChange={handleImagingInputChange}
                                                                name="imagingType"
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            >
                                                                <option value="" disabled>Select Imaging Type</option>
                                                                {imagingType.map((item) => (
                                                                    <option key={item._id} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {imagingFormSubmitted && imagingFormErrors.imagingType && (
                                                                <div className="text-danger">{imagingFormErrors.imagingType}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File Field */}
                                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="file"
                                                                onChange={handleImagingFileChange}
                                                                required=""
                                                                style={{ borderRadius: '50px' }}
                                                            />
                                                            {imagingFormSubmitted && imagingFormErrors.file && (
                                                                <div className="text-danger">{imagingFormErrors.file}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* File preview column */}
                                                    <div className="col-lg-1 col-md-1 col-sm-2">
                                                        {filePreviewURL3 && (
                                                            <img
                                                                src={filePreviewURL3}
                                                                alt="File preview"
                                                                style={{ width: "75px", height: "75px" }}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Result & Comment Field */}
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="custom-font-label">Result & Comment</label>
                                                            <textarea
                                                                name="resultComment"
                                                                id="resultComment"
                                                                rows="3"
                                                                className="form-control"
                                                                value={imagingFormValues.resultComment}
                                                                onChange={handleImagingInputChange}
                                                                required=""
                                                            ></textarea>
                                                            {imagingFormSubmitted && imagingFormErrors.resultComment && (
                                                                <div className="text-danger">{imagingFormErrors.resultComment}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ justifyContent: 'end' }}>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100">Save</button>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-5 mt-2">
                                                        <button type="button" className="btn btn-danger w-100" onClick={() => { setImagingShow(false); imagingResetForm(); }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3">
                                                <h6>Imaging</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-2">
                                                    <div className="table-responsive shadow rounded">
                                                        <table className="table table-center bg-white mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-bottom p-2" width="10%">ID</th>
                                                                    <th className="border-bottom p-2" width="17%">Imaging</th>
                                                                    <th className="border-bottom p-2" width="18%">Imaging Type</th>
                                                                    <th className="border-bottom p-2" width="45%">Result & Comment</th>
                                                                    <th className="border-bottom p-2" width="10%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {imagingData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.imaging}</td>
                                                                        <td>{item.imagingType}</td>
                                                                        <td>{item.description}</td>
                                                                        <td className="text-center">
                                                                            <Link className="btn btn-icon btn-pills btn-soft-primary"><LiaFile /></Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper >
        </>
    )
}
