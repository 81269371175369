import axiosInstance from "../../../interceptor/interceptor-instance"

export const userChatHistory = (data) => { 
    return axiosInstance.post(`doctor/chat`, data);
}

export const sendChatMessage = (data) => {
    return axiosInstance.post(`doctor/chat/send-message`, data);
}

export const searchChatUser = (data) => {
    return axiosInstance.post(`doctor/chat/search/users`, data);
}

export const chatContactList = (data) => {
    return axiosInstance.post(`doctor/chat/messages/relations`, data);
}
