import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import dummyImg from '../../assets/images/MyImages/no_image.png';
import { useTranslation } from 'react-i18next';
import Loader from "../../components/loader";
import { createAppointment } from './service/appointment-service';
import { format } from 'date-fns';
import { parse } from 'date-fns';

const AddPatientModal = ({ show, onHide, appointmentData, selectedDate, fetchAppointments }) => {

    useEffect(() => {
        if (appointmentData) {
            console.log('Appointment Data:', appointmentData);
            console.log('Date:', selectedDate);
            setFormValues({ ...formValues, contactDate: selectedDate });
            setContactDate(selectedDate); // Sync state
        }
    }, [appointmentData, selectedDate]);

    const { t } = useTranslation();
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        nationalID: '',
        phone: '',
        email: '',
        dob: '',
        gender: '',
        age: '',
        bloodGroup: '',
        address: '',
        imageFile: null,
        healthID: '', // Added healthID field
        consultationType: '',
        contactDate: '',
        consultationFor: '',
        appointmentCharge: ''
    });
    const [countryCode, setCountryCode] = useState("");
    const [phoneCode, setPhoneCode] = useState('');
    const [fullPhone, setFullPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    // State to manage whether to show healthID field or not
    const [showHealthIDField, setShowHealthIDField] = useState(true);
    // Function to determine whether to show additional fields based on healthID value
    const showAdditionalFields = !showHealthIDField;
    // State variables to hold validation errors
    const [validationErrors, setValidationErrors] = useState({
        firstName: '',
        lastName: '',
        nationalID: '',
        phone: '',
        email: '',
        dob: '',
        gender: '',
        age: '',
        bloodGroup: '',
        address: '',
        healthID: '', // Added healthID field
        consultationType: '',
        contactDate: '',
        consultationFor: '',
        appointmentCharge: ''
    });



    // Set date here
    // const [contactDate, setContactDate] = useState(selectedDate);
    const [contactDate, setContactDate] = useState(selectedDate || ''); // Initialize with selectedDate if available
    const handleContactDateChange = (e) => {
        setContactDate(e.target.value);
        setFormValues({ ...formValues, contactDate: e.target.value });
    };

    // Function to handle radio button change
    const handleRadioChange = (e) => {
        const { value } = e.target;
        setShowHealthIDField(value === 'yes');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handlePhoneChange = (value, country) => {
        const { dialCode } = country;
        if (value.startsWith(`${dialCode}`)) {
            const phoneNumber = value.slice(dialCode.length).trim();
            const countryCode = `+${dialCode}`
            setPhoneCode(countryCode)
            setFormValues({ ...formValues, phone: phoneNumber });
            setFormErrors({ ...formErrors, phone: "" });
            setFullPhone(`${countryCode}${phoneNumber}`)
            // console.log(phoneCode)
            // console.log(phoneNumber)
            // console.log(fullPhone)
        } else {
            const phoneNumber = value;
            const countryCode = `+${dialCode}`
            console.log(countryCode)
            console.log(phoneNumber)
            setPhoneCode(countryCode)
            setFormValues({ ...formValues, phone: phoneNumber });
            setFormErrors({ ...formErrors, phone: "" });
            setFullPhone(`${countryCode}${phoneNumber}`);
        }
    };

    const [getUserData, setUserData] = useState(() => {
        const storedData = localStorage.getItem("currentDoctor");
        return storedData ? JSON.parse(storedData) : null;
    });
    // c

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     // Perform validation
    //     const errors = {};

    //     // Check required fields
    //     if (!formValues.firstName || !formValues.firstName.trim()) {
    //         errors.firstName = "First Name is required.";
    //     }
    //     if (!formValues.lastName || !formValues.lastName.trim()) {
    //         errors.lastName = "Last Name is required.";
    //     }
    //     // if (!formValues.nationalID || !formValues.nationalID.trim()) {
    //     //     errors.nationalID = "National ID is required.";
    //     // }
    //     // if (!formValues.phone || !formValues.phone.trim()) {
    //     //     errors.phone = "Phone Number is required.";
    //     // }
    //     // if (!formValues.email || !formValues.email.trim()) {
    //     //     errors.email = "Email is required.";
    //     // }
    //     if (!formValues.dob || !formValues.dob.trim()) {
    //         errors.dob = "Date of Birth is required.";
    //     }
    //     if (!formValues.gender) {
    //         errors.gender = "Gender is required.";
    //     }
    //     if (!formValues.age || !formValues.age.trim()) {
    //         errors.age = "Age is required.";
    //     }
    //     // if (!formValues.bloodGroup || !formValues.bloodGroup.trim()) {
    //     //     errors.bloodGroup = "Blood Group is required.";
    //     // }
    //     if (!formValues.address || !formValues.address.trim()) {
    //         errors.address = "Address is required.";
    //     }

    //     // Check additional fields based on healthID value
    //     if (showHealthIDField) {
    //         if (!formValues.healthID || !formValues.healthID.trim()) {
    //             errors.healthID = "Health ID is required.";
    //         }
    //     }
    //     if (!formValues.consultationType) {
    //         errors.consultationType = "Consultation Type is required.";
    //     }
    //     if (!formValues.contactDate) {
    //         errors.contactDate = "Contact Date is required.";
    //     }
    //     if (!formValues.consultationFor || !formValues.consultationFor.trim()) {
    //         errors.consultationFor = "Consultation For is required.";
    //     }
    //     setValidationErrors(errors);

    //     // Check if there are any errors
    //     if (Object.keys(errors).length > 0) {
    //         return;
    //     }

    //     try {
    //         setLoading(true);
    //         const data = {
    //             // _id: getUserData._id,
    //             slot_id: appointmentData._id,
    //             start_time: format(new Date(`01/01/2000 ${appointmentData.start_time}`), "HH:mm"),
    //             end_time: format(new Date(`01/01/2000 ${appointmentData.end_time}`), "HH:mm"),
    //             duration: appointmentData.duration,
    //             // appointment_date: format(contactDate, "yyyy-MM-dd"),
    //             appointment_date: format(new Date(contactDate), "yyyy-MM-dd"),
    //             appointment_charges: formValues.appointmentCharge,
    //             consultation_for: formValues.consultationFor,
    //             consultation_type: formValues.consultationType,
    //             first_name: formValues.firstName,
    //             last_name: formValues.lastName,
    //             national_id: formValues.nationalID,
    //             email: formValues.email,
    //             gender: formValues.gender,
    //             dob: format(formValues.dob, "yyyy-MM-dd"),
    //             age: formValues.age,
    //             address: formValues.address,
    //             blood_group: formValues.bloodGroup,
    //             country_code: countryCode,
    //             phone_code: phoneCode,
    //             mobile: formValues.phone,
    //         };
    //         const response = await createAppointment(data);
    //         console.log(response.data.status);
    //     } catch (error) {
    //         console.error("Error fetching appointments:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    //     console.log('Form submitted:', formValues);
    //     // Add your form submission logic here
    //     // Example: Call an API to add the patient
    //     // toast.success('Patient added successfully!');
    //     // onHide(); // Close the modal after form submission
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        if (showHealthIDField) {
            // Validate only the fields for "yes" case
            if (!formValues.healthID || !formValues.healthID.trim()) {
                errors.healthID = "Health ID is required.";
            }
            if (!formValues.consultationType) {
                errors.consultationType = "Consultation Type is required.";
            }
            if (!contactDate) {
                errors.contactDate = "Contact Date is required.";
            }
            if (!formValues.appointmentCharge) {
                errors.appointmentCharge = "Appointment Charge is required.";
            }
            if (!formValues.consultationFor || !formValues.consultationFor.trim()) {
                errors.consultationFor = "Consultation For is required.";
            }
        } else {
            // Validate all fields for "no" case
            if (!formValues.firstName || !formValues.firstName.trim()) {
                errors.firstName = "First Name is required.";
            }
            if (!formValues.lastName || !formValues.lastName.trim()) {
                errors.lastName = "Last Name is required.";
            }
            // if (!formValues.nationalID || !formValues.nationalID.trim()) {
            //     errors.nationalID = "National ID is required.";
            // }
            // if (!formValues.phone || !formValues.phone.trim()) {
            //     errors.phone = "Phone Number is required.";
            // }
            // if (!formValues.email || !formValues.email.trim()) {
            //     errors.email = "Email is required.";
            // }
            if (!formValues.dob || !formValues.dob.trim()) {
                errors.dob = "Date of Birth is required.";
            }
            if (!formValues.gender) {
                errors.gender = "Gender is required.";
            }
            if (!formValues.age || !formValues.age.trim()) {
                errors.age = "Age is required.";
            }
            // if (!formValues.bloodGroup || !formValues.bloodGroup.trim()) {
            //     errors.bloodGroup = "Blood Group is required.";
            // }
            if (!formValues.address || !formValues.address.trim()) {
                errors.address = "Address is required.";
            }
            if (!formValues.consultationType) {
                errors.consultationType = "Consultation Type is required.";
            }
            if (!contactDate) {
                errors.contactDate = "Contact Date is required.";
            }
            if (!formValues.appointmentCharge) {
                errors.appointmentCharge = "Appointment Charge is required.";
            }
            if (!formValues.consultationFor || !formValues.consultationFor.trim()) {
                errors.consultationFor = "Consultation For is required.";
            }
        }

        setValidationErrors(errors);

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            return;
        }

        try {
            setLoading(true);
            let data = {};
            if (showHealthIDField) {
                data = {
                    slot_id: appointmentData._id,
                    start_time: format(new Date(`01/01/2000 ${appointmentData.start_time}`), "HH:mm"),
                    end_time: format(new Date(`01/01/2000 ${appointmentData.end_time}`), "HH:mm"),
                    duration: appointmentData.duration,
                    appointment_date: format(new Date(contactDate), "yyyy-MM-dd"),
                    appointment_charges: formValues.appointmentCharge,
                    consultation_for: formValues.consultationFor,
                    consultation_type: formValues.consultationType,
                    health_id: formValues.healthID,
                };
            } else {
                data = {
                    slot_id: appointmentData._id,
                    start_time: format(new Date(`01/01/2000 ${appointmentData.start_time}`), "HH:mm"),
                    end_time: format(new Date(`01/01/2000 ${appointmentData.end_time}`), "HH:mm"),
                    duration: appointmentData.duration,
                    appointment_date: format(new Date(contactDate), "yyyy-MM-dd"),
                    appointment_charges: formValues.appointmentCharge,
                    consultation_for: formValues.consultationFor,
                    consultation_type: formValues.consultationType,
                    first_name: formValues.firstName,
                    last_name: formValues.lastName,
                    national_id: formValues.nationalID,
                    email: formValues.email,
                    gender: formValues.gender,
                    dob: format(new Date(formValues.dob), "yyyy-MM-dd"),
                    age: formValues.age,
                    address: formValues.address,
                    blood_group: formValues.bloodGroup,
                    country_code: formValues.countryCode,
                    phone_code: formValues.phoneCode,
                    mobile: formValues.phone,
                };
            }

            const response = await createAppointment(data);
            if(response.data.status === "success") {
                toast.success(response.data.message);
                fetchAppointments();
                console.log(response.data.status);
            }
        } catch (error) {
            console.error("Error creating appointment:", error);
        } finally {
            setLoading(false);
        }

        console.log('Form submitted:', formValues);
        onHide(); // Close the modal after form submission
    };


    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFormValues({ ...formValues, imageFile: file });
        }
    };

    const [imagePreview, setImagePreview] = useState(dummyImg);

    // Reset form values and errors when the modal is closed
    useEffect(() => {
        if (!show) {
            setFormValues({
                firstName: '',
                lastName: '',
                nationalID: '',
                phone: '',
                email: '',
                dob: '',
                gender: '',
                age: '',
                bloodGroup: '',
                address: '',
                imageFile: null,
                healthID: '', // Added healthID field
                consultationType: '', // Added consultationType field
                contactDate: '', // Added contactDate field
                consultationFor: '', // Added consultationFor field
            });
            setValidationErrors({
                firstName: '',
                lastName: '',
                nationalID: '',
                phone: '',
                email: '',
                dob: '',
                gender: '',
                age: '',
                bloodGroup: '',
                address: '',
                healthID: '', // Added healthID field
                consultationType: '', // Added consultationType field
                contactDate: '', // Added contactDate field
                consultationFor: '', // Added consultationFor field
            });
        }
    }, [show]);

    return (
        <>
            <Loader loading={loading} />
            <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add Patient")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="col-md-6">
                                <h6>{t("Does the patient have a Health ID")}</h6>
                                <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                    <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                        <div className="form-check mb-0">
                                            <input
                                                className="form-check-input custom-radio-input"
                                                type="radio"
                                                name="hasHealthID"
                                                id="healthIDYes"
                                                value="yes"
                                                checked={showHealthIDField} // checked state based on showHealthIDField
                                                onChange={() => setShowHealthIDField(true)} // Show health ID field
                                            />
                                            <label className="form-check-label custom-radio-label" htmlFor="healthIDYes">
                                                {t("Yes")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <div className="form-check mb-0">
                                            <input
                                                 className="form-check-input custom-radio-input"
                                                type="radio"
                                                name="hasHealthID"
                                                id="healthIDNo"
                                                value="no"
                                                checked={!showHealthIDField} // checked state based on showHealthIDField
                                                onChange={() => setShowHealthIDField(false)} // Hide health ID field
                                            />
                                            <label className="form-check-label custom-radio-label" htmlFor="healthIDNo">
                                                {t("No")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {/* Conditionally render Health ID field based on radio button selection */}
                            {showHealthIDField && (
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label htmlFor="healthID" className="form-label">
                                            {t("Health ID")}
                                        </label>
                                        <input
                                            style={{ borderRadius: "50px" }}
                                            type="text"
                                            className="form-control"
                                            id="healthID"
                                            name="healthID"
                                            value={formValues.healthID}
                                            onChange={handleInputChange}
                                            placeholder={t("Enter Health ID")}
                                            required=""
                                        />
                                        <div className="text-danger">{validationErrors.healthID}</div>
                                    </div>
                                </div>
                            )}
                            {/* Consultation Type Field */}
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label className="form-label">{t("Consultation Type")}</label>
                                    <select
                                        name="consultationType"
                                        className="form-select form-control"
                                        value={formValues.consultationType}
                                        onChange={handleInputChange}
                                        style={{ borderRadius: "50px" }}
                                    >
                                        <option value="">{t("Select Consultation Type")}</option>
                                        <option value="online">Online</option>
                                        <option value="offline">Offline</option>
                                    </select>
                                    <div className="text-danger">{validationErrors.consultationType}</div>
                                </div>
                            </div>

                            {/* Contact Date Field */}
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label className="form-label">Contact Date</label>
                                    <input
                                        name="contactDate"
                                        type="date"
                                        className="form-control"
                                        value={contactDate} // Bind the contactDate field value to the input field
                                        onChange={handleContactDateChange} // Handle input changes
                                        style={{ borderRadius: "50px" }}
                                    />
                                    <div className="text-danger">{validationErrors.contactDate}</div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label className="form-label">{t("Appointment Charge")}</label>
                                    <input
                                        name="appointmentCharge"
                                        type="text" // Assuming appointment charge is a number
                                        className="form-control"
                                        value={formValues.appointmentCharge}
                                        onChange={handleInputChange}
                                        style={{ borderRadius: "50px" }}
                                        placeholder={t("Enter Appointment Charge")}
                                    />
                                    <div className="text-danger">{validationErrors.appointmentCharge}</div>
                                </div>
                            </div>

                            {/* Consultation For Field */}
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label">{t("Consultation For")}</label>

                                    <textarea
                                        className="form-control"
                                        id="consultationFor"
                                        rows={3}
                                        name="consultationFor"
                                        value={formValues.consultationFor}
                                        onChange={handleInputChange}
                                        placeholder={t("Enter Consultation For")}
                                        required=""
                                        // style={{ borderRadius: "50px", padding: "0.375rem 1rem" }}
                                    />
                                    <div className="text-danger">{validationErrors.consultationFor}</div>
                                </div>
                            </div>
                        </div>

                        {/* Additional Fields */}
                        {/* Only show additional fields if healthID is not 'yes' */}
                        {showAdditionalFields && (
                            <>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="firstName" className="form-label">
                                                {t("First Name")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                name="firstName"
                                                value={formValues.firstName}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter First Name")}
                                                required=""
                                            />
                                            <div className="text-danger">{validationErrors.firstName}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label">
                                                {t("Last Name")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                name="lastName"
                                                value={formValues.lastName}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter Last Name")}
                                                required=""
                                            />
                                            <div className="text-danger">{validationErrors.lastName}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="nationalID" className="form-label">
                                                {t("National ID")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="text"
                                                className="form-control"
                                                id="nationalID"
                                                name="nationalID"
                                                value={formValues.nationalID}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter National ID")}
                                            // required=""
                                            />
                                            <div className="text-danger">{validationErrors.nationalID}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="phone" className="form-label">
                                                {t("Phone Number")}
                                            </label>
                                            <PhoneInput
                                                country="in"
                                                value={fullPhone}
                                                onChange={handlePhoneChange}
                                                containerClassName="phone-input-contained"
                                                enableSearch
                                                searchPlaceholder="Search your country"
                                                searchNotFound="Country not found"
                                            // required=""
                                            />
                                            <div className="text-danger">{validationErrors.phone}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">
                                                {t("Email")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={formValues.email}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter Email")}
                                            // required=""
                                            />
                                            <div className="text-danger">{validationErrors.email}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="dob" className="form-label">
                                                {t("Date of Birth")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="date"
                                                className="form-control"
                                                id="dob"
                                                name="dob"
                                                value={formValues.dob}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter Date of Birth")}
                                                required=""
                                            />
                                            <div className="text-danger">{validationErrors.dob}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label">
                                                {t("Gender")}
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                id="gender"
                                                name="gender"
                                                value={formValues.gender}
                                                onChange={handleInputChange}
                                                required=""
                                                style={{ borderRadius: "50px" }}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                            <div className="text-danger">{validationErrors.gender}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="age" className="form-label">
                                                {t("Age")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="number"
                                                className="form-control"
                                                id="age"
                                                name="age"
                                                value={formValues.age}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter Age")}
                                                required=""
                                            />
                                            <div className="text-danger">{validationErrors.age}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="bloodGroup" className="form-label">
                                                {t("Blood Group")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="text"
                                                className="form-control"
                                                id="bloodGroup"
                                                name="bloodGroup"
                                                value={formValues.bloodGroup}
                                                onChange={handleInputChange}
                                                placeholder={t("Enter Blood Group")}
                                            // required=""
                                            />
                                            <div className="text-danger">{validationErrors.bloodGroup}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="image" className="form-label">
                                                {t("Profile Image")}
                                            </label>
                                            <input
                                                style={{ borderRadius: "50px" }}
                                                type="file"
                                                className="form-control"
                                                id="image"
                                                name="image"
                                                onChange={handleImageChange}
                                                accept="image/jpeg, image/jpg, image/png, image/tiff, image/bpg, image/bmp, image/heic, image/heif"
                                            />
                                            <div className="text-danger">{validationErrors.image}</div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        {formValues.imageFile && (
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <img src={imagePreview} alt="Profile Preview" style={{ objectFit: 'contain' }}
                                                            height="75px"
                                                            width="75px" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">
                                                {t("Address")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="address"
                                                name="address"
                                                value={formValues.address}
                                                onChange={handleInputChange}
                                                placeholder="Enter Address"
                                                required=""
                                                style={{ borderRadius: "50px" }}
                                            />
                                            <div className="text-danger">{validationErrors.address}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="row my-2">
                            <div className="col-md-12 text-end">
                                <button type="submit" className="btn btn-primary">{t("Save")}</button>
                                <button type="button" className="btn btn-danger ms-3" onClick={onHide}>{t("Cancel")}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </>
    );

};

export default AddPatientModal;
