import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../interceptor/interceptor-instance";
import { toast } from "react-toastify";
import TopHeader from "../../components/topHeader";
import Wrapper from "../../components/wrapper";
import SimpleBar from "simplebar-react";

export default function ChangePassword(props) {
    const [toggle, setToggle] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [toggleOldPassword, setToggleOldPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        // Validation for old password
        if (!oldPassword.trim()) {
            errors.oldPassword = "Old password is required";
        }

        // Validation for new password
        if (!newPassword.trim()) {
            errors.newPassword = "New password is required";
        } else if (newPassword.length < 8) {
            errors.newPassword = "New password must be at least 8 characters long";
        }

        // Validation for confirm password
        if (!confirmPassword.trim()) {
            errors.confirmPassword = "Confirm password is required";
        } else if (confirmPassword !== newPassword) {
            errors.confirmPassword = "Passwords do not match";
        }

        // Set errors
        setErrors(errors);

        // If there are errors, prevent form submission
        if (Object.keys(errors).length > 0) {
            return;
        }

        // If no errors, proceed with form submission logic
        // console.log("Form submitted:", { oldPassword, newPassword });
        const body = {
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
        }
        try {
            const response = await axiosInstance.patch(`doctor/profile/change/password`, body)
            if (response.data.status === "success") {
                // Reset form values
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
                toast.success(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const navigate = useNavigate();

    const dashboard = () => {
        navigate("/index");
    };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing fixed-layout-responsive" style={{ width: "-webkit-fill-available" }}>
                <SimpleBar className="change-password-simple-bar">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="d-md-flex justify-content-between mb-4">
                                        <h5 className="mb-0 custom-text-shadow">Change Password</h5>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Old password <span className="text-danger">*</span></label>
                                            <div className="position-relative">
                                                <input
                                                    type={toggleOldPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Old password"
                                                    value={oldPassword}
                                                    onChange={(e) => setOldPassword(e.target.value)}
                                                    required=''
                                                />
                                                <div className="show-hide" onClick={() => setToggleOldPassword(!toggleOldPassword)}>
                                                    {toggleOldPassword ? (
                                                        <i className="mdi mdi-eye-off"></i>
                                                    ) : (
                                                        <i className="mdi mdi-eye"></i>
                                                    )}
                                                </div>
                                            </div>
                                            {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">New password <span className="text-danger">*</span></label>
                                            <div className="position-relative">
                                                <input
                                                    type={toggleNewPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="New password"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    required=''
                                                />
                                                <div className="show-hide" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                                    {toggleNewPassword ? (
                                                        <i className="mdi mdi-eye-off"></i>
                                                    ) : (
                                                        <i className="mdi mdi-eye"></i>
                                                    )}
                                                </div>
                                            </div>
                                            {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Re-type New password <span className="text-danger">*</span></label>
                                            <div className="position-relative">
                                                <input
                                                    type={toggleConfirmPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Re-type New password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required=''
                                                />
                                                <div className="show-hide" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                    {toggleConfirmPassword ? (
                                                        <i className="mdi mdi-eye-off"></i>
                                                    ) : (
                                                        <i className="mdi mdi-eye"></i>
                                                    )}
                                                </div>
                                            </div>
                                            {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-3 mt-3">
                                                <button type="submit" className="btn btn-primary w-100">Change Password</button>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <button type="button" className="btn btn-danger w-100" onClick={dashboard}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
                </div>
            </div>
        </Wrapper>
    )
}
