import React, { useState } from 'react'
import bg from '../../assets/images/MyImages/bg.png'
import colorLogo from '../../assets/images/MyImages/color_logo_500.png'
import loginImg from '../../assets/images/MyImages/login_image.png'
import OtpInput from 'react-otp-input';
import styles from './email-verify.module.css';
import { useAuth } from './auth';

export default function EmailVerify({ email }) {
    const [otp, setOtp] = useState(0);
    const [otpError, setOtpError] = useState("");
    const { emailVerify } = useAuth();

    const validateFields = () => {
        let isValid = true;
        if (!otp) {
            setOtpError("Otp is required");
            isValid = false;
        } else {
            setOtpError("");
        }
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateFields()) {
            const body = {
                role: 'doctor',
                otp: otp
            }
            emailVerify(body);
        }
    }
    return (
        <>
            <section className="bg-home d-flex bg-light align-items-center login-page-height main-login-wrapper" style={{ backgroundImage: `url(${bg})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="card login-page shadow mt-4" style={{ borderRadius: '5px' }}>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-0 col-sm-0 p-0 d-none d-lg-block" style={{ position: 'relative' }}>
                                            <img src={loginImg} className="w-100 h-auto" style={{ borderRadius: '5px' }} alt="" />
                                            <img src={colorLogo} className="h-auto" style={{ width: '40%', position: 'absolute', top: '8%', left: '30%', transform: 'translate(-50%, -50%)' }} alt="" />
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-sm-12 p-5">
                                            <h4 className="text-left">Verify Email</h4>
                                            <p>An email has been sent to {email}. Please verify your email.</p>
                                            <form className="login-form mt-4" onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">OTP <span className="text-danger">*</span></label>
                                                    <OtpInput
                                                        value={otp} containerStyle={{ justifyContent: 'space-around' }} inputStyle={`${styles.formControl}`}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        shouldAutoFocus={true}
                                                        inputType={'tel'}
                                                        renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                                        renderInput={(props) => <input {...props} />}
                                                    />
                                                    {otpError && <div className="text-danger">{otpError}</div>}
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-primary">Verify</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
