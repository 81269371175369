import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoDark from '../assets/images/logo-dark.png'
import logoLight from '../assets/images/logo-light.png'
import logoIcon from '../assets/images/svg/landing.png';
import patientLogo from '../assets/images/svg/patient-logo.png';
import patientEMRLogo from '../assets/images/svg/patient-emr.png';
import chatLogo from '../assets/images/svg/chat-logo.png';
import videoLogo from '../assets/images/svg/video-call.png';
import settingLogo from '../assets/images/svg/setting.png';
import appointmentLight from '../assets/images/svg/calendar_appointment_0.png';
import appointmentDark from '../assets/images/svg/calendar_appointment_1.png';
import patientLight from '../assets/images/svg/patient_0.png';
import patientDark from '../assets/images/svg/patient_1.png';
import chatLight from '../assets/images/svg/chat_0.png';
import chatDark from '../assets/images/svg/chat_1.png';
import incomeLight from '../assets/images/svg/income_0.png';
import incomeDark from '../assets/images/svg/income_1.png';
import settingLight from '../assets/images/svg/Setting_0.png';
import settingDark from '../assets/images/svg/Setting_1.png';
import { GrDashboard } from '../assets/icons/vander'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export default function Sidebar({ manuClass }) {
    const [manu, setManu] = useState('');
    const [subManu, setSubManu] = useState('');
    const location = useLocation();

    useEffect(() => {
        var current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)
        setSubManu(current)
    }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const navigate = useNavigate();

    const backToLanding = () => {
        navigate('/landing');
        localStorage.removeItem("patientData");
        localStorage.removeItem("patientAppointmentType");
    };

    return (
        <nav id="sidebar" className={manuClass}>
            <SimpleBar className="sidebar-content" data-simplebar style={{ height: 'calc(100% - 60px)' }}>
                {/* <div className="sidebar-brand">
                        <Link to="/index">
                            <img src={logoDark} height="28" className="logo-light-mode" alt=""/>
                            <img src={logoLight} height="28" className="logo-dark-mode" alt=""/>
                            <span className="sidebar-colored">
                                <img src={logoLight} height="28" alt=""/>
                            </span>
                        </Link>
                    </div> */}

                <ul className="sidebar-menu">
                    <li className={`${manu === "landing" || "" ? "active" : ""} ms-0`}>
                    <Link to="/landing" onClick={backToLanding}>
                        <img src={logoIcon} className="d-inline-block mb-0 icon" />
                    </Link></li>
                    {/* <li className={`${manu === "doctors" ? "active" : ""} ms-0`}><Link to="/doctors"><img src={appointmentLight} className="d-inline-block mb-0 icon"/></Link></li>                          */}
                    <li className={`${manu === "patient-dashboard" ? "active" : ""} ms-0`}>
                        <Link to="/patient-dashboard">
                            <img
                                src={patientLogo}
                                className="d-inline-block mb-0 icon"
                                alt="Patient Icon"
                            />
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <img
                                src={patientEMRLogo}
                                className="d-inline-block mb-0 icon"
                                alt="Patient Icon"
                            />
                        </Link>
                    </li>
                    <li className={`${manu === "chat" ? "active" : ""} ms-0`}>
                        <Link to="/chat">
                            <img
                                src={chatLogo}
                                className="d-inline-block mb-0 icon"
                                alt="Patient Icon"
                            />
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <img
                                src={videoLogo}
                                className="d-inline-block mb-0 icon"
                                alt="Patient Icon"
                            />
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <img
                                src={settingLogo}
                                className="d-inline-block mb-0 icon"
                                alt="Patient Icon"
                            />
                        </Link>
                    </li>
                    {/* <li className={`${manu === "appointments" ? "active" : ""} ms-0`}>
                        <Link to="/appointments">
                            <img
                                src={manu === "appointments" ? appointmentDark : appointmentLight}
                                className="d-inline-block mb-0 icon"
                                alt="Appointment Icon"
                            />
                        </Link>
                    </li>
                    <li className={`${manu === "chat" ? "active" : ""} ms-0`}>
                        <Link to="/chat">
                            <img
                                src={manu === "chat" ? chatDark : chatLight}
                                className="d-inline-block mb-0 icon"
                                alt="Chat Icon"
                            />
                        </Link>
                    </li>
                    <li className={`${manu === "accounts" ? "active" : ""} ms-0`}>
                        <Link to="/accounts">
                            <img
                                src={manu === "accounts" ? incomeDark : incomeLight}
                                className="d-inline-block mb-0 icon"
                                alt="Income Icon"
                            />
                        </Link>
                    </li> */}
                    {/* <li className={`${manu === "settings" ? "active" : ""} ms-0`}>
                        <Link to="/settings">
                            <img
                                src={manu === "settings" ? settingDark : settingLight}
                                className="d-inline-block mb-0 icon"
                                alt="Income Icon"
                            />
                        </Link>
                    </li> */}
                </ul>

            </SimpleBar>
            {/* <ul className="sidebar-footer list-unstyled mb-0">
                    <li className="list-inline-item mb-0 ms-1">
                        <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">
                            <FaRegComment />
                        </Link>
                    </li>
                </ul> */}
        </nav>
    )
}