import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/wrapper';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiSearch, LiaTimesSolid, FiEdit } from '../../assets/icons/vander';
import { medicineData } from '../../data/data';

export default function ListMedicines() {
    const [viewProfile, setViewProfile] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [show, setShow] = useState(false);
    
    // State for form values and errors
    const [formValues, setFormValues] = useState({ medicine: '' });
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Event handler for closing modals
    useEffect(() => {
        const closeModals = () => {
            setViewProfile(false);
            setEditProfile(false);
        };
        document.addEventListener('mousedown', closeModals);
        return () => document.removeEventListener('mousedown', closeModals);
    }, []);

    // Toggle dropdown visibility
    const toggleDropdown = (index) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null);
        } else {
            setOpenDropdownIndex(index);
        }
    };

    // Handle clearing the search field
    const handleClear = () => {
        setSearchValue('');
    };

    // Handle search field input change
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    // Handle input change for form fields
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Validate the form fields
    const validateForm = () => {
        const errors = {};

        // Validate Medicine field
        if (!formValues.medicine.trim()) {
            errors.medicine = 'Medicine Name is required.';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);

        if (validateForm()) {
            // Perform form submission logic here, e.g., send formValues to an API
            console.log('Form submitted:', formValues);

            // Reset form and close the modal after successful submission
            resetForm();
            setShow(false);
        }
    };

    // Function to reset the form values and errors
    const resetForm = () => {
        setFormValues({ medicine: '' });
        setFormErrors({});
        setFormSubmitted(false);
    };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 p-4 rounded shadow">
                                <div className="row">
                                    <div className="col-xl-9 col-md-6">
                                        <h5 className="mb-0 custom-text-shadow">Medicines</h5>
                                        <nav aria-label="breadcrumb" className="d-inline-block mt-2">
                                            <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                                                <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Medicines</li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                        <button className="btn btn-primary" onClick={() => setShow(true)}>Add A Medicines</button>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <div className="col-lg-10 col-md-9 col-sm-9 mt-2">
                                        <input
                                            type="text"
                                            className="form-control border rounded-pill"
                                            style={{ lineHeight: 2 }}
                                            name="s"
                                            id="s"
                                            placeholder="Search..."
                                            value={searchValue}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mt-2 d-flex justify-content-start align-items-center" style={{ gap: '18px' }}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Search</Tooltip>}
                                        >
                                            <button className="btn btn-icon btn-pills btn-primary" style={{ height: '45px', width: '45px' }}>
                                                <FiSearch className="icons" />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Clear</Tooltip>}
                                        >
                                            <button
                                                className="btn btn-icon btn-pills btn-danger"
                                                style={{ height: '45px', width: '45px' }}
                                                onClick={handleClear}
                                            >
                                                <LiaTimesSolid className="icons" />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <div className="table-responsive shadow rounded">
                                            <table className="table table-center table-bordered bg-white mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-bottom p-3" width="10%">ID</th>
                                                        <th className="border-bottom p-3" width="78%">Medicine Name</th>
                                                        <th className="border-bottom p-3" width="12%">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {medicineData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.id}</td>
                                                            <td>{item.name}</td>
                                                            <td className='text-center'>
															<OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}>
                                                                <Link className="btn btn-icon btn-pills btn-soft-primary"><FiEdit className="icons" onClick={() => setShow(true)}/></Link>
                                                            </OverlayTrigger>
															</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row text-center">
                                    <div className="col-12 mt-4">
                                        <div className="d-md-flex align-items-center text-center justify-content-between">
                                            <span className="text-muted me-3">Showing 1 - 10 out of 50</span>
                                            <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                                <li className="page-item"><Link className="page-link" to="#" aria-label="Previous">Prev</Link></li>
                                                <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                                                <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                <li className="page-item"><Link className="page-link" to="#" aria-label="Next">Next</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="LoginForm">
                <Modal show={show} onHide={() => { setShow(false); resetForm(); }} centered backdrop="static">
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="LoginForm-title">Add A Medicine</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                {/* Medicine Field */}
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Medicine Name <span className="text-danger">*</span></label>
                                        <input
                                            name="medicine"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Medicine Name"
                                            value={formValues.medicine}
                                            onChange={handleInputChange}
                                        />
                                        {formSubmitted && formErrors.medicine && (
                                            <div className="text-danger">{formErrors.medicine}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setShow(false); resetForm(); }}>Cancel</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Wrapper>
    );
}
