import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "./assets/scss/style.scss";
import "./assets/scss/bootstrap.scss";
import "./assets/css/materialdesignicons.min.css"
import Index from "./page";
import IndexDarkSidebar from "./page/index-sidebar-dark";
import IndexSidebarColored from "./page/index-sidebar-colored";
import Doctors from "./page/doctors/doctors";
import AddDoctor from "./page/doctors/add-doctor";
import DrProfile from "./page/doctors/dr-profile";
import Chats from "./page/my-chat/chat";
import Email from "./page/apps/email";
import Calendar from "./page/apps/calendar";
import Shop from "./page/pharmacy/shop";
import ProductDetail from "./page/pharmacy/product-detail";
import ShopCart from "./page/pharmacy/shopcart";
import Checkout from "./page/pharmacy/checkout";
import Blogs from "./page/blog/blogs";
import BlogDetail from "./page/blog/blog-detail";
import Faqs from "./page/faqs";
import Review from "./page/review";
import InvoiceList from "./page/invoice-list";
import Invoice from "./page/invoice";
import Terms from "./page/terms";
import Privacy from "./page/privacy";
import BlankPage from "./page/blank-page";
import EmailConfirmation from "./page/email/email-confirmation";
import EmailPasswordReset from "./page/email/email-password-reset";
import EmailAlert from "./page/email/email-alert";
import EmailInvoice from "./page/email/email-invoice";
import Login from "./page/authentication/login";
import Signup from "./page/authentication/signup";
import ForgotPassword from "./page/authentication/forgot-password";
import LockScreen from "./page/authentication/lock-screen";
import UiButton from "./page/uiComponents/ui-button";
import UiBadges from "./page/uiComponents/ui-badges";
import UiAlert from "./page/uiComponents/ui-alert";
import UiDropDown from "./page/uiComponents/ui-dropdown";
import UiTypography from "./page/uiComponents/ui-typography";
import UiBackground from "./page/uiComponents/ui-background";
import UiText from "./page/uiComponents/ui-text";
import UiTooltipPopover from "./page/uiComponents/ui-tooltip-popover";
import UiShadow from "./page/uiComponents/ui-shadow";
import UiBorder from "./page/uiComponents/ui-border";
import UiForm from "./page/uiComponents/ui-form";
import UiPagination from "./page/uiComponents/ui-pagination";
import UiAvatar from "./page/uiComponents/ui-avatar";
import UiModals from "./page/uiComponents/ui-modals";
import UiIcon from "./page/uiComponents/ui-icons";
import Comingsoon from "./page/comingsoon";
import Maintenance from "./page/maintenance";
import Error from "./page/error";
import ThankYou from "./page/thankyou";
import ResetPassword from "./page/authentication/reset-password";
import ChangePassword from "./page/MyPages/change-password";
import { ToastContainer } from "react-toastify";
import EditDoctor from "./page/doctors/edit-dcotor";
import ViewDoctor from "./page/doctors/view-doctor";
import Assistance from "./page/assistances/list-assistance";
import AddAssistance from "./page/assistances/add-assistance";
import EditAssistance from "./page/assistances/edit-assistance";
import ViewAssistance from "./page/assistances/view-assistance";
import DoctorPrescription from "./page/doctors/doctor-prescription";
import DoctorAvailability from "./page/doctors/doctor-availability";
import MedicalListRecord from "./page/medical-records/medical-record-list";
import ListAllergies from "./page/Masters/list-allergy";
import ListProblems from "./page/Masters/list-problem";
import ListImagingTest from "./page/Masters/list-imaging";
import ListLabTest from "./page/Masters/list-lab-test";
import ListMedicines from "./page/Masters/list-medicine";
import ListTimeSlot from "./page/Masters/list-time-slot";
import { AuthProvider } from "./page/authentication/auth";
import PrivateRoute from "./page/authentication/private-route";
import EmailVerify from "./page/authentication/email-verify";
import Notifications from "./page/doctor-notifications/notification";
import LandingPage from "./page/landing/landing";
import PatientSearchList from "./page/patient-search-list/patient-search-list";
import AddPatient from './page/add-patient/add-patient';
import Appointments from "./page/appointments/appointments";
import Settings from "./page/time-settings/settings";
import Accounts from "./page/accounts/accounts";
import AccountDetails from "./page/accounts/accounts-details";
import PatientDashboard from "./page/patient-dashboard/patient-dashboard";
import UpdateSettings from "./page/time-settings/update-settings";

function App() {
	return (
		<>
			<AuthProvider>
				<Routes>
					{/* private Routes */}
					<Route element={<PrivateRoute />}>
						<Route path="/" element={<LandingPage />} />
						<Route path="/landing" element={<LandingPage />} />
						<Route path="/patient-search-list" element={<PatientSearchList />} />
						<Route path="/index" element={<Index />} />
						<Route path="/patient-dashboard" element={<PatientDashboard />} />
						<Route path="/change-password" element={<ChangePassword />} />
						<Route path="/notifications" element={<Notifications />} />
						<Route path="/index-sidebar-dark" element={<IndexDarkSidebar />} />
						<Route path="/index-sidebar-colored" element={<IndexSidebarColored />} />
						<Route path="/doctors" element={<Doctors />} />
						<Route path="/add-doctor" element={<AddDoctor />} />
						<Route path="/edit-doctor" element={<EditDoctor />} />
						<Route path="/view-doctor" element={<ViewDoctor />} />
						<Route path="/appointments" element={<Appointments />} />
						<Route path="/doctor-prescription" element={<DoctorPrescription />} />
						<Route path="/doctor-availability" element={<DoctorAvailability />} />
						<Route path="/dr-profile" element={<DrProfile />} />
						<Route path="/dr-profile/:id" element={<DrProfile />} />
						<Route path="/staff" element={<Assistance />} />
						<Route path="/add-staff" element={<AddAssistance />} />
						<Route path="/edit-staff" element={<EditAssistance />} />
						<Route path="/view-staff" element={<ViewAssistance />} />
						<Route path="/add-patient" element={<AddPatient />} />
						<Route path="/medical-record-list" element={<MedicalListRecord />} />
						<Route path="/allergies" element={<ListAllergies />} />
						<Route path="/problems" element={<ListProblems />} />
						<Route path="/imaging-test" element={<ListImagingTest />} />
						<Route path="/lab-test" element={<ListLabTest />} />
						<Route path="/medicines" element={<ListMedicines />} />
						<Route path="/time-slot" element={<ListTimeSlot />} />
						<Route path="/chat" element={<Chats />} />
						<Route path="/settings/:doctorId" element={<Settings />} />
						<Route path="/update-settings/:doctorId" element={<UpdateSettings />} />
						<Route path="/accounts" element={<Accounts />} />
						<Route path="/account-details" element={<AccountDetails />} />
						<Route path="/email" element={<Email />} />
						<Route path="/calendar" element={<Calendar />} />
						<Route path="/shop" element={<Shop />} />
						<Route path="/product-detail" element={<ProductDetail />} />
						<Route path="/product-detail/:id" element={<ProductDetail />} />
						<Route path="/shopcart" element={<ShopCart />} />
						<Route path="/checkout" element={<Checkout />} />
						<Route path="/blogs" element={<Blogs />} />
						<Route path="/blog-detail" element={<BlogDetail />} />
						<Route path="/blog-detail/:id" element={<BlogDetail />} />
						<Route path="/faqs" element={<Faqs />} />
						<Route path="/review" element={<Review />} />
						<Route path="/invoice-list" element={<InvoiceList />} />
						<Route path="/invoice" element={<Invoice />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="/blank-page" element={<BlankPage />} />
						<Route path="/email-confirmation" element={<EmailConfirmation />} />
						<Route path="/email-password-reset" element={<EmailPasswordReset />} />
						<Route path="/email-alert" element={<EmailAlert />} />
						<Route path="/email-invoice" element={<EmailInvoice />} />
					</Route>

					{/* Public Routes */}
					<Route path="/login" element={<Login />} />
					<Route path="/verify-email" element={<EmailVerify />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password/:id" element={<ResetPassword />} />
					<Route path="/lock-screen" element={<LockScreen />} />
					<Route path="/ui-button" element={<UiButton />} />
					<Route path="/ui-badges" element={<UiBadges />} />
					<Route path="/ui-alert" element={<UiAlert />} />
					<Route path="/ui-dropdown" element={<UiDropDown />} />
					<Route path="/ui-typography" element={<UiTypography />} />
					<Route path="/ui-background" element={<UiBackground />} />
					<Route path="/ui-text" element={<UiText />} />
					<Route path="/ui-tooltip-popover" element={<UiTooltipPopover />} />
					<Route path="/ui-shadow" element={<UiShadow />} />
					<Route path="/ui-border" element={<UiBorder />} />
					<Route path="/ui-form" element={<UiForm />} />
					<Route path="/ui-pagination" element={<UiPagination />} />
					<Route path="/ui-avatar" element={<UiAvatar />} />
					<Route path="/ui-modals" element={<UiModals />} />
					<Route path="/ui-icons" element={<UiIcon />} />
					<Route path="/comingsoon" element={<Comingsoon />} />
					<Route path="/maintenance" element={<Maintenance />} />
					<Route path="/error" element={<Error />} />
					<Route path="*" element={<Error />} />
					<Route path="thankyou" element={<ThankYou />} />
				</Routes>
			</AuthProvider>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
				theme="colored" />
		</>
	);
}

export default App;
