import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const MyPagination = ({ totalPages, currentPage, onPageChange }) => {
	const handlePageChange = (pageNumber) => {
		onPageChange(pageNumber);
	};
	return (
		<div className='d-flex justify-content-end'>
			<Pagination>
				<PaginationItem disabled={currentPage === 1}>
					<PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>Prev</PaginationLink>
				</PaginationItem>
				{Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
					<PaginationItem key={pageNumber} active={pageNumber === currentPage}>
						<PaginationLink onClick={() => handlePageChange(pageNumber)}>
							{pageNumber}
						</PaginationLink>
					</PaginationItem>
				))}
				<PaginationItem disabled={currentPage === totalPages}>
					<PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>Next</PaginationLink>
				</PaginationItem>
			</Pagination>
		</div>
	)
}

export default MyPagination