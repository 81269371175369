import React, { useState } from 'react';
import errorImage from '../assets/images/MyImages/no_image.png';

export default function ImageLoader({ imageUrl }) {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };
  return (
    <div style={{ position: 'relative', width: '100%', height: '175px', }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {/* Your loader component */}
          <div className="img-loader-container">
            <div className="img-loader"></div>
          </div>
        </div>
      )}
      <img
        src={imageUrl}
        alt="Loaded from API"
        style={{ display: loading ? 'none' : 'block', width: '100%', height: '175px' }}
        onLoad={handleImageLoaded}
        onError={(e) => {
          e.target.src = errorImage;
      }}
      />
    </div>
  )
}
