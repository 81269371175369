import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiSearch, FiFileText } from '../../assets/icons/vander';
import { useTranslation } from 'react-i18next';
import TopHeader from "../../components/topHeader";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getPatientList } from './services/patient-service';
import MyPagination from '../../components/pagination';
import Loader from '../../components/loader';
import { format } from 'date-fns';

export default function PatientSearchList(props) {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [patientsList, setPatientsList] = useState([]);
    const location = useLocation();
    const [initialLoad, setInitialLoad] = useState(true);

    const handleClear = () => {
        setSearchValue('');
        setPatientsList([]);
        setTotalItems(0);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const getPatients = async () => {
        setLoading(true);
        const body = {
            pageNo: page,
            pageSize: size,
            search: searchValue
        };
        try {
            const { data } = await getPatientList(body);
            // console.log(data.data.patients);
            if (data) {
                const patients = data.data.patients || [];
                setPatientsList(patients);
                const totalCount = data.data.patients.count;
                const totalPagesItems = Math.ceil(totalCount / size);
                setTotalItems(totalPagesItems);
            }
        } catch (error) {
            console.error('Error fetching patients:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query');
        if (query) {
            setSearchValue(query);
        }
        setInitialLoad(false);
    }, [location.search]);

    useEffect(() => {
        if (!initialLoad) {
            getPatients();
        }
    }, [page, size, initialLoad]); // Removed searchValue from dependencies

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page whenever page size changes
    };

    const handleSearchButtonClick = () => {
        setPage(1); // Reset page to 1
        getPatients(); // Trigger search
    };

    const formatDOB = (dob) => {
        return format(new Date(dob), 'dd MMM yyyy');
    };

    const navigate = useNavigate();

    const viewAction = (patient) => {
        console.log("==========");
        console.log(patient);
        navigate('/patient-dashboard');
        localStorage.setItem("patientData", JSON.stringify(patient))
        localStorage.setItem("patientAppointmentType", JSON.stringify('non-appointment'))
    };

    return (
        <>
            <Loader loading={loading} />
            <div className={`${toggle ? '' : 'toggled'} page-wrapper doctris-theme`}>
                <main className="page-content bg-light">
                    <TopHeader toggle={toggle} setToggle={setToggle} />
                    {props.children}
                </main>
                <div className="container-fluid" style={{ marginTop: "6rem" }}>
                    <div className="card p-3 shadow">
                        <div className="row my-4">
                            <div className="col-lg-11 col-md-11 col-sm-10 mt-2">
                                <input
                                    type="text"
                                    className="form-control border rounded-pill"
                                    style={{ lineHeight: 2 }}
                                    placeholder={t('Search Patient')}
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-2 mt-2 d-flex justify-content-start align-items-center" style={{ gap: '18px' }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">{t("Search")}</Tooltip>}
                                >
                                    <button
                                        className="btn btn-icon btn-pills btn-primary"
                                        onClick={handleSearchButtonClick} // Trigger search on button click
                                        style={{ height: '45px', width: '45px' }}
                                    >
                                        <FiSearch className="icons" />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="table-responsive">
                                <table className="table table-center table-padding mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="border-bottom text-start">#</th>
                                            <th scope="col" className="border-bottom text-start">{t("Name")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Email")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Age")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Gender")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("DOB")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Contact")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Blood Group")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Address")}</th>
                                            <th scope="col" className="border-bottom text-start">{t("Actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientsList.length > 0 ? patientsList.map((patient, index) => (
                                            <tr key={patient._id}>
                                                <th scope="row" className="text-start">{index + 1}</th>
                                                <td className="text-start">{`${patient.first_name || '-'} ${patient.last_name || '-'}`}</td>
                                                <td className="text-start">{patient.email || '-'}</td>
                                                <td className="text-start">{patient.age || '-'}</td>
                                                <td className="text-start" style={{ textTransform: 'capitalize' }}>{patient.gender || '-'}</td>
                                                <td className="text-start">{formatDOB(patient.dob) || '-'}</td>
                                                <td className="text-start">{patient.phone_code} {patient.mobile || '-'}</td>
                                                <td className="text-start">{patient.blood_group || '-'}</td>
                                                <td className="text-start">{patient.address || '-'}</td>

                                                <td className="text-start">
                                                    {/* <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">{t("View Details")}</Tooltip>} 
                                                    >
                                                        <Link onClick={() => viewAction(patient)} className="btn btn-icon btn-pills btn-primary">
                                                            <FiFileText className="icons" />
                                                        </Link>
                                                    </OverlayTrigger> */}
                                                    <button className="btn btn-icon btn-pills btn-soft-primary mt-1" onClick={() => viewAction(patient)}>
                                                        <FiFileText className="icons" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="9" className="text-center">{t("No patients found.")}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-6">
                                <div className="d-flex align-items-center">
                                    <span>{t("Show")}&nbsp;</span>
                                    <select style={{ display: 'unset', width: 'auto', padding: '0.375rem 2.25rem 0.375rem 0.75rem' }} value={size} onChange={handlePageSizeChange} className="form-control form-select">
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>&nbsp;
                                    <span>{t("entries")}</span>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <MyPagination totalPages={totalItems} currentPage={page} onPageChange={handlePageChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
