import axios from 'axios';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const axiosInstance = axios.create({
    baseURL: 'https://sandbox.noorimeds.com/'
})

const logoutAndRedirectToLogin = () => {
    // Clear authentication token, user data, or any other authentication state
    localStorage.removeItem("authToken")
    localStorage.removeItem("currentDoctor");
    localStorage.removeItem("patientData");
    localStorage.removeItem("patientAppointmentType");
    // Redirect to the login page
    window.location.href = `/login`; // Adjust the login page URL as needed
};

// Add a request and response interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const lang = i18next.language
        // Get the token from your authentication system (e.g., localStorage)
        const token = localStorage.getItem('authToken');
        // If a token exists, add it to the request headers
        if (lang) {
            config.headers['Accept-Language'] = lang;
        }
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // Handle successful response
        return response;
    },
    (error) => {
        // Handle response error
        if (error.response) {
            // The request was made and the server responded with a status code
            switch (error.response.status) {
                case 400:
                    // Display SweetAlert with dynamic error message
                    toast.error(error.response.data.message || 'Bad request error occurred')
                    break;
                case 401:
                    // Handle 401 Unauthorized error if needed
                    Swal.fire({
                        icon: 'error',
                        title: 'Unauthorized access',
                        text: error.response.data.message || 'Please login again to continue',
                    }).then(() => {
                        logoutAndRedirectToLogin();
                        // window.location.reload();
                    })
                    break;
                case 422:
                    // Handle 422 Unprocessable Entity error
                    if (error.response.data.errors && error.response.data.errors.length > 0) {
                        error.response.data.errors.forEach(errorMessage => {
                            toast.error(errorMessage);
                        });
                    } else {
                        toast.error('Unprocessable Entity error occurred');
                    }
                    break;
                // Add more cases for other status codes if needed
                default:
                    // Handle other response errors here
                    console.error('Response error:', error.response.data.errors);
                    console.error('Status code:', error.response.status);
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error('An error occurred');
                    }
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;