import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/wrapper';
import { patientData } from '../../data/data';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiSearch, LiaTimesSolid } from '../../assets/icons/vander';


export default function MedicalListRecord() {

    const [viewProfile, setViewProfile] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    // Event handler to close modals when clicking outside
    useEffect(() => {
        const closeModals = () => {
            setViewProfile(false);
            setEditProfile(false);
        };

        document.addEventListener('mousedown', closeModals);

        return () => {
            document.removeEventListener('mousedown', closeModals);
        };
    }, []);

    // Function to toggle dropdown visibility for a specific index
    const toggleDropdown = (index) => {
        // If the dropdown for this index is already open, close it
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null);
        } else {
            // Otherwise, open the dropdown for this index and close others
            setOpenDropdownIndex(index);
        }
    };

    // State to store the search input value
    const [searchValue, setSearchValue] = useState('');

    // Event handler to clear the search input field
    const handleClear = () => {
        setSearchValue('');
    };

    // Event handler to update the search input value
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 p-4 rounded shadow">
                                <div className="row mb-2">
                                    <div className="d-md-flex justify-content-between">
                                        <h5 className="mb-0 custom-text-shadow">Medical Records (EMR)</h5>
                                        <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
                                            <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                                                <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Medical Records (EMR)</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <div className="col-lg-10 col-md-9 col-sm-9 mt-2">
                                        <input
                                            type="text"
                                            className="form-control border rounded-pill"
                                            style={{ lineHeight: 2 }}
                                            name="s"
                                            id="s"
                                            placeholder="Search..."
                                            value={searchValue} // Bind the input value to the searchValue state
                                            onChange={handleSearchChange} // Update searchValue on input change
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mt-2 d-flex justify-content-start align-items-center" style={{ gap: '18px' }}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Search</Tooltip>}
                                        >
                                            <button className="btn btn-icon btn-pills btn-primary" style={{ height: '45px', width: '45px' }}>
                                                <FiSearch className="icons" />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Clear</Tooltip>}
                                        >
                                            <button
                                                className="btn btn-icon btn-pills btn-danger"
                                                style={{ height: '45px', width: '45px' }}
                                                onClick={handleClear} // Attach the handleClear function to the button's onClick event
                                            >
                                                <LiaTimesSolid className="icons" />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <div className="table-responsive shadow rounded">
                                            <table className="table table-center table-bordered bg-white mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-bottom p-3">Id</th>
                                                        <th className="border-bottom p-3">Name</th>
                                                        <th className="border-bottom p-3">Age</th>
                                                        <th className="border-bottom p-3">Gender</th>
                                                        <th className="border-bottom p-3">Address</th>
                                                        <th className="border-bottom p-3">Mobile No.</th>
                                                        <th className="border-bottom p-3">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {patientData.map((item, index) => {
                                                        const isDropdownOpen = openDropdownIndex === index;

                                                        return (
                                                            <tr key={index}>
                                                                <th className="">{item.id}</th>
                                                                <td className="py-3">
                                                                    <Link to="#" className="text-dark">
                                                                        <div className="d-flex align-items-center">
                                                                            <img src={item.image} className="avatar avatar-md-sm rounded-circle shadow" alt="" />
                                                                            <span className="ms-2">{item.name}</span>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="">{item.age}</td>
                                                                <td className="">{item.gender}</td>
                                                                <td className="">{item.address}</td>
                                                                <td className="">{item.mobileNo}</td>
                                                                <td className="text-end ">
                                                                    <div className="btn-group dropdown-primary">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary dropdown-toggle"
                                                                            onClick={() => toggleDropdown(index)}
                                                                            style={{ padding: '8px 25px' }}
                                                                        >
                                                                            Actions
                                                                        </button>
                                                                        <div className={`${isDropdownOpen ? 'show' : ''} dropdown-menu`}>
                                                                            {/* <Link to="/patient-profile" className="dropdown-item">View</Link>
                                                                            <Link to="/patient-appointment" className="dropdown-item">Appointments</Link> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row text-center">
                                    <div className="col-12 mt-4">
                                        <div className="d-md-flex align-items-center text-center justify-content-between">
                                            <span className="text-muted me-3">Showing 1 - 10 out of 50</span>
                                            <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                                <li className="page-item"><Link className="page-link" to="#" aria-label="Previous">Prev</Link></li>
                                                <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                                                <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                <li className="page-item"><Link className="page-link" to="#" aria-label="Next">Next</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

