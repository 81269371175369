import React, { useEffect, useState } from 'react';
import Wrapper from "../../components/wrapper";
import SimpleBar from "simplebar-react";
import { LiaPlusSolid, LiaTrashSolid } from '../../assets/icons/vander'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDoctorDetails, setDoctorAvailability } from './services/doctor-service';
import Loader from '../../components/loader';
import { toast } from 'react-toastify';

export default function Settings() {
    let params = useParams();
	let doctorId = params.doctorId;
	// let data = doctorData.find((doctor) => doctor.id === parseInt(doctorId))
	let [activeIndex, setActiveIndex] = useState(1)
	const navigate = useNavigate();
	const { t } = useTranslation();
	const baseUrl = 'https://sandbox.noorimeds.com/';

	const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

	const initialValues = {
		monday: true,
		monday_slots: [{ from_time: "", to_time: "" }],
		tuesday: true,
		tuesday_slots: [{ from_time: "", to_time: "" }],
		wednesday: true,
		wednesday_slots: [{ from_time: "", to_time: "" }],
		thursday: true,
		thursday_slots: [{ from_time: "", to_time: "" }],
		friday: true,
		friday_slots: [{ from_time: "", to_time: "" }],
		saturday: true,
		saturday_slots: [{ from_time: "", to_time: "" }],
		sunday: true,
		sunday_slots: [{ from_time: "", to_time: "" }],
	};

	const setTime = [
		{ time: "00:00" }, { time: "00:30" }, { time: "01:00" }, { time: "01:30" }, { time: "02:00" }, { time: "02:30" }, { time: "03:00" }, { time: "03:30" },
		{ time: "04:00" }, { time: "04:30" }, { time: "05:00" }, { time: "05:30" }, { time: "06:00" }, { time: "06:30" }, { time: "07:00" }, { time: "07:30" },
		{ time: "08:00" }, { time: "08:30" }, { time: "09:00" }, { time: "09:30" }, { time: "10:00" }, { time: "10:30" }, { time: "11:00" }, { time: "11:30" },
		{ time: "12:00" }, { time: "12:30" }, { time: "13:00" }, { time: "13:30" }, { time: "14:00" }, { time: "14:30" }, { time: "15:00" }, { time: "15:30" },
		{ time: "16:00" }, { time: "16:30" }, { time: "17:00" }, { time: "17:30" }, { time: "18:00" }, { time: "18:30" }, { time: "19:00" }, { time: "19:30" },
		{ time: "20:00" }, { time: "20:30" }, { time: "21:00" }, { time: "21:30" }, { time: "22:00" }, { time: "22:30" }, { time: "23:00" }, { time: "23:30" },
	];

	const initialFormValues = {
		appointmentTypes: [{ type: "", fees: "" }],
	};

	const appointmentTypesOptions = [
		{ value: "Online", label: "Online" },
		{ value: "Offline", label: "Offline" },
		// Add more options as needed
	];

	const [formValues, setFormValues] = useState(initialValues);
	const [errors, setErrors] = useState({});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		if (doctorId) {
			getDoctorInfo();
		} else {
			navigate('/dr-profile')
		}
	}, [])

	const getDoctorInfo = async () => {
		try {
			setLoading(true);
			const response = await getDoctorDetails(doctorId);
			if (response) {
				setData(response.data.data.doctor);
				if (response.data.data.doctor.consultation_fee.length !== 0) {
					const cunsultationData = response.data.data.doctor.consultation_fee;
					// Transform consultation_fee data
					const consultationFees = cunsultationData.map(consultation => ({
						type: consultation.consultation_type.charAt(0).toUpperCase() + consultation.consultation_type.slice(1),
						fees: consultation.fee,
					}));
					setFormValues2({ appointmentTypes: consultationFees });
				}
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			console.error('Error fetching data:', e);
		}
	}

	// Function to handle form value changes
	const handleDayChange = (event) => {
		const { name, value, checked, type } = event.target;

		setFormValues((prevValues) => ({
			...prevValues,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	// Function to handle slot changes
	const handleSlotChange = (day, index, field, value) => {
		setFormValues((prevValues) => {
			const slots = [...prevValues[`${day}_slots`]];
			slots[index][field] = value;
			return { ...prevValues, [`${day}_slots`]: slots };
		});

		// Clear error for the specific field when changed
		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			delete newErrors[`${day}_slots[${index}].${field}`];
			return newErrors;
		});
	};

	// Function to handle changes in the time slots with validation
	// const handleSlotChange = (day, index, field, value) => {
	//   setFormValues((prevValues) => {
	//     const slots = [...prevValues[`${day}_slots`]];
	//     slots[index][field] = value;

	//     const newErrors = { ...errors };

	//     // Validation 1: Ensure end time is greater than start time
	//     if (field === 'from_time' || field === 'to_time') {
	//       const fromTime = slots[index].from_time;
	//       const toTime = slots[index].to_time;

	//       if (fromTime && toTime && fromTime >= toTime) {
	//         newErrors[`${day}_slots[${index}].to_time`] = 'End time must be greater than start time.';
	//       } else {
	//         delete newErrors[`${day}_slots[${index}].to_time`];
	//       }
	//     }

	//     // Validation 2: Ensure next row's start time is greater than previous row's end time
	//     if (field === 'to_time' && index < slots.length - 1) {
	//       const nextFromTime = slots[index + 1].from_time;
	//       const currentToTime = value;

	//       if (nextFromTime && currentToTime && currentToTime >= nextFromTime) {
	//         newErrors[`${day}_slots[${index + 1}].from_time`] = 'Next row\'s start time must be greater than previous row\'s end time.';
	//       } else {
	//         delete newErrors[`${day}_slots[${index + 1}].from_time`];
	//       }
	//     }

	//     if (field === 'from_time' && index > 0) {
	//       const prevToTime = slots[index - 1].to_time;
	//       const currentFromTime = value;

	//       if (prevToTime && currentFromTime && currentFromTime <= prevToTime) {
	//         newErrors[`${day}_slots[${index}].from_time`] = 'Start time must be greater than previous row\'s end time.';
	//       } else {
	//         delete newErrors[`${day}_slots[${index}].from_time`];
	//       }
	//     }

	//     setErrors(newErrors);
	//     return { ...prevValues, [`${day}_slots`]: slots };
	//   });
	// };

	// Function to add a new time slot
	const handleAddSlot = (day) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[`${day}_slots`]: [...prevValues[`${day}_slots`], { from_time: "", to_time: "" }],
		}));
	};

	// add slots with required validation
	// const handleAddSlot_old = (day) => {
	//   setFormValues((prevValues) => {
	//     const slots = [...prevValues[`${day}_slots`]];
	//     const lastSlot = slots[slots.length - 1];

	//     if (lastSlot.from_time && lastSlot.to_time) {
	//       return {
	//         ...prevValues,
	//         [`${day}_slots`]: [...slots, { from_time: "", to_time: "" }],
	//       };
	//     } else {
	//       setErrors((prevErrors) => ({
	//         ...prevErrors,
	//         [`${day}_slots[${slots.length - 1}].from_time`]: !lastSlot.from_time ? "From time is required." : "",
	//         [`${day}_slots[${slots.length - 1}].to_time`]: !lastSlot.to_time ? "To time is required." : "",
	//       }));
	//       return prevValues;
	//     }
	//   });
	// };

	// add slots with updated validation
	// const handleAddSlot = (day) => {
	//   setFormValues((prevValues) => {
	//     const slots = [...prevValues[`${day}_slots`]];
	//     const lastSlot = slots[slots.length - 1];
	//     const newErrors = { ...errors };

	//     if (lastSlot.from_time && lastSlot.to_time) {
	//       if (lastSlot.from_time >= lastSlot.to_time) {
	//         newErrors[`${day}_slots[${slots.length - 1}].to_time`] = 'End time must be greater than start time.';
	//         setErrors(newErrors);
	//         return prevValues;
	//       } else {
	//         delete newErrors[`${day}_slots[${slots.length - 1}].to_time`];
	//         setErrors(newErrors);
	//         return {
	//           ...prevValues,
	//           [`${day}_slots`]: [...slots, { from_time: "", to_time: "" }],
	//         };
	//       }
	//     } else {
	//       if (!lastSlot.from_time) {
	//         newErrors[`${day}_slots[${slots.length - 1}].from_time`] = "From time is required.";
	//       }
	//       if (!lastSlot.to_time) {
	//         newErrors[`${day}_slots[${slots.length - 1}].to_time`] = "To time is required.";
	//       }
	//       setErrors(newErrors);
	//       return prevValues;
	//     }
	//   });
	// };

	// Function to remove a time slot
	const handleRemoveSlot = (day, index) => {
		setFormValues((prevValues) => {
			const slots = [...prevValues[`${day}_slots`]];
			slots.splice(index, 1);
			return { ...prevValues, [`${day}_slots`]: slots };
		});
	};

	// Function to validate the form
	const validateForm = () => {
		const newErrors = {};
		// Add validation rules here
		["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach((day) => {
			// Skip validation for the day if it is set to "Not Available"
			if (formValues[day]) {
				const slots = formValues[`${day}_slots`];
				slots.forEach((slot, index) => {
					// Check for missing from_time and to_time
					if (!slot.from_time) {
						newErrors[`${day}_slots[${index}].from_time`] = "From time is required.";
					}
					if (!slot.to_time) {
						newErrors[`${day}_slots[${index}].to_time`] = "To time is required.";
					}
				});
			}
		});

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	// Function to handle form submission
	const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setFormSubmitted(true); // Set form submission state to true

		if (validateForm()) {
			// Submit the form values
			console.log("Form values:", formValues);
			// Add your API request logic here, e.g., axios.post("/api/add-availability", formValues)
			const availability = [];
			["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach((day) => {
				if (formValues[day]) {
					const shifts = formValues[`${day}_slots`].map((slot) => ({
						start_time: slot.from_time,
						end_time: slot.to_time,
					}));
					availability.push({
						day: day.charAt(0).toUpperCase() + day.slice(1),
						shifts: shifts,
						is_availability: formValues[day],
					});
				}
				else {
					availability.push({
						day: day.charAt(0).toUpperCase() + day.slice(1),
						shifts: [],
						is_availability: formValues[day],
					});
				}
			});

			console.log("Availability: ", availability);
			const body = {
				doctor_id: doctorId,
				availability: availability
			}
			try {
				setLoading(true);
				const response = await setDoctorAvailability(body);
				if (response.data.status === 'success') {
					toast.success(response.data.message);
					navigate('/dr-profile');
					setLoading(false);
				}
			} catch (error) {
				setLoading(false);
				console.error('Error fetching data:', error);
			}
		} else {
			console.log("Invalid form");
		}
	};

	// Appointment Function
	const [formValues2, setFormValues2] = useState(initialFormValues);

	// Function to handle form value changes
	const handleChange2 = (index, event) => {
		const { name, value } = event.target;
		const updatedAppointmentTypes = [...formValues2.appointmentTypes];
		updatedAppointmentTypes[index][name] = value;

		// Disable the selected option in subsequent appointment type fields
		if (value === "Online") {
			updatedAppointmentTypes.forEach((type, idx) => {
				if (idx !== index) {
					type.type = "Offline";
				}
			});
		} else if (value === "Offline") {
			updatedAppointmentTypes.forEach((type, idx) => {
				if (idx !== index) {
					type.type = "Online";
				}
			});
		}

		setFormValues2((prevValues) => ({
			...prevValues,
			appointmentTypes: updatedAppointmentTypes,
		}));
	};

	// Function to add a new appointment type field
	const handleAddAppointmentType = () => {
		// Check if the length of appointmentTypesOptions is equal to the length of formValues2.appointmentTypes
		if (appointmentTypesOptions.length === formValues2.appointmentTypes.length) {
			console.log("Cannot add more appointment types.");
			// Optionally, you can return or perform other actions here
		} else {
			setFormValues2((prevValues) => ({
				...prevValues,
				appointmentTypes: [...prevValues.appointmentTypes, { type: "", fees: "" }],
			}));
		}
	};

	// Function to remove an appointment type field
	const handleRemoveAppointmentType = (index) => {
		const updatedAppointmentTypes = [...formValues2.appointmentTypes];
		updatedAppointmentTypes.splice(index, 1);
		setFormValues2((prevValues) => ({
			...prevValues,
			appointmentTypes: updatedAppointmentTypes,
		}));
	};

	// Function to handle form submission
	// Function to handle form submission
	const handleSubmit2 = (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setFormSubmitted(true); // Set form submission state to true

		// Validate the form
		if (validateForm2()) {
			// Submit the form values
			console.log("Form values:", formValues2);
			// Add your API request logic here, e.g., axios.post("/api/add-availability", formValues)
			// Reset form after submission
			setFormValues2(initialFormValues);
		} else {
			console.log("Invalid form");
		}
	};

	// Function to validate the form
	// Function to validate the form
	const validateForm2 = () => {
		const newErrors = {};

		// Validate appointment types and feess
		formValues2.appointmentTypes.forEach((appointmentType, index) => {
			if (!appointmentType.type) {
				newErrors[`appointmentTypes[${index}].type`] = "Appointment type is required.";
			}
			if (!appointmentType.fees) {
				newErrors[`appointmentTypes[${index}].fees`] = "Fees is required.";
			} else if (isNaN(appointmentType.fees)) {
				newErrors[`appointmentTypes[${index}].fees`] = "Fees must be a number.";
			}
		});

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing fixed-layout-responsive" style={{ position: "fixed", width: "-webkit-fill-available" }}>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-12 mt-2">
                            <div className="card border-0 p-4 shadow">
                                <SimpleBar style={{ height: '515px' }}>
                                    <form onSubmit={handleSubmit2}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    {formValues2.appointmentTypes.map((appointmentType, index) => (
                                                        <div key={index} className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label className="form-label" htmlFor={`type-${index}`}>Appointment Type:</label>
                                                                <select
                                                                    id={`type-${index}`}
                                                                    name="type"
                                                                    className="form-select"
                                                                    value={appointmentType.type}
                                                                    onChange={(e) => handleChange2(index, e)}
                                                                    style={{ borderRadius: '50px' }}
                                                                    required=""
                                                                >
                                                                    <option value="">Select Appointment Type</option>
                                                                    {appointmentTypesOptions.map((option) => (
                                                                        <option
                                                                            key={option.value}
                                                                            value={option.value}
                                                                            disabled={index > 0 && option.value === "Online" && formValues2.appointmentTypes[0].type === "Online"}
                                                                        >
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {/* Render error message if form is submitted and there's an error */}
                                                                {formSubmitted && errors[`appointmentTypes[${index}].type`] && (
                                                                    <div className="text-danger">
                                                                        {errors[`appointmentTypes[${index}].type`]}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-md-12 mt-2">
                                                                <label className="form-label" htmlFor={`fees-${index}`}>Fees:</label>
                                                                <input
                                                                    type="text"
                                                                    id={`fees-${index}`}
                                                                    name="fees"
                                                                    placeholder="Enter Fees"
                                                                    className="form-control"
                                                                    value={appointmentType.fees}
                                                                    onChange={(e) => handleChange2(index, e)}
                                                                    style={{ borderRadius: '50px' }}
                                                                    required=""
                                                                />
                                                                {/* Render error message if form is submitted and there's an error */}
                                                                {formSubmitted && errors[`appointmentTypes[${index}].fees`] && (
                                                                    <div className="text-danger">
                                                                        {errors[`appointmentTypes[${index}].fees`]}
                                                                    </div>
                                                                )}
                                                                {/* Render remove button */}
                                                                {formValues2.appointmentTypes.length > 1 && (
                                                                    <button type="button" className="btn btn-icon btn-pills btn-soft-danger my-2" onClick={() => handleRemoveAppointmentType(index)}>
                                                                        <LiaTrashSolid />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                <button type="button" className="btn btn-warning w-100" onClick={handleAddAppointmentType}>
                                                    Add More
                                                </button>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <button type="submit" className="btn btn-primary w-100">Submit</button>
                                            </div>
                                        </div>
                                    </form>

                                </SimpleBar>
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-12 mt-2">
                            <div className="card border-0 p-4 shadow">
                                <SimpleBar style={{ height: '515px' }}>
                                <form onSubmit={handleSubmit}>
										{["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day, dayIndex, daysArray) => (
											<div key={day}>
												<div className="row mb-3" style={{ justifyContent: 'flex-end' }}>
													<div className="col-md-4 d-flex align-items-center mt-2">
														<label className="m-0"><b>{day.charAt(0).toUpperCase() + day.slice(1)}</b></label>
														<div className="ms-auto me-4">
															<div className="form-check form-switch" style={{ paddingLeft: '3.2em' }}>
																<input
																	style={{ cursor: 'pointer' }}
																	className="form-check-input custom-switch-style"
																	type="checkbox"
																	id={`flexSwitchCheck-${day}`}
																	name={day}
																	checked={formValues[day]}
																	onChange={handleDayChange}
																/>
																&nbsp;&nbsp;<label className="form-check-label" style={{ fontSize: "12px" }} htmlFor={`flexSwitchCheck-${day}`}>
																	{formValues[day] ? "Available" : "Not Available"}
																</label>
															</div>
														</div>
													</div>
													<div className="col-md-8">
														{formValues[`${day}_slots`].map((slot, index, slotsArray) => (
															<div key={index} className="row mt-2">
																<div className="col-md-3 d-flex align-items-center">
																	<div className="form-group mb-0 w-100 me-auto">
																		<select
																			className="form-control"
																			name={`${day}_slots[${index}].from_time`}
																			value={slot.from_time}
																			onChange={(e) => handleSlotChange(day, index, "from_time", e.target.value)}
																			disabled={!formValues[day]}
																		>
																			<option value="">Select</option>
																			{setTime.map((time, idx) => (
																				<option key={idx} value={time.time}>
																					{time.time}
																				</option>
																			))}
																		</select>
																		{formSubmitted && errors[`${day}_slots[${index}].from_time`] && (
																			<div className="text-danger">
																				<small>{errors[`${day}_slots[${index}].from_time`]}</small>
																			</div>
																		)}
																	</div>
																</div>
																<div className="col-md-2 d-flex align-items-center" style={{ justifyContent: "center", marginTop: "5px" }}>
																	<label style={{ fontSize: "small" }}><b >To</b></label>
																</div>
																<div className="col-md-3 d-flex align-items-center">
																	<div className="form-group mb-0 w-100 me-auto">
																		<select
																			className="form-control"
																			name={`${day}_slots[${index}].to_time`}
																			value={slot.to_time}
																			onChange={(e) => handleSlotChange(day, index, "to_time", e.target.value)}
																			disabled={!formValues[day]}
																		>
																			<option value="">Select</option>
																			{setTime.map((time, idx) => (
																				<option key={idx} value={time.time}>
																					{time.time}
																				</option>
																			))}
																		</select>
																		{formSubmitted && errors[`${day}_slots[${index}].to_time`] && (
																			<div className="text-danger">
																				<small>{errors[`${day}_slots[${index}].to_time`]}</small>
																			</div>
																		)}
																	</div>
																</div>
																<div className="col-md-2 d-flex align-items-center">
																	{index === slotsArray.length - 1 && (
																		<button type="button" className="btn btn-icon btn-pills btn-soft-success"
																			onClick={() => handleAddSlot(day)} disabled={!formValues[day]}>
																			<LiaPlusSolid />
																		</button>
																	)}
																</div>
																<div className="col-md-2 d-flex align-items-center">
																	{index > 0 && (
																		<button type="button" className="btn btn-icon btn-pills btn-soft-danger ms-1"
																			onClick={() => handleRemoveSlot(day, index)} disabled={!formValues[day]}>
																			<LiaTrashSolid />
																		</button>
																	)}
																</div>
																{/* {index !== data.availability[0].length - 1 && <hr className="my-3" />} */}
															</div>
														))}
													</div>
												</div>
												{dayIndex < daysArray.length - 1 && <hr className="my-3" />}
											</div>
										))}
										<div className="row py-2">
											<div className="col-lg-6 col-md-6 col-sm-6"></div>
											<div className="col-lg-3 col-md-3 col-sm-3 my-2">
												<button type="submit" className="btn btn-primary w-100">
													Submit
												</button>
											</div>
											<div className="col-lg-3 col-md-3 col-sm-3 my-2">
												<button type="button" className="btn btn-danger w-100">
													Cancel
												</button>
											</div>
										</div>
									</form>
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
