import React, { useState, useEffect } from "react";
import Wrapper from "../../components/wrapper";
import { FiPlus, FiFileText, FiCheck, LiaTrashSolid, FiUser, RiStethoscopeLine, FiClock, LiaLongArrowAltLeftSolid, LiaLongArrowAltRightSolid } from '../../assets/icons/vander';
import SimpleBar from "simplebar-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, getYear } from "date-fns";
import { getAllAppointments } from "./service/appointment-service";
import MyPagination from '../../components/pagination';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';
import AddPatientModal from './add-patient-modal'; // Import your modal component here
import PaymentModal from './payment-modal'; // Import the PaymentModal component
import { useNavigate } from 'react-router-dom';
import EllipsisTooltip from "../../components/EllipsisTooltip";
import Multiselect from "multiselect-react-dropdown";
import axiosInstance from "../../interceptor/interceptor-instance";

export default function Appointments() {

	const setTime = [
		{ time: "00:00" }, { time: "00:30" }, { time: "01:00" }, { time: "01:30" }, { time: "02:00" },
		{ time: "02:30" }, { time: "03:00" }, { time: "03:30" }, { time: "04:00" }, { time: "04:30" },
		{ time: "05:00" }, { time: "05:30" }, { time: "06:00" }, { time: "06:30" }, { time: "07:00" },
		{ time: "07:30" }, { time: "08:00" }, { time: "08:30" }, { time: "09:00" }, { time: "09:30" },
		{ time: "10:00" }, { time: "10:30" }, { time: "11:00" }, { time: "11:30" }, { time: "12:00" },
		{ time: "12:30" }, { time: "13:00" }, { time: "13:30" }, { time: "14:00" }, { time: "14:30" },
		{ time: "15:00" }, { time: "15:30" }, { time: "16:00" }, { time: "16:30" }, { time: "17:00" },
		{ time: "17:30" }, { time: "18:00" }, { time: "18:30" }, { time: "19:00" }, { time: "19:30" },
		{ time: "20:00" }, { time: "20:30" }, { time: "21:00" }, { time: "21:30" }, { time: "22:00" },
		{ time: "22:30" }, { time: "23:00" }, { time: "23:30" },
	];

	const [startDate, setStartDate] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(getYear(new Date())); // Initialize with today's year
	const [formattedDate, setFormattedDate] = useState(format(new Date(), "eee, MMM d")); // Initialize with today's formatted date

	const handleDateChange = (date) => {
		setStartDate(date);
		setSelectedYear(getYear(date));
		setFormattedDate(format(date, "eee, MMM d"));
	};

	const [getUserData, setUserData] = useState(() => {
		const storedData = localStorage.getItem("currentDoctor");
		return storedData ? JSON.parse(storedData) : null;
	});

	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [appointments, setAppointments] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);

	const [checkDoctorsCount, setCheckDoctorsCount] = useState([]);

	const fetchAppointments = async () => {
		try {
			setLoading(true);
			const data = {
				doctors: getUserData._id,
				date: format(startDate, "yyyy-MM-dd"),
				pageNo: '1',
				pageSize: '10',
				// search: ""
			};
			const response = await getAllAppointments(data);
			// Initialize an empty array to store all appointments
			let allAppointments = [];
			// Iterate over each doctor
			response.data.data.doctors.forEach((doctor, index) => {
				// Concatenate the slots of each doctor to the allAppointments array
				allAppointments = allAppointments.concat(doctor.slots);
			});
			const count = response.data.data.doctors.length;
			// console.log('my====', allAppointments);
			// setCheckDoctorsCount(count);
			setCheckDoctorsCount([count]);
			setAppointments(allAppointments);
			setTotalItems(response.totalItems);
			console.log(allAppointments);
		} catch (error) {
			console.error("Error fetching appointments:", error);
		} finally {
			setLoading(false);
		}
	};


	useEffect(() => {
		fetchAppointments();
	}, [page, size, getUserData._id]); // Combine all dependencies

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const handlePageSizeChange = (event) => {
		setSize(parseInt(event.target.value, 3));
		setPage(1); // Reset to first page whenever page size changes
	};

	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState(null);

	const handleOpenModal = (itemData) => {
		setModalData(itemData);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setModalData(null);
	};

	// Start Consultation Function
	const navigate = useNavigate();

	const viewAction = (item) => {
		localStorage.setItem("patientData", JSON.stringify(item.appointment))
		localStorage.setItem("patientAppointmentType", JSON.stringify('appointment'))
		navigate('/patient-dashboard');
		// navigate('/patient-dashboard', { state: { appointmentData: item } }); 
	};


	//  Payment Modal
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [paymentData, setPaymentData] = useState(null);

	const handleOpenPaymentModal = (itemData) => {
		setPaymentData(itemData);
		setShowPaymentModal(true);
	};

	const handleClosePaymentModal = () => {
		setShowPaymentModal(false);
		setPaymentData(null);
	};

	const handleSavePayment = (paymentDetails) => {
		// Implement the save payment logic here
		console.log("Payment Details:", paymentDetails);
		// Close the modal after saving
		handleClosePaymentModal();
	};

	const markAsCompleted = (item) => {
		console.log(item.appointment_id);
		// markAsPaid(item.appointment_id)
		//      .then(response => {
		//          console.log('Appointment marked as completed successfully:', response);
		//          // Handle success if needed
		//          if(response.data.status === "success") {
		//              toast.success(response.data.message);
		//              handleClose();
		//              fetchAppointments();
		//          }
		//      })
		//      .catch(error => {
		//          console.error('Error marking appointment as paid:', error);
		//          // Handle error if needed
		//      });
	};

	const markAsCancelled = () => {
	};

	useEffect(() => {
		getDoctorMaster(); // Fetch doctor master data when the component mounts
	}, []);

	const [selectedDoctors, setSelectedDoctors] = useState([]);
	const [doctorMaster, setDoctorMaster] = useState([]);
	// Doctor Master
	const getDoctorMaster = async () => {
		try {
			const response = await axiosInstance.get(`/masters/doctors`);
			if (response.data.status === 'success') {
				const doctors = response.data.data.doctors;
				// Add the "all" option
				doctors.unshift({ _id: 'all', full_name: 'All Doctors' });
				setDoctorMaster(doctors);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			// Handle error gracefully
		}
	};

	const handleDoctorChange = (selectedList) => {
		// Check if "all" is selected
		if (selectedList.some(doctor => doctor._id === 'all')) {
			setSelectedDoctors(doctorMaster);
		} else {
			setSelectedDoctors(selectedList);
		}
	};

	const getDoctorIdsString = () => {
		if (selectedDoctors.some(doctor => doctor._id === 'all')) {
			return doctorMaster.filter(doctor => doctor._id !== 'all').map(doctor => doctor._id).join(',');
		}
		return selectedDoctors.map(doctor => doctor._id).join(',');
	};

	// useEffect to log appointments after it's updated
	useEffect(() => {
		console.log("Appointments after update:", appointments);
	}, [appointments]);

	const handleSearch = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		console.log("Search date:", format(startDate, "yyyy-MM-dd"));
		try {
			setLoading(true);
			setCurrentPage(1);
			const data = {
				doctors: getDoctorIdsString() ? getDoctorIdsString() : getUserData._id,
				date: format(startDate, "yyyy-MM-dd"), // Using the startDate state directly
				pageNo: page,
				pageSize: size,
			};
			const response = await getAllAppointments(data);

			let doctors = response.data.data.doctors;
			const doctorCount = doctors.length;
			setCheckDoctorsCount(doctorCount);
			if (doctorCount > 0) {
				const allAppointments = [];

				if (doctors.length > 1) {
					doctors.forEach((doctor) => {
						allAppointments.push({
							doctor_fullname: doctor.doctor_fullname,
							date: doctor.date,
							slots: doctor.slots,
						});
					})
				} else {
					allAppointments.push(...doctors[0].slots)
				}
				console.log(allAppointments)
				setAppointments(allAppointments);
				setTotalItems(response.data.totalItems);
			} else {
				setAppointments([]);
				setTotalItems(0);
			}
		} catch (error) {
			console.error("Error fetching appointments:", error);
		} finally {
			setLoading(false);
		}
	};

	const [appointmentVisibility, setAppointmentVisibility] = useState(null);

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem('currentDoctor'));
		// console.log(data);
		if (data) {
			// console.log(data.appointment_visibility);
			setAppointmentVisibility(data.appointment_visibility);
			//   console.log(appointmentVisibility);
		}
	}, []);

	const isValidDate = (date) => {
		return date instanceof Date && !isNaN(date);
	};

	const safeFormatDOB = (date) => {
		if (isValidDate(new Date(date))) {
			return formatDOB(date);
		} else {
			return 'N/A';
		}
	};

	const formatDOB = (dob) => {
		return format(new Date(dob), 'dd MMM yyyy');
	};
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 3;

	// Calculate the total number of pages based on the total number of items and items per page
	const totalPages = Math.ceil(appointments.length / itemsPerPage);

	// Update the appointments data to display based on the current page
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentAppointments = appointments.slice(indexOfFirstItem, indexOfLastItem);

	// Function to handle navigating to the previous page
	const goToPreviousPage = () => {
		setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
	};

	// Function to handle navigating to the next page
	const goToNextPage = () => {
		setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
	};

	return (
		<>
			<Wrapper>
				<Loader loading={loading} />
				<div className="container-fluid">
					<div className="layout-specing fixed-layout-responsive" style={{ position: "fixed", width: "-webkit-fill-available" }}>
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-4 mt-2">
								<div className="card p-3 shadow">
									<div className="col-md-12">
										<SimpleBar style={{ height: '530px' }}>
											<form onSubmit={handleSearch}>
												<div className="row">
													{(appointmentVisibility === 'others' || appointmentVisibility === 'all') && (
														<div className="col-md-12">
															<div className="mb-3">
																<label className="custom-font-label">Doctor</label>
																<Multiselect
																	options={doctorMaster}
																	displayValue="full_name"
																	placeholder="Select Doctor"
																	selectedValues={selectedDoctors}
																	onSelect={handleDoctorChange}
																	onRemove={handleDoctorChange}
																	disabled={appointmentVisibility === 'self'}
																/>
															</div>
														</div>
													)}
													{/* Display selected year and formatted date */}
													<div className="mt-4">
														<p className="mb-1" style={{ fontSize: "small", color: "#007af4" }}>{selectedYear}</p>
														<label style={{ fontSize: "18px", color: "#007af4" }}>{formattedDate}</label>
													</div>
													<div className="col-md-12">
														{/* DatePicker component */}
														<DatePicker
															selected={startDate}
															onChange={handleDateChange}
															dateFormat="yyyy-MM-dd"
															className="form-control"
															inline
														/>
													</div>
													<div className="offset-md-1 col-md-10 mt-3 mb-3">
														<button type="submit" className="btn btn-primary w-100">Search</button>
													</div>
												</div>
											</form>
										</SimpleBar>
									</div>
								</div>
							</div>
							<div className="col-xl-9 col-lg-8 col-md-8 mt-2">
								<div className="card p-3 shadow">
									{checkDoctorsCount <= 1 && (
										<SimpleBar style={{ height: '530px' }}>
											<div className="row">
												<div className="col-md-12">
													<div className="table-responsive">
														<table className="table table-striped">
															<thead>
																<tr>
																	<th>#</th>
																	<th>{t("Time")}</th>
																	<th>{t("Duration")}</th>
																	<th>{t("Status")}</th>
																	<th>{t("Name")}</th>
																	<th>{t("Consultation Type")}</th>
																	<th>{t("Consultation Reason")}</th>
																	<th>{t("Actions")}</th>
																</tr>
															</thead>
															<tbody>
																{appointments.length > 0 ? (
																	appointments.map((item, index) => (
																		<tr key={index}>
																			{/* Display the index of the item */}
																			<td>{index + 1}</td>

																			{/* Display the start and end times */}
																			<td>{item?.start_time} - {item?.end_time}</td>

																			{/* Display the duration */}
																			<td>{item?.duration}</td>

																			{/* Display the status with capitalized text */}
																			<td style={{ textTransform: "capitalize" }}>{item.status}</td>

																			{/* Conditional rendering based on item availability */}
																			{item.is_available ? (
																				// If item is available, display dashes in three columns
																				<>
																					<td><span>-</span></td>
																					<td><span>-</span></td>
																					<td><span>-</span></td>
																				</>
																			) : (
																				// If item is not available, display appointment details
																				<>
																					<td>{item?.appointment?.patient_fullname}</td>
																					<td>{item?.appointment?.consulation_type}</td>
																					<td>
																						{/* Use a tooltip to display consultation reason */}
																						<EllipsisTooltip text={item?.appointment?.consulation_reason} />
																					</td>
																				</>
																			)}

																			{/* Conditional rendering for action buttons */}
																			<td>
																				{item.is_available ? (
																					// If item is available, display a button to open a modal
																					<>
																						<button className="btn btn-icon btn-pills btn-soft-primary mt-1" onClick={() => handleOpenModal(item)}>
																							<FiPlus className="icons" />
																						</button>

																						{/* Conditional rendering for pending appointment status */}
																						{item?.appointment?.appointment_status === "pending" && (
																							<>
																								<button className="btn btn-icon btn-pills btn-soft-success ms-2" onClick={() => markAsCompleted(item)}>
																									<FiCheck className="icons" />
																								</button>
																								<button className="btn btn-icon btn-pills btn-soft-danger ms-2" onClick={() => markAsCancelled(item)}>
																									<LiaTrashSolid className="icons" />
																								</button>
																							</>
																						)}
																					</>
																				) : (
																					// If item is not available, display a button to view action and possibly a payment button
																					<>
																						<button className="btn btn-icon btn-pills btn-soft-primary mt-1" onClick={() => viewAction(item)}>
																							<FiFileText className="icons" />
																						</button>

																						{/* Conditional rendering for unpaid appointments */}
																						{item?.appointment?.is_paid === false && (
																							<button className="btn btn-icon btn-pills btn-soft-primary ms-2" onClick={() => handleOpenPaymentModal(item)}>
																								$
																							</button>
																						)}
																					</>
																				)}
																			</td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan="9" className="text-center">{t("Doctor is not available.")}</td>
																	</tr>
																)}

															</tbody>
														</table>
													</div>
												</div>
											</div>
										</SimpleBar>
									)}
									{checkDoctorsCount > 1 ? (
										<div className="row">
											{appointments.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((doctor, index) => (
												<div className="col-md-4" key={index}>
													<SimpleBar style={{ height: '478px' }}>
														<div className="card">
															<div className="d-flex">
																<div className="card-body col-md-4 p-2">
																	<div className="card" style={{ borderRadius: '16px' }}>
																		<div className="card-header text-center pt-3 pb-2" style={{ background: 'linear-gradient(145deg, rgb(0, 122, 244) 0%, rgb(0, 188, 152) 100%)', borderColor: 'gray', color: 'white' }}>
																			<div className="d-flex align-items-center">
																				<div className="media-body">
																					<h6 className="font-weight-bold">{doctor.doctor_fullname}</h6>
																				</div>
																			</div>
																		</div>
																		<div className="card shadow" style={{ borderRadius: '0 0 0 0' }}>
																			<div className="row col-md-12 mt-2">
																				<div className="col-md-6 text-center">
																					<label className="custom-font-label">{safeFormatDOB(doctor.date)}</label>
																				</div>
																				<div className="col-md-6 text-center">
																					<label className="custom-font-label" style={{ textTransform: "uppercase" }}>{doctor?.slots[0]?.start_time} - {doctor.slots[doctor?.slots.length - 1]?.start_time}</label> &nbsp;&nbsp;
																					<i className="fa fa-angle-up me-2" style={{ fontSize: '16px' }}></i>
																				</div>
																			</div>
																		</div>
																		<div className="row col-md-12 mt-2">
																			<div className="col-md-6 text-center">
																				<label className="custom-font-label">Time</label>
																			</div>
																			<div className="col-md-6 text-center">
																				<label className="custom-font-label">Appointment</label>
																			</div>
																		</div>
																		{doctor.slots.length === 0 && (
																			<div className="card shadow mt-1 text-center" style={{ borderRadius: '0 0 0 0', borderColor: 'gray' }}>
																				<div className="col-md-12 mt-2" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
																					<label className="custom-font-label" style={{ textTransform: "uppercase" }}>Doctor is not available</label>
																				</div>
																			</div>
																		)}

																		{doctor.slots.map((slot, index) => (
																			<div className="card shadow mt-1" style={{ borderRadius: '0 0 0 0', borderColor: 'gray' }} key={index}>
																				<div className="d-flex">
																					<div className="col-md-5 mt-2" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
																						<FiClock />&nbsp;&nbsp;
																						<label className="custom-font-label" style={{ textTransform: "uppercase" }}>{slot.start_time}</label>
																					</div>
																					<div className="col-md-7 mt-2" style={{ paddingLeft: '0px' }}>
																						<RiStethoscopeLine />&nbsp;&nbsp;
																						{/* <label className="custom-font-label">{slot.is_available ? "Available" : slot?.appointment?.patient_fullname}</label> */}
																						<label className="custom-font-label">
																							{slot.is_available ? <> {t("Slot Is Available")}&nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-icon btn-pills btn-soft-primary" onClick={() => handleOpenModal(slot?.appointment)}>
																								<FiPlus className="icons" />
																							</button></> : slot?.appointment?.patient_fullname}
																						</label>
																					</div>
																				</div>
																			</div>
																		))}

																	</div>
																</div>
															</div>
														</div>
													</SimpleBar>
												</div>
											))}
											<div className="col-md-12 mt-2">
												<div className="d-flex justify-content-center">
													<button className="btn btn-sm btn-primary mt-3 me-1" onClick={goToPreviousPage} disabled={currentPage === 1}>
														<LiaLongArrowAltLeftSolid />&nbsp;&nbsp;
														{t("Previous")}
													</button>&nbsp;&nbsp;
													<button className="btn btn-sm btn-primary mt-3" onClick={goToNextPage} disabled={currentPage === totalPages}>
														{t("Next")}&nbsp;&nbsp;
														<LiaLongArrowAltRightSolid />
													</button>
												</div>
											</div>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
			{/* Add your modal component here */}
			{/* <AddPatientModal show={showModal} handleClose={handleCloseModal} data={modalData} /> */}
			<AddPatientModal show={showModal} onHide={handleCloseModal} appointmentData={modalData} selectedDate={format(startDate, "yyyy-MM-dd")} fetchAppointments={fetchAppointments} />
			<PaymentModal show={showPaymentModal} handleClose={handleClosePaymentModal} data={paymentData} handleSave={handleSavePayment} />
		</>
	);
}
