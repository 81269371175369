import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { LiaMedkitSolid, FaBed, LiaFileMedicalAltSolid, TbUsersGroup, FiSearch, LiaTimesSolid } from '../../assets/icons/vander';
import { useTranslation } from 'react-i18next';
import TopHeader from "../../components/topHeader";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function LandingPage(props) {
    const [showButtons, setShowButtons] = useState(false);
    const { t } = useTranslation();
    const aboutData = [
        {
            icon: LiaMedkitSolid,
            title: '220',
            desc: 'Appointment'
        },
        {
            icon: FaBed,
            title: '558',
            desc: 'Patients'
        },
        {
            icon: LiaFileMedicalAltSolid,
            title: '$2164',
            desc: 'Avg. Costs'
        },
        {
            icon: TbUsersGroup,
            title: '112',
            desc: 'Staff Members'
        },
    ];

    useEffect(() => {
        // Trigger the transition effect after the component is mounted
        setShowButtons(true);
    }, []);

    const [toggle, setToggle] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    // Event handler to clear the search input field
    const handleClear = () => {
        setSearchValue('');
    };

    // Event handler to navigate to the patient search list with the search query as a query parameter
    const handleSearchChange = () => {
        if (searchValue.trim()) {
            // Navigate to /patient-search-list with the search query as a query parameter
            navigate(`/patient-search-list?query=${encodeURIComponent(searchValue)}`);
        }
    };

    const addPatient = () => {
        navigate("/add-patient");
    };

    const startConsultation = () => {
        navigate("/appointments");
    };

    const chatPage = () => {
        navigate("/chat");
    };

    const calenderAction = () => {
        navigate("/appointments");
    };

    const goToAccounts = () => {
        navigate("/accounts");
    };

    return (
        <>
            <div className={`${toggle ? '' : 'toggled'} page-wrapper doctris-theme`}>
                <main className="page-content bg-light">
                    <TopHeader toggle={toggle} setToggle={setToggle} />
                    {props.children}
                </main>
                <div className="container-fluid" style={{ marginTop: "6rem" }}>
                    <div className="card p-3 shadow">
                        <div className="row">
                            {aboutData.map((item, index) => {
                                let Icon = item.icon;
                                return (
                                    <div className="col-xl-3 col-lg-3 col-md-4 mt-2" key={index}>
                                        <div className="card features feature-primary rounded border-0 shadow p-4">
                                            <div className="d-flex align-items-center">
                                                <div className="icon text-center rounded-md">
                                                    <Icon className="h3 mb-0" />
                                                </div>
                                                <div className="flex-1 ms-2">
                                                    <h5 className="mb-0">{item.title}</h5>
                                                    <p className="text-muted mb-0">{t(item.desc)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="row my-4">
                            <div className="col-lg-10 col-md-9 col-sm-9 mt-2">
                                <input
                                    type="text"
                                    className="form-control border rounded-pill"
                                    style={{ lineHeight: 2 }}
                                    placeholder={t('Search Patient')}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-3 mt-2 d-flex justify-content-start align-items-center" style={{ gap: '18px' }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">{t("Search")}</Tooltip>}
                                >
                                    <button
                                        className="btn btn-icon btn-pills btn-primary"
                                        onClick={handleSearchChange}
                                        style={{ height: '45px', width: '45px' }}
                                    >
                                        <FiSearch className="icons" />
                                    </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">{t("Clear")}</Tooltip>}
                                >
                                    <button
                                        className="btn btn-icon btn-pills btn-danger"
                                        style={{ height: '45px', width: '45px' }}
                                        onClick={handleClear}
                                    >
                                        <LiaTimesSolid className="icons" />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button 
                                // onClick={calenderAction}
                                 type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Calendar")}</button>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button onClick={chatPage} type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Chat")}</button>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button 
                                // onClick={startConsultation}
                                 type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Start Consultation")}</button>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button onClick={addPatient} type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Add A New Patient")}</button>
                            </div>
                            {/* <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Patients")}</button>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Referrals")}</button>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 my-3">
                                <button onClick={goToAccounts} type="submit" style={{ fontSize: "18px", fontWeight: "500" }} className="btn btn-pills btn-soft-info w-100">{t("Accounts")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
