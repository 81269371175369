import React, { useState } from "react";
import Wrapper from "../../components/wrapper";
import SimpleBar from "simplebar-react";
import { accountData } from "../../data/data"; 
import { FiDownload, FiEye } from '../../assets/icons/vander'
import { Link, useNavigate } from "react-router-dom";

export default function Accounts() {

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing fixed-layout-responsive" style={{ position: "fixed", width: "-webkit-fill-available" }}>
                    <div className="row">

                        <div className="col-xl-12 col-lg-12 col-md-12 mt-2">
                            <div className="card border-0 p-4 shadow">
                                <SimpleBar style={{ height: '515px' }}>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <div className="table-responsive shadow">
                                                <table className="table table-center table-striped table-hover bg-white mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-bottom p-3" width="5%">#</th>
                                                            <th className="border-bottom p-3" width="15%">Patient ID</th>
                                                            <th className="border-bottom p-3" width="20%">Name</th>
                                                            <th className="border-bottom p-3" width="15%">Date</th>
                                                            <th className="border-bottom p-3" width="15%">Amount</th>
                                                            <th className="border-bottom p-3" width="15%">Status</th>
                                                            <th className="border-bottom p-3" width="15%">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accountData.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.patientID}</td>
                                                                    <td>
                                                                        {/* <Link to="#" className="text-dark"> */}
                                                                        <div className="d-flex align-items-center">
                                                                            <img src={item.client} className="avatar avatar-ex-sm rounded-circle shadow" alt="" />
                                                                            <span className="ms-2">{item.clientName}</span>
                                                                        </div>
                                                                        {/* </Link> */}
                                                                    </td>
                                                                    <td>{item.date}</td>
                                                                    <td>{item.amount}</td>
                                                                    <td>{item.status}</td>
                                                                    <td className="text-left">
                                                                        <Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiDownload /></Link>
                                                                        <Link to="/account-details" className="btn btn-icon btn-pills btn-soft-primary ms-2"><FiEye /></Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
