import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

const DocumentsModal = ({
    show,
    onHide,
    title,
    documentType,
    handleDocumentsInputChange,
    handleDocumentsSubmit,
    previewURLs,
    documentsFormErrors,
    documentsFormSubmitted,
    resetDocumentsForm // New prop for resetting the form
}) => {
    const { t } = useTranslation();

    const handleCancelClick = () => {
        resetDocumentsForm();
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
            <Modal.Header closeButton>
                <h5 className="modal-title">{title}</h5>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(event) => handleDocumentsSubmit(event, documentType)}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <label>{title}</label>
                            <input
                                type="file"
                                name={documentType}
                                className="form-control"
                                onChange={handleDocumentsInputChange}
                                required=""
                                style={{ borderRadius: '50px' }}
                            />
                            {documentsFormSubmitted && documentsFormErrors[documentType] && (
                                <div className="text-danger">{documentsFormErrors[documentType]}</div>
                            )}
                            {previewURLs[documentType] && (
                                <div className="col-lg-3 col-md-3 col-sm-4 mt-2">
                                    <img
                                        src={previewURLs[documentType]}
                                        alt={`${title} preview`}
                                        style={{ width: "75px", height: "75px" }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row my-4">
                        <div className="col-md-12 text-end">
                            <button type="submit" className="btn btn-primary">{t("Save")}</button>
                            <button type="button" className="btn btn-danger ms-3" onClick={handleCancelClick}>{t("Cancel")}</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default DocumentsModal;
