import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import socketIOClient from 'socket.io-client';
import chatBg from '../../assets/images/bg/bg-chat.png';
import doctor1 from '../../assets/images/doctors/01.jpg';
import doctor2 from '../../assets/images/doctors/02.jpg';
import Wrapper from "../../components/wrapper";
import SimpleBar from "simplebar-react";
import { RiTimeLine, FiSettings, LuUser2, FiTrash, AiOutlineSend, FiSmile, FiPaperclip, FiStopCircle } from '../../assets/icons/vander';
import { FaEllipsis } from 'react-icons/fa6';
import { chatData } from "../../data/data";
import { useTranslation } from 'react-i18next';
import { chatContactList, userChatHistory, searchChatUser, sendChatMessage } from "./services/chat-service";
import Loader from '../../components/loader';
import { debounce } from 'lodash'; // Import lodash debounce
import dummyImage from '../../assets/images/doctors/blank_profile.png';
import { BaseurlForImage } from "../../environments/environments";
import AttachmentPreview from "./attachment-preview"; // Import the AttachmentPreview component
import Modal from 'react-bootstrap/Modal';
import dummyDocument from '../../assets/images/MyImages/documents.png';
import youtube from '../../assets/images/MyImages/youtube.png';

export default function Chats() {
    // const ENDPOINT = 'https://78c2-103-66-5-231.ngrok-free.app';
    // const socket = socketIOClient(ENDPOINT);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    let [profileSetting, setProfileSetting] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    // const [chatContactListData, setChatContactListData] = useState({});
    const [chatContactListData, setChatContactListData] = useState({ res: [], type: '' });
    const [chatHistoryData, setChatHistoryData] = useState([]);
    const [chatPersonData, setChatPersonData] = useState({});
    const [isChatSelected, setIsChatSelected] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [getUserData, setUserData] = useState(() => {
        const storedData = localStorage.getItem("currentDoctor");
        return storedData ? JSON.parse(storedData) : null;
    });
    // console.log(getUserData._id);

    const chatContainerRef = useRef(null);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistoryData]);

    useEffect(() => {
        const closeProfile = () => {
            setProfileSetting(false);
        };
        document.addEventListener('mousedown', closeProfile);
        return () => {
            document.removeEventListener('mousedown', closeProfile);
        };
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = {
                pageNo: 1,
                pageSize: 10
            };
            const response = await chatContactList(data);
            // console.log(response.data);
            const customData = {
                res: response.data.data.sender,
                type: 'relation'
            }
            // console.log(customData);
            setChatContactListData(customData);
            // console.log(chatContactListData);
        } catch (error) {
            // console.error("Error fetching chat contact list:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = async (value) => {
        setLoading(true);
        try {
            if (value.length === 0) {
                // If search value is empty, call fetchData
                // console.log("Search is empty, calling fetchData");
                await fetchData();
                return; // Exit the function early after calling fetchData
            }

            // Otherwise, call searchChatUser API
            const data = {
                search: value,
            };
            const response = await searchChatUser(data);
            // console.log("Search response:", response.data);
            if (response.data.status === "success") {
                const users = response.data.data.users;
                // console.log("Number of users found:", users.length);
                const customData = {
                    res: users,
                    type: 'search'
                }
                setChatContactListData(customData);
                // console.log(chatContactListData);
            } else {
                // console.log("No users found in the response:", response);
                setChatContactListData({ res: [], type: '' });
                await fetchData();
            }
        } catch (error) {
            console.error("Error searching users:", error);
        } finally {
            setLoading(false);
        }
    };

    const delayedSearch = useRef(debounce(handleSearch, 300)).current;

    const handleInputChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);
        delayedSearch(value);
    };

    const specificPersonChat = async (senderData, type) => {
        // console.log(senderData);
        // console.log(type);
        try {
            setLoading(true);
            setIsChatSelected(true); // Set chat as selected
            const data = {
                sender_id: (type === 'relation') ? ((getUserData._id === senderData.receiver_id) ? senderData.sender_id : senderData.receiver_id) : senderData._id,
                sender_role: (type === 'relation') ? ((getUserData._id === senderData.receiver_id) ? senderData.sender_role : senderData.receiver_role) : senderData.role,
                pageNo: 1,
                pageSize: 10
            };
            const response = await userChatHistory(data);
            // console.log(response.data);
            // console.log(response.data.data.user);
            setChatPersonData(response.data.data.user);
            setChatHistoryData(response.data.data.messages);
        } catch (error) {
            console.error("Error fetching chat contact list:", error);
        } finally {
            setLoading(false);
        }
    };

    const [message, setMessage] = useState('');

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendButtonClick = async (item) => {
        // Pass the message to your desired function here
        console.log('Message:', message);
        try {
            setLoading(true);
            // const data = {
            //     receiver_id: item._id,
            //     receiver_role: item.role,
            //     message: message
            // };
            const formData = new FormData();
            formData.append('receiver_id', item._id);
            formData.append('receiver_role', item.role);
            formData.append('message', message);
            const response = await sendChatMessage(formData);
            // console.log(response.data);
            if (response.data.status === "success") {
                // Clear the message after sending
                setMessage('');
                // Refresh the chat by calling specificPersonChat with the updated item
                await specificPersonChat(item);
                await fetchData();
            }
        } catch (error) {
            console.error("Error fetching chat contact list:", error);
        } finally {
            setLoading(false);
        }
    };

    // Reference to the file input element
    const fileInputRef = useRef(null);

    const handleFileUpload = async (file, chatPersonData) => {
        try {
            setLoading(true);
            // Check if file is selected
            if (file) {
                // Create form data object
                const formData = new FormData();
                formData.append('attachment', file);
                formData.append('receiver_id', chatPersonData._id);
                formData.append('receiver_role', chatPersonData.role);
                formData.append('message', "Test");
                // Call sendChatMessage API
                const response = await sendChatMessage(formData);
                if (response.data.status === "success") {
                    // Clear the message after sending
                    setMessage('');
                    // Refresh the chat by calling specificPersonChat with the updated chatPersonData
                    await specificPersonChat(chatPersonData);
                    await fetchData();
                }
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle file input change
    const handleFileInputChange = (event, chatPersonData) => {
        const file = event.target.files[0];
        // Call handleFileUpload function to upload the file
        handleFileUpload(file, chatPersonData);
    };

    // Function to trigger file input click event
    const handleFileButtonClick = (chatPersonData) => {
        // Access the file input element
        const fileInput = fileInputRef.current;
        // Trigger click event on the file input element
        fileInput.click();
    };

    const handleImageClick = (url) => {
        if (isImage(url)) {
            // It's an image, set previewImageUrl to show in modal
            setPreviewImageUrl(url);
        } else {
            // Not an image, open the URL in a new tab
            window.open(url, '_blank');
        }
    };

    // Function to check if the URL is an image
    const isImage = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Add more if needed
        return imageExtensions.includes(extension);
    };

    const isDocument = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        const documentExtensions = ['doc', 'docx', 'pdf', 'txt', 'ppt', 'pptx', 'xls', 'xlsx'];
        return documentExtensions.includes(extension);
    };

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'mkv'];
        return videoExtensions.includes(extension);
    };

    return (
        <Wrapper>
            <Loader loading={loading} />
            <div className="container-fluid">
                <div className="layout-specing fixed-layout-responsive" style={{ position: "fixed", width: "-webkit-fill-available" }}>
                    <div className="row">
                        <div className="col-xl-3 col-lg-5 col-md-5 col-12 mt-2">
                            <div className="card border-0 shadow">
                                <div className="text-center p-3 border-bottom">
                                    <input
                                        type="text"
                                        className="form-control border rounded-pill"
                                        style={{ lineHeight: 2 }}
                                        name="search"
                                        id="search"
                                        placeholder="Search"
                                        value={searchValue}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <SimpleBar className="p-2 chat chat-list" style={{ height: '492px' }}>
                                    <Loader loading={loading} />
                                    {chatContactListData?.type === "relation" ? (
                                        chatContactListData.res && chatContactListData.res.length > 0 ? (
                                            // Render chat contact list items if data is available
                                            chatContactListData.res.map((item, index) => (
                                                <Link to="#" className={`${item.bg === true ? 'active' : ''} d-flex chat-list p-2 rounded position-relative`} key={index} onClick={() => specificPersonChat(item, chatContactListData?.type)}>
                                                    <div className="position-relative">
                                                        <img src={getUserData._id === item?.receiver_id ? (
                                                            item?.sender_profile_picture ? BaseurlForImage + item?.sender_profile_picture : dummyImage
                                                        ) : (
                                                            item?.receiver_profile_picture ? BaseurlForImage + item?.receiver_profile_picture : dummyImage
                                                        )}
                                                            className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                                        {item?.status === 'active' ?
                                                            <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i> :
                                                            <i className="mdi mdi-checkbox-blank-circle text-danger on-off align-text-bottom"></i>
                                                        }
                                                    </div>
                                                    <div className="overflow-hidden flex-1 ms-2">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 style={{ fontSize: "small" }} className="text-dark mb-0 d-block">{getUserData._id === item?.receiver_id ? item?.sender_name : item?.receiver_name}</h6>
                                                            <small className="text-dark">{item?.time}</small>
                                                        </div>
                                                        {item?.badge === true ?
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-dark mb-0 text-truncate" style={{ fontSize: "12px" }}>{item?.message}</div>
                                                                <span className="badge rounded-pill bg-soft-danger">{item?.badgeNo}</span>
                                                            </div> :
                                                            <div className="text-dark text-truncate" style={{ fontSize: "12px" }}>{item?.message}</div>
                                                        }
                                                    </div>
                                                </Link>
                                            ))
                                        ) : (
                                            // Render a placeholder or message when data is not available
                                            <div className="text-center">No chat available</div>
                                        )
                                    ) : (
                                        // Render different content when type is not "relation"
                                        chatContactListData.res && chatContactListData.res.length > 0 ? (
                                            // Render chat contact list items if data is available
                                            chatContactListData.res.map((item, index) => (
                                                <Link to="#" className={`${item.bg === true ? 'active' : ''} d-flex chat-list p-2 rounded position-relative`} key={index} onClick={() => specificPersonChat(item, chatContactListData?.type)}>
                                                    <div className="position-relative">
                                                        <img src={item?.profile_picture ? BaseurlForImage + item?.profile_picture : dummyImage} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                                        {item?.status === 'active' ?
                                                            <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i> :
                                                            <i className="mdi mdi-checkbox-blank-circle text-danger on-off align-text-bottom"></i>
                                                        }
                                                    </div>
                                                    <div className="overflow-hidden flex-1 ms-2">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 style={{ fontSize: "small" }} className="text-dark mb-0 d-block">{item?.first_name} {item?.last_name}</h6>
                                                            <small className="text-dark">{item?.time}</small>
                                                        </div>
                                                        {item?.badge === true ?
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-dark mb-0 text-truncate" style={{ fontSize: "12px" }}>{item?.message}</div>
                                                                <span className="badge rounded-pill bg-soft-danger">{item?.badgeNo}</span>
                                                            </div> :
                                                            <div className="text-dark text-truncate" style={{ fontSize: "12px" }}>{item?.message}</div>
                                                        }
                                                    </div>
                                                </Link>
                                            ))
                                        ) : (
                                            // Render a placeholder or message when data is not available
                                            <div className="text-center">User not found</div>
                                        )
                                    )}

                                </SimpleBar>
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-7 col-md-7 col-12 mt-2">
                            <div className="card chat chat-person border-0 shadow">
                                {isChatSelected ? (
                                    <React.Fragment>
                                        <div className="d-flex justify-content-between border-bottom p-3">
                                            <div className="d-flex">
                                                <img src={chatPersonData?.profile_picture ? BaseurlForImage + chatPersonData?.profile_picture : dummyImage} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                                <div className="overflow-hidden ms-3">
                                                    <Link to="#" className="text-dark mb-0 h6 d-block text-truncate">{chatPersonData?.first_name} {chatPersonData?.last_name}</Link>
                                                    {chatPersonData?.status === 'active' ? (
                                                        <small className="text-dark">
                                                            <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i> Online
                                                        </small>
                                                    ) : (
                                                        <small className="text-dark">
                                                            <i className="mdi mdi-checkbox-blank-circle text-danger on-off align-text-bottom"></i> Offline
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <SimpleBar style={{ height: '435px', overflowY: 'auto', scrollBehavior: 'smooth' }} ref={chatContainerRef}>
                                            {chatHistoryData && chatHistoryData.length > 0 ? (
                                                <ul className="p-4 list-unstyled mb-0 chat">
                                                    {chatHistoryData.map((message, index) => (
                                                        <li key={index} className={getUserData._id === message?.sender?.sender_id ? 'chat-right' : ''}>
                                                            <div className="d-inline-block">
                                                                <div className="d-flex chat-type mb-3">
                                                                    {getUserData._id === message?.sender?.sender_id ? (
                                                                        <>
                                                                            <div className="d-flex flex-column"> {/* Container for image and message */}
                                                                                {message.attachment && (
                                                                                    <div className="px-2 py-2">
                                                                                        {isDocument(message.attachment) ? (
                                                                                            <img
                                                                                                src={dummyDocument}
                                                                                                className="chat-image-size rounded"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                alt="Document"
                                                                                                onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                                            />
                                                                                        ) : isVideo(message.attachment) ? (
                                                                                            <img
                                                                                                src={youtube}
                                                                                                className="chat-image-size rounded"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                alt="Video"
                                                                                                onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={BaseurlForImage + message.attachment}
                                                                                                className="chat-image-size rounded"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                alt="Image"
                                                                                                onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                <div className="chat-msg" style={{ maxWidth: '500px' }}>
                                                                                    <p className={`small shadow px-3 py-2 rounded mb-1 ${getUserData._id === message?.sender?.sender_id ? 'bg-primary text-white' : 'bg-custom text-dark'}`}>
                                                                                        {message.message}
                                                                                    </p>
                                                                                    <small className="text-muted">{new Date(message.createdAt).toLocaleString()}</small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="position-relative ms-2">
                                                                                <img
                                                                                    src={message.sender.profile_picture ? BaseurlForImage + message.sender.profile_picture : dummyImage}
                                                                                    className="avatar avatar-md-sm rounded-circle border shadow"
                                                                                    alt=""
                                                                                />
                                                                                <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="position-relative me-2">
                                                                                <img
                                                                                    src={message.sender.profile_picture ? BaseurlForImage + message.sender.profile_picture : dummyImage}
                                                                                    className="avatar avatar-md-sm rounded-circle border shadow"
                                                                                    alt=""
                                                                                />
                                                                                <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                {message.attachment && (
                                                                                    <div className="px-2 py-2">
                                                                                        {isDocument(message.attachment) ? (
                                                                                            <img
                                                                                                src={dummyDocument}
                                                                                                className="chat-image-size rounded"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                alt="Document"
                                                                                                onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                                            />
                                                                                        ) : isVideo(message.attachment) ? (
                                                                                            <img
                                                                                                src={youtube}
                                                                                                className="chat-image-size rounded"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                alt="Video"
                                                                                                onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={BaseurlForImage + message.attachment}
                                                                                                className="chat-image-size rounded"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                alt="Image"
                                                                                                onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                <div className="chat-msg" style={{ maxWidth: '500px' }}>
                                                                                    <p className={`small shadow px-3 py-2 rounded mb-1 ${getUserData._id !== message?.sender?.sender_id ? 'bg-light text-dark' : 'bg-custom text-dark'}`}>
                                                                                        {message.message}
                                                                                    </p>
                                                                                    <small className="text-muted">{new Date(message.createdAt).toLocaleString()}</small>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <div className="text-center mt-4">No chat history found</div>
                                            )}
                                        </SimpleBar>
                                        <div className="p-2 rounded-bottom shadow">
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control border"
                                                        placeholder="Enter Message..."
                                                        value={message}
                                                        onChange={handleMessageChange}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        className="btn btn-icon btn-primary mx-2"
                                                        onClick={() => handleSendButtonClick(chatPersonData)}
                                                    >
                                                        <AiOutlineSend />
                                                    </button>
                                                    <button className="btn btn-icon btn-primary mx-2" onClick={() => handleFileButtonClick(chatPersonData)}>
                                                        <FiPaperclip />
                                                        {/* Hidden file input element */}
                                                        <input
                                                            type="file"
                                                            ref={fileInputRef}
                                                            style={{ display: "none" }}
                                                            onChange={(event) => handleFileInputChange(event, chatPersonData)} // Handle file input change
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div style={{ height: '565px' }}>
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                            {/* <img src={dummyImage} alt="No Chat Selected" className="img-fluid" /> */}
                                            <p className="text-muted">Select a chat to start messaging</p>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={!!previewImageUrl} onHide={() => setPreviewImageUrl(null)} size="lg" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    <img src={previewImageUrl} alt="Preview" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </Wrapper>
    );
}
