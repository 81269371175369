import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import bg from '../../assets/images/MyImages/bg.png'
import colorLogo from '../../assets/images/MyImages/color_logo_500.png'
import loginImg from '../../assets/images/MyImages/login_image.png';
import { toast } from "react-toastify";
import axiosInstance from "../../interceptor/interceptor-instance";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    // const [userId, setUserId] = useState("");

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(""); // Clear email error when user types
    };

    const validateFields = () => {
        let isValid = true;

        // Email validation
        if (!email) {
            setEmailError("Email is required");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            console.log("Invalid email format");
            setEmailError("Please enter a valid email address");
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form submission
        if (validateFields()) {
            // Perform login or other actions if fields are valid
            // For example, navigate to another page
            const body = {
                role: 'doctor',
                email: email
            }
            try {
                const response = await axiosInstance.post(`doctor/auth/forgot-password`, body)
                if(response.data.status === 'success') {
                    const userId = response.data.data.doctor._id;
                    // setUserId(response.data.data.doctor._id)
                    // setToken(response.data.data.token);
                    // localStorage.setItem('authToken', response.data.data.token);
                    // setEmailSent(true);
                    console.log('user id: '+userId)
                    navigate(`/reset-password/${userId}`, { replace: true })
                    // navigate(`/reset-password`, { state: { email: email }, replace: true })
                    setEmail("");
                    toast.success(response.data.message);
                }
            } catch(error) {
                console.error('Error fetching data:', error);
                // toast.error(error.message);
            }
        }
    };

    return (
        <>
            <section className="bg-home d-flex bg-light align-items-center login-page-height main-login-wrapper" style={{ backgroundImage: `url(${bg})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="card login-page shadow mt-4" style={{ borderRadius: '5px' }}>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-0 col-sm-0 p-0 d-none d-lg-block" style={{ position: 'relative' }}>
                                            <img src={loginImg} className="w-100 h-auto" style={{ borderRadius: '5px' }} alt="" />
                                            <img src={colorLogo} className="h-auto" style={{ width: '40%', position: 'absolute', top: '8%', left: '30%', transform: 'translate(-50%, -50%)' }} alt="" />
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-sm-12 p-5">
                                            <h4 className="text-left">Forgot Password</h4>
                                            <p>Please enter your email address. You will receive a link to create a new password via email.</p>
                                            <form className="login-form mt-4" onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">Email <span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control" placeholder="Enter Email" name="email" required="" value={email} onChange={handleEmailChange} />
                                                    {emailError && <div className="text-danger">{emailError}</div>}
                                                </div>
                                                <div className="d-flex justify-content-between mb-5">
                                                    <div>
                                                        <Link to="/login" style={{color: '#000'}}>Back To <u style={{color: '#007af4'}}>Login</u></Link>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-primary">Forgot Password</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
