import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../components/wrapper";

export default function DoctorPrescription() {
    const [viewProfile, setViewProfile] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [openDropdowns, setOpenDropdowns] = useState([]);

    // Event handler to close modals when clicking outside
    useEffect(() => {
        const closeModals = () => {
            setViewProfile(false);
            setEditProfile(false);
        };

        document.addEventListener('mousedown', closeModals);

        return () => {
            document.removeEventListener('mousedown', closeModals);
        };
    }, []);

    // Function to toggle dropdown visibility for a specific index
    const toggleDropdown = (index) => {
        setOpenDropdowns(prevState => {
            // Check if the index is already in the openDropdowns array
            if (prevState.includes(index)) {
                // If yes, remove it from the array
                return prevState.filter(item => item !== index);
            } else {
                // If no, add it to the array
                return [...prevState, index];
            }
        });
    };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 p-4 rounded shadow">
                                <div className="row">
                                    <div className="col-xl-9 col-md-6">
                                        <h5 className="mb-0 custom-text-shadow">Prescriptions</h5>

                                        <nav aria-label="breadcrumb" className="d-inline-block mt-2">
                                            <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                                                <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                                <li className="breadcrumb-item"><Link to="/doctors">Doctors</Link></li>
                                                <li className="breadcrumb-item"><Link to="/doctor-appointment">Doctor Appointments</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Prescriptions</li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                        <Link to="/doctor-appointment" className="btn btn-primary">Back</Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 mt-4">
                                        <div className="table-responsive shadow rounded">
                                            <table className="table table-center table-bordered bg-white mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="border-bottom p-3">#</th>
                                                        <th className="border-bottom p-3">Medication</th>
                                                        <th className="border-bottom p-3">Prescription Type</th>
                                                        <th className="border-bottom p-3">Dose</th>
                                                        <th className="border-bottom p-3">Quantity</th>
                                                        <th className="border-bottom p-3">Comments/Instruction</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className="p-3">1</th>
                                                        <td className="">Allopurinol Tab, 100 mg</td>
                                                        <td className="">Acute</td>
                                                        <td className="">Twice A Day</td>
                                                        <td className="">6</td>
                                                        <td className="">Take Medicine with Milk</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="p-3">2</th>
                                                        <td className="">Paracetamol  Tab, 100 mg</td>
                                                        <td className="">Acute</td>
                                                        <td className="">Once A Day</td>
                                                        <td className="">3</td>
                                                        <td className="">Take Medicine with Milk</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="p-3">3</th>
                                                        <td className="">Agave syrup</td>
                                                        <td className="">Acute</td>
                                                        <td className="">Thrice A Day</td>
                                                        <td className="">1</td>
                                                        <td className="">-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-4">
                                        <div className="card border-0 p-4 rounded shadow">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h6 className="mb-2 custom-text-shadow">Doctor Comments :</h6>
                                                </div>
                                                <div className="col-md-12">
                                                    <span className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                                </div>
                                            </div>
                                        </div>            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
