import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import logoIcon from '../assets/images/logo-icon.png';
import country1 from '../assets/images/language/united-kingdom.png';
import country2 from '../assets/images/language/france.png';
import doctor1 from '../assets/images/doctors/01.jpg';
import { FaBars, FiSettings, RiNotification2Line, GrLock, RiLogoutCircleRLine, FiUser } from '../assets/icons/vander';
import { countryData, mailData } from "../data/data";
import SimpleBar from "simplebar-react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import PersonChatTwo from "./personChatTwo";
import { useTranslation } from 'react-i18next';
import { changeLanguage } from "i18next";
import { useAuth } from "../page/authentication/auth";
import axiosInstance from "../interceptor/interceptor-instance";
import { BaseurlForImage } from '../environments/environments';
import dummyDocument from '../assets/images/doctors/blank_profile.png';

export default function TopHeader({ toggle, setToggle }) {
    const [countryModal, setCountryModal] = useState(false);
    const [mailModal, setMailModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [show, setShow] = useState(false);
    const userModalRef = useRef(null);
    const userModalRef2 = useRef(null);
    const { logout } = useAuth();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const handleCloseModals = (event) => {
            // Close user modal if clicked outside
            if (userModalRef.current && userModalRef.current.contains) {
                if (!userModalRef.current.contains(event.target)) {
                    setUserModal(false);
                }
            }

            // Close country modal if clicked outside
            if (userModalRef2.current && userModalRef2.current.contains) {
                if (!userModalRef2.current.contains(event.target)) {
                    setCountryModal(false);
                }
            }

            // Close mail modal
            setMailModal(false);
        };

        document.addEventListener('mousedown', handleCloseModals);

        return () => {
            document.removeEventListener('mousedown', handleCloseModals);
        };
    }, []);

    // Multi-lingual  Functionality

    const { t } = useTranslation(); // Translation

    // Initialize language state from local storage
    const [lang, setLang] = useState(localStorage.getItem('language') || 'en');

    // Initialize the flag image based on the language in local storage
    const [langFlag, setLangFlag] = useState(
        lang === 'fr' ? country2 : country1
    );


    // useEffect to handle language change and flag update
    useEffect(() => {
        // Change the language using i18next
        changeLanguage(lang);
        // Update the language flag based on the selected language
        if (lang === 'fr') {
            setLangFlag(country2);
        } else if (lang === 'en') {
            setLangFlag(country1);
        }
    }, [lang]);

    const [getUserData, setUserData] = useState(JSON.parse(localStorage.getItem("currentDoctor")) || null);

    const selectLanguage = async (lng) => {
        // console.log("=================");
        localStorage.setItem('language', lng)
        // Change the language using i18next
        await changeLanguage(lng);

        // Update the state to reflect the new language
        setLang(lng);

        // Update the language flag based on the selected language
        if (lng === 'en') {
            setCountryModal(false);
            setLangFlag(country1);
        } else if (lng === 'fr') {
            setCountryModal(false);
            setLangFlag(country2);
        }
    };

    const navigate = useNavigate();

    const notificationList = () => {
        navigate("/notifications");
    };

    const handleLogout = () => {
        logout();
    }

    const backToLanding = () => {
        navigate('/landing');
        localStorage.removeItem("patientData");
        localStorage.removeItem("patientAppointmentType");
    };

    return (
        <div className="top-header">
            <div className="header-bar d-flex justify-content-between border-bottom">
                <div className="d-flex align-items-center">
                    <Link to="/landing" onClick={backToLanding} className="logo-icon" style={{ marginRight: "8px" }}>
                        <img src={logoIcon} height="30" className="small" alt="" />
                        <span className="big">
                            <img src={logoDark} height="40" className="logo-light-mode" alt="" />
                            <img src={logoLight} height="40" className="logo-dark-mode" alt="" />
                        </span>
                    </Link>
                    <Link onClick={() => setToggle(!toggle)} className="btn btn-icon btn-pills btn-soft-primary ms-2 side-bar-collapse icon-size-responsive" to="#">
                        <FaBars />
                    </Link>
                    {/* <div className="search-bar p-0 d-none d-lg-block ms-2">
                        <div id="search" className="menu-search mb-0">
                            <form role="search" method="get" id="searchform" className="searchform">
                                <div>
                                    <input type="text" className="form-control border rounded-pill" name="s" id="s" placeholder="Search Keywords..." />
                                    <input type="submit" id="searchsubmit" defaultValue="Search" />
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>

                <ul className="list-unstyled mb-0">
                    <li className="list-inline-item mt-2 mx-2">
                        <div className="dropdown dropdown-primary">
                            <button type="button" className="btn btn-pills btn-soft-primary dropdown-toggle p-0 icon-size-responsive" onClick={() => setCountryModal(!countryModal)}>
                                <img src={langFlag} className="avatar avatar-ex-small rounded-circle p-2" alt="" />
                            </button>
                            <SimpleBar ref={userModalRef2} className={`${countryModal ? 'show' : ''} dropdown-menu dd-menu drop-ups dropdown-menu-end shadow border-0 mt-3 p-2`} style={{ height: 'auto', position: 'absolute', right: '0' }}>
                                {countryData.map((item, index) => (
                                    <Link key={index} className="d-flex align-items-center mt-2" onClick={() => selectLanguage(item.lang)}>
                                        <img src={item.image} className="avatar avatar-client rounded-circle shadow" alt="" />
                                        <div className="flex-1 text-left ms-2 overflow-hidden">
                                            <small className="text-dark mb-0">{item.name}</small>
                                        </div>
                                    </Link>
                                ))}
                            </SimpleBar>

                        </div>
                    </li>

                    {/* <li className="list-inline-item mb-0 ms-1">
                        <Link to="#" onClick={handleShow} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            <div className="btn btn-icon btn-pills btn-soft-primary"><FiSettings className="fea icon-sm" /></div>
                        </Link>
                    </li>
                    <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: '430px' }}>
                        <Offcanvas.Header closeButton className="offcanvas-header p-4 border-bottom">
                            <h5 id="offcanvasRightLabel" className="mb-0">
                                <img src={logoDark} height="22" className="light-version" alt="" />
                                <img src={logoLight} height="22" className="dark-version" alt="" />
                            </h5>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <PersonChatTwo />
                        </Offcanvas.Body>
                    </Offcanvas> */}

                    <li className="list-inline-item mt-2 mx-2">
                        <div className="dropdown dropdown-primary">
                            <button type="button" className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0 icon-size-responsive" onClick={notificationList}>
                                <RiNotification2Line className="fea icon-sm" />
                                <span className="position-absolute translate-middle badge rounded-pill" style={{ background: '#ff0000', top: '4px', left: "90%" }}>4 <span className="visually-hidden">unread mail</span></span>
                            </button>
                        </div>
                    </li>
                    {/* <li className="list-inline-item mt-2 mx-2">
                        <div className="dropdown dropdown-primary">
                            <button type="button"  className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0 icon-size-responsive" onClick={() => setMailModal(!mailModal)}>
                                <RiNotification2Line className="fea icon-sm" />
                                <span className="position-absolute translate-middle badge rounded-pill" style={{background: '#ff0000', top: '4px', left: "90%"}}>4 <span className="visually-hidden">unread mail</span></span>
                            </button>
                            <SimpleBar className={`${mailModal ? 'show' : ''} dropdown-menu dd-menu dropdown-menu-end shadow rounded border-0 mt-3 px-2 py-2`} style={{ height: '320px', width: '300px', position: 'absolute', right: '0' }}>
                                {mailData.map((item, index) => (
                                    <Link to="#" className="d-flex align-items-center justify-content-between py-2" key={index}>
                                        <div className="d-inline-flex position-relative overflow-hidden">
                                            <img src={item.image} className="avatar avatar-md-sm rounded-circle shadow" alt="" />
                                            <small className="text-dark mb-0 d-block text-truncate ms-3">{item.desc} <b>{item.desc2}</b> <small className="text-muted fw-normal d-inline-block">{item.time}</small></small>
                                        </div>
                                    </Link>
                                ))}
                            </SimpleBar>
                        </div>
                    </li> */}

                    <li className="list-inline-item mb-0 ms-1">
                        <div className="dropdown dropdown-primary">
                            {/* <button type="button" className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0" onClick={() => setUserModal(!userModal)}>
                                <img src={doctor1} className="avatar avatar-ex-small rounded-circle" alt="" />
                            </button> */}
                            <div className="d-flex">
                                <div className="overflow-hidden mt-1" style={{ marginRight: '8px', textAlign: 'end' }}>
                                    <label className="text-dark mb-0 d-block text-truncate header-name-responsive">Dr. {getUserData.first_name} {getUserData.last_name}</label>
                                    <small style={{ color: '#00bc98' }}><i className="mdi mdi-checkbox-blank-circle on-off align-text-bottom" style={{ fontSize: "xx-small", color: '#00bc98' }}></i> Online</small>
                                </div>
                                <button type="button" className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0" onClick={() => setUserModal(!userModal)}>
                                    {/* <img src={BaseurlForImage + getUserData.profile_picture} className="avatar avatar-md-sm rounded-circle icon-size-responsive" alt="" style={{ border: '3px solid #00bc98' }} /> */}
                                    <img
                                        src={getUserData.profile_picture ? BaseurlForImage + getUserData.profile_picture : dummyDocument}
                                        className="avatar avatar-md-sm rounded-circle icon-size-responsive"
                                        alt=""
                                        style={{ border: '3px solid #00bc98' }}
                                    />
                                </button>
                            </div>
                            <div ref={userModalRef} className={`${userModal ? 'show' : ''} dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3`} style={{ minWidth: '200px', position: 'absolute', right: '0' }}>
                                {/* <Link className="dropdown-item d-flex align-items-center text-dark" to="/dr-profile">
                                    <img src={doctor1} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                    <div className="flex-1 ms-2">
                                        <span className="d-block mb-1">Calvin Carlo</span>
                                        <small className="text-muted">Orthopedic</small>
                                    </div>
                                </Link> */}
                                <Link className="dropdown-item text-dark d-flex align-items-center" to="/dr-profile">
                                    <span className="mb-0 d-inline-block me-1">
                                        <FiUser className="align-middle h6 mb-0" />
                                    </span>My Profile
                                </Link>
                                <Link className="dropdown-item text-dark d-flex align-items-center" to="/change-password">
                                    <span className="mb-0 d-inline-flex me-1">
                                        <GrLock className="align-middle h6 mb-0" />
                                    </span>Change Password
                                </Link>
                                <div className="dropdown-divider border-top"></div>
                                <Link className="dropdown-item text-dark d-flex align-items-center" onClick={handleLogout}>
                                    <span className="mb-0 d-inline-block me-1">
                                        <RiLogoutCircleRLine className="align-middle h6 mb-0" />
                                    </span> Logout
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
