import React, { useState } from "react";
import { Link } from "react-router-dom";
import TopHeader from "../../components/topHeader";
import Wrapper from "../../components/wrapper";
import { dummyNotifications } from '../../data/data';
import SimpleBar from "simplebar-react";
import { RiNotification2Line, RiNotificationOffFill } from '../../assets/icons/vander';
import MyPagination from '../../components/pagination';
import { useTranslation } from 'react-i18next';

export default function Notifications(props) {
    const { t } = useTranslation();
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [toggle, setToggle] = useState(false);
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    
    const handlePageSizeChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page whenever page size changes
    };

    return (
        <Wrapper>
            {/* <div className={`${toggle ? '' : 'toggled'} page-wrapper doctris-theme`}>
                <main className="page-content bg-light">
                    <TopHeader toggle={toggle} setToggle={setToggle} />
                    {props.children}
                </main> */}
                <div className="container-fluid" >
                    <div className="layout-specing">
                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                <div className="card p-4 mt-4">
                                    <div className="row mb-3">
                                        <div className="col-xl-9 col-md-6">
                                            <h5 className="mb-0 custom-text-shadow">Notifications</h5>

                                            <nav aria-label="breadcrumb" className="d-inline-block mt-2">
                                                <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                                                    <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Notifications</li>
                                                </ul>
                                            </nav>
                                        </div>

                                        <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                            <Link to="#" className="btn btn-primary">Read All</Link>
                                        </div>
                                    </div>

                                    {/* <SimpleBar style={{ height: '400px' }}> */}
                                    <div className="row">
                                        {dummyNotifications.map((item, i) => (
                                            <div className="col-md-6">
                                                <div key={i} className="card border-0 shadow rounded mb-4" style={{ borderRadius: '25px' }}>
                                                    <div className="main-content-label mb-0">
                                                        <span style={{ fontSize: 'medium' }}>
                                                            <strong>{item.notification_title}</strong>
                                                        </span>
                                                        {!item.is_notification_read ? (
                                                            <a
                                                                href="#"
                                                                className="float-end btn-read"
                                                                title="Mark As Read"

                                                            >
                                                                <RiNotification2Line className="fea icon-sm" />
                                                            </a>
                                                        ) : (
                                                            <a href="#" className="float-end btn-read" title="Read">
                                                                <RiNotificationOffFill className="fea icon-sm" />

                                                            </a>
                                                        )}
                                                    </div>
                                                    <div className="card-body" style={{ padding: '16px' }}>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p style={{ fontSize: '14px' }}>{item.notification_msg}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 text-end">
                                                                <span style={{ fontSize: '14px' }}>
                                                                    <strong>
                                                                        {new Date(item.notification_send_date).toLocaleDateString('en-GB', {
                                                                            day: '2-digit',
                                                                            month: 'short',
                                                                            year: 'numeric',
                                                                        })}
                                                                    </strong>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>

                                    <div className="row mt-4">
                            <div className="col-sm-6">
                                <div className="d-flex align-items-center">
                                    <span>{t("Show")}&nbsp;</span>
                                    <select style={{ display: 'unset', width: 'auto', padding: '0.375rem 2.25rem 0.375rem 0.75rem' }} value={size} onChange={handlePageSizeChange} className="form-control form-select">
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>&nbsp;
                                    <span>{t("entries")}</span>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <MyPagination totalPages={totalItems} currentPage={page} onPageChange={handlePageChange} />
                            </div>
                        </div>
                                    {/* </SimpleBar> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </Wrapper>
    )
}