import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Wrapper from "../../components/wrapper";

const initialValues = {
    monday: true,
    monday_slots: [{ from_time: "", to_time: "" }],
    tuesday: true,
    tuesday_slots: [{ from_time: "", to_time: "" }],
    wednesday: true,
    wednesday_slots: [{ from_time: "", to_time: "" }],
    thursday: true,
    thursday_slots: [{ from_time: "", to_time: "" }],
    friday: true,
    friday_slots: [{ from_time: "", to_time: "" }],
    saturday: true,
    saturday_slots: [{ from_time: "", to_time: "" }],
    sunday: true,
    sunday_slots: [{ from_time: "", to_time: "" }],
};

const setTime = [
    { time: "00:00" },
    { time: "00:30" },
    { time: "01:00" },
    { time: "01:30" },
    { time: "02:00" },
    { time: "02:30" },
    { time: "03:00" },
    { time: "03:30" },
    { time: "04:00" },
    { time: "04:30" },
    { time: "05:00" },
    { time: "05:30" },
    { time: "06:00" },
    { time: "06:30" },
    { time: "07:00" },
    { time: "07:30" },
    { time: "08:00" },
    { time: "08:30" },
    { time: "09:00" },
    { time: "09:30" },
    { time: "10:00" },
    { time: "10:30" },
    { time: "11:00" },
    { time: "11:30" },
    { time: "12:00" },
    { time: "12:30" },
    { time: "13:00" },
    { time: "13:30" },
    { time: "14:00" },
    { time: "14:30" },
    { time: "15:00" },
    { time: "15:30" },
    { time: "16:00" },
    { time: "16:30" },
    { time: "17:00" },
    { time: "17:30" },
    { time: "18:00" },
    { time: "18:30" },
    { time: "19:00" },
    { time: "19:30" },
    { time: "20:00" },
    { time: "20:30" },
    { time: "21:00" },
    { time: "21:30" },
    { time: "22:00" },
    { time: "22:30" },
    { time: "23:00" },
    { time: "23:30" },
];

export default function DoctorAvailability() {
    const [formValues, setFormValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Function to handle form value changes
    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;

        setFormValues((prevValues) => {
            const updatedValues = {
                ...prevValues,
                [name]: type === "checkbox" ? checked : value,
            };

            // If the availability is set to "Not Available," clear the time slot values for the day
            if (type === "checkbox" && !checked) {
                updatedValues[`${name}_slots`].forEach((slot) => {
                    slot.from_time = "";
                    slot.to_time = "";
                });

                // Remove validation errors for the day when switching to "Not Available"
                setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    // Remove errors for the day slots
                    delete newErrors[`${name}_slots`];
                    return newErrors;
                });
            }

            return updatedValues;
        });
    };

    // Function to handle slot changes
    const handleSlotChange = (day, index, field, value) => {
        setFormValues((prevValues) => {
            const slots = prevValues[`${day}_slots`].slice();
            slots[index][field] = value;
            return { ...prevValues, [`${day}_slots`]: slots };
        });

        // Clear error for the specific field when changed
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[`${day}_slots[${index}].${field}`];
            return newErrors;
        });
    };

    // Function to validate the form
    const validateForm = () => {
        const newErrors = {};
        // Add validation rules here
        ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach((day) => {
            // Skip validation for the day if it is set to "Not Available"
            if (formValues[day]) {
                const slots = formValues[`${day}_slots`];
                slots.forEach((slot, index) => {
                    // Check for missing from_time and to_time
                    if (!slot.from_time) {
                        newErrors[`${day}_slots[${index}].from_time`] = "From time is required.";
                    }
                    if (!slot.to_time) {
                        newErrors[`${day}_slots[${index}].to_time`] = "To time is required.";
                    }
                });
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true); // Set form submission state to true

        if (validateForm()) {
            // Submit the form values
            console.log("Form values:", formValues);
            // Add your API request logic here, e.g., axios.post("/api/add-availability", formValues)
        } else {
            console.log("Invalid form");
        }
    };

    const navigate = useNavigate();

    const backToList = () => {
        navigate("/doctors");
    };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-md-flex justify-content-between">
                        <h5 className="mb-0 custom-text-shadow">Doctor Availability</h5>
                        <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
                            <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/doctors">Doctors</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Doctor Availability</li>
                            </ul>
                        </nav>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card border-0 p-4 rounded shadow">
                                <form onSubmit={handleSubmit}>
                                    {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day) => (
                                        <div key={day}>
                                            <div className="row mb-3" style={{ justifyContent: 'flex-end' }}>
                                                <div className="col-md-4 d-flex align-items-center mt-2">
                                                    <h6 className="m-0"><b>{day.charAt(0).toUpperCase() + day.slice(1)}</b></h6>
                                                    <div className="ms-auto me-4">
                                                        <div className="form-check form-switch" style={{ paddingLeft: '3.2em' }}>
                                                            <input
                                                                style={{ cursor: 'pointer' }}
                                                                className="form-check-input custom-switch-style"
                                                                type="checkbox"
                                                                id={`flexSwitchCheck-${day}`}
                                                                name={day}
                                                                checked={formValues[day]}
                                                                onChange={handleChange}
                                                            />
                                                            &nbsp;&nbsp;<label className="form-check-label" htmlFor={`flexSwitchCheck-${day}`}>
                                                                {formValues[day] ? "Available" : "Not Available"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    {formValues[`${day}_slots`].map((slot, index) => (
                                                        <div key={index} className="row mt-2">
                                                            <div className="col-md-5 d-flex align-items-center">
                                                                <div className="form-group mb-0 w-100 me-auto">
                                                                    <select
                                                                        className="form-control"
                                                                        name={`${day}_slots[${index}].from_time`}
                                                                        value={slot.from_time}
                                                                        onChange={(e) => handleSlotChange(day, index, "from_time", e.target.value)}
                                                                        disabled={!formValues[day]}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {setTime.map((time, idx) => (
                                                                            <option key={idx} value={time.time}>
                                                                                {time.time}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {formSubmitted && errors[`${day}_slots[${index}].from_time`] && (
                                                                        <div className="text-danger">
                                                                            {errors[`${day}_slots[${index}].from_time`]}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 d-flex align-items-center" style={{justifyContent: "center", marginTop: "5px"}}>
                                                                <h6><b>To</b></h6>
                                                            </div>
                                                            <div className="col-md-5 d-flex align-items-center">
                                                                <div className="form-group mb-0 w-100 me-auto">
                                                                    <select
                                                                        className="form-control"
                                                                        name={`${day}_slots[${index}].to_time`}
                                                                        value={slot.to_time}
                                                                        onChange={(e) => handleSlotChange(day, index, "to_time", e.target.value)}
                                                                        disabled={!formValues[day]}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {setTime.map((time, idx) => (
                                                                            <option key={idx} value={time.time}>
                                                                                {time.time}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {formSubmitted && errors[`${day}_slots[${index}].to_time`] && (
                                                                        <div className="text-danger">
                                                                            {errors[`${day}_slots[${index}].to_time`]}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="row pt-2">
                                        <div className="col-lg-8 col-md-6 col-sm-6"></div>
                                        <div className="col-lg-2 col-md-3 col-sm-3 mt-2">
                                            <button type="submit" className="btn btn-primary w-100">
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-3 mt-2">
                                            <button type="button" className="btn btn-danger w-100" onClick={backToList}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
