import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const EllipsisTooltip = ({ text }) => {
  return (
    <span>
      <span className="comment-ellipsis" data-tooltip-id="tooltip" data-tooltip-content={text}>
        {text}
      </span>
      <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
    </span>
  );
};

export default EllipsisTooltip;
