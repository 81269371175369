import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import doctor1 from '../../assets/images/client/01.jpg'
import Wrapper from "../../components/wrapper";
import { clientReview, companyLogo, doctorData, drTimetable, experienceData } from "../../data/data";

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import {FiArrowRight, RiTimeFill, FiPhone, FiMail} from '../../assets/icons/vander'

export default function ViewDoctor(){
    let params = useParams();
    let id = params.id
    let data = doctorData.find((doctor)=>doctor.id === parseInt(id))

    let [activeIndex, setActiveIndex] = useState(1)

    let settings = {
        container: '.slider-range-four',
        items: 4,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 2
            },
            

            320: {
                items: 1
            },
        },
      };
      let settings2 ={
        container: '.client-review-slider',
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
      }

    return(
       <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-md-flex justify-content-between">
                        <h5 className="mb-0">View Doctor</h5>

                        <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
                            <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/doctors">Doctor</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Profile</li>
                            </ul>
                        </nav>
                    </div>

                    <div className="card rounded shadow overflow-hidden mt-4 border-0">
                        <div className="p-5 bg-primary"></div>
                        <div className="avatar-profile d-flex margin-nagative mt-n5 position-relative ps-3">
                            <img src={data?.image ? data.image : doctor1} className="rounded-circle shadow-md avatar avatar-medium" alt=""/>
                            <div className="mt-4 ms-3 pt-3">
                                <h5 className="mt-3 mb-1">{data?.name ? data.name : 'Dr. Calvin Carlo'}</h5>
                                <p className="text-muted mb-0">{data?.title ? data.title : 'Orthopedic'}</p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12 mt-4">
                                <div className="card border-0 rounded-0 p-4">
                                    <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded shadow overflow-hidden bg-light">
                                        <li className="nav-item">
                                            <Link className={`${activeIndex === 1 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={()=>setActiveIndex(1)}>
                                                <div className="text-center pt-1 pb-1">
                                                    <h5 className="mb-0">Overview</h5>
                                                </div>
                                            </Link>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link className={`${activeIndex === 2 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={()=>setActiveIndex(2)}>
                                                <div className="text-center pt-1 pb-1">
                                                    <h5 className="mb-0">Experience</h5>
                                                </div>
                                            </Link>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link className={`${activeIndex === 3 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={()=>setActiveIndex(3)}>
                                                <div className="text-center pt-1 pb-1">
                                                    <h5 className="mb-0">Reviews</h5>
                                                </div>
                                            </Link>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link className={`${activeIndex === 4 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={()=>setActiveIndex(4)} >
                                                <div className="text-center pt-1 pb-1">
                                                    <h5 className="mb-0">Availability</h5>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
        
                                    <div className="tab-content mt-4" id="pills-tabContent">
                                        {activeIndex === 1 ? 
                                            <div className="tab-pane fade show active">
                                                <p className="text-muted">A gynecologist is a surgeon who specializes in the female reproductive system, which includes the cervix, fallopian tubes, ovaries, uterus, vagina and vulva. Menstrual problems, contraception, sexuality, menopause and infertility issues are diagnosed and treated by a gynecologist; most gynecologists also provide prenatal care, and some provide primary care.</p>
                                            
                                                <h6 className="mb-0">Specialties: </h6>
            
                                                <ul className="list-unstyled mt-4">
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Women's health services</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Pregnancy care</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Surgical procedures</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Specialty care</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Conclusion</li>
                                                </ul>
                                            </div> : ''
                                        }
                                        {activeIndex === 2 ? 
                                            <div className="tab-pane fade show active">
                                                <h5 className="mb-1">Experience:</h5>
            
                                                <p className="text-muted mt-4">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also incomprehensible, but it imitates the rhythm of most European languages in Latin script. The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.</p>
                                            
                                                <h6 className="mb-0">Professional Experience:</h6>
            
                                                <div className="row">
                                                    <div className="col-12 mt-4">
                                                        <div className="col-md-12">
                                                            <div className="slider-range-four tiny-timeline">
                                                                <TinySlider settings={settings}>
                                                                    {experienceData.map((item,index) =>{
                                                                        return(
                                                                            <div className="tiny-slide text-center" key={index}>
                                                                                <div className="card border-0 p-4 item-box mb-2 shadow rounded">
                                                                                    <p className="text-muted mb-0">{item.year}</p>
                                                                                    <h6 className="mt-1">{item.name}</h6>
                                                                                    <p className="text-muted mb-0">{item.place}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </TinySlider>    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''
                                        }
                                        {activeIndex === 3 ? 
                                            <div className="tab-pane fade show active">
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-8 text-center">
                                                        <div className="client-review-slider">
                                                            <TinySlider settings={settings2}>
                                                                {clientReview.map((item,index) =>{
                                                                    return(
                                                                        <div className="tiny-slide text-center" key={index}>
                                                                            <p className="text-muted fw-normal fst-italic">{item.desc}</p>
                                                                            <img src={item.image} className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3" alt=""/>
                                                                            <ul className="list-unstyled mb-0">
                                                                                <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                                <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                                <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                                <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                                <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            </ul>
                                                                            <h6 className="text-primary">{item.name} <small className="text-muted">{item.title}</small></h6>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </TinySlider>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div className="row justify-content-center">
                                                    {companyLogo.map((item, index) =>{
                                                        return(
                                                            <div className="col-lg-2 col-md-2 col-6 text-center py-4" key={index}>
                                                                <img src={item} className="avatar avatar-client" alt=""/>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div> : ''
                                        }
                                        {activeIndex === 4 ? 
                                            <div className="tab-pane fade show active">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12">
                                                        <div className="card border-0 p-3 pt-0 rounded shadow">
                                                            <ul className="list-unstyled mb-0">
                                                                {drTimetable.map((item,index) =>{
                                                                    return(
                                                                    <li className="d-flex justify-content-between mt-2 ms-0 mt-3" key={index}>
                                                                        <p className="text-muted mb-0"><RiTimeFill className="text-primary align-middle h5 mb-0"/> {item.day}</p>
                                                                        <p className="text-primary mb-0"><span className="text-dark">Time:</span>{item.time}</p>
                                                                    </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
            
                                                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                                        <div className="card border-0 text-center features feature-primary">
                                                            <div className="icon text-center mx-auto rounded-md">
                                                                <i className="uil uil-phone h3 mb-0"></i><FiPhone className="h3 mb-0"/>
                                                            </div>
                                
                                                            <div className="card-body p-0 mt-4">
                                                                <h5 className="title fw-bold">Phone</h5>
                                                                <p className="text-muted">Great doctor if you need your family member to get effective immediate assistance</p>
                                                                <Link to="tel:+152534-468-854" className="link">+152 534-468-854</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                
                                                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                                        <div className="card border-0 text-center features feature-primary">
                                                            <div className="icon text-center mx-auto rounded-md">
                                                                <i className="uil uil-envelope h3 mb-0"></i><FiMail className="h3 mb-0"/>
                                                            </div>
                                
                                                            <div className="card-body p-0 mt-4">
                                                                <h5 className="title fw-bold">Email</h5>
                                                                <p className="text-muted">Great doctor if you need your family member to get effective immediate assistance</p>
                                                                <Link to="mailto:contact@example.com" className="link">contact@example.com</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </Wrapper>
    )
}