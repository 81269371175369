import React, { useEffect, useState, useRef } from "react";
import SimpleBar from 'simplebar-react';
import { sendChatMessage, userChatHistory } from "../page/my-chat/services/chat-service";
import { AiOutlineSend } from "react-icons/ai";
import { FiPaperclip, FiClock } from 'react-icons/fi';
import { BaseurlForImage } from "../environments/environments";
import Modal from 'react-bootstrap/Modal';
import dummyDocument from '../assets/images/MyImages/documents.png';
import youtube from '../assets/images/MyImages/youtube.png';
import dummyImage from '../assets/images/doctors/blank_profile.png';
import Loader from '../components/loader';
import { debounce } from 'lodash';
const PersonChat = ({ height, patientId }) => {
    console.log("Patient ID:", patientId);
    const [show, setShow] = useState(false);
    const [getUserData, setUserData] = useState(() => {
        const storedData = localStorage.getItem("currentDoctor");
        return storedData ? JSON.parse(storedData) : null;
    });

    const [loading, setLoading] = useState(false);
    const [isChatSelected, setIsChatSelected] = useState(false);
    const [chatPersonData, setChatPersonData] = useState(null);
    const [chatHistoryData, setChatHistoryData] = useState([]);
    const [message, setMessage] = useState('');
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const chatContainerRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (patientId) {
            specificPersonChat(patientId);
        }
    }, [patientId]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistoryData]);

    const specificPersonChat = async (patientId) => {
        try {
            setLoading(true);
            setIsChatSelected(true);
            const data = {
                sender_id: patientId,
                sender_role: 'patient',
                pageNo: 1,
                pageSize: 10
            };
            const response = await userChatHistory(data);
            setChatPersonData(response.data.data.user);
            setChatHistoryData(response.data.data.messages);
        } catch (error) {
            console.error("Error fetching chat history:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendButtonClick = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('receiver_id', patientId);
            formData.append('receiver_role', 'patient');
            formData.append('message', message);
            const response = await sendChatMessage(formData);
            if (response.data.status === "success") {
                setMessage('');
                if (chatPersonData) {
                    await specificPersonChat(patientId);
                }
            }
        } catch (error) {
            console.error("Error sending chat message:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = async (file) => {
        try {
            setLoading(true);
            if (file) {
                const formData = new FormData();
                formData.append('attachment', file);
                formData.append('receiver_id', patientId);
                formData.append('receiver_role', 'patient');
                formData.append('message', "Test");
                const response = await sendChatMessage(formData);
                if (response.data.status === "success") {
                    setMessage('');
                    await specificPersonChat(patientId);
                }
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        handleFileUpload(file);
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageClick = (url) => {
        if (isImage(url)) {
            setPreviewImageUrl(url);
        } else {
            window.open(url, '_blank');
        }
    };

    const isImage = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
        return imageExtensions.includes(extension);
    };

    const isDocument = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        const documentExtensions = ['doc', 'docx', 'pdf', 'txt', 'ppt', 'pptx', 'xls', 'xlsx'];
        return documentExtensions.includes(extension);
    };

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'mkv'];
        return videoExtensions.includes(extension);
    };

    return (
        <>
            <Loader loading={loading} />
            <div className="card chat chat-person border-0 shadow rounded" style={{ maxHeight: height, overflowY: 'auto' }} ref={chatContainerRef}>
                {/* <SimpleBar style={{ height: height }}> */}
                {/* <div className="card-body p-4"> */}
                {chatHistoryData.length === 0 ? (
                    <div className="text-center mt-4">No chat history found</div>
                ) : (
                    <ul className="p-1 list-unstyled mb-0 chat" data-simplebar>
                        {chatHistoryData.map((message, index) => (
                            <li key={index} className={getUserData._id === message?.sender?.sender_id ? 'chat-right' : ''}>
                                <div className="d-inline-block">
                                    <div className="d-flex chat-type mb-3">
                                        {getUserData._id === message?.sender?.sender_id ? (
                                            <>
                                                <div className="d-flex flex-column">
                                                    {/* Display attachment if present */}
                                                    {message.attachment && (
                                                        <div className="px-2 py-2">
                                                            {/* Determine type of attachment (image, document, video) */}
                                                            {isDocument(message.attachment) ? (
                                                                <img
                                                                    src={dummyDocument}
                                                                    className="chat-image-size rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="Document"
                                                                    onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                />
                                                            ) : isVideo(message.attachment) ? (
                                                                <img
                                                                    src={youtube}
                                                                    className="chat-image-size rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="Video"
                                                                    onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={BaseurlForImage + message.attachment}
                                                                    className="chat-image-size rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="Image"
                                                                    onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                />
                                                            )}
                                                        </div>
                                                    )}

                                                    {/* Display message */}
                                                    <div className="chat-msg" style={{ maxWidth: '500px' }}>
                                                        <p className={`small shadow px-3 py-2 rounded mb-1 ${getUserData._id === message?.sender?.sender_id ? 'bg-primary text-white' : 'bg-light text-dark'}`}>
                                                            {message.message}
                                                        </p>
                                                        <small className="text-muted">{new Date(message.createdAt).toLocaleString()}</small>
                                                    </div>
                                                </div>
                                                {/* Display sender's profile image */}
                                                <div className="position-relative ms-2">
                                                    <img
                                                        src={message.sender.role === 'doctor' ? (message.sender.profile_picture ? BaseurlForImage + message.sender.profile_picture : dummyImage) : (chatPersonData.profile_picture ? BaseurlForImage + chatPersonData.profile_picture : dummyImage)}
                                                        className="avatar avatar-md-sm rounded-circle border shadow"
                                                        alt=""
                                                    />
                                                    <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/* Display sender's profile image */}
                                                <div className="position-relative me-2">
                                                    <img
                                                        src={message.sender.role === 'doctor' ? (message.sender.profile_picture ? BaseurlForImage + message.sender.profile_picture : dummyImage) : (chatPersonData.profile_picture ? BaseurlForImage + chatPersonData.profile_picture : dummyImage)}
                                                        className="avatar avatar-md-sm rounded-circle border shadow"
                                                        alt=""
                                                    />
                                                    <i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    {/* Display attachment if present */}
                                                    {message.attachment && (
                                                        <div className="px-2 py-2">
                                                            {/* Determine type of attachment (image, document, video) */}
                                                            {isDocument(message.attachment) ? (
                                                                <img
                                                                    src={dummyDocument}
                                                                    className="chat-image-size rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="Document"
                                                                    onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                />
                                                            ) : isVideo(message.attachment) ? (
                                                                <img
                                                                    src={youtube}
                                                                    className="chat-image-size rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="Video"
                                                                    onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={BaseurlForImage + message.attachment}
                                                                    className="chat-image-size rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="Image"
                                                                    onClick={() => handleImageClick(BaseurlForImage + message.attachment)}
                                                                />
                                                            )}
                                                        </div>
                                                    )}

                                                    {/* Display message */}
                                                    <div className="chat-msg" style={{ maxWidth: '500px' }}>
                                                        <p className={`small shadow px-3 py-2 rounded mb-1 ${getUserData._id === message?.sender?.sender_id ? 'bg-primary text-white' : 'bg-light text-dark'}`}>
                                                            {message.message}
                                                        </p>
                                                        <small className="text-muted">{new Date(message.createdAt).toLocaleString()}</small>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>

                )}
            </div>
            {/* </SimpleBar> */}


            <div className="p-2 rounded-bottom shadow mb-2">
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control border"
                            placeholder="Enter Message..."
                            value={message}
                            onChange={handleMessageChange}
                        />
                    </div>
                    <div className="col-auto">
                        <button
                            className="btn btn-icon btn-primary mx-2"
                            onClick={handleSendButtonClick}
                        >
                            <AiOutlineSend />
                        </button>
                        <button className="btn btn-icon btn-primary mx-2" onClick={handleFileButtonClick}>
                            <FiPaperclip />
                            {/* Hidden file input element */}
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileInputChange}
                            />
                        </button>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default PersonChat;
