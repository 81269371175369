import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Multiselect } from 'multiselect-react-dropdown';
import Wrapper from "../../components/wrapper";
import { doctorData } from "../../data/data";
import Modal from 'react-bootstrap/Modal';
import 'tiny-slider/dist/tiny-slider.css';
import { FiArrowRight, FiEye, FiEdit, RiAspectRatioLine, RiCamera3Fill, RiAwardLine, RiBankCard2Line, RiHome4Line, RiCalendar2Line, RiUser3Line, RiTimeFill, FiPhone, FiMail, RiBuilding4Line, FiPlus } from '../../assets/icons/vander'
import { toast } from "react-toastify";
import axiosInstance from "../../interceptor/interceptor-instance";
import { BaseurlForImage } from '../../environments/environments';
import { format } from 'date-fns';
import PhoneInput from 'react-phone-input-2';
import dummyDocument from '../../assets/images/MyImages/documents.png';
import DocumentsModal from './documentsModal';
// import errorImage from "../../assets/images/doctors/blank_profile.png"
import errorImage from '../../assets/images/MyImages/no_image.png';
import { useTranslation } from 'react-i18next';
import Loader from "../../components/loader";
import ImageLoader from "../../components/imageLoader";
import blankProfile from '../../assets/images/doctors/blank_profile.png';
import SimpleBar from "simplebar-react";

export default function DrProfile(props) {
    const [toggle, setToggle] = useState(false);
    const { t } = useTranslation();
    // const [getUserData, setUserData] = useState(JSON.parse(localStorage.getItem("currentDoctor")) || null);
    const [getUserData, setUserData] = useState(() => {
        const storedData = localStorage.getItem("currentDoctor");
        return storedData ? JSON.parse(storedData) : null;
    });
    // console.log(getUserData._id);

    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getProfileDetails = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`doctor/profile/${getUserData._id}`, '');
            if (response.data.status === 'success') {
                setProfileData(response.data.data.doctor); // Store the response data
                // console.log(response.data.data); // Log the correct response data
                // toast.success(response.data.message);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Get Department Master API Call
    const [departments, setDepartments] = useState([]);

    const getDepartmentMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/departments`, '');
            if (response.data.status === 'success') {
                setDepartments(response.data.data.departments);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    // Get Specialty Master API Call
    const [specialties, setSpecialties] = useState([]);

    const getSpecialtyMaster = async () => {
        try {
            const response = await axiosInstance.get(`/masters/specialities`, '');
            if (response.data.status === 'success') {
                setSpecialties(response.data.data.specialities);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    };

    useEffect(() => {
        getProfileDetails();
        getDepartmentMaster();
        getSpecialtyMaster();
    }, []);

    let [activeIndex, setActiveIndex] = useState(1)

    let settings = {
        container: '.slider-range-four',
        items: 4,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 2
            },


            320: {
                items: 1
            },
        },
    };
    let settings2 = {
        container: '.client-review-slider',
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
    }

    // Personal Details Functions
    const [phoneCode, setPhoneCode] = useState('');
    const [fullPhone, setFullPhone] = useState('');

    let [personalDetailsShow, setPersonalDetailsShow] = useState(false);
    const [personalDetailsFormValues, setPersonalDetailsFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone_number: '',
        DOB: '',
        Gender: '',
        address: ''
    });

    const [personalDetailsFormErrors, setPersonalDetailsFormErrors] = useState({});
    const [personalDetailsFormSubmitted, setPersonalDetailsFormSubmitted] = useState(false);

    // Function to handle input changes and update form values
    const handleInputChange1 = (event) => {
        const { name, value } = event.target;
        setPersonalDetailsFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handlePhoneChange = (value, country) => {
        const { dialCode } = country;
        if (value.startsWith(`${dialCode}`)) {
            const phoneNumber = value.slice(dialCode.length).trim();
            const countryCode = `+${dialCode}`
            setPhoneCode(countryCode)
            setPersonalDetailsFormValues({ ...personalDetailsFormValues, phone_number: phoneNumber });
            setPersonalDetailsFormErrors({ ...personalDetailsFormErrors, phone_number: "" });
            setFullPhone(`${countryCode}${phoneNumber}`)
            console.log(phoneCode)
            console.log(phoneNumber)
            console.log(fullPhone)
        } else {
            const phoneNumber = value;
            const countryCode = `+${dialCode}`
            console.log(countryCode)
            console.log(phoneNumber)
            setPhoneCode(countryCode)
            setPersonalDetailsFormValues({ ...personalDetailsFormValues, phone_number: phoneNumber });
            setPersonalDetailsFormErrors({ ...personalDetailsFormErrors, phone_number: "" });
            setFullPhone(`${countryCode}${phoneNumber}`);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const personalDetailsModalOpen = () => {
        console.log(profileData);
        setPersonalDetailsFormValues({
            firstName: profileData.first_name || '',
            lastName: profileData.last_name || '',
            email: profileData.email || '',
            // phone_number: profileData.mobile || '',
            phone_number: profileData.mobile || '',
            DOB: profileData.dob ? formatDate(profileData.dob) : '',
            Gender: profileData.gender || '',
            address: profileData.address || ''
        });
        setPhoneCode(profileData.phone_code)
        setPersonalDetailsShow(true);
    }


    // Function to validate the form
    const validatePersonalDetailsForm = () => {
        const errors = {};
        if (!personalDetailsFormValues.firstName) {
            errors.firstName = 'First name is required.';
        }

        if (!personalDetailsFormValues.lastName) {
            errors.lastName = 'Last name is required.';
        }

        if (!personalDetailsFormValues.email) {
            errors.email = 'Email is required.';
        }

        if (!personalDetailsFormValues.phone_number) {
            errors.phone_number = 'Phone number is required.';
        }

        if (!personalDetailsFormValues.DOB) {
            errors.DOB = 'DOB is required.';
        }

        if (!personalDetailsFormValues.Gender) {
            errors.Gender = 'Gender is required.';
        }

        if (!personalDetailsFormValues.address) {
            errors.address = 'Address is required.';
        }

        setPersonalDetailsFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    // Function to handle form submission
    const handleSubmitPersonalDetails = async (event) => {
        console.log(personalDetailsFormValues.DOB)
        event.preventDefault();
        setPersonalDetailsFormSubmitted(true);
        // Validate the form
        if (validatePersonalDetailsForm()) {
            console.log('Form is valid, submitting form:', personalDetailsFormValues);
            // Prepare the request data
            const requestData = {
                first_name: personalDetailsFormValues.firstName,
                last_name: personalDetailsFormValues.lastName,
                gender: personalDetailsFormValues.Gender.toLowerCase(),
                dob: personalDetailsFormValues.DOB,
                address: personalDetailsFormValues.address,
                mobile: personalDetailsFormValues.phone_number,
                phone_code: phoneCode,
                country_code: personalDetailsFormValues.phone_code,
                // country: 'Morocco', // Assuming country is Morocco
                // city: 'Casablanca', // Assuming city is Casablanca
                // location: {
                //     coordinates: ['33.5948', '-7.6114'], // Assuming these coordinates for Casablanca
                // },
            };

            try {
                setLoading(true);
                // Send the PUT request
                const response = await axiosInstance.put('/doctor/profile/change', requestData);

                if (response.status === 'success') {
                    console.log("Profile updated successfully");
                    localStorage.setItem('currentDoctor', JSON.stringify(response.data.doctor))
                    // Close the modal since the form is valid
                    setPersonalDetailsShow(false);
                    personalDetailsResetForm();
                }
                setLoading(false);
            } catch (error) {
                setLoading(true);
                console.error("Error while updating profile:", error);
            }
        } else {
            console.log('Form validation failed');
        }
    };

    // Function to reset the personal details form to its default values
    const personalDetailsResetForm = () => {
        // Reset the form values to their initial state
        setPersonalDetailsFormValues({
            firstName: '',
            lastName: '',
            email: '',
            DOB: '',
            Gender: '',
            address: ''
        });
        // Reset the form errors to an empty object
        setPersonalDetailsFormErrors({});
        // Reset the form submission state to false
        setPersonalDetailsFormSubmitted(false);
    };

    // Professional Details Function
    const [professionalDetailsShow, setProfessionalDetailsShow] = useState(false);
    const [professionalDetailsFormValues, setProfessionalDetailsFormValues] = useState({
        departments: [],
        licenseNumber: '',
        experienceYears: '',
        specialties: []
    });
    const [professionalDetailsFormErrors, setProfessionalDetailsFormErrors] = useState({});
    const [professionalDetailsFormSubmitted, setProfessionalDetailsFormSubmitted] = useState(false);

    // Function to handle input changes and update form values
    const handleProfessionalInputChange = (event) => {
        const { name, value } = event.target;
        setProfessionalDetailsFormValues({ ...professionalDetailsFormValues, [name]: value });
    };

    // Function to validate the form
    const validateProfessionalDetailsForm = () => {
        const errors = {};
        if (professionalDetailsFormValues.departments.length === 0) {
            errors.departments = 'Department is required.';
        }
        if (!professionalDetailsFormValues.licenseNumber) {
            errors.licenseNumber = 'Medical License Number is required.';
        }
        if (!professionalDetailsFormValues.experienceYears) {
            errors.experienceYears = 'Years of Experience is required.';
        }
        if (professionalDetailsFormValues.specialties.length === 0) {
            errors.specialties = 'Specialty is required.';
        }
        setProfessionalDetailsFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const professionalModalOpen = () => {
        console.log("Departments from profileData:", profileData.departments);
        console.log("Specialities from profileData:", profileData.specialities);

        // Filter out null or undefined values from departments and specialities arrays
        const filteredDepartments = profileData.departments.filter(dept => dept !== null && dept !== undefined);
        const filteredSpecialities = profileData.specialities.filter(spec => spec !== null && spec !== undefined);

        if (filteredDepartments.length > 0 && filteredSpecialities.length > 0) {
            console.log("Mapped Departments:", filteredDepartments);
            console.log("Mapped Specialities:", filteredSpecialities);

            // Transform the data structure and ensure consistency
            const transformedDepartments = filteredDepartments.map(department => {
                return {
                    id: department.id || department._id,
                    name: department.name
                };
            });

            const transformedSpecialities = filteredSpecialities.map(specialty => {
                return {
                    id: specialty.id || specialty._id,
                    name: specialty.name
                };
            });

            setProfessionalDetailsFormValues({
                departments: transformedDepartments,
                licenseNumber: profileData.medical_license_number || '',
                experienceYears: profileData.experience || '',
                specialties: transformedSpecialities.filter(spec => spec.id !== undefined) // Filter out undefined values
            });
            setProfessionalDetailsShow(true);
        } else {
            console.error("Error: Departments or Specialities data not available");
        }
    };


    // Function to handle form submission
    const handleProfessionalDetailsSubmit = async (event) => {
        event.preventDefault();
        setProfessionalDetailsFormSubmitted(true);

        // Validate the form
        if (validateProfessionalDetailsForm()) {
            console.log('Form is valid, submitting form:', professionalDetailsFormValues);

            // Filter out null and undefined values from departments array before mapping to IDs
            const departmentsIds = professionalDetailsFormValues.departments
                .filter(dept => dept && (dept.id || dept._id))
                .map(dept => dept.id || dept._id);

            // Filter out null and undefined values from specialties array before mapping to IDs
            const specialtiesIds = professionalDetailsFormValues.specialties
                .filter(spec => spec && (spec.id || spec._id))
                .map(spec => spec.id || spec._id);

            // Prepare the data for the PUT request
            const requestData = {
                medical_license_number: professionalDetailsFormValues.licenseNumber,
                experience: professionalDetailsFormValues.experienceYears,
                specialities: specialtiesIds,
                departments: departmentsIds
            };

            try {
                setLoading(true);
                // Send the PUT request
                const response = await axiosInstance.put(`doctor/profile/professional/change`, requestData);
                if (response.data.status === 'success') {
                    console.log("Professional details updated successfully");
                    getProfileDetails();
                    setProfessionalDetailsShow(false);
                    resetProfessionalDetailsForm();
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error while updating professional details:", error);
            }

        } else {
            console.log('Form validation failed');
        }
    };


    // Function to reset the form to its default values
    const resetProfessionalDetailsForm = () => {
        setProfessionalDetailsFormValues({
            departments: [],
            licenseNumber: '',
            experienceYears: '',
            specialties: []
        });
        setProfessionalDetailsFormErrors({});
        setProfessionalDetailsFormSubmitted(false);
    };


    // Documents Functions
    const [documentsShow, setDocumentsShow] = useState({
        address: false,
        medical_certificate: false,
        identity: false,
        other: false
    });

    const [documentsFormValues, setDocumentsFormValues] = useState({
        address: null,
        medical_certificate: null,
        identity: null,
        other: null
    });

    const [previewURLs, setPreviewURLs] = useState({
        address: null,
        medical_certificate: null,
        identity: null,
        other: null,
    });

    const [documentsFormErrors, setDocumentsFormErrors] = useState({});
    const [documentsFormSubmitted, setDocumentsFormSubmitted] = useState(false);

    const handleDocumentsInputChange = (event) => {
        const { name } = event.target;
        const file = event.target.files[0];

        setDocumentsFormValues((prevValues) => ({
            ...prevValues,
            [name]: file,
        }));

        if (file) {
            const previewURL = file.type === 'application/pdf' ? dummyDocument : URL.createObjectURL(file);
            setPreviewURLs((prevURLs) => ({
                ...prevURLs,
                [name]: previewURL,
            }));
        }
    };

    const validateDocumentsForm = (documentType) => {
        const errors = {};
        if (!documentsFormValues[documentType]) {
            errors[documentType] = `${documentType.replace(/([A-Z])/g, ' $1')} is required.`;
        }
        setDocumentsFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleDocumentsSubmit = async (event, documentType) => {
        event.preventDefault();
        setDocumentsFormSubmitted(true);
        if (validateDocumentsForm(documentType)) {
            console.log('Form is valid, submitting form:', documentsFormValues[documentType]);
            const formData = new FormData();
            formData.append('document', documentsFormValues[documentType]);
            formData.append('type', documentType);

            try {
                setLoading(true);
                const response = await axiosInstance.post(`doctor/documents/upload`, formData);
                if (response.status === 'success') {
                    console.log("Document uploaded successfully");
                    getProfileDetails();
                    setDocumentsShow((prevShow) => ({ ...prevShow, [documentType]: false }));
                    resetDocumentsForm();
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error:', error);
            }
        } else {
            console.log('Form validation failed');
        }
    };

    const resetDocumentsForm = () => {
        Object.values(previewURLs).forEach(url => {
            if (url) URL.revokeObjectURL(url);
        });

        setDocumentsFormValues({
            address: null,
            medical_certificate: null,
            identity: null,
            other: null,
        });

        setPreviewURLs({
            address: null,
            medical_certificate: null,
            identity: null,
            other: null,
        });

        setDocumentsFormErrors({});
        setDocumentsFormSubmitted(false);
    };

    const documentTypes = ["identity", "medical_certificate", "address", "other"];
    const [noDocumentsArr] = useState([
        { id: 1, documentImage: errorImage, documentName: 'Identity', type: 'identity' },
        { id: 2, documentImage: errorImage, documentName: 'Medical Certificate', type: 'medical_certificate' },
        { id: 3, documentImage: errorImage, documentName: 'Address', type: 'address' },
        { id: 4, documentImage: errorImage, documentName: 'Other', type: 'other' },
    ]);

    const openModal = (documentType) => {
        console.log(`Opening modal for: ${documentType}`);
        setDocumentsShow((prevShow) => ({ ...prevShow, [documentType]: true }));
    };

    const closeModal = (documentType) => {
        setDocumentsShow((prevShow) => ({ ...prevShow, [documentType]: false }));
        resetDocumentsForm();
    };

    useEffect(() => {
        console.log("Updated documentsShow state:", documentsShow);
    }, [documentsShow]);

    const renderDocumentCard = (document, index) => (
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-4" key={index}>
            <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                <ImageLoader imageUrl={BaseurlForImage + document.document} />
                <div className="card-body p-2 mt-1 text-center">
                    <span className="text-dark">{document.type.replace(/_/g, ' ').toUpperCase()}</span><br />
                    <a href={BaseurlForImage + document.document} target="_blank" rel="noopener noreferrer" className="btn btn-icon btn-pills btn-soft-primary my-2" style={{ marginRight: "12px" }}><FiEye className="icons" /></a>
                    <button className="btn btn-icon btn-pills btn-soft-success mt-1" onClick={() => openModal(document.type)}><FiEdit className="icons" /></button>
                </div>
            </div>
        </div>
    );

    const renderEmptyDocumentCard = (noDocument, index) => (
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-4" key={index}>
            <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                <img src={noDocument.documentImage} style={{ height: '175px', width: '100%' }} alt="" />
                <div className="card-body p-2 mt-1 text-center">
                    <span className="text-dark">{noDocument.documentName.toUpperCase()}</span><br />
                    <button className="btn btn-icon btn-pills btn-soft-success mt-1" onClick={() => openModal(noDocument.type)}><FiPlus className="icons" /></button>
                </div>
            </div>
        </div>
    );

    const [image, setImage] = useState(null);
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/bpg', 'image/bmp', 'image/heic', 'image/heif'];

    const handleFileChange = (files) => {
        if (files.length > 0) {
            const file = files[0];
            if (allowedTypes.includes(file.type)) {
                console.log("Selected file:", file.name);

                // Convert the file to binary format
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    const binaryString = reader.result;
                    // You can set the binary data to state or perform other actions here
                    setImage(binaryString);
                    // Append the binary file to form data
                    const formData = new FormData();
                    formData.append('picture', file, file.name);
                    try {
                        setLoading(true);
                        const response = await axiosInstance.post(`doctor/profile/picture/change`, formData)
                        if (response.status === 'success') {
                            toast.success(response.data.message);
                            getProfileDetails();
                        }
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        console.error('Error fetching data:', error);
                        // toast.error(error.message);
                    }
                };
            } else {
                toast.log("Invalid file type. Please select an image file.");
            }
        }
    };

    const formatDOB = (dob) => {
        return format(new Date(dob), 'dd MMM yyyy');
    };

    const formatShifts = (shifts) => {
        return shifts.map((shift, index) => (
            <span key={index}>
                {shift.start_time} - {shift.end_time}
                {index < shifts.length - 1 && ', '}
            </span>
        ));
    };


    return (
        <Wrapper>
            <Loader loading={loading} />
            {profileData && (
                <div className="container-fluid">
                    <div className="layout-specing fixed-layout-responsive" style={{ width: "-webkit-fill-available" }}>
                        <div className="card shadow mt-4 border-0">
                            <SimpleBar className="profile-simple-bar">
                                <div className="avatar-profile d-flex mt-n5 position-relative ps-3" style={{ margin: "2rem", marginBottom: "0rem" }}>
                                    <div>
                                        <div>
                                            <img src={profileData.profile_picture ? BaseurlForImage + profileData.profile_picture : blankProfile} style={{ border: "2px solid #00bc98" }} className="rounded-circle avatar avatar-custom" alt="" />
                                        </div>
                                        <div className="text-center my-1">
                                            {/* Trigger file input click when the camera icon is clicked */}
                                            <label htmlFor="fileInput" className="btn btn-icon btn-pills btn-soft-primary">
                                                <RiCamera3Fill />
                                            </label>
                                            {/* File input element hidden */}
                                            <input type="file" id="fileInput" style={{ display: "none" }} onChange={(e) => handleFileChange(e.target.files)} accept="image/*" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <h5 className="mt-3 mb-1">Dr. {profileData.first_name} {profileData.last_name}</h5>
                                        <p className="text-muted mb-0">
                                            {profileData.specialities && profileData.specialities.length > 0 ? (
                                                profileData.specialities.map((speciality, index) => (
                                                    <span key={speciality.id}>
                                                        {speciality.name}
                                                        {index < profileData.specialities.length - 1 && ', '}
                                                    </span>
                                                ))
                                            ) : (
                                                'No specialties listed'
                                            )}
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card p-4">
                                            <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded shadow overflow-hidden bg-light">
                                                <li className="nav-item">
                                                    <Link className={`${activeIndex === 1 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setActiveIndex(1)}>
                                                        <div className="text-center pt-1 pb-1">
                                                            <h5 className="mb-0">Personal Details</h5>
                                                        </div>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className={`${activeIndex === 2 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setActiveIndex(2)}>
                                                        <div className="text-center pt-1 pb-1">
                                                            <h5 className="mb-0">Professional Details</h5>
                                                        </div>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className={`${activeIndex === 3 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setActiveIndex(3)}>
                                                        <div className="text-center pt-1 pb-1">
                                                            <h5 className="mb-0">Document</h5>
                                                        </div>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className={`${activeIndex === 4 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setActiveIndex(4)} >
                                                        <div className="text-center pt-1 pb-1">
                                                            <h5 className="mb-0">Availability</h5>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>

                                            <div className="tab-content mt-4" id="pills-tabContent">
                                                {activeIndex === 1 ?
                                                    <div className="tab-pane fade show active">
                                                        <div className="row mb-2">
                                                            <div className="col-xl-9 col-md-6">
                                                                <h5 className="mb-0 custom-text-shadow">Personal Details</h5>
                                                            </div>

                                                            <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                                                <Link onClick={personalDetailsModalOpen} to="" className="btn btn-primary">Update</Link>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><FiMail className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Email :</h6>
                                                                <span>{profileData.email}</span>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><FiPhone className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Mobile Number :</h6>
                                                                <span>{profileData.phone_code} {profileData.mobile}</span>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><RiUser3Line className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Gender :</h6>
                                                                <span>{profileData.gender.toUpperCase()}</span>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><RiCalendar2Line className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;DOB :</h6>
                                                                <span>{formatDOB(profileData.dob)}</span>
                                                            </div>
                                                            {profileData.address && profileData.address.trim() !== "" && (
                                                                <div className="col-lg-12 col-md-8 mt-3">
                                                                    <h6 className="mb-0 custom-text-shadow mb-2"><RiHome4Line className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Address :</h6>
                                                                    <span>{profileData.address}</span>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {/* <ul className="list-unstyled mt-4">
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Women's health services</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Pregnancy care</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Surgical procedures</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Specialty care</li>
                                                    <li className="mt-1 ms-0"><FiArrowRight className="text-primary"/> Conclusion</li>
                                                </ul> */}

                                                        <Modal show={personalDetailsShow} onHide={() => { setPersonalDetailsShow(false); personalDetailsResetForm(); }} size="lg" centered backdrop="static">
                                                            <Modal.Header closeButton>
                                                                <h5 className="modal-title" id="personalModalID">Personal Details</h5>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <form onSubmit={handleSubmitPersonalDetails}>
                                                                    <div className="row">
                                                                        {/* First Name Field */}
                                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">First Name</label>
                                                                                <input
                                                                                    name="firstName"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Enter First Name"
                                                                                    value={personalDetailsFormValues.firstName}
                                                                                    onChange={handleInputChange1}
                                                                                    required=""
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.firstName && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.firstName}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* Last Name Field */}
                                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Last Name</label>
                                                                                <input
                                                                                    name="lastName"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Last Name"
                                                                                    value={personalDetailsFormValues.lastName}
                                                                                    onChange={handleInputChange1}
                                                                                    required=""
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.lastName && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.lastName}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* Email Field */}
                                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Email</label>
                                                                                <input
                                                                                    name="lastName"
                                                                                    type="email"
                                                                                    disabled
                                                                                    className="form-control"
                                                                                    placeholder="Enter Email"
                                                                                    value={personalDetailsFormValues.email}
                                                                                    onChange={handleInputChange1}
                                                                                    required=""
                                                                                    style={{ borderRadius: '50px' }}
                                                                                />
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.email && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.email}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                                                                {/* <PhoneInput
                                                                                country="in"
                                                                                containerClassName="phone-input-contained"
                                                                                enableSearch
                                                                                searchPlaceholder="Search your country"
                                                                                searchNotFound="Country not found"
                                                                                value={personalDetailsFormValues.phone_number}
                                                                                onChange={(value) => handlePhoneChange({ target: { name: 'phone_number', value } })}
                                                                                required=""
                                                                                style={{ borderRadius: "50px" }}
                                                                            /> */}
                                                                                <PhoneInput
                                                                                    country="in"
                                                                                    value={`${phoneCode}${personalDetailsFormValues.phone_number}`}
                                                                                    onChange={handlePhoneChange}
                                                                                    containerClassName="phone-input-contained"
                                                                                    enableSearch
                                                                                    searchPlaceholder="Search your country"
                                                                                    searchNotFound="Country not found"
                                                                                    required=""
                                                                                />
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.phone_number && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.phone_number}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        {/* DOB Field */}
                                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">DOB</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    name="DOB"
                                                                                    value={personalDetailsFormValues.DOB}
                                                                                    onChange={handleInputChange1}
                                                                                    required=""
                                                                                    style={{ borderRadius: "50px" }}
                                                                                />
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.DOB && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.DOB}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* Gender Field */}
                                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                                            <div className="mb-3">
                                                                                <label className="custom-font-label">Gender</label>
                                                                                <div style={{ display: "-webkit-box", marginTop: "5px" }}>
                                                                                    <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                        <div className="form-check mb-0">
                                                                                            <input
                                                                                                className="form-check-input custom-radio-input"
                                                                                                type="radio"
                                                                                                name="Gender"
                                                                                                value="male"
                                                                                                id="flexRadioDefault1"
                                                                                                onChange={handleInputChange1}
                                                                                                checked={personalDetailsFormValues.Gender === 'male'}
                                                                                            />
                                                                                            <label className="form-check-label custom-radio-label" htmlFor="flexRadioDefault1">Male</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="custom-control custom-radio custom-control-inline" style={{ marginRight: "12px" }}>
                                                                                        <div className="form-check mb-0">
                                                                                            <input
                                                                                                className="form-check-input custom-radio-input"
                                                                                                type="radio"
                                                                                                name="Gender"
                                                                                                value="female"
                                                                                                id="flexRadioDefault2"
                                                                                                onChange={handleInputChange1}
                                                                                                checked={personalDetailsFormValues.Gender === 'female'}
                                                                                            />
                                                                                            <label className="form-check-label custom-radio-label" htmlFor="flexRadioDefault2">Female</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                        <div className="form-check mb-0"> 
                                                                                            <input
                                                                                                className="form-check-input custom-radio-input"
                                                                                                type="radio"
                                                                                                name="Gender"
                                                                                                value="other"
                                                                                                id="flexRadioDefault3"
                                                                                                onChange={handleInputChange1}
                                                                                                checked={personalDetailsFormValues.Gender === 'other'}
                                                                                            />
                                                                                            <label className="form-check-label custom-radio-label" htmlFor="flexRadioDefault3">Other</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.Gender && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.Gender}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Address <span className="text-danger">*</span></label>
                                                                                <textarea name="address" id="address" rows="2" className="form-control"
                                                                                    value={personalDetailsFormValues.address}
                                                                                    onChange={handleInputChange1}
                                                                                    required=""></textarea>
                                                                                {personalDetailsFormSubmitted && personalDetailsFormErrors.address && (
                                                                                    <div className="text-danger">{personalDetailsFormErrors.address}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ justifyContent: 'end' }}>
                                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 mt-2">
                                                                            <button type="submit" className="btn btn-primary w-100">Save</button>
                                                                        </div>
                                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 mt-2">
                                                                            <button type="button" className="btn btn-danger w-100"
                                                                                onClick={() => { setPersonalDetailsShow(false); personalDetailsResetForm(); }}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div> : ''
                                                }
                                                {activeIndex === 2 ?
                                                    <div className="tab-pane fade show active">
                                                        <div className="row mb-2">
                                                            <div className="col-xl-9 col-md-6">
                                                                <h5 className="mb-0 custom-text-shadow">Professional Details</h5>
                                                            </div>

                                                            <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                                                <Link to="" onClick={professionalModalOpen} className="btn btn-primary">Update</Link>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><RiBankCard2Line className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Medical License Number :</h6>
                                                                {profileData.medical_license_number ? (
                                                                    <span>{profileData.medical_license_number}</span>
                                                                ) : (
                                                                    <span>{t('Not provided')}</span>
                                                                )}
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><RiAwardLine className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Years Of Experience :</h6>
                                                                {profileData.experience ? (
                                                                    <span>{profileData.experience} Years Of Experience</span>
                                                                ) : (
                                                                    <span>{t('Not provided')}</span>
                                                                )}
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><RiBuilding4Line className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Department :</h6>
                                                                <ul className="list-unstyled mt-4">
                                                                    {profileData.departments && profileData.departments.length > 0 ? (
                                                                        profileData.departments.map((department, index) => (
                                                                            <li className="mt-1 ms-0" key={department.id}>
                                                                                <FiArrowRight className="text-primary" />&nbsp;{department.name}
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        'No departments listed'
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mt-3">
                                                                <h6 className="mb-0 custom-text-shadow mb-2"><RiAspectRatioLine className="text-primary align-middle h5 mb-1" />&nbsp;&nbsp;Specialty :</h6>
                                                                <ul className="list-unstyled mt-4">
                                                                    {profileData.specialities && profileData.specialities.length > 0 ? (
                                                                        profileData.specialities.map((speciality, index) => (
                                                                            <li className="mt-1 ms-0" key={speciality.id}>
                                                                                <FiArrowRight className="text-primary" />&nbsp;{speciality.name}
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        'No specialities listed'
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        {/* <p className="text-muted mt-4">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also incomprehensible, but it imitates the rhythm of most European languages in Latin script. The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.</p>

                                                <h6 className="mb-0">Professional Experience:</h6>

                                                <div className="row">
                                                    <div className="col-12 mt-4">
                                                        <div className="col-md-12">
                                                            <div className="slider-range-four tiny-timeline">
                                                                <TinySlider settings={settings}>
                                                                    {experienceData.map((item, index) => {
                                                                        return (
                                                                            <div className="tiny-slide text-center" key={index}>
                                                                                <div className="card border-0 p-4 item-box mb-2 shadow rounded">
                                                                                    <p className="text-muted mb-0">{item.year}</p>
                                                                                    <h6 className="mt-1">{item.name}</h6>
                                                                                    <p className="text-muted mb-0">{item.place}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </TinySlider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                        {/* Professional Details Modal */}
                                                        {/*  The modal code to update the Professional Details */}
                                                        <Modal show={professionalDetailsShow} onHide={() => { setProfessionalDetailsShow(false); resetProfessionalDetailsForm(); }} size="lg" centered backdrop="static">
                                                            <Modal.Header closeButton>
                                                                <h5 className="modal-title">Professional Details</h5>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <form onSubmit={handleProfessionalDetailsSubmit}>
                                                                    <div className="row">
                                                                        {/* Medical License Number Field */}
                                                                        <div className="col-md-6">
                                                                            <label>Medical License Number</label>
                                                                            <input
                                                                                name="licenseNumber"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Medical License Number"
                                                                                value={professionalDetailsFormValues.licenseNumber}
                                                                                onChange={handleProfessionalInputChange}
                                                                                style={{ borderRadius: "50px" }}
                                                                                required=""
                                                                            />
                                                                            {professionalDetailsFormSubmitted && professionalDetailsFormErrors.licenseNumber && (
                                                                                <div className="text-danger">{professionalDetailsFormErrors.licenseNumber}</div>
                                                                            )}
                                                                        </div>
                                                                        {/* Years Of Experience Field */}
                                                                        <div className="col-md-6">
                                                                            <label>Years Of Experience</label>
                                                                            <input
                                                                                name="experienceYears"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Years Of Experience"
                                                                                value={professionalDetailsFormValues.experienceYears}
                                                                                onChange={handleProfessionalInputChange}
                                                                                style={{ borderRadius: "50px" }}
                                                                                required=""
                                                                            />
                                                                            {professionalDetailsFormSubmitted && professionalDetailsFormErrors.experienceYears && (
                                                                                <div className="text-danger">{professionalDetailsFormErrors.experienceYears}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-3">
                                                                        {/* Department Field */}
                                                                        <div className="col-md-6">
                                                                            <label>Department</label>
                                                                            <Multiselect
                                                                                displayValue="name" // Specify which property of the object to display
                                                                                placeholder="Select Department"
                                                                                options={departments} // Use departments array directly
                                                                                selectedValues={professionalDetailsFormValues.departments.filter(Boolean)} // Filter out null values
                                                                                onSelect={(selectedList) => {
                                                                                    // Filter out null values and update state
                                                                                    const filteredList = selectedList.filter(item => item !== null);
                                                                                    setProfessionalDetailsFormValues({ ...professionalDetailsFormValues, departments: filteredList });
                                                                                    setProfessionalDetailsFormErrors({ ...professionalDetailsFormErrors, departments: "" });
                                                                                }}
                                                                                onRemove={(selectedList) => {
                                                                                    // Filter out null values and update state
                                                                                    const filteredList = selectedList.filter(item => item !== null);
                                                                                    setProfessionalDetailsFormValues({ ...professionalDetailsFormValues, departments: filteredList });
                                                                                    setProfessionalDetailsFormErrors({ ...professionalDetailsFormErrors, departments: "" });
                                                                                }}
                                                                                required=""
                                                                            />
                                                                            {professionalDetailsFormSubmitted && professionalDetailsFormErrors.departments && (
                                                                                <div className="text-danger">{professionalDetailsFormErrors.departments}</div>
                                                                            )}
                                                                        </div>
                                                                        {/* Specialty Field */}
                                                                        <div className="col-md-6">
                                                                            <label>Specialty</label>
                                                                            <Multiselect
                                                                                options={specialties} // Use specialties array directly
                                                                                displayValue="name" // Specify which property of the object to display
                                                                                placeholder="Select Specialty"
                                                                                selectedValues={professionalDetailsFormValues.specialties.filter(Boolean)} // Filter out null values
                                                                                onSelect={(selectedList) => {
                                                                                    // Filter out null values and update state
                                                                                    const filteredList = selectedList.filter(item => item !== null);
                                                                                    setProfessionalDetailsFormValues({ ...professionalDetailsFormValues, specialties: filteredList });
                                                                                    setProfessionalDetailsFormErrors({ ...professionalDetailsFormErrors, specialties: "" });
                                                                                }}
                                                                                onRemove={(selectedList) => {
                                                                                    // Filter out null values and update state
                                                                                    const filteredList = selectedList.filter(item => item !== null);
                                                                                    setProfessionalDetailsFormValues({ ...professionalDetailsFormValues, specialties: filteredList });
                                                                                    setProfessionalDetailsFormErrors({ ...professionalDetailsFormErrors, specialties: "" });
                                                                                }}
                                                                                required=""
                                                                            />
                                                                            {professionalDetailsFormSubmitted && professionalDetailsFormErrors.specialties && (
                                                                                <div className="text-danger">{professionalDetailsFormErrors.specialties}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>



                                                                    <div className="row mt-3">
                                                                        <div className="col-md-12 text-end">
                                                                            <button type="submit" className="btn btn-primary">Save</button>
                                                                            <button type="button" className="btn btn-danger ms-3" onClick={() => { setProfessionalDetailsShow(false); resetProfessionalDetailsForm(); }}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div> : ''
                                                }
                                                {activeIndex === 3 ?
                                                    <div className="tab-pane fade show active">
                                                        <div className="row mb-2">
                                                            <div className="col-xl-9 col-md-6">
                                                                <h5 className="mb-0 custom-text-shadow">Documents</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            {documentTypes.map((type, index) => {
                                                                const document = profileData.documents.find(doc => doc.type === type);
                                                                return document ? renderDocumentCard(document, index) : renderEmptyDocumentCard(noDocumentsArr.find(doc => doc.type === type), index);
                                                            })}

                                                            {documentTypes.map((type) => (
                                                                <DocumentsModal
                                                                    key={type}
                                                                    show={documentsShow[type]}
                                                                    onHide={() => closeModal(type)}
                                                                    title={`${type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())} Proof`}
                                                                    documentType={type}
                                                                    handleDocumentsInputChange={handleDocumentsInputChange}
                                                                    handleDocumentsSubmit={handleDocumentsSubmit}
                                                                    previewURLs={previewURLs}
                                                                    documentsFormErrors={documentsFormErrors}
                                                                    documentsFormSubmitted={documentsFormSubmitted}
                                                                    resetDocumentsForm={resetDocumentsForm}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div> : ''
                                                }
                                                {activeIndex === 4 ?
                                                    <div className="tab-pane fade show active">
                                                        <div className="row mb-2">
                                                            <div className="col-xl-9 col-md-6">
                                                                <h5 className="mb-0 custom-text-shadow">Availability</h5>
                                                            </div>

                                                            <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                                                                {/* <Link to="/settings" className="btn btn-primary">Update</Link> */}
                                                                {profileData.availability.length !== 0 ? (
                                                                    <Link to={`/update-settings/${getUserData._id}`} className="btn btn-primary">{t('Update')}</Link>
                                                                ) : (
                                                                    <Link to={`/settings/${getUserData._id}`} className="btn btn-primary">{t('Set')}</Link>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-5 mt-2">
                                                                <div className="card border-0 p-3 pt-0 rounded shadow">
                                                                    <h6 className="my-2">{t('Availability')}</h6>
                                                                    <ul className="list-unstyled mb-0">
                                                                        {profileData.availability.length !== 0 ? (
                                                                            profileData.availability.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <li className="d-flex justify-content-between mt-2 ms-0 mt-3">
                                                                                            <p className="text-muted mb-0"><RiTimeFill className="text-primary align-middle h5 mb-0" /> {item.day}</p>
                                                                                            <ul className="list-unstyled mb-0">
                                                                                                {item.is_availability ? (
                                                                                                    item.shifts.map((subitem, subIndex) => {
                                                                                                        return (
                                                                                                            <li key={subIndex} className="text-primary">
                                                                                                                <span className="text-dark">Time:</span> {subitem.start_time} - {subitem.end_time}
                                                                                                            </li>
                                                                                                        )
                                                                                                    })
                                                                                                ) : (
                                                                                                    <li key="not-available"><span className="text-danger">Not available</span></li>
                                                                                                )}
                                                                                            </ul>
                                                                                        </li>
                                                                                        {index !== profileData.availability.length - 1 && <hr className="my-3" />}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        ) : (
                                                                            <p className="text-muted mb-0">{t('Not set')}</p>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 mt-2">
                                                                <div className="card border-0 p-3 pt-0 rounded shadow">
                                                                    <h6 className="my-2">{t('Fees')}</h6>
                                                                    {profileData.consultation_fee && profileData.consultation_fee.length > 0 ? (
                                                                        <ul className="list-unstyled mb-0">
                                                                            {profileData.consultation_fee.map((fee) => (
                                                                                <li key={fee._id} className="d-flex justify-content-between mt-2 ms-0 mt-3">
                                                                                    <p className="mb-0 text-dark">
                                                                                        {fee.consultation_type.charAt(0).toUpperCase() + fee.consultation_type.slice(1)}
                                                                                    </p>
                                                                                    <p className="text-primary mb-0">
                                                                                        {parseFloat(fee.fee)} £
                                                                                    </p>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    ) : (
                                                                        <p className="text-muted mb-0">{t('No consultation fees available')}</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper>
    )
}