// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../interceptor/interceptor-instance';

// import { toast } from 'react-toastify';

const AuthContext = createContext();  // Authentication Functionality

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("currentDoctor")) || null); 
    const [token, setToken] = useState(localStorage.getItem("authToken") || "");
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();
    // Check if user is logged in when the component mounts
    // useEffect(() => {
    //   if (user) {
    //     setUser(JSON.parse(localStorage.getItem("currentDoctor")));
    //   }
    // }, [user]);

    // useEffect(() => {
    //   console.log('loggedUser: ' + user)
    // }, [user])

    const login = async (userData) => {
        // Perform login logic, e.g., send login request to backend
        // If login is successful, set the user data in state and local storage
        const body = {
            role: "doctor",
            email: userData.email,
            password: userData.password
        }
        try {
            setLoading(true);
            const response = await axiosInstance.post('doctor/auth/login', body)
            if (response.data.data.doctor.is_verified) {
                // console.log('response: '+response.data.data)
                setUserId(response.data.data.doctor._id)
                setUser(response.data.data.doctor)
                setToken(response.data.data.token)
                localStorage.setItem('authToken', response.data.data.token)
                localStorage.setItem('currentDoctor', JSON.stringify(response.data.data.doctor))
                navigate(`/landing`, { replace: true });
            } else {
                setUserId(response.data.data.doctor._id);
                navigate(`/verify-email`, { replace: true });
            }
            console.log(response.data)
            // toast.success(response.data.message);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
			setLoading(false);
		}
    };

    const emailVerify = async (data) => {
        const verificationOTP = parseInt(data.otp) 
        const body = {
            role: data.role,
            otp: verificationOTP
        }
        try {
            const response = await axiosInstance.patch(`doctor/auth/otp/verify/${userId}`, body);
            setUser(response.data.data.doctor)
            setToken(response.data.data.token)
            localStorage.setItem('authToken', response.data.data.token)
            localStorage.setItem('currentDoctor', JSON.stringify(response.data.data.doctor))
            navigate(`/landing`, { replace: true });
        } catch (error) {
            // navigate(`/reset-password`, { state: { email: email, verified: emailVerified }, replace: true })
            console.error('Error fetching data:', error);
            // toast.error(error.message);
        }
    }

    const logout = () => {
        // Clear user data from state and local storage
        setUser(null);
        setToken('')
        localStorage.removeItem("authToken")
        localStorage.removeItem("currentDoctor");
        localStorage.removeItem("patientData");
        localStorage.removeItem("patientAppointmentType");
        navigate(`/login`)
    };

    return (
        <AuthContext.Provider value={{ user, token, login, emailVerify, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
