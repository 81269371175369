import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import dummyImg from "../../assets/images/MyImages/no_image.png";
import { Multiselect } from 'multiselect-react-dropdown';
import { toast } from "react-toastify";

export default function EditAssistance() {
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        dob: '',
        gender: '',
        age: '',
        imageFile: null,
    });
    const [togglePassword, setTogglePassword] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Get the current date in the format required by the date input element
    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
    };

    // Handle phone number change
    const handlePhoneChange = (phone) => {
        setFormValues({ ...formValues, phone });
        setFormErrors({ ...formErrors, phone: "" });
    };

    // Function to handle image changes
    const handleImageChange = (e) => {
        console.log('handleImageChange called');
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            console.log('File selected:', file);
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/bpg', 'image/bmp', 'image/heic', 'image/heif'];
    
            // Check if the selected file type is allowed
            if (allowedTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImageSource(e.target.result); // Set the image source to the selected image
                    console.log('Image source updated:', e.target.result);
                };
                reader.readAsDataURL(file);
                setImageFile(file);
                console.log('File set:', file);
            } else {
                // Show an error toast message if the file type is not allowed
                toast.error("File type is not allowed. Only png, jpg, jpeg, tiff, bpg, bmp, heic, and heif files are allowed.");
                
                // Reset the image file and image source to the default image
                setImageSource(dummyImg);
                setImageFile(null);
                
                // Clear the file input
                e.target.value = ""; // Set the file input value to an empty string to remove the chosen file
    
                console.log('File type not allowed');
            }
        }
    };
    
    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const errors = {};

        // Validation for required fields
        if (!formValues.firstName.trim()) {
            errors.firstName = "First Name is required.";
        }
        if (!formValues.lastName.trim()) {
            errors.lastName = "Last Name is required.";
        }
        if (!formValues.phone) {
            errors.phone = "Phone Number is required.";
        }
        if (!formValues.dob.trim()) {
            errors.dob = "Date of Birth is required.";
        }
        if (new Date(formValues.dob) > new Date()) {
            errors.dob = "Date of Birth cannot be in the future.";
        }
        if (!formValues.gender) {
            errors.gender = "Gender is required.";
        }
        if (!formValues.age.trim()) {
            errors.age = "Age is required.";
        }

        setFormErrors(errors);

        // If there are no errors, proceed with form submission logic
        if (Object.keys(errors).length === 0) {
            console.log("Form submitted:", formValues);
            // Perform form submission logic here
        }
    };

    // Handle selection and removal for Specific Assistance
    const handleSpecificAssistanceChange = (selectedList) => {
        setFormValues({ ...formValues, specificAssistance: selectedList });
        if (selectedList.length > 0) {
            setFormErrors({ ...formErrors, specificAssistance: "" });
        } else {
            setFormErrors({ ...formErrors, specificAssistance: "At least select one Assistance." });
        }
    };

    // State variables for image handling
    const [imageFile, setImageFile] = useState(null);
    const [imageSource, setImageSource] = useState(dummyImg);

    const navigate = useNavigate();

    const backToList = () => {
        navigate("/staff ");
    };

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-md-flex justify-content-between">
                        <h5 className="mb-0 custom-text-shadow">Edit Staff</h5>
                        <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
                            <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/index">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/staff">Staff</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit Staff</li>
                            </ul>
                        </nav>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mt-4">
                            <div className="card border-0 p-4 rounded shadow">
                                <form className="mt-4" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {/* First Name Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">First Name <span className="text-danger">*</span></label>
                                                <input
                                                    name="firstName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    value={formValues.firstName}
                                                    onChange={handleInputChange}
                                                    required=""
                                                />
                                                {formSubmitted && formErrors.firstName && <div className="text-danger">{formErrors.firstName}</div>}
                                            </div>
                                        </div>

                                        {/* Last Name Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                                <input
                                                    name="lastName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    value={formValues.lastName}
                                                    onChange={handleInputChange}
                                                    required=""
                                                />
                                                {formSubmitted && formErrors.lastName && <div className="text-danger">{formErrors.lastName}</div>}
                                            </div>
                                        </div>

                                        {/* Phone Number Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                                <PhoneInput
                                                    country="in"
                                                    value={formValues.phone}
                                                    onChange={handlePhoneChange}
                                                    containerClassName="phone-input-contained"
                                                    enableSearch
                                                    searchPlaceholder="Search your country"
                                                    searchNotFound="Country not found"
                                                    required=""
                                                />
                                                {formSubmitted && formErrors.phone && <div className="text-danger">{formErrors.phone}</div>}
                                            </div>
                                        </div>

                                        {/* Email Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    value={formValues.email}
                                                    onChange={handleInputChange}
                                                    required=""
                                                />
                                                {formSubmitted && formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                                            </div>
                                        </div>

                                        {/* Date of Birth Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={formValues.dob}
                                                    onChange={handleInputChange}
                                                    name="dob"
                                                    max={getCurrentDate()} // Setting max to current date to restrict future dates
                                                    required=""
                                                />
                                                {formSubmitted && formErrors.dob && <div className="text-danger">{formErrors.dob}</div>}
                                            </div>
                                        </div>

                                        {/* Gender Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Gender <span className="text-danger">*</span></label>
                                                <select
                                                    className="form-select form-control"
                                                    value={formValues.gender}
                                                    onChange={handleInputChange}
                                                    name="gender"
                                                    required=""
                                                >
                                                    <option value="">Select</option>
                                                    <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                                </select>
                                                {formSubmitted && formErrors.gender && <div className="text-danger">{formErrors.gender}</div>}
                                            </div>
                                        </div>                               
                                    </div>

                                    {/* Form submission buttons */}
                                    <div className="row" style={{ justifyContent: 'end', alignItems: 'center' }}>
                                        {/* Age Field */}
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Age <span className="text-danger">*</span></label>
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control"
                                                    placeholder="Enter Your Age"
                                                    value={formValues.age}
                                                    onChange={handleInputChange}
                                                    name="age"
                                                    required=""
                                                />
                                                {formSubmitted && formErrors.age && <div className="text-danger">{formErrors.age}</div>}
                                            </div>
                                        </div>

                                        {/* Profile Image Field */}
                                        <div className="col-md-3 mb-3">
                                        <label className="form-label">Profile Image </label>
                                            <input className="form-control" type="file" onChange={handleImageChange} />

                                        </div>

                                        {/* Profile Image Preview */}
                                        <div className="col-md-1">
                                            <img
                                                src={imageSource}
                                                style={{ objectFit: 'contain' }}
                                                height="75px"
                                                width="75px"
                                                alt="Staff Profile"
                                            />
                                        </div>    
                                        <div className="col-md-2">
                                            <button type="submit" className="btn btn-primary w-100">Update</button>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-danger w-100" onClick={backToList}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
