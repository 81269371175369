import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { markAsPaid } from './service/appointment-service';
import { toast } from 'react-toastify';

const PaymentModal = ({ show, handleClose, handleSave, appointmentData, fetchAppointments }) => {
	const [formValues, setFormValues] = useState({
		amount: '',
		paymentMode: ''
	});

	const initialFormValues = {
        amount: '',
        paymentMode: ''
    };


	useEffect(() => {
		if (appointmentData) {
			console.log('Appointment Data:', appointmentData);
		}
	}, [appointmentData]);

	const [validationErrors, setValidationErrors] = useState({});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const validateForm = () => {
		const errors = {};
		if (!formValues.amount) {
			errors.amount = 'Amount is required';
		}
		if (!formValues.paymentMode) {
			errors.paymentMode = 'Payment mode is required';
		}
		setValidationErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			handleSave(formValues);

			const appointmentId = appointmentData.appointment_id; 
			const paymentData = {
				paid_amount: formValues.amount,
				payment_mode: formValues.paymentMode
			};
			markAsPaid(appointmentId, paymentData)
				.then(response => {
					console.log('Appointment marked as paid successfully:', response);
					// Handle success if needed
					if(response.data.status === "success") {
						toast.success(response.data.message);
						handleClose();
						fetchAppointments();
					}
				})
				.catch(error => {
					console.error('Error marking appointment as paid:', error);
					// Handle error if needed
				});
		}
	};

	const handleModalClose = () => {
        setFormValues(initialFormValues);
        handleClose();
    };

	return (
		<Modal show={show} onHide={handleModalClose} centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Add Payment Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit}>
					<div className="mb-3">
						<label htmlFor="amount" className="form-label">Amount</label>
						<input
							type="text"
							className="form-control"
							id="amount"
							name="amount"
							value={formValues.amount}
							onChange={handleInputChange}
							placeholder="Enter amount"
							style={{ borderRadius: '50px' }}
						/>
						<div className="text-danger">{validationErrors.amount}</div>
					</div>
					<div className="mb-3">
						<label htmlFor="paymentMode" className="form-label">Payment Mode</label>
						<select
							className="form-select form-control"
							id="paymentMode"
							name="paymentMode"
							value={formValues.paymentMode}
							onChange={handleInputChange}
							style={{ borderRadius: '50px' }}
						>
							<option value="">Select Payment Mode</option>
							<option value="cash">Cash</option>
							<option value="card">Card</option>
							<option value="other">Other</option>
						</select>
						<div className="text-danger">{validationErrors.paymentMode}</div>
					</div>
					<div className="row my-2">
						<div className="col-md-12 text-end">
							<button type="submit" className="btn btn-primary">Save</button>
							<button type="button" className="btn btn-danger ms-3" onClick={handleModalClose}>Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default PaymentModal;
