import axiosInstance from "../../../interceptor/interceptor-instance"


export const patientList = (data) => {
    return axiosInstance.post(`/admin/patient`, data);
}

export const addPatient = (data) => {
    return axiosInstance.post(`doctor/patient/add`, data);
}

export const getPatientDetails = (id) => {
    return axiosInstance.get(`admin/patient/profile/${id}`);
}

export const updatePatient = (id, data) => {
    return axiosInstance.put(`admin/patient/change/${id}`, data);
}

export const updateProfilePicture = (data) => {
    return axiosInstance.post(`admin/patient/picture/change`, data);
}