import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import bg from '../../assets/images/MyImages/bg.png';
import colorLogo from '../../assets/images/MyImages/color_logo_500.png';
import loginImg from '../../assets/images/MyImages/login_image.png';
import { useAuth } from "./auth";
import Loader from '../../components/loader';

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [togglePassword, setTogglePassword] = useState(false);
    const { user, login } = useAuth();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if(user) {
        // if(localStorage.getItem('currentDoctor')) {
          navigate(`/landing`);  // Authentication Functionality
        }
      }, [user, navigate])

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(""); // Clear email error when user types
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(""); // Clear password error when user types
    };

    const validateFields = () => {
        let isValid = true;

        // Email validation
        if (!email) {
            setEmailError("Email is required");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            console.log("Invalid email format");
            setEmailError("Please enter a valid email address");
            isValid = false;
        }

        // Password validation
        if (!password) {
            setPasswordError("Password is required");
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form submission
        if (validateFields()) {
            // Perform login or other actions if fields are valid
            // For example, navigate to another page
            const loginData = {email: email, password: password }
            login(loginData);
        }
    };

    return (
        <>
        <Loader loading={loading} />
            <section className="bg-home d-flex bg-light align-items-center login-page-height main-login-wrapper" style={{ backgroundImage: `url(${bg})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="card login-page shadow mt-4" style={{ borderRadius: '5px' }}>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-0 col-sm-0 p-0 d-none d-lg-block" style={{ position: 'relative' }}>
                                            <img src={loginImg} className="w-100 h-auto" style={{ borderRadius: '5px' }} alt="" />
                                            <img src={colorLogo} className="h-auto" style={{ width: '40%', position: 'absolute', top: '8%', left: '30%', transform: 'translate(-50%, -50%)' }} alt="" />
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-sm-12 p-5">
                                            <h4 className="text-left">Welcome to Noorimeds!</h4>
                                            <form className="login-form mt-4" onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">Email <span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control" placeholder="Enter Email" name="email" required="" value={email} onChange={handleEmailChange} />
                                                    {emailError && <div className="text-danger">{emailError}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Password <span className="text-danger">*</span></label>
                                                    <div className="position-relative">
                                                        <input placeholder="Enter Password" className="form-control" autoComplete="current-password" type={togglePassword ? "text" : "password"} onChange={handlePasswordChange} value={password} />
                                                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                                            {togglePassword ? (
                                                                <i className="mdi mdi-eye-off"></i>
                                                            ) : (
                                                                <i className="mdi mdi-eye"></i>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {passwordError && <div className="text-danger">{passwordError}</div>}
                                                </div>
                                                <div className="d-flex justify-content-between mb-5">
                                                    <div>
                                                        <Link to="/forgot-password" style={{color: '#ff0000'}}><u>Forgot Password ?</u></Link>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-primary">Login</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
