import React, { useState } from "react";
import Wrapper from "../../components/wrapper";
import SimpleBar from "simplebar-react";
import { accountData } from "../../data/data";
import { FiDownload, FiEye } from '../../assets/icons/vander'
import { Link } from "react-router-dom";
import client1 from '../../assets/images/client/01.jpg'

export default function AccountDetails() {

    return (
        <Wrapper>
            <div className="container-fluid">
                <div className="layout-specing fixed-layout-responsive" style={{ position: "fixed", width: "-webkit-fill-available" }}>
                    <div className="row">

                        <div className="col-xl-12 col-lg-12 col-md-12 mt-2">
                            <div className="card border-0 p-4 shadow">
                                <SimpleBar style={{ height: '515px' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="bg-primary bg-gradient"></div>
                                            <div className="avatar-profile d-flex mt-n5 position-relative ps-3" style={{ marginBottom: "0.5rem" }}>
                                                <img src={client1} className="rounded-circle avatar avatar-md-md" alt="" />
                                                <div className="mt-1 ms-3">
                                                    <h5 className="mt-3 mb-1">Howard Tanner</h5>
                                                    <p className="text-muted mb-0">UK44123</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <div className="table-responsive shadow">
                                                <table className="table table-center table-striped table-hover bg-white mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-bottom p-3" width="5%">#</th>
                                                            <th className="border-bottom p-3" width="15%">Date</th>
                                                            <th className="border-bottom p-3" width="15%">Amount</th>
                                                            <th className="border-bottom p-3" width="15%">Status</th>
                                                            <th className="border-bottom p-3" width="15%">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accountData.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="p-1">{item.id}</td>
                                                                    <td className="p-1">{item.date}</td>
                                                                    <td className="p-1">{item.amount}</td>
                                                                    <td className="p-1">{item.status}</td>
                                                                    <td className="text-left p-1">
                                                                        <Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiDownload /></Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
