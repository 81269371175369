import axiosInstance from "../../../interceptor/interceptor-instance"

export const createAppointmentEMR = (data) => {
    return axiosInstance.post(`doctor/appointment/emr/create`, data);
}

export const addAllergyEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/allergies', data);
};

export const addVaccinationEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/vaccination', data);
};

export const addLabReportsEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/lab_profile', data);
};

export const addDocumentsEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/documents', data);
};

export const addImagingEMR = (data) => {
    return axiosInstance.post('doctor/appointment/add/imaging', data);
};

export const patientContactHistory = (data) => {
    return axiosInstance.post('doctor/appointment/contact/history', data);
};

export const doctorReferPatient = (data) => {
    return axiosInstance.post('doctor/patient/refer', data);
};
