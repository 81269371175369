import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import './i18n';
import enTranslation from './translation/en.json';
import frTranslation from './translation/fr.json';

// i18next.use(initReactI18next).init({
// 	resources: {
// 		en: {
// 			translation: enTranslation
// 		},
// 		fr: {
// 			translation: frTranslation
// 		}
// 	}
// })

// Multi-lingual  Functionality
i18next.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        fr: { translation: frTranslation },
    },
    lng: localStorage.getItem('language') || 'en', // Default to 'en' if no language is stored
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <I18nextProvider i18n={i18next}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	// </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
